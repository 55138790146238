import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Text } from 'rebass';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import theme from '../../_styles/theme';
import StylingUtils from '../../_app/utils/stylingUtils';
import { Loader } from '../Loader';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';

const HEIGHT = '140px';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: calc(100% - 12px); // scrollbar space
  height: ${HEIGHT};
  bottom: calc(-${HEIGHT} + 4px);
  padding-top: 12px;

  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;

  border: 1px solid ${theme.colors.border};
  border-top: none;

  background-color: ${props => props.bckg || theme.colors.bgNavyBlue};

  box-shadow: 0 12px 14px -5px rgba(0, 0, 0, 0.21);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const ScrollWrapper = styled(Box)`
  width: calc(100% - 17px); // scroll space
  height: calc(100% - 15px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    background-color: ${props => props.scrollbckg};
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${props => props.scrollcolor};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const Suggestion = styled(Box)`
  cursor: pointer;
  padding: 9px 14px 10px;
  font-size: ${theme.fontSizes[2]}px;
  color: ${props =>
    props.inpcolor
      ? StylingUtils.hexToRgbA(props.inpcolor, 0.8)
      : StylingUtils.hexToRgbA(theme.colors.text, 0.8)};

  &:hover {
    color: ${props => (props.inpcolor ? props.inpcolor : theme.colors.text)}
`;

export const Suggestions = ({
  suggestions,
  onSuggestionClicked,
  isSuggestionsFetching,
  primaryColor,
  style,
  inpColor,
}) => {
  const scrollbckg = StylingUtils.hexToRgbA(primaryColor, 0.1);
  const { bckg } = style || {};
  return (
    <Wrapper bckg={bckg}>
      {isSuggestionsFetching ? (
        <Box mt={2}>
          <Loader />
        </Box>
      ) : (
        !isEmpty(suggestions) && (
          <ScrollWrapper
            scrollcolor={StylingUtils.hexToRgbA(primaryColor, 0.65)}
            scrollbckg={scrollbckg}
          >
            {suggestions.map((sug, index) => (
              <Suggestion
                key={`suggestion_${index}`}
                inpcolor={inpColor}
                onClick={() => onSuggestionClicked(sug)}
              >
                {!sug.active && '❎ '}
                {sug.active && '✅ '}
                {sug.label}
              </Suggestion>
            ))}
          </ScrollWrapper>
        )
      )}
      {!isSuggestionsFetching && isEmpty(suggestions) && (
        <Text mt={3} textAlign="center" fontSize={1} color="textSemiVisible">
          <FormattedMessage id="chat.tagging.notfound" />
        </Text>
      )}
    </Wrapper>
  );
};
