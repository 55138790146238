import React from 'react';
import { Box, Text } from 'rebass';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import theme from '../_styles/theme';
import { toHex } from '../_app/utils/otherUtils';
import StylingUtils from '../_app/utils/stylingUtils';
import { AgendaIcon } from '../_assets/icons/agendaIcon';
import ScrollIndicator from '../_assets/icons/scrollIndicator';
import IconDetail from '../components/IconDetail';
import { AgendaContainer } from './AgendaContainer/AgendaContainer';
import { TABLET_BREAKPOINT } from '../_styles/breakpoints';
import { headerPadding } from './index';

const ColLeft = ({ children, gradientBottom, gradientTop }) => (
  <Box
    css={css`
      box-sizing: border-box;
      width: 320px;
      height: 100vh;
      overflow: hidden;
      padding: calc(28px + ${headerPadding}) 30px 20px 30px;

      background-image: linear-gradient(
        to bottom,
        ${gradientTop || theme.colors.backgroundDarker} 11%,
        ${gradientBottom || theme.colors.background}
      );
    `}
  >
    {children}
  </Box>
);

const AgendaContainerWrapper = styled(Box)`
  left: -20px;
  height: 100%;
  overflow-y: auto;
  padding-left: 17px;

  ${StylingUtils.hidingScrollbarFormula()};

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    margin-right: -15px;
  }
`;

export const ColumnLeft = ({
  mainGradient1,
  mainGradient2,
  labelText,
  hideIcons,
  primaryColor,
  hasCustomStyle,
  iconsColor,
  isArchived,
  showScrollIndicator,
  containerRef,
  activeEvent,
  handleScrollHiding,
  setActiveTabIndex,
  handleAgendaInfoButtonClick,
  agendaTimelineStyle,
  agendaItemStyle,
  bubbleStyle,
  breakoutItemStyle,
  chatStyle,
  isLoadingAgenda,
  agendaItems,
  activeAgendaItem,
  changeActiveAgendaItem,
  handleOnBubbleClick,
  showAgendaItemSpeakers,
}) => {
  let scrollIndicator = false;
  if (
    !localStorage.getItem(activeEvent?.id) &&
    containerRef.current &&
    showScrollIndicator
  ) {
    if (containerRef.current.scrollHeight > containerRef.current.clientHeight) {
      scrollIndicator = true;
    } else {
      scrollIndicator = false;
    }
  }

  return (
    <Box
      css={css`
        position: relative;
      `}
    >
      <ColLeft
        gradientBottom={toHex(mainGradient2)}
        gradientTop={toHex(mainGradient1)}
      >
        <Text fontSize={2} fontWeight="almostBold" letterSpacing={1.56}>
          <Box ml="8px">
            <IconDetail
              mt={-1}
              fontColor={toHex(labelText)}
              icon={
                !hideIcons && (
                  <AgendaIcon
                    fill={(hasCustomStyle && toHex(iconsColor)) || primaryColor}
                    width="12px"
                    height="18px"
                  />
                )
              }
              text="AGENDA"
              isArchived={isArchived}
            />
          </Box>
        </Text>
        <AgendaContainerWrapper
          ref={containerRef}
          onScroll={handleScrollHiding}
        >
          <AgendaContainer
            playFunc={setActiveTabIndex}
            infoFunc={handleAgendaInfoButtonClick}
            customStyles={{
              agendaTimelineStyle,
              agendaItemStyle,
              bubbleStyle,
              breakoutItemStyle,
              chatStyle,
            }}
            isLoading={isLoadingAgenda}
            agendaItems={agendaItems}
            activeAgendaItem={activeAgendaItem}
            onSetActive={idx => {
              changeActiveAgendaItem(idx);
            }}
            bubbleStyle={bubbleStyle}
            onBubbleClick={handleOnBubbleClick}
            showSpeakers={showAgendaItemSpeakers}
          />
        </AgendaContainerWrapper>
      </ColLeft>
      <ScrollIndicator
        shadow
        visible={showScrollIndicator && scrollIndicator}
        customStyles={{
          position: 'absolute',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </Box>
  );
};
