import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Select } from '@rebass/forms';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import theme from '../../_styles/theme';
import StylingUtils from '../../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { SendIcon } from '../../_assets/icons/sendIcon';
import { Suggestions } from './Suggestion';

const InputWrapper = styled(Box)`
  position: relative;
  z-index: 999;
`;

const MsgTextInput = styled.textarea`
  background-color: ${props =>
    props.bckgColor ? props.bckgColor : theme.colors.bgNavyBlue};
  color: ${props =>
    props.inpColor
      ? props.inpColor
      : StylingUtils.hexToRgbA(theme.colors.text, 0.9)};
  font-size: ${theme.fontSizes[2]}px;
  font-family: Roboto, Helvetica, Arial, sans-serif;

  width: calc(100% - 12px);
  height: 80px;
  resize: none;

  padding: 14px 30px 20px 13px;

  border: 1px solid ${theme.colors.border};
  border-top-width: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: ${props => props.borderRadius};
  border-bottom-right-radius: ${props => props.borderRadius};
  outline: none !important;

  ${StylingUtils.hidingScrollbarFormula()};

  ::placeholder {
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[1]}px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border-color: red;
    ${props => props.inpColor && `color: ${props.inpColor};`}
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[1]}px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border-color: red;
    ${props => props.inpColor && `color: ${props.inpColor};`}
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[1]}px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border-color: red;
    ${props => props.inpColor && `color: ${props.inpColor};`}
  }

  :-moz-placeholder {
    /* Firefox 18- */
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[1]}px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border-color: red;
    ${props => props.inpColor && `color: ${props.inpColor};`}
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    font-size: ${theme.fontSizes[3]}px;
  }
`;

const SendButton = styled(Box)`
  position: absolute;
  bottom: 20px;
  right: 24px;
  width: 18px;
  height: 18px;

  z-index: 2;

  :hover {
    cursor: pointer;
  }
`;

const CustomSelect = styled(Select)`
  background-color: ${StylingUtils.hexToRgbA(theme.colors.bgNavyBlue, 0.6)};
  border-radius: 5px;
  border: 1px solid ${theme.colors.border};
  color: ${theme.colors.textSemiVisible};
  outline: none !important;

  width: calc(100% - 10px);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    font-size: ${theme.fontSizes[3]}px;
  }
`;

const KEYS_TO_CLOSE_SUGGEST = ['Escape', 'ArrowRight'];
const MAX_NUMBER_OF_CHARACTERS = 1000;

export const tagRegExp = new RegExp(
  // eslint-disable-next-line
  `@[a-zA-Z0-9_()[\\]]+((\\W{0,1})([a-z()]+))?$`,
  'g',
);
// export const tagRegExp = /@[a-zA-Z0-9_()]+((\W{0,1})([a-z()]+))?$/g; // old regex

export const MsgInput = ({
  id,
  onMsgConfirmed,
  primaryColor,
  suggestions,
  onFetchSuggestions,
  onClearSuggestions,
  onTagSuggestExpanded,
  isSuggestionsFetching,
  disableTaggingAutosuggest,
  showParticipants,
  participants,
  customStyles,
}) => {
  const intl = useIntl();
  const PLACEHOLDER = intl.formatMessage({ id: 'chat.tooltip' });
  const [value, setValue] = useState('');
  const [recipientId, setRecipientId] = useState('');
  const [tagSuggestExpanded, setTagSuggestExpanded] = useState(false);
  const [placeholder, setPlaceholder] = useState(PLACEHOLDER);
  const [tags, setTags] = useState([]);
  // const [lastKeyPressed, setLastKeyPressed] = useState(null);

  const { bckgColor, inpColor } = customStyles || {};

  const onFetchSuggestionsDebounced = useCallback(
    debounce(onFetchSuggestions, 300),
    [],
  );

  const handleSendButton = (e, regExp) => {
    if (regExp.test(value)) {
      handleSubmit(e);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (value) {
      setValue('');
      setTags([]);
      onMsgConfirmed(value, recipientId, tags);
    }
  };

  const checkForTags = input => {
    const matched = input.match(tagRegExp);

    if (matched) {
      const noAtSymbol = matched[0].replace('@', '');
      if (!disableTaggingAutosuggest) {
        onFetchSuggestionsDebounced(noAtSymbol);
      }
    }
  };

  const handleSuggestionChosen = val => {
    setTagSuggestExpanded(false);
    onTagSuggestExpanded(false);

    if (isEmpty(tags) || !tags.some(tag => tag.id === val.id)) {
      setTags([...tags, val]);
    }
    const lastTagRegexp = new RegExp('@([^@]*)$', 'g');
    const lastTag = value.match(lastTagRegexp);

    if (lastTag) {
      const newInputText = value.replace(lastTagRegexp, `@${val.value} `);
      setValue(newInputText);
      onClearSuggestions();
    }
  };

  const regExp = /[\S]+/g;
  return (
    <Box>
      {showParticipants && (
        <Box>
          <CustomSelect
            id="recipient_id"
            name="recipient_id"
            value={recipientId}
            onChange={e => setRecipientId(e.target.value)}
          >
            <option value="">
              {intl.formatMessage({ id: 'chat.send_to_all' })}
            </option>
            {participants &&
              participants.map(({ id: pid, name, active }) => (
                <option key={pid} value={pid}>
                  {!active && '❎ '}
                  {active && '✅ '}
                  {name}
                </option>
              ))}
          </CustomSelect>
        </Box>
      )}
      <InputWrapper>
        <MsgTextInput
          id={`msg-input-${id}`}
          name="msg-input"
          maxLength={MAX_NUMBER_OF_CHARACTERS}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            const {
              target: { value: val },
            } = e;
            if (tagSuggestExpanded) {
              checkForTags(val);
            }
            setValue(e.target.value);
          }}
          onFocus={() => {
            setPlaceholder('');
          }}
          onBlur={() => {
            setPlaceholder(PLACEHOLDER);
          }}
          onKeyDown={e => {
            const {
              key,
              target: { value: val },
            } = e;

            if (key === '@') {
              setTagSuggestExpanded(true);
              onTagSuggestExpanded(true, () => setTagSuggestExpanded(false));
            }

            if (
              key === 'Backspace' &&
              value &&
              value[value.length - 1] === '@'
            ) {
              setTagSuggestExpanded(false);
              onTagSuggestExpanded(false);
            }

            if (key === 'Enter' && val) {
              e.preventDefault();
              if (!tagSuggestExpanded && regExp.test(value)) {
                handleSubmit(e);
              } else {
                setTagSuggestExpanded(false);
                onTagSuggestExpanded(false);
                if (!isEmpty(suggestions)) {
                  handleSuggestionChosen(suggestions[0]);
                }
              }
            }
            if (KEYS_TO_CLOSE_SUGGEST.includes(key)) {
              setTagSuggestExpanded(false);
              onTagSuggestExpanded(false);
            }
          }}
          type="text"
          borderRadius={
            !disableTaggingAutosuggest && tagSuggestExpanded ? '0px' : '10px'
          }
          bckgColor={bckgColor}
          inpColor={inpColor}
        />
        <SendButton onClick={e => handleSendButton(e, regExp)}>
          <SendIcon fill={primaryColor} />
        </SendButton>
        {!disableTaggingAutosuggest && tagSuggestExpanded && (
          <Suggestions
            inpColor={inpColor}
            suggestions={suggestions}
            isSuggestionsFetching={isSuggestionsFetching}
            onSuggestionClicked={handleSuggestionChosen}
            primaryColor={primaryColor}
            onClose={() => {
              setTagSuggestExpanded(false);
              onTagSuggestExpanded(false);
            }}
            style={{
              bckg: bckgColor,
            }}
          />
        )}
      </InputWrapper>
    </Box>
  );
};
