import React from 'react';
import { Flex, Button as RebassButton } from 'rebass';
import styled, { css } from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';
import theme from '../../../_styles/theme';
import StylingUtils from '../../../_app/utils/stylingUtils';

const ModalBody = styled.div`
  position: relative;
  padding: 40px 50px;
  max-width: 665px;
  font-size: ${theme.fontSizes[4]}px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.35);
  color: ${theme.colors.textDark};
  font-family: ${theme.fonts.body};

  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${theme.fontSizes[3]}px;
    width: 500px;
  }

  @media (max-width: 555px) {
    width: 320px;
  }
`;

const Header = styled.h1`
  font-size: ${theme.fontSizes[6]}px;
  margin-bottom: 25px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${theme.fontSizes[5]}px;
  }
`;

const Description = styled.p`
  letter-spacing: 1px;
  line-height: 130%;
`;

const ButtonsRow = styled(Flex)`
  width: 100%;
  padding-top: 20px;
`;

const Button = ({ bgColor, children, secondItem, ...props }) => (
  <RebassButton
    type="button"
    css={css`
      margin-left: ${secondItem ? '5px' : '0px'};
      outline: none;
      color: white;
      height: 52px;
      background-color: ${bgColor || theme.colors.primary};
      border-radius: 5px;
      border: 0px;
      justify-content: center;
      align-items: center;
      font-weight: ${theme.fontWeights.emph};
      font-size: 17px;
      &:hover {
        background-color: ${StylingUtils.hexToRgbA(
          bgColor || theme.colors.primary,
          0.74,
        )};
        cursor: pointer;
      }

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        margin-left: 0px;
        margin-top: ${secondItem ? '5px' : '0px'};
      }
    `}
    {...props}
  >
    {children}
  </RebassButton>
);

const ZoomModalContent = ({ zoomUrl, closeModal, primaryColor }) => {
  const openZoomInNewTab = () => {
    window.open(zoomUrl, '_blank');
    closeModal();
  };

  return (
    <ModalBody>
      <Header>
        Your browser may not support all of the features of{' '}
        <span style={{ fontWeight: theme.fontWeights.emph }}>Conference+</span>
      </Header>
      <Description>
        Some features of <b>Zoom meetings</b> might not work as expected. You
        may want to:
        <br /> &nbsp;&nbsp;- Open the meeting in the desktop application
        <br /> &nbsp;&nbsp;- Update your browser to one of the newer browsers
        like{' '}
        <a
          href="https://www.google.com/chrome/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome
        </a>{' '}
        or{' '}
        <a
          href="https://www.mozilla.org/firefox/new/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mozilla Firefox
        </a>{' '}
        for a better user experience
      </Description>
      <ButtonsRow>
        <Flex
          css={css`
            margin-left: auto;
            @media (max-width: ${MOBILE_BREAKPOINT}px) {
              width: 100%;
              flex-direction: column;
            }
          `}
        >
          <Button
            onClick={() => openZoomInNewTab()}
            bgColor={primaryColor || theme.colors.bgLight}
            color="#fff"
          >
            Open in Zoom App
          </Button>
          <Button
            onClick={closeModal}
            bgColor={primaryColor || theme.colors.bgLight}
            color="#fff"
            secondItem
          >
            Cancel
          </Button>
        </Flex>
      </ButtonsRow>
    </ModalBody>
  );
};

export default ZoomModalContent;
