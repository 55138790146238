import { Box } from 'rebass';
import React from 'react';
import styled from '@emotion/styled';
import theme from '../../_styles/theme';

const CheckBoxWrapper = styled(Box)`
  margin: 8px 10px 8px 0px;
  overflow: auto;

  input + div {
    background-color: ${theme.colors.checkBoxTag};
    padding: 13px 40px;
    word-break: break-word;
    border-radius: 25px;
    border: 2px solid ${props => props.mainColor || theme.colors.text};
    color: ${props => props.mainColor || theme.colors.text};
  }

  input:checked + div {
    background-color: ${props => props.mainColor || theme.colors.text};
    color: ${theme.colors.text};
  }

  input:hover + div {
    cursor: pointer;
  }
`;

const LabelStyled = styled.label`
  position: relative;
  text-align: center;
  display: block;
`;
const InputStyled = styled.input`
  position: absolute;
  top: -20px;
`;

const CheckboxTag = ({
  input,
  id,
  meta,
  mainColor,
  checkboxContent,
  ...custom
}) => {
  return (
    <CheckBoxWrapper mainColor={mainColor} mt={2}>
      <LabelStyled htmlFor={id}>
        <InputStyled id={id} {...input} {...custom} />
        <Box>{checkboxContent}</Box>
      </LabelStyled>
    </CheckBoxWrapper>
  );
};

export default CheckboxTag;
