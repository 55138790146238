import { useEffect } from 'react';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';

const ScrolltoWrapper = ({ children, scrollTo, clearScroll, id }) => {
  useEffect(() => {
    if (scrollTo) {
      const presenter = document.getElementById(scrollTo);
      if (presenter) {
        if (window.innerWidth > MOBILE_BREAKPOINT) {
          presenter.scrollIntoView();
          document.getElementById('middleColumnTag').scrollTop -= 177; // scroll additional margin + top bar height (desktop)
        } else {
          document.getElementById(id).scrollTop +=
            presenter.getBoundingClientRect().top - 130; // scroll additional margin + top bar height (mobile)
        }
      }
    }

    return () => {
      clearScroll();
    };
  }, [scrollTo]);

  return children;
};

export default ScrolltoWrapper;
