import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { inject, observer } from 'mobx-react';
import Autolinker from 'autolinker';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { isEmpty, get } from 'lodash';
import { when } from 'mobx';
import { css } from 'styled-components';
import Cookies from 'js-cookie';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { MsgInput } from './MsgInput';
import { LS_KEYS } from '../../_app/utils/LocalStorageManager';
import { Loader } from '../Loader';
import { ChatMsg } from './ChatMsg';
import StylingUtils from '../../_app/utils/stylingUtils';
import theme from '../../_styles/theme';
import Settings from '../../settings';
import { NameInput } from './NameInput';
import { toHex } from '../../_app/utils/otherUtils';
import { POLL_INTERVAL } from '../../_app/constants';

const ChatContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: hidden;
  position: relative;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 18px;
    padding-right: 18px;
    height: auto;
    min-height: calc(
      100% - 100px
    ); // hack - for disclaimer to sit on bottom of page always
  }
`;

const ChatBottom = ({ children, showInput }) => (
  <Flex
    css={css`
      position: relative;
      flex-direction: column;
      height: calc(100% - ${showInput ? '87px' : '0px'});
      ${StylingUtils.animationFadeIn()};
    `}
  >
    {children}
  </Flex>
);

const NoMsgs = ({ children, color }) => (
  <Flex
    css={css`
      justify-content: center;
      flex-grow: 1;
      font-size: ${theme.fontSizes[2]}px;
      color: ${color || StylingUtils.hexToRgbA(theme.colors.text, 0.2)};
      line-height: 1.5em;
    `}
  >
    {children}
  </Flex>
);

const ChatTop = styled(Box)``;

export const ScrollContent = ({
  children,
  scrollColor,
  isMobile,
  ...props
}) => (
  <Box
    css={css`
      overflow: auto;
      min-height: 100%;
      padding-bottom: 30px;
      ${StylingUtils.customScroll(scrollColor)}
    `}
    {...props}
  >
    {children}
  </Box>
);

const NewMessageReceived = styled.div`
  font-size: ${theme.fontSizes[1]}px;
  border-radius: 13px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  width: 75%;
  max-width: 250px;
  height: 25px;
  line-height: 25px;
  padding: 0 15px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  text-align: center;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.7s ease-in-out;
  color: ${({ color }) => color || '#fff'};
  background-color: ${({ bg }) => bg || '#000'};
  cursor: pointer;
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    bottom: 40px;
  }
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    position: fixed;
  }
`;

const LoaderWrapper = styled(Box)`
  position: absolute;
  // top: 200px;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.3);
  background-color: ${props =>
    props.loadingMore ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0)'};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    top: 50px;
  }
`;

const GET_PARTICIPANTS_DEFAULT_INTERVAL = 25000;

let CURRENT_SUBSCRIPTIONS = null;

const CHAT_MESSEGES_COUNT_MAX = 200;

@inject(
  'chatStore',
  'pollStore',
  'homeStore',
  'modalStore',
  'userStore',
  'cableStore',
)
@observer
class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameSet: false,
      newMessageBadge: false,
    };
    this.chatContainerRef = React.createRef();
  }

  async componentDidMount() {
    const {
      channelId,
      chatStore: { getChatArchive, getChatParticipants },
      chatStore,
      homeStore: { activeEvent },
      userStore: { user },
      cableStore,
      chatScrollParentRef,
    } = this.props;

    let chatScrollElem;
    let mobileChat = false;

    if (chatScrollParentRef && chatScrollParentRef.current) {
      chatScrollElem = chatScrollParentRef.current;
      mobileChat = true;
    } else {
      chatScrollElem = document.getElementById('chat-scroll');
    }
    chatScrollElem.addEventListener('scroll', ev =>
      this.checkChatScrollPosition(ev, mobileChat),
    );

    await getChatArchive(channelId);
    await this.chatScrollToTop(true, true);

    const showParticipants =
      activeEvent &&
      user &&
      activeEvent.chat_mode === 'with_moderator_only' &&
      activeEvent.chat_moderator_id === user.id;

    if (showParticipants) {
      await clearInterval(chatStore.getChatParticipantsIntervalHandler);
      getChatParticipants(channelId);
      chatStore.getChatParticipantsIntervalHandler = setInterval(
        () => getChatParticipants(channelId),
        GET_PARTICIPANTS_DEFAULT_INTERVAL,
      );
    }

    await this.pollResubscribe();
    await this.resubscribe();

    when(
      // eslint-disable-next-line react/destructuring-assignment
      () => !isEmpty(get(this.props.userStore, 'anonUser.id')),
      async () => {
        // eslint-disable-next-line no-console
        if (cableStore.CABLE_INSTANCE) {
          cableStore.CABLE_INSTANCE.disconnect();
        }
        CURRENT_SUBSCRIPTIONS = null;
        cableStore.clearStore();
        await this.resubscribe();
      },
    );
  }

  async componentDidUpdate(prevProps) {
    const {
      activeAgendaItem,
      channelId,
      chatStore: { getChatArchive },
    } = this.props;

    if (prevProps.channelId !== channelId) {
      try {
        await getChatArchive(channelId);
        await this.resubscribe();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error: ', e);
      }
    }

    if (prevProps.activeAgendaItem.id !== activeAgendaItem.id) {
      // await this.pollResubscribe();
      await this.chatScrollToTop(true, true);
    }
  }

  async componentWillUnmount() {
    const {
      chatStore,
      pollStore,
      chatScrollParentRef,
      cableStore,
    } = this.props;
    await clearInterval(pollStore.pollQuestionIntervalHandler);
    pollStore.pollQuestionIntervalHandler = null;
    await clearInterval(chatStore.getChatParticipantsIntervalHandler);
    chatStore.getChatParticipantsIntervalHandler = null;
    if (cableStore.CABLE_INSTANCE) {
      if (CURRENT_SUBSCRIPTIONS)
        cableStore.CABLE_INSTANCE.subscriptions.remove(CURRENT_SUBSCRIPTIONS);
      cableStore.CABLE_INSTANCE.disconnect();
    }
    CURRENT_SUBSCRIPTIONS = null;

    let chatScrollElem;
    let mobileChat = false;
    if (chatScrollParentRef && chatScrollParentRef.current) {
      chatScrollElem = chatScrollParentRef.current;
      mobileChat = true;
    } else {
      chatScrollElem = document.getElementById('chat-scroll');
    }
    if (chatScrollElem) {
      chatScrollElem.removeEventListener('scroll', ev =>
        this.checkChatScrollPosition(ev, mobileChat),
      );
    }
  }

  checkChatScrollPosition = (ev, mobileChat) => {
    if (ev.target.scrollTop <= (mobileChat ? 100 : 10)) {
      this.setState({ newMessageBadge: false });
    }
  };

  resubscribe = async () => {
    const {
      channelId,
      chatStore: { addNewMsg },
      cableStore,
      cableStore: { setCable },
    } = this.props;
    console.log(`CHAT: RESUBSCRIBE: current channelId: ${channelId}`);
    if (cableStore.CABLE_INSTANCE == null) {
      await setCable();
    }

    if (!channelId) {
      if (CURRENT_SUBSCRIPTIONS !== null) {
        console.log('CHAT: REMOVE CURRENT SUBSCRIPTIONS. NO CHANNEL');
        cableStore.CABLE_INSTANCE.subscriptions.remove(CURRENT_SUBSCRIPTIONS);
        CURRENT_SUBSCRIPTIONS = null;
      }
    }
    if (channelId) {
      if (
        CURRENT_SUBSCRIPTIONS !== null &&
        !CURRENT_SUBSCRIPTIONS.identifier.includes(channelId)
      ) {
        console.log(
          'CHAT: REMOVE CURRENT SUBSCRIPTIONS. WILL SUBSCRIBE TO NEW CHANNEL',
        );
        cableStore.CABLE_INSTANCE.subscriptions.remove(CURRENT_SUBSCRIPTIONS);
        CURRENT_SUBSCRIPTIONS = null;
      }
      if (CURRENT_SUBSCRIPTIONS == null) {
        console.log(`CHAT: CREATES NEW SUBSCRIPTION for room: ${channelId}`);
        const thiz = this;
        CURRENT_SUBSCRIPTIONS = cableStore.CABLE_INSTANCE.subscriptions.create(
          {
            channel: 'ChatRoomChannel',
            room: channelId,
          },
          {
            async received(msg) {
              console.log('CHAT: MSG RECEIVED: ', { msg });
              await addNewMsg(msg);
              if (!msg.user_reaction) {
                if (thiz.isMineMessage(msg)) {
                  thiz.chatScrollToTop(true);
                }
                thiz.setNewMessageBadge();
              }
            },
          },
        );
      }
    }
  };

  setNewMessageBadge = () => {
    const { chatScrollParentRef, isMobile } = this.props;
    let element;
    if (chatScrollParentRef?.current) {
      element = chatScrollParentRef.current;
    } else {
      element = document.getElementById('chat-scroll');
    }
    if (
      element &&
      element.scrollTop >
        (chatScrollParentRef && isMobile
          ? this.chatContainerRef.current?.offsetTop - 39
          : 0)
    ) {
      this.setState({ newMessageBadge: true });
      setTimeout(() => this.setState({ newMessageBadge: false }), 5000);
    } else {
      this.setState({ newMessageBadge: false });
    }
  };

  isMineMessage = msgObj => {
    const {
      userStore: { user },
    } = this.props;
    const { [LS_KEYS.ANONYM_CHAT_ID]: anonChatId } = Cookies.get();
    const finalUserId = anonChatId || user?.id;
    if (
      msgObj.user?.id === finalUserId ||
      msgObj.anonymous_chat_user?.id === finalUserId
    ) {
      return true;
    }
    return false;
  };

  pollResubscribe = async () => {
    const {
      homeStore: { activeEvent },
      pollStore: { getPollQuestions },
      pollStore,
    } = this.props;

    await clearInterval(pollStore.pollQuestionIntervalHandler);

    await getPollQuestions(activeEvent);
    pollStore.pollQuestionIntervalHandler = await setInterval(
      () => getPollQuestions(activeEvent),
      POLL_INTERVAL,
    );
  };

  chatScrollToTop = async (
    hideNewMessageBadge = false,
    withoutOffsetOnMobile = false,
  ) => {
    const { chatScrollParentRef } = this.props;
    let element;
    let offset = 0;
    if (chatScrollParentRef?.current) {
      element = chatScrollParentRef.current;
      offset = !withoutOffsetOnMobile
        ? this.chatContainerRef.current?.offsetTop - 39
        : 0;
    } else {
      element = document.getElementById('chat-scroll');
    }
    if (element) {
      element.scrollTo(0, offset);
    }
    if (hideNewMessageBadge) this.setState({ newMessageBadge: false });
  };

  wrapMsgWithSyntax = (msg, usersTags) => {
    let wrappedMsg = msg;
    if (usersTags && !isEmpty(usersTags)) {
      usersTags.forEach(usr => {
        const usrTag = `@${usr.value}`;
        const tagRegExp = new RegExp(
          `${usrTag.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`, // escape string for use in regex
          'g',
        );
        wrappedMsg = wrappedMsg.replace(
          tagRegExp,
          `<span class="tag" tagged-user-id="${usr.id}">${usrTag}</span>`,
        );
      });
    }
    return wrappedMsg;
  };

  handleMsgSend = async (msg, recipientId = null, usersTags) => {
    const {
      channelId,
      chatStore: { sendMsg },
      userStore: { user },
    } = this.props;

    const isAnonymous = isEmpty(user);

    const urlsInMsg = Autolinker.parse(msg, {
      urls: true,
    });

    sendMsg(
      {
        message: this.wrapMsgWithSyntax(msg, usersTags),
        chat_room_id: channelId,
        recipient_id: recipientId || '',
        found_links: urlsInMsg.map(item => item.url),
      },
      isAnonymous,
    );
  };

  handleAvatarAdd = () => {
    const {
      modalStore: { setVisibility },
    } = this.props;

    setVisibility(
      true,
      <Settings onClose={() => setVisibility(false)} />,
      true,
    );
  };

  handleNameConfirm = async formValues => {
    const { name } = formValues;
    const {
      userStore: { setAnonUser },
    } = this.props;
    await setAnonUser({ name });
    this.setState({ nameSet: true });
  };

  render() {
    const { nameSet, newMessageBadge } = this.state;
    const {
      chatStore: {
        isLoading,
        isLoadingMore,
        chat,
        chat: { participants },
        chatRoomNumberOfUsers,
        activeChatRoomName: userName,
        chatPagination: { last: lastPage },
        getUserTagSuggestions,
        userTaggingSuggestions,
        isSuggestionsFetching,
        clearSuggestions,
        loadMoreMessages,
        updateReaction,
      },
      homeStore: {
        activeOrganization,
        activeEvent,
        activeEvent: { allow_reporting_messages: allowReportingMessages },
      },
      userStore: { user, anonUser },
      modalStore: { setLayerVisibility, setVisibility },
      chatStyle,
      disableTaggingAutosuggest,
      showInput,
      channelId,
      chatScrollParentRef,
    } = this.props;
    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? `#${activeOrganization.dominant_color}`
        : theme.colors.textSecondary;

    const { [LS_KEYS.ANONYM_CHAT_NAME]: anonChatName } = Cookies.get();
    const { [LS_KEYS.ANONYM_CHAT_ID]: anonChatId } = Cookies.get();
    const finalUserId = anonChatId || user?.id;
    const {
      no_content_text: noContentText,
      chat_input_bckg: chatInputBckg,
      chat_primary: chatPrimary,
      chat_input_text: chatInputTxt,
      buttons_bg: buttonsBg,
      buttons_text_color: buttonsTextColor,
    } = (activeEvent && activeEvent.event_custom_theme) || {};
    const chatBckgHex = toHex(chatInputBckg);
    const chatPrimaryHex = toHex(chatPrimary);
    const noContentTextHex = toHex(noContentText);
    const chatInputTxtHex = toHex(chatInputTxt);
    const buttonsBgHex = toHex(buttonsBg) || primaryColor;
    const showNameInput =
      isEmpty(user) && get(activeEvent, 'public') && !anonChatName && !nameSet;
    const showParticipants =
      activeEvent &&
      user &&
      activeEvent.chat_mode === 'with_moderator_only' &&
      activeEvent.chat_moderator_id === user.id;

    const { chat_moderator_id: chatModeratorId } = activeEvent;

    const chatMessages = (chat.messages || [])
      .sort(
        (a, b) =>
          new Date(b.posted_at).getTime() - new Date(a.posted_at).getTime(),
      )
      .slice(0, CHAT_MESSEGES_COUNT_MAX);

    return (
      <ChatContainer
        pt={showParticipants ? '14px' : '5px'}
        ref={this.chatContainerRef}
      >
        <>
          {showInput && (
            <ChatTop pb="5px">
              {showNameInput ? (
                <NameInput
                  primaryColor={chatPrimaryHex || primaryColor}
                  onNameConfirm={this.handleNameConfirm}
                />
              ) : (
                <MsgInput
                  id={chat.name}
                  onMsgConfirmed={this.handleMsgSend}
                  onFetchSuggestions={query =>
                    getUserTagSuggestions(query, activeEvent.id)
                  }
                  onClearSuggestions={clearSuggestions}
                  onTagSuggestExpanded={setLayerVisibility}
                  isSuggestionsFetching={isSuggestionsFetching}
                  primaryColor={chatPrimaryHex || primaryColor}
                  disableTaggingAutosuggest={disableTaggingAutosuggest}
                  suggestions={userTaggingSuggestions
                    .filter(({ id }) => id !== finalUserId)
                    .map(usSugg => ({
                      label: usSugg.name
                        ? `@${usSugg.name}`
                        : `@${usSugg.first_name} ${usSugg.last_name}`,
                      value: usSugg.name
                        ? usSugg.name
                        : `${usSugg.first_name} ${usSugg.last_name}`,
                      id: usSugg.id,
                    }))}
                  participants={participants}
                  showParticipants={showParticipants}
                  customStyles={{
                    bckgColor: chatBckgHex,
                    inpColor: chatInputTxtHex,
                  }}
                />
              )}
            </ChatTop>
          )}
          <ChatBottom showInput={showInput}>
            {!isEmpty(chat) &&
              isEmpty(get(chat, 'messages')) &&
              showInput &&
              !isLoading && (
                <NoMsgs color={noContentTextHex}>
                  {chatRoomNumberOfUsers &&
                  chatRoomNumberOfUsers === 2 &&
                  userName ? (
                    <FormattedMessage
                      id="chat.roomnomsgs"
                      values={{ userName }}
                    />
                  ) : (
                    <FormattedMessage id="chat.nomsgs" />
                  )}
                </NoMsgs>
              )}
            <ScrollContent
              id="chat-scroll"
              scrollColor={chatPrimaryHex || primaryColor}
            >
              {chatScrollParentRef && chatScrollParentRef.current ? (
                <InfiniteScroll
                  style={{ paddingBottom: '50px' }}
                  pageStart={1}
                  initialLoad={false}
                  loadMore={page => loadMoreMessages(page, channelId)}
                  hasMore={
                    chatMessages.length < CHAT_MESSEGES_COUNT_MAX &&
                    !lastPage &&
                    !isLoadingMore
                  }
                  threshold={500}
                  useWindow={false}
                  getScrollParent={() => chatScrollParentRef.current}
                >
                  {!isEmpty(chat) &&
                    !isEmpty(get(chat, 'messages')) &&
                    !isLoading &&
                    chatMessages.map(msg => (
                      <ChatMsg
                        key={msg.id}
                        msg={msg}
                        userId={user && user.id}
                        onAvatarAdd={this.handleAvatarAdd}
                        chatStyle={chatStyle}
                        amIModerator={showParticipants}
                        myself={user || anonUser}
                        primaryColor={chatPrimaryHex || primaryColor}
                        anonMyself={anonChatName || nameSet}
                        allowEmotes={showInput && !showNameInput}
                        updateReaction={updateReaction}
                        isLogged={showNameInput}
                        setVisibility={setVisibility}
                        moderatorId={chatModeratorId}
                        allowReportMessages={allowReportingMessages}
                      />
                    ))}
                </InfiniteScroll>
              ) : (
                <InfiniteScroll
                  style={{ paddingBottom: '50px' }}
                  pageStart={1}
                  initialLoad={false}
                  loadMore={page => loadMoreMessages(page, channelId)}
                  hasMore={
                    chatMessages.length < CHAT_MESSEGES_COUNT_MAX &&
                    !lastPage &&
                    !isLoadingMore
                  }
                  threshold={500}
                  useWindow={false}
                >
                  {!isEmpty(chat) &&
                    !isEmpty(get(chat, 'messages')) &&
                    !isLoading &&
                    chatMessages.map(msg => (
                      <ChatMsg
                        key={msg.id}
                        msg={msg}
                        userId={user && user.id}
                        onAvatarAdd={this.handleAvatarAdd}
                        chatStyle={chatStyle}
                        amIModerator={showParticipants}
                        myself={user || anonUser}
                        primaryColor={chatPrimaryHex || primaryColor}
                        anonMyself={anonChatName || nameSet}
                        allowEmotes={showInput && !showNameInput}
                        isLogged={showNameInput}
                        setVisibility={setVisibility}
                        updateReaction={updateReaction}
                        moderatorId={chatModeratorId}
                        allowReportMessages={allowReportingMessages}
                      />
                    ))}
                </InfiniteScroll>
              )}
            </ScrollContent>
            {(isLoading || isLoadingMore) && (
              <LoaderWrapper loadingMore={isLoadingMore}>
                <Flex
                  css={css`
                    align-items: center;
                    margin-top: 200px;
                  `}
                >
                  <Loader />
                </Flex>
              </LoaderWrapper>
            )}
          </ChatBottom>
        </>
        <NewMessageReceived
          show={newMessageBadge}
          bg={buttonsBgHex}
          color={toHex(buttonsTextColor)}
          onClick={() => this.chatScrollToTop(true)}
        >
          <FormattedMessage id="chat.newMessage" />
        </NewMessageReceived>
      </ChatContainer>
    );
  }
}

export default Chat;
