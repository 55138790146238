import React from 'react';
import { Flex, Box } from 'rebass';
import { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { SpeakersBubbles } from './SpeakersBubbles';
import { Button } from '../../../components/Button/Button';
import theme from '../../../_styles/theme';
import { PlayIcon } from '../../../_assets/icons/playIcon';

const SpeakersRow = ({
  id,
  speakers,
  showSpeakers,
  lastItem,
  agendaItemStyle,
  onBubbleClick,
  bubbleStyle,
  onSetActive,
  chatStyle,
  infoFunc,
  playFunc,
  timestamp,
  isOnEventBrief,
}) => {
  const showPlayButton = timestamp !== 0;

  const handlePlay = () => {
    onSetActive();
    playFunc(0);

    const middleCol = document.getElementById('middleColumnTag');
    if (middleCol) {
      middleCol.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Flex
      css={css`
        padding-top: ${isOnEventBrief ? '5' : '0'}px;
        align-items: center;
      `}
    >
      {!isEmpty(speakers) && showSpeakers && (
        <SpeakersBubbles
          speakers={speakers}
          bubbleStyle={bubbleStyle}
          onClick={onBubbleClick}
          tooltipStyle={{
            bgColor:
              chatStyle.bgColorActive ||
              agendaItemStyle.buttonsBg ||
              agendaItemStyle.orgPrimaryColor,
          }}
        />
      )}
      {!lastItem && !isOnEventBrief && (
        <Flex
          css={css`
            margin-left: auto;
            max-width: 100px;
            min-width: ${showPlayButton ? '100' : '50'}px;
          `}
        >
          {showPlayButton && (
            <Box
              css={css`
                padding-left: 5px;
                margin-left: auto;
                width: 50px;
              `}
            >
              <Button
                onClick={() => handlePlay()}
                height="28px"
                fontSize="14px"
                bgColor={
                  agendaItemStyle.buttonsBg || agendaItemStyle.orgPrimaryColor
                }
              >
                <PlayIcon />
              </Button>
            </Box>
          )}
          {!agendaItemStyle.hideTabbedSectionsBar && (
            <Box
              css={css`
                margin-left: ${showPlayButton === true ? '0px' : 'auto'};
                padding-left: 5px;
                width: 45px;
              `}
            >
              <Button
                onClick={e => infoFunc(e, `agenda_${id}`)}
                height="28px"
                fontSize="10px"
                bgColor={
                  agendaItemStyle.eventListingButtonsBg ||
                  theme.colors.textDarkSecondary
                }
              >
                INFO
              </Button>
            </Box>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default SpeakersRow;
