import React from 'react';
import { Flex, Box, Image } from 'rebass';
import styled from '@emotion/styled';
import { css } from 'styled-components';
import theme from '../../_styles/theme';
import { REACTION_TYPES } from '../../_app/constants';

const Container = styled(Flex)`
  flex-direction: column;
`;

const Title = styled(Box)`
  font-weight: ${theme.fontWeights.emph};
  font-size: ${theme.fontSizes[2]}px;
`;

const EmojiList = styled(Flex)`
  padding-top: 10px;
`;

export const ReactionSelector = ({ handleReactionClick }) => {
  return (
    <Container>
      <Title>Post a video reaction</Title>
      <EmojiList>
        {REACTION_TYPES.map(reaction => (
          <Image
            onClick={() => handleReactionClick(reaction)}
            key={`reaction_${reaction}`}
            css={css`
              margin-right: 8px;
              height: 30px;
              transition: all 0.2s ease-in-out;
              &:hover {
                cursor: pointer;
                transform: scale(1.2);
              }
            `}
            src={`/images/icons/chat/${reaction}.svg`}
          />
        ))}
      </EmojiList>
    </Container>
  );
};
