/* eslint-disable */
import React from 'react';

export const SponsorIcon = ({ fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="28" viewBox="0 0 21 12" {...props}>
    <g fill={fill || '#fff'}>
      <path d="M12.24 9c-.457 0-.914-.157-1.189-.394l-.914-.787c-.183-.158-.183-.394 0-.551.183-.158.457-.158.64 0l.914.787c.275.236.823.236 1.189 0 .366-.315.366-.709 0-1.024L10.137 4.67c-.183-.157-.183-.393 0-.55.183-.158.457-.158.64 0L13.52 6.48c.64.551.64 1.496 0 2.126-.366.237-.823.394-1.28.394zM2.465 3c-.173 0-.346-.067-.433-.2-.086-.2 0-.333.26-.466l3.722-1.198c.779-.267 1.731-.133 2.337.333l.52.4c.172.132.172.332 0 .465-.174.133-.434.133-.607 0l-.519-.333c-.346-.266-.952-.333-1.385-.2L2.638 3h-.173z"/>
      <path d="M10.5 4c-.41 0-.74-.146-1.068-.365-.329-.365-.493-.803-.411-1.24.082-.438.329-.803.822-.95l2.878-1.24c.575-.219 1.15-.292 1.726-.073l4.276 1.533c.246.073.328.292.246.51-.082.22-.329.292-.575.22L14.118.861c-.328-.146-.74-.146-.986 0l-2.878 1.24c-.246.074-.41.22-.41.438 0 .22 0 .365.164.511.246.22.575.22.822.146l2.548-1.167c.165-.073.412 0 .576.146.082.146 0 .364-.164.51l-2.55 1.168c-.246.073-.492.146-.74.146zM11.353 11c-.428 0-.856-.18-1.112-.54L9.128 9.293c-.17-.18-.17-.45 0-.63.171-.179.428-.179.6 0l1.112 1.17c.256.27.77.27 1.112 0 .342-.27.257-.81 0-1.17l-.856-.898c-.17-.18-.17-.45 0-.63.171-.179.428-.179.6 0l.855.9c.599.629.599 1.708 0 2.427-.342.36-.77.539-1.198.539z"/>
      <path d="M8.33 12c-.433 0-.78-.163-1.127-.408L5.21 9.878c-.78-.654-1.735-.98-2.775-.98-.26-.082-.434-.245-.434-.49S2.173 8 2.434 8c1.214 0 2.428.408 3.382 1.224L7.81 10.94c.347.245.78.245 1.128 0 .347-.327.347-.735 0-1.061L8.07 9.06c-.173-.163-.173-.408 0-.571.174-.163.434-.163.608 0l.867.816c.607.572.607 1.551 0 2.204-.347.327-.78.49-1.214.49zM16.044 9c-.3 0-.501 0-.802-.082l-1.904-.49c-.3-.081-.4-.326-.3-.49.1-.244.4-.326.6-.244l1.905.49c.5.163 1.102.081 1.503-.245l1.102-.817c.2-.163.501-.163.702 0 .2.164.2.409 0 .572l-1.103.816c-.6.327-1.202.49-1.703.49z"/>
      <path d="M20.625 10h-2.25C18.15 10 18 9.82 18 9.55v-8.1c0-.27.15-.45.375-.45h2.25c.225 0 .375.18.375.45v8.1c0 .27-.15.45-.375.45zm-1.875-.9h1.5V1.9h-1.5v7.2zM2.625 10H.375C.15 10 0 9.82 0 9.55v-8.1C0 1.18.15 1 .375 1h2.25C2.85 1 3 1.18 3 1.45v8.1c0 .27-.15.45-.375.45zM.75 9.1h1.5V1.9H.75v7.2z"/>
    </g>
  </svg>
);
