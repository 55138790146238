import get from 'lodash/get';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Flex, Image, Text } from 'rebass';
import Box from '@material-ui/core/Box';
import { inject, observer } from 'mobx-react';
import theme from '../../../_styles/theme';
import StylingUtils from '../../../_app/utils/stylingUtils';
import { CloseIcon } from '../../../_assets/icons/close';

// TODO: check on safari

const CUSTOM_MOBILE_BREAKPOINT = 960;

const Card = styled(Flex)`
  position: relative;
  background-color: ${theme.colors.card};
  border-radius: 10px;
  // overflow: hidden;

  width: 80vw;
  max-height: 90vh;

  box-shadow: 0 14px 16px -6px rgba(0, 0, 0, 0.43);

  ${StylingUtils.animationFadeIn()}
  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    width: 94vw;
    height: 78vh;
  }
`;

const HeaderWrapper = styled(Box)`
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 127px;
  width: 100%;
  overflow-y: hidden;
  background: ${theme.colors.bgNavyBlue};
`;

const HeaderImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const Content = styled(Flex)`
  flex-direction: row;
  position: relative;
  width: 100%;

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    height: 100%;
    flex-direction: column;
  }
`;

const Left = styled(Box)`
  position: relative;

  width: 330px;
  height: 100%;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    width: 100%;
    max-height: 360px;
  }
`;

const PortraitWrapper = styled(Box)`
  position: absolute;
  top: 40px;
  right: 40px;
  height: 173px;
  width: 171px;
  overflow: hidden;

  border: 8px solid white;

  box-shadow: 0 14px 16px -6px rgba(0, 0, 0, 0.43);

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    left: 0; // dont get it, but it does the job
    margin: 0 auto;
    height: calc(100vw - 120px); // yes - vw to keep 1:1 aspect ratio
    width: calc(100vw - 120px);
    max-width: 300px;
    max-height: 300px;
  }
`;

const Portrait = styled(Image)`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  max-width: initial;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Right = styled(Flex)`
  flex-direction: column;
  position: relative;
  width: calc(100% - 330px);
  padding: 160px 47px 30px 0;
  overflow-y: hidden;

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    padding: 8px 25px 30px 25px;
    width: 100%;
  }
`;

const CardTitle = ({ children, textColor }) => (
  <Text
    css={css`
      font-size: ${theme.fontSizes[6]}px;
      font-weight: ${theme.fontWeights.heading};
      color: ${textColor || theme.colors.textDark};
      margin-bottom: 13px;

      @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
        font-size: ${theme.fontSizes[5]}px;
      }
    `}
  >
    {children}
  </Text>
);

const CardSubtitle = styled(Text)`
  font-size: ${theme.fontSizes[3]}px;
  font-weight: ${theme.fontWeights.heading};
  color: ${theme.colors.textDarkSecondary};
  line-height: 1.2em;
  letter-spacing: 0.9px;

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    font-size: ${theme.fontSizes[2]}px;
  }
`;

const Separator = styled(Box)`
  background: ${theme.colors.borderLight};
  width: 100%;
  height: 1px;
  margin: 20px 0;
  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    margin: 8px 0;
  }
`;

const Main = styled(Box)`
  flex-grow: 1;
  position: relative;
  width: 100%;

  max-height: 25vh;
  overflow-y: scroll;

  ${props => props.customScroll}

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    height: 30vh;
  }
`;
const CardText = styled(Text)`
  // position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
  padding-right: 20px;

  overflow-y: scroll;

  ${StylingUtils.hidingScrollbarFormula()};
`;

const Name = styled(Text)`
  font-size: ${theme.fontSizes[3]}px;
  font-weight: ${theme.fontWeights.heading};
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Job = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.emph};
  line-height: 1.2em;
`;

const CompanyName = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.emph};
  line-height: 1.2em;
`;

const IconWrapper = styled(Box)`
  position: absolute;
  display: ${props => (props.isLeft ? 'none' : 'block')};

  top: 15px;
  right: 15px;

  cursor: pointer;
  transform: scale(0.7);

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    top: 10px;
    right: 15px;
    z-index: 2;
    transform: scale(0.8);
    display: ${props => (props.isRight ? 'none' : 'block')};
  }
`;

export const SpeakerPopup = ({
  primaryColor,
  scrollColor,
  name,
  job,
  companyName,
  image,
  bio,
  headerImg,
  onClose,
  isMobile,
}) => (
  <Card>
    <HeaderWrapper>
      {headerImg && <HeaderImage src={headerImg} />}
    </HeaderWrapper>
    <Content isMobile={isMobile}>
      <Left>
        <IconWrapper onClick={onClose} isLeft>
          {/* used on mobile layout */}
          <CloseIcon fill="#fff" />
        </IconWrapper>
        <PortraitWrapper>
          <Portrait src={get(image, 'thumb.url')} key={get(image, 'id')} />
        </PortraitWrapper>
      </Left>
      <Right primaryColor={primaryColor}>
        <IconWrapper onClick={onClose} isRight>
          {/* used on desktop layout */}
          <CloseIcon fill="#fff" />
        </IconWrapper>
        <CardTitle textColor={theme.colors.textDark}>{name}</CardTitle>
        <CardSubtitle>
          {job && <Text>{job}</Text>}
          {companyName && <Text>{companyName}</Text>}
        </CardSubtitle>
        <Separator />
        <Main
          customScroll={StylingUtils.customScroll(
            scrollColor || theme.colors.textDarkSecondary,
            {
              bckg: 'transparent',
            },
          )}
        >
          <CardText>
            <div
              dangerouslySetInnerHTML={{
                __html: bio,
              }}
            />
          </CardText>
        </Main>
      </Right>
    </Content>
  </Card>
);

const Container = styled(Box)`
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  margin-bottom: 20px;
  margin-right: 10px;

  width: 200px;

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    width: 100%;
    overflow: hidden;
  }
`;
const SpeakerThumbnail = styled('div')`
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 200px;
  background-color: ${theme.colors.backgroundDarker};

  margin: 0 20px 15px 0;

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    margin-bottom: 12px;
    overflow: hidden;
  }
`;

const SpeakerImage = styled('img')`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;

  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media (max-width: ${CUSTOM_MOBILE_BREAKPOINT}px) {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: 80vw;
  }
`;

const Speaker = ({
  image,
  name,
  job,
  company_name: companyName,
  bio,
  primaryColor,
  scrollColor,
  headerImg,
  isMobile,
  modalStore: { setVisibility },
}) => {
  return (
    <Container
      onClick={() =>
        !!bio &&
        setVisibility(
          true,
          <SpeakerPopup
            primaryColor={primaryColor}
            scrollColor={scrollColor}
            image={image}
            job={job}
            name={name}
            companyName={companyName}
            bio={bio}
            headerImg={headerImg}
            onClose={() => setVisibility(false)}
            isMobile={isMobile}
          />,
        )
      }
      clickable={bio && !!bio.toString()}
    >
      <SpeakerThumbnail>
        {get(image, 'thumb.url') && (
          <SpeakerImage src={get(image, 'thumb.url')} />
        )}
      </SpeakerThumbnail>
      {name && <Name>{name}</Name>}
      {job && <Job>{job}</Job>}
      {companyName && <CompanyName>{companyName}</CompanyName>}
    </Container>
  );
};

export default inject('modalStore')(observer(Speaker));
