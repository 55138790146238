import React from 'react';
import { EventListItem } from '../../components/SideMenu/EventsList/EventListItem';

export const EventTile = ({
  name,
  onClick,
  status,
  bgColor,
  buttonsTextColor,
  isTablet,
  eventListingButtonsBg,
  ...event
}) => {
  return (
    <>
      <EventListItem
        isTablet={isTablet}
        onClick={onClick ? () => onClick(event) : null}
        {...event}
        name={name}
        status={status}
        bgColor={bgColor}
        buttonsTextColor={buttonsTextColor}
        eventListingButtonsBg={eventListingButtonsBg}
      />
    </>
  );
};
