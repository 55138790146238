import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu';
import styled from '@emotion/styled';
import { css } from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import { EventsInfo } from './EventsList/EventsInfo';
import { EventsList } from './EventsList/EventsList';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { Loader } from '../Loader';
import routes from '../../_app/routing/routes';
import { DefaultAvatar } from '../../_assets/icons/defaultAvatar';
import { MenuIcon } from '../../_assets/icons/menuIcon';
import { LogoutIcon } from '../../_assets/icons/logoutIcon';
import theme from '../../_styles/theme';
import { LS_KEYS } from '../../_app/utils/LocalStorageManager';
import { toHex } from '../../_app/utils/otherUtils';
import StylingUtils from '../../_app/utils/stylingUtils';
import { Avatar, SettingsLink } from '../HeaderBar/HeaderBar';
import Settings from '../../settings';

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    zIndex: 1000,
    width: '38px',
    height: '30px',
    left: '23px',
    top: '13px',
    padding: '6px',
  },

  bmMenuWrap: {
    background: `linear-gradient(to right, #181d23, ${theme.colors.bgDark})`,
  },

  bmMenu: {
    height: '100vh',
  },
  bmItemList: {
    height: '100%',
  },
  bmItem: {
    display: 'block',
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat',
  },
};

const EventsContainer = styled(Box)`
  margin: 18px 33px 45px 47px;
`;

const CloseButtonContainer = styled(Flex)`
  cursor: pointer;
  align-items: center;
  height: 57px;
`;

const CloseText = styled(Text)`
  margin-left: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 2.07;
`;

const SignInOut = styled(Flex)`
  margin: 48px 33px 0 33px;
  padding-left: 14px;
  padding-bottom: 80px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 180px;
  }
`;

const LogoutIconWrapper = styled(Box)`
  margin-left: 6px;
  margin-right: 22px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ScrollContainer = styled(Box)`
  overflow: auto;
  height: 100%;
  height: -webkit-fill-available;

  ${props => props.customScrollFormula}
`;

const EventsListButton = ({
  customStyle,
  children,
  clickable,
  active,
  status,
  isOnEventList,
  ...props
}) => (
  <Flex
    css={css`
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      // border-radius: ${status ? '15px' : '5px'};
      margin-bottom: 22px;
      background-color: ${(customStyle && customStyle.bckg) || '#042840'};
      color: ${(customStyle && customStyle.textColor) || '#fff'};
      padding: 10px 12px 10px 12px;
      margin: 0px 33px 0px 47px;
      text-transform: uppercase;
      word-break: break-word;
      border: ${
        isOnEventList
          ? `4px ${(customStyle && customStyle.activeEventColor) ||
              '#fff'} solid`
          : 'none'
      };
    `}
    {...props}
  >
    {children}
  </Flex>
);

@inject(
  'authStore',
  'eventsStore',
  'eventBriefStore',
  'organizationsStore',
  'homeStore',
  'pollStore',
  'userStore',
  'networkingStore',
  'modalStore',
  'exploreItemsStore',
  'chatStore',
  'chatRoomsStore',
)
@observer
class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  openProfileEdit = () => {
    this.closeMenu();
    const {
      modalStore: { setVisibility },
    } = this.props;

    setVisibility(
      true,
      <Settings onClose={() => setVisibility(false)} />,
      true,
    );
  };
  /* eslint-disable */
  handleEventClick = async event => {
    const {
      history,
      userStore: { hasAcceptedAttendance },
      eventBriefStore: { getEventBrief },
      homeStore: { setActiveEvent, activeEvent, resetVideoReactions },
      pollStore: { clearStore: clearPollStore, getPollQuestions },
      authStore: { isAuthenticated },
      networkingStore: { getParticipants, clearNetworkingStore },
      eventsStore: { getEventTranslations },
      exploreItemsStore: { getExploreItems },
      exploreItemsStore,
      chatStore: {
        clearStore: clearChatStore,
        setActiveChatRoomId,
        setActiveChatRoomName,
        setActiveChatNumberOfUsers,
      },
      chatRoomsStore: {
        clearStore: clearChatRoomsStore,
        getChatRooms,
      }
    } = this.props;

    resetVideoReactions();
    exploreItemsStore.clearStore();

    let {
      location: { search, pathname },
    } = window;

    if (!activeEvent || (event && event.id !== toJS(activeEvent.id))) {
      const isSignIn = pathname.includes('/sign-in');
      const isSignUp = pathname.includes('/sign-up');
      const isResetPassword = pathname.includes('/reset-password');
      const isResetPasswordFinish = pathname.includes('/reset-password-finish');

      const hideEventAccess =
        get(event, 'hide_registration_form') && get(event, 'hide_login_form');

      let whereTo = '';
      if (isAuthenticated && !hideEventAccess) {
        if (event.public) {
          whereTo = '/event';
          search = search ? `${search}&enter=true` : `?enter=true`;
        } else {
          if (hasAcceptedAttendance(event.id)) {
            whereTo = '/event';
            search = '';
          } else {
            whereTo = '/sign-up';
            search = '';
          }
        }
      } else {
        whereTo = '/sign-up';
      }

      if (whereTo !== '/event') {
        if (isSignIn || isResetPasswordFinish) {
          whereTo = '/sign-in';
        }
        if (isSignUp || isResetPassword) {
          whereTo = '/sign-up';
        }

        // commented to not stay on password reset pages after changing event

        // if (isResetPassword) {
        //   whereTo = '/reset-password';
        // }
        // if (isResetPasswordFinish) {
        //   whereTo = '/reset-password-finish';
        // }
      }

      history.push(`${whereTo}/${get(event, 'slug', '')}${search || ''}`);
      if (whereTo !== '/event') {
        await getEventBrief({ eventId: event.slug });
      }

      clearNetworkingStore();
      clearChatStore();
      clearChatRoomsStore();
      await setActiveEvent(event);
      await getExploreItems(event.id);
      await getEventTranslations(event.id);
      await setActiveChatRoomId(event.chat_room_id);
      await setActiveChatRoomName('GROUP CHAT');
      setActiveChatNumberOfUsers(event.users_count);
      await clearPollStore();
      await getPollQuestions(event);
      await getParticipants({}, 1);
      await getChatRooms(event.id);
    }

    this.closeMenu();
  };
  /* eslint-enable */

  closeMenu = () => {
    /* walkaround for closing menu */
    const element = document.getElementsByClassName('bm-overlay')[0];
    element.click();
  };

  handleLogout = async () => {
    const {
      history: {
        push,
        location: { search, pathname },
      },
      authStore: { logout },
    } = this.props;
    await logout({});
    const eventSlug = pathname.split('/')[2]; // because cannot access 'match: {params}' from this component
    push({ pathname: `${routes.auth.signIn}/${eventSlug || ''}`, search });
    this.closeMenu();
  };

  handleMenuToggle = () => {
    const html = document.querySelector('html');
    html.classList.toggle('lock-html-overflow');
  };

  render() {
    const { width, chosenOrganization } = this.state;
    const {
      history: {
        location: { pathname },
      },
      homeStore: { activeEvent, activeAgendaItem, activeOrganization },
      authStore: { isAuthenticated },
      eventsStore: { events, isLoading: isLoadingEvents, getClosestEvent },
      eventBriefStore: { event: eventFromBrief },
      userStore: { user, anonUser },
    } = this.props;

    const {
      [LS_KEYS.ANONYM_CHAT_NAME]: anonChatName,
      [LS_KEYS.ANONYM_CHAT_USER_AVATAR]: anonAvatar,
    } = Cookies.get();
    const { name: storeAnonName, avatar: storeAnonAvatar } = anonUser || {};
    const avatarUrl = get(user || anonUser, 'avatar.thumb.url');
    const finalAnonUsername = storeAnonName || anonChatName;
    const finalAnonAvatar = get(storeAnonAvatar, 'thumb.url') || anonAvatar;
    const isAnonUser = !!finalAnonUsername;
    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : '#fff';

    let closestFutureEvent;
    if (events && events.length > 0)
      closestFutureEvent = getClosestEvent(events);

    const isOnEventList = /(^\/$|^\/reset-password$|^\/reset-password-finish$)/.test(
      pathname,
    );

    const pastEvents = events.filter(ev =>
      moment(ev.agenda_end_datetime).isBefore(moment()),
    );
    const upcomingEvents = events.filter(ev =>
      moment(ev.agenda_end_datetime).isSameOrAfter(moment()),
    );
    const isMobile = width < MOBILE_BREAKPOINT;

    const finalOrganization = chosenOrganization || activeOrganization;

    const theEvent = activeEvent || eventFromBrief;

    const eventTheme = StylingUtils.mergeThemesArray([
      get(theEvent, 'event_custom_theme'),
      get(activeAgendaItem, 'agenda_item_custom_theme'),
    ]);

    let finalTheme;
    if (!isOnEventList) {
      finalTheme = eventTheme;
    } else if (closestFutureEvent) {
      finalTheme = closestFutureEvent.event_custom_theme;
    }

    const {
      sidebar_gradient2: sidebarGradient2,
      sidebar_gradient1: sidebarGradient1,
      sidebar_event_tile_bckg: eventTileBckg,
      sidebar_event_tile_text: eventTileText,
      sidebar_scroll: sidebarScroll,
      sidebar_active_event: sidebarActiveEvent,
      icons: iconsColor,
      header_icons: headerIcons,
      header_font_family: headerFontFamily,
      header_profile_border: headerProfileBorder,
      header_text_primary: headerTextPrimary,
      chat_primary: chatPrimary,
      chat_avatar: chatAvatar,
    } = finalTheme || {};

    const gradient = `linear-gradient(to bottom,
            ${toHex(sidebarGradient1) || '#181d23'},
            ${toHex(sidebarGradient2) || theme.colors.bgDark}
          )`;

    const eventTileBckgHex = toHex(eventTileBckg);
    const eventTileTextHex = toHex(eventTileText);
    const activeEventHex = toHex(sidebarActiveEvent);

    const avatarBckg = toHex(chatAvatar) || toHex(chatPrimary) || primaryColor;
    const titleColor = headerTextPrimary ? toHex(headerTextPrimary) : '#fff';
    const isOnEventPage = document.URL.includes('/event');

    return (
      <Menu
        customBurgerIcon={
          <MenuIcon
            fill={toHex(headerIcons) || '#fff'}
            fillOpacity={isMobile ? 1 : 0}
          />
        }
        customCrossIcon={false}
        isOpen={false}
        styles={{
          ...styles,
          bmMenuWrap: {
            ...styles.bmMenuWrap,
            backgroundImage: gradient,
          },
          ...(isMobile && {
            bmBurgerButton: {
              ...styles.bmBurgerButton,
              left: null,
              right: 28,
            },
          }),
        }}
        onStateChange={this.handleMenuToggle}
        width={280}
        pageWrapId="page-container-inner"
        outerContainerId="page-container-outer"
        bodyClassName="side-menu-opened"
      >
        <ScrollContainer
          customScrollFormula={StylingUtils.customScroll(
            toHex(sidebarScroll) || '#fff',
          )}
        >
          <CloseButtonContainer
            onClick={this.closeMenu}
            fontFamily={headerFontFamily || theme.fonts.body}
          >
            <Box ml="16px" mt="2px">
              <CloseIcon />
            </Box>
            <CloseText fontSize={3}>
              <FormattedMessage id="menu.close" />
            </CloseText>
          </CloseButtonContainer>
          {events.length > 1 && (
            <>
              <Flex
                css={css`
                  margin: 0px 33px 0px 47px;
                  flex-direction: column;
                `}
              >
                <Text fontWeight="bold" mb={3}>
                  {activeOrganization && activeOrganization.name}
                </Text>
                <Text mb={3}>
                  <FormattedMessage id="menu.allSessions" />
                </Text>
              </Flex>

              <Link
                onClick={this.closeMenu}
                style={{ outline: 'none', textDecoration: 'none' }}
                to="/"
              >
                <EventsListButton
                  isOnEventList={isOnEventList}
                  customStyle={{
                    bckg: eventTileBckgHex,
                    textColor: eventTileTextHex,
                    activeEventColor: activeEventHex,
                  }}
                >
                  <Text
                    fontSize="4"
                    letterSpacing={2}
                    textTransform="uppercase"
                    fontWeight={theme.fontWeights.bold}
                  >
                    {activeOrganization &&
                      activeOrganization.name.toUpperCase()}{' '}
                    <FormattedMessage id="menu.eventListing" />
                  </Text>
                </EventsListButton>
              </Link>
            </>
          )}

          {!isLoadingEvents && !isEmpty(upcomingEvents) && finalOrganization && (
            <EventsContainer>
              <EventsInfo
                organizationName={finalOrganization.name}
                showOrgName={events.length <= 1}
              />
              <EventsList
                activeEvent={theEvent}
                events={upcomingEvents}
                onClick={this.handleEventClick}
                eventTileStyle={{
                  bckg: eventTileBckgHex,
                  textColor: eventTileTextHex,
                  activeEventColor: activeEventHex,
                }}
              />
            </EventsContainer>
          )}
          <EventsContainer>
            {isLoadingEvents ? (
              <Loader />
            ) : (
              !isEmpty(pastEvents) &&
              finalOrganization && (
                <>
                  <EventsInfo isPast />
                  <EventsList
                    activeEvent={theEvent}
                    events={pastEvents.reverse()}
                    onClick={this.handleEventClick}
                    eventTileStyle={{
                      bckg: eventTileBckgHex,
                      textColor: eventTileTextHex,
                      activeEventColor: activeEventHex,
                    }}
                  />
                </>
              )
            )}
          </EventsContainer>

          <SignInOut flexDirection="column" justifyContent="flex-start">
            {isMobile && isAuthenticated && user && !isAnonUser && (
              <Flex alignItems="center">
                {avatarUrl && (
                  <Avatar
                    src={avatarUrl}
                    borderColor={toHex(headerProfileBorder) || '#ccc'}
                    onClick={this.openProfileEdit}
                    customStyles={{ marginRight: '18px' }}
                  />
                )}
                {!avatarUrl && (
                  <DefaultAvatar
                    fill={avatarBckg}
                    style={{
                      borderRadius: '50%',
                      width: 30,
                      height: 30,
                      marginRight: 18,
                      minWidth: 'auto',
                      border: `2px solid ${toHex(headerProfileBorder) ||
                        '#ccc'}`,
                      cursor: 'pointer',
                    }}
                    onClick={this.openProfileEdit}
                  />
                )}
                <SettingsLink color={titleColor} onClick={this.openProfileEdit}>
                  {user.first_name} {user.last_name}
                </SettingsLink>
              </Flex>
            )}

            {isMobile && theEvent && isOnEventPage && isAnonUser && (
              <Flex alignItems="center">
                {finalAnonAvatar ? (
                  <Avatar
                    src={finalAnonAvatar}
                    borderColor={toHex(headerProfileBorder) || '#ccc'}
                    onClick={this.openProfileEdit}
                    customStyles={{ marginRight: '18px' }}
                  />
                ) : (
                  <DefaultAvatar
                    fill={avatarBckg}
                    style={{
                      borderRadius: '50%',
                      width: 30,
                      height: 30,
                      marginRight: 18,
                      minWidth: 'auto',
                      border: `2px solid ${toHex(headerProfileBorder) ||
                        '#ccc'}`,
                      cursor: 'pointer',
                    }}
                    onClick={this.openProfileEdit}
                  />
                )}
                <SettingsLink color={titleColor} onClick={this.openProfileEdit}>
                  {finalAnonUsername}
                </SettingsLink>
              </Flex>
            )}

            {isMobile && isAuthenticated && (
              <Flex mt={10} alignItems="center" onClick={this.handleLogout}>
                <LogoutIconWrapper>
                  <LogoutIcon fill={toHex(headerIcons) || toHex(iconsColor)} />
                </LogoutIconWrapper>
                <FormattedMessage id="menu.signout" />
              </Flex>
            )}
          </SignInOut>
        </ScrollContainer>
      </Menu>
    );
  }
}

export default SideMenu;
