import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Meter } from './style';

const PasswordStrengthBar = ({ password }) => {
  const pass = password || '';

  let passwordStrength = 0;
  if (pass.match(/[a-z]/)) {
    passwordStrength += 1;
  }
  if (pass.match(/[A-Z]/)) {
    passwordStrength += 1;
  }
  if (pass.match(/\d/)) {
    passwordStrength += 1;
  }
  if (pass.match(/\W/)) {
    passwordStrength += 1;
  }
  if (pass && pass.length >= 8) {
    passwordStrength += 1;
  }

  return (
    <Bar>
      <Meter strength={passwordStrength} value={pass} />
    </Bar>
  );
};
PasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired,
};
export default PasswordStrengthBar;
