import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Box, Flex, Text, Link } from 'rebass';
import styled from '@emotion/styled';
import get from 'lodash/get';
import ReCAPTCHA from 'react-google-recaptcha';
import { isEmpty } from 'lodash';
import {
  mustBeAtLeast,
  composeValidators,
  mustBeValidEmail,
  passwordsMustMatch,
  passwordMustBeStrength,
  required,
} from '../../_app/formValidators';
import PasswordStrengthBar from '../../components/PasswordStrengthBar/PasswordStrengthBar';
import { placeholderTranslator } from '../../_app/placeholderTranslator';
import TextInput from '../../components/_forms/TextInput';
import Checkbox from '../../components/_forms/Checkbox';
import { Button } from '../../components/Button/Button';
import theme from '../../_styles/theme';
import CustomSelect from '../../components/_forms/Select';
import countryCodesList from '../../_assets/countryCodesList';
import Disclaimer from '../../components/Disclaimer';
import { markLinkAsTag } from '../../_app/utils/otherUtils';

const FieldWrapper = styled(Box)`
  margin-top: ${props => props.mt || '18px'};
`;

const RecaptchaWrapper = styled(Box)`
  position: absolute;
  z-index: 2;
`;

const ErrorText = styled(Text)`
  color: red;
  line-height: 22px;
  margin-bottom: '4px';
`;

export const FIELD_TYPES = {
  TEXT: 'text',
  CHECKBOX: 'checkbox',
  COUNTRY: 'country',
  JOB_TITLE: 'job_title',
  COMPANY: 'company',
  SELECT: 'select',
};

export const componentForField = type => {
  switch (type) {
    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.JOB_TITLE:
    case FIELD_TYPES.COMPANY:
      return TextInput;
    case FIELD_TYPES.COUNTRY:
      return CustomSelect;
    case FIELD_TYPES.CHECKBOX:
      return Checkbox;
    case FIELD_TYPES.SELECT:
      return CustomSelect;
    default:
      return null;
  }
};

const SignUpForm = ({
  activeOrganization,
  onSubmit,
  isLoading,
  user,
  queryParams,
  eventId,
  shouldSetPassword,
  primaryColor,
  buttonsTextColor,
  extraFields,
  disclaimer,
  hideTermsAndConditions,
  event,
}) => (
  <Form
    initialValues={extraFields.reduce(
      (acc, { field_name: fieldName }) => ({
        ...acc,
        [fieldName]: get(user, fieldName) || '',
      }),
      {
        email: get(queryParams, 'email') || get(user, 'email'),
        first_name: get(queryParams, 'first_name') || get(user, 'first_name'),
        last_name: get(queryParams, 'last_name') || get(user, 'last_name'),
        terms_and_conditions_accepted:
          get(queryParams, 'terms_and_conditions_accepted') ||
          get(user, 'terms_and_conditions_accepted') ||
          !get(user, 'skip_terms_and_conditions_accepted'),
        event_id: eventId,
      },
    )}
    onSubmit={onSubmit}
    render={({ handleSubmit, submitErrors, values }) => {
      const filteredExtraField = extraFields.filter(
        ({ field_name: fieldName }) => get(user, fieldName, '____') === '____',
      );
      const extraFieldsCheckboxes = filteredExtraField.filter(
        ({ _field_type: type }) => type === FIELD_TYPES.CHECKBOX,
      );
      const extraFieldsOther = filteredExtraField.filter(
        ({ _field_type: type }) => type !== FIELD_TYPES.CHECKBOX,
      );
      // eslint-disable-next-line
      const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
      const recaptchaRef = React.createRef();

      const onSubmitWithReCAPTCHA = async e => {
        submitErrors = undefined;
        e.preventDefault();
        if (event && event.recaptcha_enabled) {
          recaptchaRef.current.reset();
          const recaptchaToken = await recaptchaRef.current.executeAsync();
          console.log('recaptchaToken', recaptchaToken);
          values['g-recaptcha-response'] = recaptchaToken;
        }
        handleSubmit();
      };

      return (
        <form style={{ height: '100%' }}>
          <Flex
            height="100%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box mb={0}>
              {(!user || !user.first_name) && (
                <FieldWrapper>
                  <Field
                    name="first_name"
                    id="first-name-input"
                    label={<FormattedMessage id="signup.firstname" />}
                    inputType="text"
                    placeholder={placeholderTranslator('signup.firstname')}
                    validate={composeValidators(required)}
                    disabled={user}
                    component={TextInput}
                    autocomplete="given-name"
                    maxLength={150}
                  />
                </FieldWrapper>
              )}
              {(!user || !user.last_name) && (
                <FieldWrapper>
                  <Field
                    name="last_name"
                    id="last-name-input"
                    label={<FormattedMessage id="signup.lastname" />}
                    inputType="text"
                    placeholder={placeholderTranslator('signup.lastname')}
                    validate={composeValidators(required)}
                    disabled={user}
                    component={TextInput}
                    autocomplete="family-name"
                    maxLength={150}
                  />
                </FieldWrapper>
              )}
              {!user && (
                <FieldWrapper>
                  <Field
                    id="email-input"
                    name="email"
                    label={<FormattedMessage id="signup.email" />}
                    inputType="email"
                    placeholder={placeholderTranslator('signup.email')}
                    validate={composeValidators(required, mustBeValidEmail)}
                    disabled={user}
                    component={TextInput}
                    autocomplete="username"
                    maxLength={150}
                  />
                </FieldWrapper>
              )}
              {!isEmpty(extraFieldsOther) &&
                extraFieldsOther.map(
                  ({
                    field_name: fieldName,
                    name,
                    _field_type: fieldType,
                    options,
                    required: isRequired,
                  }) => {
                    let fieldOptions = [];
                    if (fieldType !== FIELD_TYPES.COUNTRY && options) {
                      fieldOptions = options.map(option => ({
                        label: option,
                        value: option,
                      }));
                    }
                    return (
                      <FieldWrapper key={`field-${fieldName}`}>
                        <Field
                          name={fieldName}
                          label={
                            <FormattedMessage
                              id={`signup.fields.${fieldName}`}
                              defaultMessage={name}
                            />
                          }
                          parse={value => value} // a hack to send empty values
                          validate={isRequired && required}
                          component={componentForField(fieldType)}
                          options={
                            fieldType === FIELD_TYPES.COUNTRY
                              ? Object.keys(countryCodesList).map(key => ({
                                  value: key,
                                  label: countryCodesList[key],
                                }))
                              : fieldOptions
                          }
                          maxLength={150}
                        />
                      </FieldWrapper>
                    );
                  },
                )}
              {!user && (
                <>
                  <FieldWrapper>
                    <Field
                      name="password"
                      id="password-input"
                      label={<FormattedMessage id="signup.passreq" />}
                      placeholder={placeholderTranslator('signup.pass')}
                      inputType="password"
                      validate={
                        shouldSetPassword
                          ? composeValidators(
                              required,
                              mustBeAtLeast(8),
                              passwordMustBeStrength,
                            )
                          : null
                      }
                      disabled={!shouldSetPassword}
                      component={TextInput}
                      autocomplete="new-password"
                      maxLength={150}
                    />
                    <PasswordStrengthBar password={values.password} />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="password_confirmation"
                      id="password-confirmation-input"
                      label={<FormattedMessage id="signup.passconfirm" />}
                      inputType="password"
                      placeholder={placeholderTranslator('signup.passconfirm')}
                      validate={
                        shouldSetPassword
                          ? composeValidators(required, passwordsMustMatch)
                          : null
                      }
                      disabled={!shouldSetPassword}
                      component={TextInput}
                      autocomplete="new-password"
                      maxLength={150}
                    />
                  </FieldWrapper>
                </>
              )}
            </Box>
            <Box mb={4} mt={3}>
              {!isEmpty(extraFieldsCheckboxes) &&
                extraFieldsCheckboxes.map(
                  ({
                    field_name: fieldName,
                    name,
                    _field_type: fieldType,
                    required: isRequired,
                  }) => (
                    <FieldWrapper key={`field-${fieldName}`} mt="10px">
                      <Field
                        name={fieldName}
                        label={
                          <FormattedMessage
                            id={`signup.fields.${fieldName}`}
                            defaultMessage={name}
                          />
                        }
                        validate={isRequired && required}
                        component={componentForField(fieldType)}
                        type="checkbox"
                        checkboxContent={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: name,
                            }}
                          />
                        }
                      />
                    </FieldWrapper>
                  ),
                )}
              {!hideTermsAndConditions &&
                (!user || !user.terms_and_conditions_accepted) && (
                  <FieldWrapper mt="10px">
                    <Field
                      name="terms_and_conditions_accepted"
                      validate={required}
                      disabled={user}
                      type="checkbox"
                      checkboxContent={
                        <Text
                          fontSize={theme.fontSizes[1]}
                          color="textSemiVisible"
                        >
                          <FormattedMessage
                            id="signup.tandc"
                            values={{
                              a: (...chunks) => (
                                <Link
                                  color={primaryColor}
                                  mt={1}
                                  target="_blank"
                                  href={`https://cplus.live/terms.html${
                                    activeOrganization
                                      ? `?orgName=${encodeURIComponent(
                                          activeOrganization.name,
                                        )}`
                                      : ''
                                  }`}
                                >
                                  {chunks}
                                </Link>
                              ),
                              aPrivacy: (...chunks) => (
                                <Link
                                  color={primaryColor}
                                  mt={1}
                                  target="_blank"
                                  href="https://cplus.live/privacypolicy.html"
                                >
                                  {chunks}
                                </Link>
                              ),
                            }}
                          />
                        </Text>
                      }
                      component={Checkbox}
                    />
                  </FieldWrapper>
                )}
              {disclaimer && (
                <Disclaimer mt="20px">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: markLinkAsTag(disclaimer),
                    }}
                  />
                </Disclaimer>
              )}
            </Box>
            <Box>
              {!isEmpty(submitErrors?.recaptcha) && (
                <Box>
                  <ErrorText>{submitErrors?.recaptcha[0]}</ErrorText>
                </Box>
              )}
              <Box width={1} height="53px">
                <Button
                  type="button"
                  onClick={e => onSubmitWithReCAPTCHA(e)}
                  isLoading={isLoading}
                  bgColor={primaryColor}
                  color={buttonsTextColor}
                >
                  <FormattedMessage id="signup.register" />
                </Button>
              </Box>
            </Box>
            {!user ? (
              <Flex
                flexDirection="column"
                width="100%"
                mt="15px"
                mb="8px"
                alignItems="center"
                justifyContent="center"
                color="textDarkSecondary"
                fontSize={3}
              >
                {event && !event.hide_login_form && (
                  <Box mt={1} fontSize="12px">
                    <FormattedMessage
                      id="signup.signin"
                      values={{
                        a: (...chunks) => (
                          <Link
                            fontSize={theme.fontSizes[1]}
                            color={primaryColor}
                            href={eventId ? `/sign-in/${eventId}` : '/sign-in'}
                          >
                            {chunks}
                          </Link>
                        ),
                      }}
                    />
                  </Box>
                )}
              </Flex>
            ) : null}
          </Flex>
          {event && event.recaptcha_enabled && (
            <RecaptchaWrapper>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RECAPTCHA_SITE_KEY || 'sitekey-no-found'}
              />
            </RecaptchaWrapper>
          )}
        </form>
      );
    }}
  />
);

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;
