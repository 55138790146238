import styled from '@emotion/styled';
import { Box, Link } from 'rebass';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import theme from '../../_styles/theme';
import eventBriefStore from '../../stores/eventBriefStore';

const Error = styled(Box)`
  color: ${theme.colors.error};
  font-size: 14px;
`;

const errorMessage = meta => {
  const errorMsg = Array.isArray(meta.submitError)
    ? meta.submitError[0]
    : meta.submitError;

  let values;
  switch (errorMsg) {
    case 'is already taken':
      values = {
        a: (...chunks) => (
          <Link
            color={theme.colors.error}
            fontSize={2}
            href={
              get(eventBriefStore, 'event.slug')
                ? `/sign-in/${get(eventBriefStore, 'event.slug', '')}`
                : '/sign-in'
            }
          >
            {chunks}
          </Link>
        ),
      };
      break;
    default:
      values = null;
  }

  return errorMsg
    ? <FormattedMessage id={errorMsg} values={values} /> || errorMsg
    : meta.error;
};

export const FormError = ({ meta, 'data-testid': dataTestId, ...props }) => (
  <>
    {((meta.touched && meta.error) || !isEmpty(meta.submitError)) && (
      <Error data-testid={dataTestId || 'form-error'} {...props}>
        {errorMessage(meta)}
      </Error>
    )}
  </>
);
