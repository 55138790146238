/* eslint-disable */
import React from 'react';

export const BreakoutIcon = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26"
    viewBox="0 0 24 30"
  >
    <g fill={fill || '#37C2FE'} fillRule="evenodd">
      <path
        d="M6.167 16.667H4.833v-5.334h1.334V12c-.368 0-.667.299-.667.667 0 .368.299.666.667.666v3.334zM6.833 10H4.167c-.368 0-.667.299-.667.667v6.666c0 .368.299.667.667.667h2.666c.368 0 .667-.299.667-.667v-4h1.333c.368 0 .667-.298.667-.666 0-.368-.299-.667-.667-.667H7.5v-1.333c0-.368-.299-.667-.667-.667z"
        transform="translate(1 1)"
      />
      <path
        stroke={fill || '#37C2FE'}
        strokeWidth=".8"
        d="M19.5 28c.276 0 .5-.224.5-.5s-.224-.5-.5-.5H2V1h12v8.5c0 .276.223.5.5.5s.5-.224.5-.5V1c0-.552-.448-1-1-1H2c-.552 0-1 .448-1 1v26H.5c-.276 0-.5.224-.5.5s.224.5.5.5h19z"
        transform="translate(1 1)"
      />
      <path
        d="M11.905 11.435c.692-.338 1.527-.074 1.9.6l2.19 3.815 3.745-.35c.612-.055 1.157.389 1.225 1l.37 3.775.69 1.19c.15.257.19.564.11.85-.076.29-.266.537-.525.685l-3.145 1.79c-.17.099-.364.15-.56.15-.098 0-.195-.011-.29-.035-.289-.077-.535-.266-.685-.525l-.775-1.34-2.635-2.155c-.356-.288-.5-.763-.365-1.2l.625-2-2.465-4.27c-.194-.338-.241-.74-.13-1.115.108-.376.369-.69.72-.865z"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);
