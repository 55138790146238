// eslint-disable-next-line
import url from 'url';
import startsWith from 'lodash/startsWith';
import includes from 'lodash/includes';
import groupBy from 'lodash/groupBy';
import Autolinker from 'autolinker';
import moment from 'moment';

const INVALID = ['localhost', 'cplus', 'congress-plus', 'www'];
const hostSplit = url.parse(window.location.href).hostname.split('.');
const sub = hostSplit[0];

export const SUBDOMAIN = !includes(INVALID, sub) ? sub : null;

export const HOST_LENGTH_WITH_SUBDOMAIN =
  process.env.NODE_ENV === 'development' ? 2 : 3;

// HACK!!!
export const shouldRedirectToOppo = () => {
  // eslint-disable-next-line no-underscore-dangle
  const _url = url.parse(window.location.href);
  const { pathname, host, protocol, search } = _url;
  // console.log('URL : ', _url);
  if (pathname === '/sign-up/hallo-deutschland' && !startsWith(host, 'oppo')) {
    return `${protocol}//oppo.${host}${pathname}${search || ''}`;
  }
  return null;
};

export const deleteCookie = name => {
  document.cookie = `${name};path=/;expires=-1`;
};

export const toHex = str => {
  if (!str || typeof str !== 'string') {
    return str;
  }

  return str.includes('#') ? str : `#${str}`;
};

export const preventFromScrolling = elem => {
  if (elem) {
    elem.style.overflow = 'hidden';
    elem.style.position = 'fixed';
  }
};

export const allowScrolling = elem => {
  if (elem) {
    // theese styles have to match ones in GlobalStyles.js
    elem.style.overflow = '';
    elem.style.position = 'relative';
  }
};

export const markLinkAsTag = content => {
  const linkRegex = new RegExp(
    '[^(=("\')?]https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
    'ig',
  );

  const result = (content || '').replace(
    linkRegex,
    '<a href="$&" target="_blank">$&</a>',
  );
  return result;
};

export const markChatLinkAsTag = content => {
  return Autolinker.link(content || '');
  // const result = (content || '').replace(
  //   urlRegex({ strict: false }),
  //   '<a href="$&" target="_blank">$&</a>',
  // );
  // return result;
};

export const setPageTitle = name => {
  if (!name) {
    return;
  }
  document.title = name;
};

export const isMultiDayEvent = agendaItems => {
  let moreThan1Day = false;
  if (agendaItems.length > 0) {
    const firstDay = moment(agendaItems[0].start_date).date();
    const lastDay = moment(agendaItems[agendaItems.length - 1].end_date).date();
    if (firstDay !== lastDay) {
      moreThan1Day = true;
    }
  }
  return moreThan1Day;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const handleMultiDayAIs = (
  agendaItems,
  agendaItem,
  moreThan1Day,
  idx,
) => {
  let sortedAIs = {};
  let isNewDay = false;
  if (moreThan1Day) {
    sortedAIs = groupBy(agendaItems, function(b) {
      return moment(b.start_date).format('DD-MM-YYYY');
    });
    if (idx === 0) {
      isNewDay = true;
    } else {
      const aiDay = moment(agendaItem.start_date).date();
      const previousAiDay = moment(agendaItems[idx - 1].start_date).date();
      if (aiDay !== previousAiDay) {
        isNewDay = true;
      }
    }
  }
  const days = Object.keys(sortedAIs);

  return { days, sortedAIs, isNewDay };
};
