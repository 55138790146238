import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Webcam from 'react-webcam';
import StylingUtils from '../_app/utils/stylingUtils';
import theme from '../_styles/theme';
import { CloseIcon } from '../_assets/icons/close';
import FileUtils from '../_app/utils/FileUtil';
import { Loader } from './Loader';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';

const WebcamContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    position: fixed;
  }
`;

const WebcamContainerInner = styled(Box)`
  position: relative;
  height: 500px;
  width: 500px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #555;
  text-align: center;
  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: ${props => (props.asModal ? `calc(100vw - 50px)` : '100%')};
    width: ${props => (props.asModal ? `calc(100vw - 50px)` : '100%')};
    border-radius: none;
    box-shadow: none;
  }
`;

const ButtonsContainer = styled(Flex)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
`;

const WebcamButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  background-color: ${({ bg }) =>
    bg ? StylingUtils.hexToRgbA(bg, 0.8) : 'rgba(0, 0, 0, 0.8)'};
  border-radius: 5px;
  color: ${({ fontColor }) => fontColor || '#fff'};
  border: none;
  padding: 10px;
  flex-grow: 1;
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.emph};
  cursor: pointer;
  ${({ customStyles }) => customStyles || ''}
`;

const MidStateContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const videoConstraints = {
  width: { min: 500 },
  height: { min: 500 },
  facingMode: 'user',
};

const WebcamWrapper = ({
  colors,
  toggleWebcam,
  asModal,
  input: { onChange },
}) => {
  const webcamRef = React.useRef(null);
  const [permission, setPermission] = React.useState('pending');

  const renderBasedOnPermission = () => {
    if (permission === 'pending') {
      return <Loader />;
    }

    if (permission === 'denied') {
      return (
        <Text>
          <FormattedMessage id="settings.field.image.makephotodenied" />
        </Text>
      );
    }
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = FileUtils.b64toBlob(imageSrc);
    onChange([new File([blob], 'webcam-image.jpeg', { type: 'image/jpeg' })]);
    toggleWebcam(false);
  }, [webcamRef]);

  return (
    <>
      {asModal ? (
        <WebcamContainerInner asModal={asModal}>
          <Webcam
            audio={false}
            height="100%"
            width="100%"
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            style={{
              objectFit: 'cover',
            }}
            videoConstraints={videoConstraints}
            onUserMedia={() => setPermission('granted')}
            onUserMediaError={() => setPermission('denied')}
          />
          <MidStateContainer>{renderBasedOnPermission()}</MidStateContainer>
          <ButtonsContainer>
            <WebcamButton
              onClick={capture}
              type="button"
              bg={colors.buttonsBg}
              fontColor={colors.buttonsTextColor}
            >
              <FormattedMessage id="settings.field.image.takeaphoto" />
            </WebcamButton>
            <WebcamButton
              onClick={() => toggleWebcam(false)}
              type="button"
              bg="#fff"
              fontColor="#000"
              customStyles={{
                flexBasis: '30px',
                flexGrow: 0,
                marginLeft: '10px',
              }}
            >
              <CloseIcon fill="#000" height="12px" />
            </WebcamButton>
          </ButtonsContainer>
        </WebcamContainerInner>
      ) : (
        <WebcamContainer>
          <WebcamContainerInner>
            <Webcam
              audio={false}
              height="100%"
              width="100%"
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{
                objectFit: 'cover',
              }}
              videoConstraints={videoConstraints}
              onUserMedia={() => setPermission('granted')}
              onUserMediaError={() => setPermission('denied')}
            />
            <MidStateContainer>{renderBasedOnPermission()}</MidStateContainer>
            <ButtonsContainer>
              <WebcamButton
                onClick={capture}
                type="button"
                bg={colors.buttonsBg}
                fontColor={colors.buttonsTextColor}
              >
                <FormattedMessage id="settings.field.image.takeaphoto" />
              </WebcamButton>
              <WebcamButton
                onClick={() => toggleWebcam(false)}
                type="button"
                bg="#fff"
                fontColor="#000"
                customStyles={{
                  flexBasis: '30px',
                  flexGrow: 0,
                  marginLeft: '10px',
                }}
              >
                <CloseIcon fill="#000" height="12px" />
              </WebcamButton>
            </ButtonsContainer>
          </WebcamContainerInner>
        </WebcamContainer>
      )}
    </>
  );
};

export default WebcamWrapper;
