import { action, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';

export class OrganizationsStore {
  @observable isLoading = false;

  @observable organizations = [];

  @action getOrganizationBySlug = async (slug, onSuccess) => {
    this.isLoading = true;

    try {
      const { data } = await API.get(`${API_ROUTES.ORGANIZATIONS()}${slug}`);
      if (onSuccess) {
        await onSuccess(data);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = async () => {
    this.organizations = [];
  };
}

export default new OrganizationsStore();
