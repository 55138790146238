import React from 'react';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';
import theme from '../../../_styles/theme';
import { Button } from '../../Button/Button';

const ModalBody = styled.div`
  position: relative;
  padding: 40px 50px;
  max-width: 665px;
  font-size: ${theme.fontSizes[4]}px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.35);
  color: ${theme.colors.textDark};
  font-family: ${theme.fonts.body};

  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 500px;
  }

  @media (max-width: 555px) {
    width: 320px;
  }
`;

const Header = styled.h1`
  font-size: ${theme.fontSizes[6]}px;
  margin-bottom: 25px;
`;

const Description = styled.p`
  letter-spacing: 1px;
  line-height: 130%;
`;

const StyledButton = styled(Button)`
  margin-top: 25px;
`;

function IEBrowserModalContent({ closeModalCookie }) {
  return (
    <ModalBody>
      <Header>
        Your browser may not support all of the features of{' '}
        <span style={{ fontWeight: theme.fontWeights.emph }}>Conference+</span>
      </Header>
      <Description>
        Looks like your browser is outdated. Some features might not work as
        expected. You may want to update your browser to one of the newer
        browsers like{' '}
        <a
          href="https://www.google.com/chrome/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome
        </a>{' '}
        or{' '}
        <a
          href="https://www.mozilla.org/firefox/new/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mozilla Firefox
        </a>{' '}
        for a better user experience.
      </Description>
      <StyledButton
        onClick={closeModalCookie}
        bgColor={theme.colors.bgLight}
        color="#fff"
      >
        I understand
      </StyledButton>
    </ModalBody>
  );
}

export default IEBrowserModalContent;
