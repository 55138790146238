import { Link, Text } from 'rebass';
import { Box } from '@material-ui/core';
import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import theme from '../../../_styles/theme';
import { TickIcon } from '../../../_assets/icons/tickIcon';
import StylingUtils from '../../../_app/utils/stylingUtils';
import { Button } from '../../../components/Button/Button';

const Container = styled(Box)``;

const BodyText = styled(Text)`
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
  letter-spacing: 0.3px;

  ${StylingUtils.animationFadeIn()};
`;

const Bold = styled('span')`
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.textDark};
`;

const Tick = styled('div')`
  margin: 10px 0 28px;
`;

const Header = styled(Box)`
  color: ${theme.colors.textDark};
  font-size: ${theme.fontSizes[6]}px;
  font-weight: ${theme.fontWeights.heading};
  text-transform: uppercase;
  line-height: 37px;
  letter-spacing: 1px;

  max-width: 300px;

  margin-bottom: 18px;
`;

export const ResetPasswordFinishSuccess = ({
  primaryColor,
  fontFamily,
  signInRedirectPath,
  buttonsTextColor,
}) => {
  return (
    <Container>
      <Tick sx={{ width: '63px', height: '63px' }}>
        <TickIcon fill={primaryColor} />
      </Tick>
      <Header color={primaryColor} fontFamily={fontFamily || theme.fonts.body}>
        <FormattedMessage id="resetpasswordfinishsuccess.header" />
      </Header>
      <BodyText>
        <Bold>
          <FormattedMessage id="resetpasswordfinishsuccess.message" />
        </Bold>
      </BodyText>
      <BodyText>
        <FormattedMessage id="resetpasswordfinishsuccess.desc" />
        <Box width={1} height="53px" mt="45px">
          <Link href={signInRedirectPath} style={{ textDecoration: 'none' }}>
            <Button
              onClick={() => {}}
              bgColor={primaryColor}
              color={buttonsTextColor}
              style={{ textTransform: 'uppercase' }}
            >
              <FormattedMessage id="resetpassword.signin" />
            </Button>
          </Link>
        </Box>
      </BodyText>
    </Container>
  );
};
