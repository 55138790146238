import React from 'react';
import { Box, Text } from 'rebass';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/Button/Button';
import theme from '../../../_styles/theme';
import { DefaultAvatar } from '../../../_assets/icons/defaultAvatar';

const PersonTile = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: ${theme.colors.bgTileGrey};
  border-radius: 4px;
  width: calc(20% - 10px);
  margin: 5px;
  @media screen and (max-width: 1425px) {
    width: calc(25% - 10px);
  }
  @media screen and (max-width: 1280px) {
    width: calc(33.333% - 10px);
  }
  @media screen and (max-width: 1100px) {
    width: calc(50% - 10px);
  }
`;

const PersonJob = styled(Text)`
  margin-top: 5px;
  line-height: 1.3em;
  font-size: 12px;
  word-break: break-word;
  color: ${theme.colors.textGrey};
`;

const Name = styled(Text)`
  font-size: 14px;
  font-weight: ${theme.fontWeights.heading};
  line-height: 1.4em;
  color: ${theme.colors.textOnWhiteBg};
`;

const PersonCompany = styled(Text)`
  font-weight: ${theme.fontWeights.emph};
  line-height: 1.3em;
  font-size: 14px;
  color: ${theme.colors.textGrey};
`;

const Avatar = styled(Box)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ avatar }) => (avatar ? `url(${avatar})` : '#999')} no-repeat
    center center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  &:hover {
    cursor: ${props => (props.avatar ? 'pointer' : 'default')};
  }
`;

const Person = ({
  person,
  color,
  onMoreClick,
  idx,
  id,
  avatarColor,
  buttonsTextColor,
  handleShowBigImage,
}) => {
  return (
    <PersonTile id={id}>
      <Box>
        <Avatar
          avatar={person.avatar.thumb.url}
          onClick={() => handleShowBigImage({ imgUrl: person.avatar.url })}
        >
          {!person.avatar.thumb.url && (
            <DefaultAvatar fill={avatarColor} width="60px" height="60px" />
          )}
        </Avatar>
        {(person.first_name || person.last_name) && (
          <Name>
            {person.first_name || ''} {person.last_name || ''}
          </Name>
        )}
        {person.company && <PersonCompany>{person.company}</PersonCompany>}
        {person.job_title && <PersonJob>{person.job_title}</PersonJob>}
      </Box>
      <Button
        bgColor={color}
        color={buttonsTextColor}
        height="30px"
        fontSize="12px"
        onClick={() => onMoreClick(person.id, idx)}
        mt="20px"
      >
        <FormattedMessage id="networking.connect" />
      </Button>
    </PersonTile>
  );
};

export default Person;
