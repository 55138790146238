import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { isEmpty } from 'lodash';
import { Box, Flex, Image } from 'rebass';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { AGENDA_ITEM_TYPES } from '../_app/constants';
import { markLinkAsTag, toHex } from '../_app/utils/otherUtils';
import StylingUtils from '../_app/utils/stylingUtils';
import { AgendaIcon } from '../_assets/icons/agendaIcon';
import { CalendarIcon } from '../_assets/icons/calendarIcon';
import ChevronLeft from '../_assets/icons/chevronLeft';
import { DocsIcon } from '../_assets/icons/docsIcon';
import NetworkingIcon from '../_assets/icons/networkingIcon';
import { SpeakerIcon } from '../_assets/icons/speakerIcon';
import { SponsorIcon } from '../_assets/icons/sponsorIcon';
import { breakpoint } from '../_styles/breakpoints';
import theme from '../_styles/theme';
import AgendaMain from '../components/AgendaMain';
import IconDetail from '../components/IconDetail';
import ScrolltoWrapper from '../components/ScrolltoWrapper';
import SpeakerMain from '../components/SpeakerMain';
import SponsorMain from '../components/SponsorMain';
import { DocumentsList } from './DocumentsList/DocumentsList';
import NetworkingMain from './NetworkingContainer';
import Stream from './StreamContainer/Stream';
import {
  headerPadding,
  Disclaimer,
  TabContainer,
  TabRoundedInside,
} from './index';

const middleSectionHeaderPadding = '36px';

const ColMiddle = ({
  children,
  chatColEnabled,
  bckgColor,
  agendaColEnabled,
  hideTabbedSectionsBar,
  isDisclaimer,
}) => (
  <Box
    data-scrollable="middle-column"
    css={css`
      display: flex;
      flex-direction:column;
      justify-content: space-between;
      background-color: ${bckgColor || 'transparent'};
      position: relative;
      box-sizing: border-box;
      ${chatColEnabled ? 'max-height: 100vh;' : ''}
      width: calc(100% - 320px * ${
        // eslint-disable-next-line no-nested-ternary
        2 - (chatColEnabled ? 0 : 1) - (agendaColEnabled ? 0 : 1)
      });
      padding: calc(26px + ${
        hideTabbedSectionsBar ? headerPadding : middleSectionHeaderPadding
      }) 23px ${isDisclaimer ? '10px' : '30px'};
      z-index: 4;
      overflow-y: scroll;
      max-height: 100vh;

      ${StylingUtils.hidingScrollbarFormula()};
      @media ${breakpoint.xxl} {
        padding-left: 60px;
        padding-right: 60px;
      }
    `}
    id="middleColumnTag"
  >
    {children}
  </Box>
);

const BackButtonRow = styled(Flex)`
  margin-top: ${props => (props.hideTabbedSectionsBar ? '-17px' : '3px')};
  position: ${props => (props.hideTabbedSectionsBar ? 'static' : 'absolute')};
  padding-bottom: ${props => (props.hideTabbedSectionsBar ? '20px' : '10px')};
  width: 100%;
`;

const BackButton = ({
  children,
  marginRight,
  display,
  textColor,
  ...props
}) => (
  <Box
    css={css`
      color: ${textColor || 'white'};
      font-weight: 900;
      font-size: 14px;
      margin-left: auto;
      display: ${!display ? 'flex' : 'none'};
      align-items: center;
      cursor: pointer;
      margin-right: ${marginRight};
    `}
    {...props}
  >
    {children}
  </Box>
);

const DocumentSection = styled(Box)`
  margin-top: 20px;
  position: relative;
  width: 100%;
  z-index: 2;
`;

const BottomImageDesktop = ({ chatColEnabled, agendaColEnabled, ...props }) => (
  <Image
    css={css`
      position: fixed;
      width: calc(
        100% - 320px * ${// eslint-disable-next-line no-nested-ternary
          2 - (chatColEnabled ? 0 : 1) - (agendaColEnabled ? 0 : 1)}
      );
      z-index: -1;
      bottom: 0;
      height: auto;
    `}
    {...props}
  />
);

export const ColumnMiddle = ({
  hideTabbedSectionsBar,
  chatEnabled,
  agendaColumnEnabled,
  enableDesktopAgenda,
  body1,
  disclaimer,
  backButtonOnBreakoutRoom,
  backButtonColor,
  setActiveAgendaItem,
  anyActiveItem,
  backToItem,
  videoUrl,
  bottomImgFile,
  bottomImg,
  selectedTabIndex,
  iconsColor,
  primaryColor,
  agendaItems,
  activeEvent,
  sponsors,
  networkingAvailable,
  openAsVideoElement,
  isArchived,
  exploreItem,
  exploreItemStyle,
  user,
  activeAgendaItem,
  disclaimerText,
  docsPanelEnabled,
  labelText,
  hideIcons,
  hasCustomStyle,
  iframeBreakoutRoom,
  documentStyle,
  isLoadingDocuments,
  documents,
  noContentText,
  sidebarScroll,
  scrollTo,
  buttonsTextColor,
  buttonsBg,
  chatRoomsEnabled,
  chatPrimary,
  chatAvatar,
  videoReactions,
  avatarBg,
  handleVideoBackButton,
  setActiveTabIndex,
  zoomOpenInNewTabLink,
  handleAgendaMoreButtonClick,
  setVideoUrl,
  setStateScrollTo,
  setStateChatExpanded,
  agendaDetailsStyle,
  handleShowBigImage,
}) => {
  const iconsHex = toHex(iconsColor);
  return (
    <ColMiddle
      hideTabbedSectionsBar={hideTabbedSectionsBar}
      chatColEnabled={chatEnabled}
      agendaColEnabled={agendaColumnEnabled && enableDesktopAgenda}
      bckgColor={toHex(body1)}
      isDisclaimer={disclaimer}
    >
      <Flex flexDirection="column" alignItems="center">
        {(!enableDesktopAgenda || backButtonOnBreakoutRoom) &&
          hideTabbedSectionsBar && (
            <BackButtonRow hideTabbedSectionsBar>
              <BackButton
                textColor={backButtonColor}
                display={enableDesktopAgenda && !backButtonOnBreakoutRoom}
                onClick={() => setActiveAgendaItem(anyActiveItem || backToItem)}
              >
                <Flex
                  css={css`
                    font-size: 14px;
                    padding-right: 3px;
                  `}
                >
                  <ChevronLeft height={12} paddingright={3} />
                </Flex>
                <FormattedMessage id="generic.back" />
              </BackButton>
            </BackButtonRow>
          )}
        {videoUrl && hideTabbedSectionsBar && (
          <BackButtonRow hideTabbedSectionsBar>
            <BackButton
              textColor={backButtonColor}
              onClick={() => handleVideoBackButton()}
            >
              <Flex
                css={css`
                  font-size: 14px;
                  padding-right: 3px;
                  transform: translate3d(0, 0, 0);
                `}
              >
                <ChevronLeft height={12} paddingright={3} />
              </Flex>
              <FormattedMessage id="generic.back" />
            </BackButton>
          </BackButtonRow>
        )}
        <BottomImageDesktop
          chatColEnabled={chatEnabled}
          agendaColEnabled={agendaColumnEnabled && enableDesktopAgenda}
          src={(bottomImgFile && bottomImgFile.url) || bottomImg}
        />

        <TabContainer
          selectedIndex={selectedTabIndex}
          onSelect={setActiveTabIndex}
        >
          <TabList
            className={`react-tabs__tab-list react-tabs__tab-list--sticky ${hideTabbedSectionsBar &&
              'hidden'}`}
          >
            {(!enableDesktopAgenda || backButtonOnBreakoutRoom) &&
              !hideTabbedSectionsBar && (
                <BackButtonRow>
                  <BackButton
                    textColor={backButtonColor}
                    display={enableDesktopAgenda && !backButtonOnBreakoutRoom}
                    onClick={() =>
                      setActiveAgendaItem(anyActiveItem || backToItem)
                    }
                  >
                    <Flex
                      css={css`
                        font-size: 14px;
                        padding-right: 3px;
                      `}
                    >
                      <ChevronLeft height={12} paddingright={3} />
                    </Flex>
                    <FormattedMessage id="generic.back" />
                  </BackButton>
                </BackButtonRow>
              )}
            {videoUrl && (
              <BackButtonRow>
                <BackButton
                  textColor={backButtonColor}
                  onClick={() => handleVideoBackButton()}
                >
                  <Flex
                    css={css`
                      font-size: 14px;
                      padding-right: 3px;
                      transform: translate3d(0, 0, 0);
                    `}
                  >
                    <ChevronLeft height={12} paddingright={3} />
                  </Flex>
                  <FormattedMessage id="generic.back" />
                </BackButton>
              </BackButtonRow>
            )}
            <Tab className="react-tabs__tab tabs-border">
              <Box>
                <IconDetail
                  icon={
                    <CalendarIcon
                      fill={iconsHex || primaryColor}
                      width="16px"
                      height="18px"
                    />
                  }
                  mr="0"
                  iconMR="7px"
                  lineHeight="1.25em"
                  letterSpacing="0"
                  text="DETAILS"
                  fontSize={2}
                />
              </Box>
            </Tab>

            {!isEmpty(agendaItems) && (
              <Tab className="react-tabs__tab tabs-border">
                <Box>
                  <IconDetail
                    icon={
                      <AgendaIcon
                        fill={iconsHex || primaryColor}
                        width="12px"
                        height="18px"
                      />
                    }
                    mr="0"
                    iconMR="7px"
                    lineHeight="1.25em"
                    letterSpacing="0"
                    text="AGENDA_DETAILS"
                    fontSize={2}
                  />
                </Box>
              </Tab>
            )}
            {activeEvent && !isEmpty(activeEvent.speakers) && (
              <Tab className="react-tabs__tab tabs-border">
                <Box>
                  <IconDetail
                    icon={
                      <SpeakerIcon
                        fill={iconsHex || primaryColor}
                        width="11px"
                        height="18px"
                      />
                    }
                    mr="0"
                    iconMR="7px"
                    lineHeight="1.25em"
                    letterSpacing="0"
                    text="SPEAKERS"
                    fontSize={2}
                  />
                </Box>
              </Tab>
            )}

            {!isEmpty(sponsors) && (
              <Tab className="react-tabs__tab tabs-border">
                <Box>
                  <IconDetail
                    icon={
                      <SponsorIcon
                        fill={iconsHex || primaryColor}
                        width="23px"
                        height="18px"
                      />
                    }
                    mr="0"
                    iconMR="7px"
                    lineHeight="1.25em"
                    letterSpacing="0"
                    text="SPONSORS"
                    fontSize={2}
                  />
                </Box>
              </Tab>
            )}
            {networkingAvailable && (
              <Tab className="react-tabs__tab tabs-border">
                <Box>
                  <IconDetail
                    icon={
                      <NetworkingIcon
                        fill={iconsHex || primaryColor}
                        height="18px"
                      />
                    }
                    mr="0"
                    iconMR="7px"
                    lineHeight="1.25em"
                    letterSpacing="0"
                    text="NETWORKING"
                    fontSize={2}
                  />
                </Box>
              </Tab>
            )}
          </TabList>

          <TabPanel forceRender>
            <Stream
              videoUrl={videoUrl}
              openAsVideoElement={openAsVideoElement}
              isArchived={isArchived}
              isLoading={false}
              exploreItem={exploreItem}
              exploreItemStyle={exploreItemStyle}
              user={user}
              videoReactions={videoReactions}
              avatarBg={avatarBg}
            />
            {zoomOpenInNewTabLink(
              activeAgendaItem,
              backButtonColor,
              anyActiveItem,
              backToItem,
            )}
            {disclaimer && (
              <Disclaimer
                color={toHex(disclaimerText) || theme.colors.textSemiVisible}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: markLinkAsTag(disclaimer),
                  }}
                />
              </Disclaimer>
            )}
            {docsPanelEnabled && (
              <DocumentSection>
                <IconDetail
                  fontColor={toHex(labelText)}
                  icon={
                    !hideIcons && (
                      <DocsIcon
                        fill={
                          (hasCustomStyle && toHex(iconsColor)) || primaryColor
                        }
                        width="16px"
                        height="18px"
                      />
                    )
                  }
                  text={iframeBreakoutRoom ? 'FILES' : 'DOCUMENTS'}
                />
                <DocumentsList
                  iframeBreakoutRoom={iframeBreakoutRoom}
                  handleVideoClick={setVideoUrl}
                  documentStyle={documentStyle}
                  isLoading={isLoadingDocuments}
                  documents={documents}
                  noContentTextColor={noContentText}
                  scrollColor={toHex(sidebarScroll)}
                />
              </DocumentSection>
            )}
          </TabPanel>

          {!isEmpty(agendaItems) && (
            <TabPanel>
              <TabRoundedInside id="tab-panel--agendaItems">
                <ScrolltoWrapper
                  id="tab-panel--agendaItems"
                  scrollTo={scrollTo}
                  clearScroll={() => setStateScrollTo()}
                >
                  {agendaItems.map((agendaItem, id) => {
                    if (
                      agendaItem.agenda_type ===
                        AGENDA_ITEM_TYPES.BREAKOUT_ROOM &&
                      agendaItem.group === null
                    ) {
                      return null;
                    }
                    const agendaItemSpeakers = [];
                    const agendaItemSponsors = [];
                    agendaItem.event_speaker_ids.forEach(evSpeaker => {
                      if (activeEvent.speakers) {
                        const speaker = activeEvent.speakers.find(
                          x => x.id === evSpeaker,
                        );
                        if (speaker) {
                          agendaItemSpeakers.push(speaker);
                        }
                      }
                    });
                    agendaItem.sponsor_ids.forEach(evSponsor => {
                      if (sponsors) {
                        const sponsor = sponsors.find(x => x.id === evSponsor);
                        if (sponsor) {
                          agendaItemSponsors.push(sponsor);
                        }
                      }
                    });
                    return (
                      <AgendaMain
                        id={agendaItem.id}
                        first={!id}
                        key={`agendaItem-${id}`}
                        agendaItemSpeakers={agendaItemSpeakers}
                        agendaItemSponsors={agendaItemSponsors}
                        color={toHex(buttonsBg) || toHex(primaryColor)}
                        handleAgendaMoreButtonClick={
                          handleAgendaMoreButtonClick
                        }
                        last={agendaItems.length === id + 1}
                        agendaDetailsStyle={agendaDetailsStyle}
                        primaryColor={primaryColor}
                        buttonsTextColor={toHex(buttonsTextColor)}
                        {...agendaItem}
                      />
                    );
                  })}
                </ScrolltoWrapper>
              </TabRoundedInside>
            </TabPanel>
          )}

          {activeEvent && !isEmpty(activeEvent.speakers) && (
            <TabPanel>
              <TabRoundedInside>
                <ScrolltoWrapper
                  scrollTo={scrollTo}
                  clearScroll={() => setStateScrollTo()}
                >
                  {activeEvent.speakers.map((speaker, id) => (
                    <SpeakerMain
                      key={`speaker-${id}`}
                      {...speaker}
                      last={activeEvent.speakers.length === id + 1}
                      primaryColor={primaryColor}
                      handleShowBigImage={handleShowBigImage}
                    />
                  ))}
                </ScrolltoWrapper>
              </TabRoundedInside>
            </TabPanel>
          )}

          {!isEmpty(sponsors) && (
            <TabPanel>
              <TabRoundedInside>
                {sponsors.map((sponsor, id) => (
                  <SponsorMain key={`sponsor-${id}`} {...sponsor} />
                ))}
              </TabRoundedInside>
            </TabPanel>
          )}
          {networkingAvailable && (
            <TabPanel>
              <NetworkingMain
                activeEvent={activeEvent}
                openChatWith={() => setStateChatExpanded()}
                chatRoomsEnabled={chatRoomsEnabled}
                buttonsMainColor={toHex(buttonsBg) || toHex(primaryColor)}
                buttonsTextColor={toHex(buttonsTextColor)}
                avatarColor={
                  toHex(chatAvatar) || toHex(chatPrimary) || primaryColor
                }
              />
            </TabPanel>
          )}
        </TabContainer>
      </Flex>
    </ColMiddle>
  );
};
