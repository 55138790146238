import React, { useState } from 'react';
import { Text } from 'rebass';
import styled from '@emotion/styled';
import theme from '../../_styles/theme';

const Tab = ({ children, primaryColor, active, ...rest }) => (
  <li
    style={{
      cursor: 'pointer',
      flexGrow: 1,
      listStyleType: 'none',
      height: '100%',
      borderBottom: `1px solid ${
        active ? primaryColor || '#000' : theme.colors.textSemiVisible
      }`,
      paddingTop: '12px',
      textAlign: 'center',
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    }}
    {...rest}
  >
    {children}
  </li>
);

export const CardTab = ({
  children,
  value,
  active,
  onTabClick,
  primaryColor,
}) => (
  <Tab
    active={active}
    onClick={() => !active && onTabClick(value)}
    primaryColor={primaryColor}
  >
    <Text
      color={active ? primaryColor : 'textSemiVisible'}
      fontSize={2}
      fontWeight="emph"
    >
      {children}
    </Text>
  </Tab>
);

const Container = styled('nav')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const CardTabs = ({
  tabs,
  initialTabIdx,
  primaryColor,
  onTabClick,
  currentlySelected,
}) => {
  const [activeTab] = useState(tabs[initialTabIdx]);

  if (!currentlySelected) {
    return (
      <Container>
        {tabs.map(({ label, value }) => (
          <CardTab
            primaryColor={primaryColor}
            key={`tab-${value}`}
            value={value}
            active={activeTab?.value === value}
            onTabClick={onTabClick}
          >
            {label}
          </CardTab>
        ))}
      </Container>
    );
  }
  return (
    <Container>
      {tabs.map(({ label, value }) => (
        <CardTab
          primaryColor={primaryColor}
          key={`tab-${value}`}
          value={value}
          active={currentlySelected === value}
          onTabClick={onTabClick}
        >
          {label}
        </CardTab>
      ))}
    </Container>
  );
};
