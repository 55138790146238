import React, { useEffect, useState } from 'react';
import { Box, Text, Flex } from 'rebass';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { Button } from '../../../components/Button/Button';
import theme from '../../../_styles/theme';
import { DefaultAvatar } from '../../../_assets/icons/defaultAvatar';
import { LinkedInIcon } from '../../../_assets/icons/linkedinIcon';
import { EnvelopeIcon } from '../../../_assets/icons/envelopeIcon';
import { InitiateChatIcon } from '../../../_assets/icons/initiateChatIcon';
import { CloseIcon } from '../../../_assets/icons/close';
import { Loader } from '../../../components/Loader';

const ModalWrapper = styled(Box)`
  background: ${theme.colors.card};
  width: 100%;
  color: ${theme.colors.textOnWhiteBg};
  overflow: hidden;
`;

const TopBar = styled(Box)`
  width: 100%;
  height: 60px;
  padding: 0 25px;
  background: ${({ bg, defaultColor }) => (bg ? `url("${bg}")` : defaultColor)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled(Box)`
  padding: 40px 25px 20px 25px;
  width: 100%;
`;

const Bio = styled(Text)`
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  word-break: break-word;
  width: 100%;
`;

const Name = styled(Text)`
  width: 100%;
  font-size: 16px;
  font-weight: ${theme.fontWeights.heading};
  margin-bottom: 5px;
`;

const Business = styled(Text)`
  width: 100%;
  font-size: 12px;
  font-weight: ${theme.fontWeights.emph};
  margin-bottom: 15px;
`;

const Job = styled(Text)`
  margin-top: 10px;
  line-height: 1.3em;
  font-size: 12px;
  word-break: break-word;
  color: ${theme.colors.textOnWhiteBg};
`;

const Avatar = styled(Box)`
  position: relative;
  top: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ avatar }) => (avatar ? `url(${avatar})` : '#999')} no-repeat
    center center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkedInButton = styled.a`
  ${({ primaryColor }) => primaryColor && `background: ${primaryColor}`};
  color: #fff;
  padding: 8px 15px;
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
  text-decoration: none;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
`;

const ActionButton = styled(Button)`
  font-family: inherit;
  font-size: 12px;
  width: fit-content;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
  ${({ disabled }) =>
    disabled && `border-color: ${theme.colors.textSemiVisible}`}
  color: ${({ color }) => color || '#fff'};
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const ShareDisclaimer = styled(Box)`
  color: ${theme.colors.textSemiVisible};
  font-size: ${theme.fontSizes[2]}px;
  word-break: break-word;
  width: 100%;
  line-height: 22px;
`;

const MoreView = ({
  id,
  primaryColor,
  avatarColor,
  onClose,
  changeActiveTab,
  changeMobileTab,
  activeEvent,
  chatRoomsEnabled,
  buttonsTextColor,
  openChatWith,
  networkingStore: {
    getParticipant,
    selectedParticipant: person,
    isLoadingParticipant,
    sendParticipantEmail,
    participantEmailSent,
  },
  chatStore: { setActiveChatRoomId },
  chatRoomsStore: { oneToOneChatRoom },
  authStore: { isAuthenticated },
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    getParticipant(id);
  }, []);

  const sendEmail = personId => {
    setButtonClicked(true);
    sendParticipantEmail(personId);
  };

  const emailButtonContent = userName => {
    if (person.already_shared_contact) {
      return (
        <>
          <EnvelopeIcon
            fill="white"
            height="18px"
            width="19px"
            style={{ marginRight: '9px', marginBottom: '1px' }}
          />
          {formatMessage({ id: 'networking.emailSent' })}
        </>
      );
    }

    if (buttonClicked) {
      if (!participantEmailSent)
        return (
          <Loader dark sx={{ height: '12px', width: 'auto !important' }} />
        );

      return (
        <>
          <EnvelopeIcon
            fill="white"
            height="18px"
            width="19px"
            style={{ marginRight: '9px', marginBottom: '1px' }}
          />
          {formatMessage({ id: 'networking.emailSent' })}
        </>
      );
    }
    return (
      <>
        <EnvelopeIcon
          fill={buttonsTextColor || '#fff'}
          height="18px"
          width="19px"
          style={{ marginRight: '9px', marginBottom: '1px' }}
        />
        {formatMessage({ id: 'networking.sendEmail' }, { userName })}
      </>
    );
  };

  const newChatRoomButtonContent = userName => {
    return (
      <>
        <InitiateChatIcon
          fill={buttonsTextColor || '#fff'}
          height="18px"
          width="19px"
          style={{ marginRight: '9px', marginTop: '3px' }}
        />
        {formatMessage({ id: 'chat.newChat' }, { userName })}
      </>
    );
  };

  return (
    <ModalWrapper>
      {!isLoadingParticipant && !isEmpty(person) ? (
        <>
          <TopBar defaultColor={primaryColor}>
            <Avatar avatar={person.avatar.thumb.url}>
              {!person.avatar.thumb.url && (
                <DefaultAvatar fill={avatarColor} width="60px" height="60px" />
              )}
            </Avatar>
            <StyledCloseIcon
              fill={buttonsTextColor}
              width="13px"
              onClick={onClose}
            />
          </TopBar>
          <Content>
            {(person.first_name || person.last_name) && (
              <Name>
                {person.first_name || ''} {person.last_name || ''}
              </Name>
            )}
            {person.job_title && <Job>{person.job_title}</Job>}
            {person.company && <Business>{person.company}</Business>}
            {person.bio && <Bio>{person.bio}</Bio>}
            <ShareDisclaimer mt={15}>
              {formatMessage({ id: 'networking.sendEmailDescription' }).replace(
                /%%SEND_BUTTON_LABEL%%/,
                formatMessage(
                  { id: 'networking.sendEmail' },
                  { userName: person.first_name.toUpperCase() },
                ),
              )}
            </ShareDisclaimer>
            <Box display="flex" mt="10px">
              {person.linkedin_link && (
                <LinkedInButton
                  href={person.linkedin_link}
                  target="_blank"
                  primaryColor={primaryColor}
                >
                  <LinkedInIcon
                    fill="#fff"
                    height="12px"
                    style={{ marginRight: '5px' }}
                  />
                  {formatMessage({ id: 'networking.visitProfile' })}
                </LinkedInButton>
              )}
              {isAuthenticated && (
                <Flex>
                  <ActionButton
                    height="40px"
                    onClick={() => sendEmail(id)}
                    bgColor={primaryColor}
                    disabled={buttonClicked || person.already_shared_contact}
                    color={buttonsTextColor}
                  >
                    {emailButtonContent(person.first_name.toUpperCase())}
                  </ActionButton>
                  {chatRoomsEnabled && (
                    <ActionButton
                      height="40px"
                      ml={2}
                      onClick={() => {
                        if (person.private_chat_room_id) {
                          setActiveChatRoomId(person.private_chat_room_id);
                        }
                        oneToOneChatRoom(activeEvent.id, [id]);
                        if (changeActiveTab && changeMobileTab) {
                          changeActiveTab();
                          changeMobileTab();
                        } else {
                          openChatWith();
                        }
                      }}
                      bgColor={primaryColor}
                      color={buttonsTextColor}
                    >
                      {newChatRoomButtonContent(
                        person.first_name.toUpperCase(),
                      )}
                    </ActionButton>
                  )}
                </Flex>
              )}
            </Box>
          </Content>
        </>
      ) : (
        <Loader dark />
      )}
    </ModalWrapper>
  );
};

export default inject(
  'networkingStore',
  'chatStore',
  'chatRoomsStore',
  'authStore',
)(observer(MoreView));
