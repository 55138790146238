import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Flex, Text, Image } from 'rebass';
import { css } from 'styled-components';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import qs from 'qs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SignInForm from './components/SignInForm';
import routes from '../_app/routing/routes';
import theme from '../_styles/theme';
import StylingUtils from '../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';
import EventBrief from './components/EventBrief';
import { Loader } from '../components/Loader';
import { toHex } from '../_app/utils/otherUtils';
import { CardTabs } from './components/CardTabs';

const Container = styled(Flex)`
  flex-direction: row;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const LeftPart = styled(Box)`
  position: relative;
  width: 44%;
  height: auto;
  padding: 150px 56px 119px 53px;
  ${props =>
    props.backgroundimage &&
    `
      background-image: url(${props.backgroundimage});
      background-position: top left;
      background-repeat: no-repeat;
    `};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: auto;
    padding: 60px 25px 75px 30px;
  }
`;

const RightPart = ({ children, bckg, gradient }) => (
  <Flex
    css={css`
      position: relative;
      flex-direction: column;
      width: 56%;
      height: auto;
      background: ${gradient || bckg || theme.colors.bgLight};
      padding-top: 75px;
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        padding-top: 0;
        min-height: 300px;
      }
      ${StylingUtils.animationFadeIn()}
    `}
  >
    {children}
  </Flex>
);

const EventContainer = styled(Box)`
  z-index: 2;
  width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: auto;
  }
  ${StylingUtils.customScroll(theme.colors.primary)}
`;

const Card = styled(Box)`
  background-color: ${theme.colors.card};
  border-radius: 10px;
  min-height: 520px;
  box-shadow: 0 14px 16px -6px rgba(0, 0, 0, 0.43);
  ${StylingUtils.animationFadeIn()}
`;

const CardContent = styled(Flex)`
  padding: 30px 47px 30px 38px;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 30px 31px 30px 32px;
  }
`;

const CardHeader = styled(Text)`
  font-size: 28px;
  font-weight: ${theme.fontWeights.heading};
  color: ${theme.colors.textDark};
  margin-bottom: 13px;
  text-transform: uppercase;
`;

const CardText = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
`;

const HeaderFixBlock = styled(Box)`
  position: absolute;
  top: -20px;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #000;
`;
@inject(
  'authStore',
  'organizationsStore',
  'eventsStore',
  'homeStore',
  'userStore',
  'eventBriefStore',
)
@observer
class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      sessionExpiredMessage: false,
      translationJsonFetched: false,
    };
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    const {
      history,
      location: { search },
      match: {
        params: { event_id: eventSlug },
      },
      eventsStore: { getEventTranslations },
      eventBriefStore,
      eventBriefStore: { getEventBrief, event },
      authStore: { isAuthenticated, redirectAuthenticated },
      userStore: { hasAcceptedAttendance },
      userStore: { getProfile },
    } = this.props;

    let shouldGoStrightTotheEvent = false;
    if (isAuthenticated) {
      shouldGoStrightTotheEvent = true;
    }

    const { enter, sessionexpired: sessionExpiredMessage } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    if (sessionExpiredMessage) {
      this.setState({ sessionExpiredMessage: true });
    }
    console.log('------------ SIGN-IN PAGE --------------');
    console.log({ event });
    console.log('PUBLIC: ', event && event.public);
    console.log('ENTER: ', enter);
    console.log('IS AUTHENTICATED: ', isAuthenticated);
    console.log(
      'HAS ACCEPTED ATTTENDANCE: ',
      hasAcceptedAttendance(get(eventBriefStore, 'event.id')),
    );

    console.log({ shouldGoStrightTotheEvent });

    const hideEventAccess =
      get(event, 'hide_registration_form') && get(event, 'hide_login_form');

    if (event) {
      await getEventTranslations(event.id);
      this.setState({ translationJsonFetched: true });
    }
    if (shouldGoStrightTotheEvent && !hideEventAccess) {
      await getProfile();
      await redirectAuthenticated(history, eventSlug);
    }
    await getEventBrief({ eventId: eventSlug });
  }

  async componentDidUpdate(prevProps) {
    const {
      history,
      match: {
        params: { event_id: eventSlug },
      },
      location: { search },
      eventBriefStore: { getEventBrief, event: eventBrief },
      eventsStore: { getEventTranslations },
    } = this.props;

    const { translationJsonFetched } = this.state;
    if (!eventSlug && !isEmpty(eventBrief)) {
      history.push(`/sign-up/${eventBrief.slug}${search || ''}`);
    }
    if (
      !isEmpty(eventBrief) &&
      eventBrief.hide_login_form &&
      !eventBrief.hide_registration_form &&
      !eventBrief.public
    ) {
      history.push(`/sign-up/${get(eventBrief, 'slug', '')}${search || ''}`);
    }
    if (eventSlug !== get(prevProps, 'match.params.event_id')) {
      await getEventBrief({ eventId: eventSlug });
    }
    if (
      prevProps.eventBrief !== eventBrief &&
      translationJsonFetched === false &&
      eventBrief
    ) {
      await getEventTranslations(eventBrief.id);
      // eslint-disable-next-line
      this.setState({ translationJsonFetched: true });
    }
  }

  async componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.authStore.resetPasswordMessage = '';
    window.removeEventListener('resize', this.updateWindowDimensions);

    // If we remove event object we get the logo and title disappear for a second
    // const {
    //   eventBriefStore: { clearStore },
    // } = this.props;
    // await clearStore();
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleTabClick = tabValue => {
    const {
      history,
      location: { search },
      eventBriefStore: { event },
    } = this.props;

    history.push(
      `/${tabValue}${
        event && event.slug ? `/${get(event, 'slug')}` : ''
      }${search || ''}`,
    );
  };

  handleOnSubmit = async val => {
    const {
      history,
      match: {
        params: { event_id: eventId },
      },
      location: { search },
      authStore: { signIn },
      userStore: { getProfile, hasAcceptedAttendance, getProfileDetails },
      homeStore: { activeOrganization },
      eventsStore: { getOrganizationEvents },
      eventBriefStore: { event },
    } = this.props;

    const { attendance } = qs.parse(search, { ignoreQueryPrefix: true });

    // eslint-disable-next-line no-return-await
    return await signIn(
      val,
      async () => {
        await getProfile();
        await getProfileDetails();
        await getOrganizationEvents(activeOrganization.id);
        let shouldGoStrightTotheEvent = false;

        if (event) {
          if (hasAcceptedAttendance(event.id)) {
            shouldGoStrightTotheEvent = true;
          }
        } else {
          console.log('!!!! HAVE NO EVENT AFTER SUCCESSFULL SIGN IN !!!!');
        }

        if (shouldGoStrightTotheEvent) {
          const redirUrl = `${routes.home.event}${
            event ? `/${event.slug || eventId}` : ''
          }${attendance ? `/?attendance=${attendance}` : ''}`;
          history.push(redirUrl);
        } else {
          const redirUrl = `${routes.auth.signUp}${
            event ? `/${event.slug || eventId}` : ''
          }${attendance ? `/?attendance=${attendance}` : ''}`;
          history.push(redirUrl);
        }
      },
      async error => {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
        return error;
      },
    );
  };

  render() {
    const { width, sessionExpiredMessage } = this.state;
    const {
      history,
      location: { search, state },
      authStore: { isLoading, resetPasswordMessage },
      eventBriefStore: {
        event,
        noUpcomming,
        isLoading: isLoadingBrief,
        noEventFoundFlag: noEventBriefFoundFlag,
      },
      homeStore: { activeEvent, activeOrganization, eventCustomTheme },
    } = this.props;

    const isMobile = width <= MOBILE_BREAKPOINT;
    const orgColor = get(activeOrganization, 'dominant_color')
      ? `#${get(activeOrganization, 'dominant_color')}`
      : theme.colors.textSemiVisible;
    const primaryColor = get(event, 'organization.dominant_color')
      ? `#${get(event, 'organization.dominant_color')}`
      : orgColor;

    const theEvent = activeEvent || event;
    const {
      buttons_bg: buttonsBg,
      buttons_text_color: buttonsTextColor,
      event_brief_bg: eventBriefBg,
      event_brief_gradient_top: eventBriefGrTop,
      event_brief_gradient_bottom: eventBriefGrBottom,
      header_font_family: headerFontFamily,
      terms_and_conditions_disclaimer: disclaimer,
      hide_terms_and_conditions: hideTermsAndConditions,
    } = eventCustomTheme || {};

    const rightBoxGradient =
      eventBriefGrTop && eventBriefGrBottom
        ? `linear-gradient(
            to bottom,
            ${toHex(eventBriefGrTop)},
            ${toHex(eventBriefGrBottom)}
          );`
        : null;

    if (isEmpty(theEvent) && !eventCustomTheme && !noEventBriefFoundFlag) {
      return null;
    }

    const tabColor = toHex(buttonsBg) || primaryColor;
    const { public: isPublic } = theEvent || {};

    if (isPublic) {
      history.push(`${routes.auth.signUp}/${theEvent.slug}${search || ''}`);
    }
    const initMsg = get(state, 'initMsg');

    const bottomImg = get(
      event,
      'event_custom_theme.landing_page_form_bottom_img.url',
    );

    const hideEventAccess =
      get(event, 'hide_registration_form') && get(event, 'hide_login_form');

    const tabs = [];
    if (event) {
      if (!event.hide_registration_form) {
        tabs.push({
          label: <FormattedMessage id="generic.signUp" />,
          value: 'sign-up',
        });
      }
      if (!event.hide_login_form) {
        tabs.push({
          label: <FormattedMessage id="generic.login" />,
          value: 'sign-in',
        });
      }
    }

    let initialCardTabIdx = 1;
    if (event) {
      if (event.hide_registration_form) {
        initialCardTabIdx = 0;
      } else if (event.hide_login_form) {
        initialCardTabIdx = 0;
      } else if (event.hide_registration_form && !event.hide_login_form) {
        initialCardTabIdx = 0;
      } else {
        initialCardTabIdx = 1;
      }
    }

    return (
      <Container>
        <LeftPart
          backgroundimage={get(
            event,
            'event_custom_theme.landing_page_bckg_img.url',
          )}
        >
          {event && !hideEventAccess && (
            <>
              <Card>
                {event && !isPublic && (
                  <CardTabs
                    primaryColor={
                      StylingUtils.isWhite(tabColor)
                        ? theme.colors.textDark
                        : tabColor
                    }
                    tabs={tabs}
                    initialTabIdx={initialCardTabIdx}
                    onTabClick={this.handleTabClick}
                  />
                )}
                <CardContent>
                  <Box>
                    {event && (
                      <>
                        <CardHeader
                          fontFamily={headerFontFamily || theme.fonts.body}
                        >
                          <FormattedMessage
                            id="signin.accessSimple"
                            values={{ event: event.name }}
                          />
                        </CardHeader>
                        <CardText minHeight="40px">
                          <FormattedMessage
                            id="signin.header"
                            values={{
                              event: event.name,
                              org: get(event, 'organization.name'),
                            }}
                          />
                        </CardText>
                      </>
                    )}
                    {initMsg && (
                      <Box mt={2}>
                        <Text color="error">{initMsg}</Text>
                      </Box>
                    )}
                    {isLoadingBrief && <Loader dark />}
                  </Box>
                  <Box flexGrow={1} height="100%">
                    <SignInForm
                      onSubmit={this.handleOnSubmit}
                      isLoading={isLoading || isLoadingBrief}
                      primaryColor={toHex(buttonsBg) || primaryColor}
                      buttonsTextColor={toHex(buttonsTextColor)}
                      event={event}
                      disclaimer={disclaimer}
                      hideTermsAndConditions={hideTermsAndConditions}
                      sessionExpiredMessage={sessionExpiredMessage}
                    />
                  </Box>
                </CardContent>
              </Card>
              {resetPasswordMessage && <Text>{resetPasswordMessage}</Text>}
            </>
          )}
          {bottomImg && (
            <Flex
              css={css`
                padding-top: 30px;
                align-items: center;
                justify-content: center;
              `}
            >
              <Image src={bottomImg} />
            </Flex>
          )}
        </LeftPart>
        <RightPart bckg={toHex(eventBriefBg)} gradient={rightBoxGradient}>
          <EventContainer>
            <EventBrief
              isLoading={isLoadingBrief}
              event={event}
              noUpcomming={noUpcomming}
              onOpenModal={this.handleModalOpen}
              isMobile={isMobile}
            />
          </EventContainer>
          {!isMobile && <HeaderFixBlock />}
        </RightPart>
      </Container>
    );
  }
}

export default SignInPage;
