import React, { useState } from 'react';
import { Text } from 'rebass';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import theme from '../../_styles/theme';
import { TickIcon } from '../../_assets/icons/tickIcon';
import StylingUtils from '../../_app/utils/stylingUtils';
import { Button } from '../../components/Button/Button';
import { breakpoint } from '../../_styles/breakpoints';

const Container = styled(Box)``;

const BodyText = styled(Text)`
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
  letter-spacing: 0.3px;

  ${StylingUtils.animationFadeIn()};
`;

const Bold = styled('span')`
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.textDark};
`;

const Tick = styled('div')`
  margin: 10px 0 28px;
`;

const Header = styled(Box)`
  color: ${theme.colors.textDark};
  font-size: ${theme.fontSizes[6]}px;
  font-weight: ${theme.fontWeights.heading};
  text-transform: uppercase;
  line-height: 37px;
  letter-spacing: 1px;

  max-width: 300px;
  @media ${breakpoint.xs} {
    padding-right: 20px;
  }
  @media ${breakpoint.sm} {
    max-width: 600px;
    padding-right: 50px;
  }
  @media ${breakpoint.lg} {
    padding-right: 20px;
    max-width: 300px;
  }
  @media ${breakpoint.xl} {
    max-width: 350px;
  }
  @media ${breakpoint.xxl} {
    max-width: 450px;
  }

  margin-bottom: 18px;
`;

const Enter = styled(Box)`
  color: ${theme.colors.text};
  margin-top: 48px;
  text-transform: uppercase;
`;

const CheckboxWrapper = styled(Box)`
  clear: both;
`;

const InputStyled = styled.input`
  margin: 5px 10px 0 0;
  float: left;
`;

const LabelStyled = styled.label`
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 23px;
    height: 100%;
    left: -23px;
  }
`;

export const SignUpNotNeeded = ({
  event,
  primaryColor,
  history,
  fontFamily,
  buttonsTextColor,
}) => {
  const [activeButton, setActiveButton] = useState(false);
  return (
    <Container>
      <Tick sx={{ width: '63px', height: '63px' }}>
        <TickIcon fill={primaryColor} />
      </Tick>
      <Header fontFamily={fontFamily || theme.fonts.body}>
        <FormattedMessage
          id="public.welcome"
          values={{ event: event && event.name }}
        />
      </Header>
      <BodyText>
        <Bold>
          <FormattedMessage id="public.thankyou" />
        </Bold>
      </BodyText>
      <BodyText>
        <FormattedMessage
          id="public.thankyoumessage"
          values={{
            event: event && event.name,
          }}
        />
        {event.optin_box_text && (
          <CheckboxWrapper mt="24px">
            <InputStyled
              id="buttonActivator"
              name="buttonActivator"
              type="checkbox"
              checked={activeButton}
              onChange={e => setActiveButton(e.target.checked)}
            />
            <LabelStyled
              htmlFor="buttonActivator"
              dangerouslySetInnerHTML={{
                __html: event.optin_box_text,
              }}
            />
          </CheckboxWrapper>
        )}
        <Enter>
          <Button
            onClick={() => {
              history.push(`/event/${event.slug}?enter=true`);
            }}
            bgColor={primaryColor}
            disabled={event.optin_box_text ? !activeButton : false}
            color={buttonsTextColor}
            uppercase
          >
            <FormattedMessage id="public.enter" />
          </Button>
        </Enter>
      </BodyText>
    </Container>
  );
};
