import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'rebass';
import styled from 'styled-components';
import StylingUtils from '../../../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';

const Header = styled(Text)`
  margin-top: 150px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 90px;
  }
  ${StylingUtils.animationFadeIn()}
`;
export const NoUpcommingEvent = () => (
  <Header
    fontSize={5}
    fontWeight="heading"
    textAlign="center"
    color="textSecondary"
  >
    <FormattedMessage id="eventbrief.noupcomming.header" />
  </Header>
);
