import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import { Flex, Box, Text, Link } from 'rebass';
import styled from '@emotion/styled';
import TextInput from '../../components/_forms/TextInput';
import {
  composeValidators,
  mustBeValidEmail,
  required,
} from '../../_app/formValidators';
import { Button } from '../../components/Button/Button';
import theme from '../../_styles/theme';

const FieldGroup = styled(Box)`
  margin: 36px 0;
`;

const FieldWrapper = styled(Box)`
  margin-top: 24px;
`;

const BodyText = styled(Text)`
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
`;

const ErrorText = styled(Text)`
  color: red;
  line-height: 22px;
`;
const ResetPasswordForm = ({
  isLoading,
  onSubmit,
  primaryColor,
  buttonsTextColor,
  resetPasswordMessage,
  showErrorMessage,
  signInRedirectPath,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError }) => (
      <form onSubmit={handleSubmit} style={{ height: '100%' }}>
        <Flex
          height="100%"
          justifyContent="space-between"
          flexDirection="column"
        >
          {showErrorMessage && (
            <Box mt={3}>
              <ErrorText>
                <FormattedMessage
                  id="resetpassword.tokeninvalid"
                  values={{
                    a: (...chunks) => (
                      <Link
                        fontSize={3}
                        color={primaryColor}
                        href="/reset-password"
                      >
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              </ErrorText>
            </Box>
          )}
          <FieldGroup>
            <FieldWrapper>
              <Field
                label="EMAIL"
                name="email"
                inputType="email"
                validate={composeValidators(required, mustBeValidEmail)}
                component={TextInput}
                autocomplete="username"
              />
            </FieldWrapper>
            <BodyText mt={4}>
              {resetPasswordMessage && resetPasswordMessage}
            </BodyText>
            {submitError && <ErrorText>{submitError}</ErrorText>}
          </FieldGroup>
          <Box>
            <Box width={1} height="53px">
              <Button
                bgColor={primaryColor}
                onClick={handleSubmit}
                isLoading={isLoading}
                color={buttonsTextColor}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                <FormattedMessage id="resetpassword.button" />
              </Button>
            </Box>
          </Box>
          <Flex
            flexDirection="column"
            width="100%"
            mt="18px"
            mb="8px"
            alignItems="center"
            justifyContent="center"
            color="textDarkSecondary"
            fontSize={3}
          >
            <Box mt={2}>
              <Link
                fontSize={theme.fontSizes[1]}
                color={primaryColor}
                href={signInRedirectPath}
              >
                <FormattedMessage id="resetpassword.signin" />
              </Link>
            </Box>
          </Flex>
        </Flex>
      </form>
    )}
  />
);

export default ResetPasswordForm;
