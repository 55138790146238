import React from 'react';
import { Button as RebassButton } from 'rebass';
import { css } from 'styled-components';
import theme from '../../_styles/theme';

export const ButtonLink = ({
  children,
  color,
  fontSize,
  align,
  paddingLeft,
  ...props
}) => {
  return (
    <RebassButton
      type="submit"
      css={css`
        border: none;
        font-weight: ${theme.fontWeights.body};
        font-size: ${fontSize || '17px'};
        background: transparent;
        cursor: pointer;
        outline: none;
        color: ${color || theme.colors.textDark};
        width: 100%;
        font-size: ${fontSize || '17px'};
        text-align: ${align || 'center'}!important;
        padding-left: ${paddingLeft || '16px'};
        &:hover {
          text-decoration: underline;
        }
      `}
      {...props}
    >
      {children}
    </RebassButton>
  );
};
