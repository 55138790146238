import React from 'react';
import { Flex, Box, Image } from 'rebass';
import styled from '@emotion/styled';
import { DefaultAvatar } from '../../_assets/icons/defaultAvatar';
import { Avatar } from '../../components/HeaderBar/HeaderBar';

const Container = styled(Flex)`
  position: absolute;
  z-index: 99997;
  bottom: 50px;
  left: 0;
  flex-direction: column;
`;

const ReactionContainer = styled(Flex)`
  padding-left: 20px;
  padding-bottom: 10px;

  animation: fade 10s forwards;

  @keyframes fade {
    0%,
    100% {
      opacity: 0;
    }
    10%,
    80% {
      opacity: 1;
    }
  }
`;

const AvatarBox = styled(Box)`
  width: 35px;
  display: ${props => (props.showAvatar ? 'block' : 'none')};
`;

const Content = styled(Flex)`
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  padding-left: ${props => (props.showAvatar ? '10' : '0')}px;
`;

const Img = styled(Image)`
  margin-top: 5px;
  height: 20px;
`;

export const ReactionsList = ({
  videoReactions,
  reactionBorderColor,
  streamSize,
}) => {
  const { width: streamWidth } = streamSize;

  let reactionsShowCount = 5;
  let showAvatar = true;
  if (streamWidth < 800) {
    reactionsShowCount = 4;
  }
  if (streamWidth < 650) {
    reactionsShowCount = 3;
  }
  if (streamWidth < 500) {
    reactionsShowCount = 2;
    showAvatar = false;
  }

  return (
    <Container>
      {videoReactions
        .slice(Math.max(videoReactions.length - reactionsShowCount, 0))
        .map(item => (
          <ReactionContainer key={`reaction_${item.id}`}>
            <AvatarBox showAvatar={showAvatar}>
              {item.avatarUrl ? (
                <Avatar
                  src={item.avatarUrl}
                  role="img"
                  borderColor={reactionBorderColor || '#fff'}
                  customStyles={{
                    height: '35px',
                    width: '35px',
                  }}
                />
              ) : (
                <DefaultAvatar
                  fill={reactionBorderColor || '#fff'}
                  style={{
                    borderColor: 'red',
                  }}
                />
              )}
            </AvatarBox>
            <Content showAvatar={showAvatar}>
              <Box>{item.name}</Box>
              <Box>
                <Img src={`/images/icons/chat/${item.type}.svg`} />
              </Box>
            </Content>
          </ReactionContainer>
        ))}
    </Container>
  );
};
