import { observable, action } from 'mobx';
import get from 'lodash/get';
import homeStore from './homeStore';
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';

class NetworkingStore {
  @observable isLoadingParticipants = false;

  @observable isLoadingParticipant = false;

  @observable participants = [];

  @observable participantsCount = 0;

  @observable participantsEnded = false;

  @observable selectedParticipant = null;

  @observable participantEmailSent = false;

  @observable clearState = false;

  @action getParticipants = async (
    { searchTerm = '', country = '', orderBy = '' },
    page = 1,
    per = 20,
  ) => {
    const eventId =
      get(homeStore.activeEvent, 'id') ||
      get(homeStore.activeAgendaItem, 'event_id');

    this.isLoadingParticipants = true;
    if (page === 1) {
      this.participantsEnded = false;
      this.participants = [];
    }
    if (!eventId || this.participantsEnded) return;

    try {
      const { data } = await API.get(
        `${API_ROUTES.EVENTS()}${eventId}/participants?search=${searchTerm ||
          ''}&country=${country || ''}&per=${per}&page=${page}&order_by=${
          orderBy ? encodeURI(orderBy) : ''
        }`,
      );
      this.participantsCount = data.count;
      this.participants = [...this.participants, ...data.results];
      if (this.participants.length < per * page) this.participantsEnded = true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoadingParticipants = false;
    }
  };

  @action getParticipant = async id => {
    this.isLoadingParticipant = true;

    const eventId =
      get(homeStore.activeEvent, 'id') ||
      get(homeStore.activeAgendaItem, 'event_id');

    if (!eventId) return;

    try {
      this.selectedParticipant = {};
      const { data } = await API.get(
        `${API_ROUTES.EVENTS()}${eventId}/participants/${id}`,
      );
      this.selectedParticipant = data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoadingParticipant = false;
    }
  };

  @action sendParticipantEmail = async id => {
    this.participantEmailSent = false;

    const eventId =
      get(homeStore.activeEvent, 'id') ||
      get(homeStore.activeAgendaItem, 'event_id');

    if (!eventId) return;

    try {
      await API.post(
        `${API_ROUTES.EVENTS()}${eventId}/participants/${id}/request_contact`,
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.participantEmailSent = true;
    }
  };

  @action clearNetworkingStore = () => {
    this.participants = [];
    this.selectedParticipant = null;
    this.clearState = true;
    this.participantsCount = 0;
  };
}

export default new NetworkingStore();
