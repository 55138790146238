import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { Box, Flex } from 'rebass';
import { Loader } from '../../components/Loader';
import DocumentItem from './DocumentItem';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import StylingUtils from '../../_app/utils/stylingUtils';
import theme from '../../_styles/theme';

const DocumentContainer = styled(Flex)`
  padding: 13px 0 10px;
  flex-wrap: nowrap;
  overflow: auto;

  ${props => props.customScroll}
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    margin: 0 20px;
    padding-top: 25px;
    min-height: calc(
      100% - 100px
    ); // hack - for disclaimer to sit on bottom of mobile page always
  }
`;

const DocumentColumn = styled(Box)`
  min-width: 177px;
  margin-left: 30px;
  overflow: hidden;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 0 10px 0;
    width: 100%;
  }
`;

const NoDocs = ({ children, color }) => (
  <Flex
    css={css`
      justify-content: center;
      flex-grow: 1;
      font-size: ${theme.fontSizes[2]}px;
      color: ${color || StylingUtils.hexToRgbA(theme.colors.text, 0.2)};
      line-height: 1.5em;
    `}
  >
    {children}
  </Flex>
);

const content = ({
  documents,
  isMobile,
  documentStyle,
  handleVideoClick,
  iframeBreakoutRoom,
}) =>
  isMobile
    ? documents.map((doc, idx) => (
        <DocumentColumn key={`doc-column-${idx}`}>
          <DocumentItem
            key={`doc-item-${idx}`}
            {...doc}
            documentStyle={documentStyle}
            handleVideoClick={handleVideoClick}
            iframeBreakoutRoom={iframeBreakoutRoom}
          />
        </DocumentColumn>
      ))
    : documents.map((doc, idx) => (
        <DocumentItem
          key={`doc-item-${idx}`}
          {...doc}
          documentStyle={documentStyle}
          handleVideoClick={handleVideoClick}
          iframeBreakoutRoom={iframeBreakoutRoom}
        />
      ));

const noContent = ({ noContentTextColor, isLoading }) =>
  isLoading ? (
    <Loader />
  ) : (
    <NoDocs color={noContentTextColor}>
      <FormattedMessage id="event.nodocs" />
    </NoDocs>
  );

export const DocumentsList = ({
  iframeBreakoutRoom,
  handleVideoClick,
  documentStyle,
  documents,
  isLoading,
  noContentTextColor,
  scrollColor,
  ...props
}) => (
  <DocumentContainer customScroll={StylingUtils.customScroll(scrollColor)}>
    {isEmpty(documents)
      ? noContent({ isLoading, noContentTextColor, documentStyle })
      : content({
          documents,
          handleVideoClick,
          iframeBreakoutRoom,
          documentStyle,
          ...props,
        })}
  </DocumentContainer>
);
