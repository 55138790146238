import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { FormattedMessage } from 'react-intl';
import { css } from 'styled-components';
import theme from '../../_styles/theme';
import { DefaultChat } from '../../_assets/icons/defaultChat';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';

const NewChatRoomBox = ({ onClick, chatStyle, children }) => {
  const { bgColor, text } = chatStyle || {};
  return (
    <Flex
      onClick={onClick}
      css={css`
        align-items: center;
        justify-content: center;
        width: calc(100% - 8px);
        margin-bottom: 7px;
        padding: 14px 20px 14px 13px;
        background-color: ${bgColor || theme.colors.bgNavyBlue};
        color: ${text || '#fff'};
        cursor: pointer;

        border-radius: 10px;

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
        }
      `}
    >
      {children}
    </Flex>
  );
};

const chatRoomAvatarCss = {
  borderRadius: `50%`,
  width: '50px',
  height: '50px',
  minWidth: 'auto',
};

const AvatarWrapper = ({ onClick, children, ...props }) => (
  <Flex
    css={css`
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      min-width: 42px;
    `}
    onClick={onClick}
    {...props}
  >
    {children}
  </Flex>
);

const NewChatRoomBoxMain = ({
  children,
  marginLeft,
  marginRight,
  ...props
}) => (
  <Flex
    css={css`
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: ${marginLeft};
      margin-right: ${marginRight};
      min-height: 63px;
      overflow-y: hidden;
      cursor: pointer;
    `}
    {...props}
  >
    {children}
  </Flex>
);

const ContentText = ({ children }) => (
  <Box
    css={css`
      padding-left: 10px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      font-size: ${theme.fontSizes[0]}px;
      width: 100%;
    `}
  >
    {children}
  </Box>
);

export const NewChatRoom = ({ chatStyle, toggleNewChatRoom }) => {
  const { avatarBckg } = chatStyle || {};

  return (
    <NewChatRoomBox
      onClick={() => {
        toggleNewChatRoom();
      }}
      chatStyle={chatStyle}
    >
      <AvatarWrapper>
        <DefaultChat
          width="25px"
          height="25px"
          fill={avatarBckg}
          style={chatRoomAvatarCss}
        />
      </AvatarWrapper>
      <NewChatRoomBoxMain marginLeft="12px" marginRight={0}>
        <ContentText>
          <Text
            fontSize={1}
            fontWeight={theme.fontWeights.bold}
            lineHeight={1.42}
            letterSpacing="-0.29px"
          >
            <FormattedMessage id="chat.newroomchat" />
          </Text>
        </ContentText>
      </NewChatRoomBoxMain>
    </NewChatRoomBox>
  );
};
