import React from 'react';
import { Image } from 'rebass';
import styled from '@emotion/styled';

const LoaderImage = styled(Image)`
  width: 100%;
  text-align: center;
  filter: drop-shadow(0 0 0 rgba(34, 42, 48, 0.6));
`;

export const Loader = ({ dark, ...props }) => (
  <LoaderImage
    src={dark ? '../../loader-dark.svg' : '../../loader.svg'}
    sx={{ width: 30, height: 30 }}
    {...props}
  />
);
