import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { Loader } from '../../components/Loader';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import StylingUtils from '../../_app/utils/stylingUtils';
import AgendaItem from './AgendaItemsList/AgendaItem';
import { AgendaTimeLine } from './AgendaTimeLine/AgendaTimeLine';
import { AGENDA_ITEM_TYPES } from '../../_app/constants';
import DayBreaker from './AgendaItemsList/components/DayBreaker';
import {
  isMultiDayEvent,
  handleMultiDayAIs,
} from '../../_app/utils/otherUtils';

const Container = styled(Flex)`
  padding-top: 5px;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: row;
    overflow: auto;

    min-height: calc(
      100% - 100px
    ); // hack - for disclaimer to sit on bottom of mobile page always
    width: 100%;
    padding: 25px 20px 0 30px;
    ${StylingUtils.hidingScrollbarFormula()}
  }
`;

const noOtherLiveAIs = (ai, allAis) => {
  let result = true;
  allAis.forEach(agIt => {
    if (agIt.live && agIt.id !== ai.id) {
      result = false;
    }
  });
  return result;
};

export const AgendaContainer = ({
  activeAgendaItem,
  agendaItems,
  customStyles: {
    agendaTimelineStyle,
    agendaItemStyle,
    breakoutItemStyle,
    bubbleStyle,
    chatStyle,
  },
  isLoading,
  onSetActive,
  infoFunc,
  playFunc,
  isOnEventBrief,
  ...props
}) => {
  const isOnEventPage = document.URL.includes('/event/');

  const moreThan1Day = isMultiDayEvent(agendaItems);

  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        !isEmpty(agendaItems) && (
          <Box position="relative" width="100%" height="100%">
            {agendaItems.map((agendaItem, idx) => {
              const isHappeningNow = moment().isBetween(
                agendaItem.start_date,
                agendaItem.end_date,
              );

              let correctStyles = agendaItemStyle;
              if (agendaItem.agenda_type === AGENDA_ITEM_TYPES.BREAKOUT_ROOM) {
                correctStyles = breakoutItemStyle;
              }

              const { days, sortedAIs, isNewDay } = handleMultiDayAIs(
                agendaItems,
                agendaItem,
                moreThan1Day,
                idx,
              );

              return (
                <Box key={agendaItem.id}>
                  {isNewDay && (
                    <DayBreaker
                      agendaItemStyle={agendaItemStyle}
                      dayAgendaItems={
                        sortedAIs[
                          moment(agendaItem.start_date).format('DD-MM-YYYY')
                        ]
                      }
                      idx={idx}
                      days={days}
                    />
                  )}

                  <AgendaItem
                    id={`agenda-item-${agendaItem.id}`}
                    // key={agendaItem.id}
                    bubbleStyle={bubbleStyle}
                    chatStyle={chatStyle}
                    agendaItemStyle={correctStyles}
                    agendaItem={agendaItem}
                    active={
                      activeAgendaItem && agendaItem.id === activeAgendaItem.id
                    }
                    isLive={
                      (agendaItem && agendaItem.live) ||
                      (isHappeningNow &&
                        noOtherLiveAIs(agendaItem, agendaItems))
                    }
                    onSetActive={() => onSetActive && onSetActive(idx)}
                    infoFunc={infoFunc}
                    playFunc={playFunc}
                    isOnEventBrief={isOnEventBrief}
                    {...props}
                  />
                </Box>
              );
            })}
            <Box mb={isOnEventPage ? '120px' : ''}>
              {/* mb - to make visible over shadow */}
              <AgendaItem
                id="last"
                key="last"
                bubbleStyle={bubbleStyle}
                chatStyle={chatStyle}
                agendaItemStyle={agendaItemStyle}
                lastItem
                agendaItem={{
                  start_date: last(agendaItems).end_date,
                  end_date: last(agendaItems).end_date,
                  // location: last(agendaItems).location,
                  description: <FormattedMessage id="agendaitem.end" />,
                }}
                infoFunc={infoFunc}
                playFunc={playFunc}
                isOnEventBrief={isOnEventBrief}
                active={false}
                onSetActive={() => false}
              />
            </Box>
            <AgendaTimeLine
              activeAgendaItem={activeAgendaItem}
              customStyle={agendaTimelineStyle}
              activeIdx={
                activeAgendaItem &&
                agendaItems.findIndex(ai => ai.id === activeAgendaItem.id)
              }
              agendaItems={agendaItems}
              moreThan1Day={moreThan1Day}
            />
          </Box>
        )
      )}
    </Container>
  );
};
