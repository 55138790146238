import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Flex, Text, Image } from 'rebass';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Box } from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import SignUpForm from './components/SignUpForm';
import FileUtil from '../_app/utils/FileUtil';
import routes from '../_app/routing/routes';
import { Loader } from '../components/Loader';
import EventBrief from '../signIn/components/EventBrief';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';
import StylingUtils from '../_app/utils/stylingUtils';
import theme from '../_styles/theme';
import { SignUpSuccess } from './components/SignUpSuccess';
import UserInterestsForm from '../userInterests/UserInterestsForm';
import { SignUpAlreadyRequested } from './components/SignUpAlreadyRequested';
import { SignUpNotNeeded } from './components/SignUpNotNeeded';
import { SignUpNotPossible } from './components/SignUpNotPossible';
import { toHex } from '../_app/utils/otherUtils';
import { CardTabs } from '../signIn/components/CardTabs';

const LoaderWrapper = styled(Box)`
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
`;

const Container = styled(Flex)`
  flex-direction: row;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const LeftPart = styled(Box)`
  position: relative;
  width: 44%;
  height: auto;
  padding: 150px 56px 70px 53px;
  ${props =>
    props.backgroundimage &&
    `
      background-image: url(${props.backgroundimage});
      background-position: top left;
      background-repeat: no-repeat;
    `};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: auto;
    padding: 60px 25px 75px 30px;
  }
`;

const RightPart = ({ children, bckg, gradient }) => (
  <Flex
    css={css`
      position: relative;
      flex-direction: column;
      width: 56%;
      height: auto;
      background: ${gradient || bckg || theme.colors.bgLight};
      padding-top: 75px;
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        padding-top: 0;
        min-height: 300px;
      }
      ${StylingUtils.animationFadeIn()}
    `}
  >
    {children}
  </Flex>
);

const EventContainer = styled(Box)`
  position: relative;
  z-index: 2;
  height: auto;
  overflow-y: auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: auto;
  }
  ${StylingUtils.customScroll(theme.colors.primary)}
`;

const Card = styled(Box)`
  background-color: ${theme.colors.card};
  border-radius: 10px;
  min-height: 520px;
  box-shadow: 0 14px 16px -6px rgba(0, 0, 0, 0.43);
  ${StylingUtils.animationFadeIn()}
`;

const CardContent = styled(Flex)`
  padding: 30px 47px 30px 38px;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 30px 31px 30px 32px;
  }
`;

const CardHeader = styled(Text)`
  font-size: 28px;
  font-weight: ${theme.fontWeights.heading};
  color: ${theme.colors.textDark};
  margin-bottom: 13px;
  text-transform: uppercase;
`;

const CardText = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
`;

const HeaderFixBlock = styled(Box)`
  position: absolute;
  top: -20px;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #000;
`;

@inject(
  'authStore',
  'organizationsStore',
  'eventsStore',
  'homeStore',
  'userStore',
  'eventBriefStore',
)
@observer
class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      showSuccess: false,
      showSuccessAlreadyRequested: false,
      showInterestsForm: false,
      stateResolved: false,
      shouldGotToTheEvent: false,
    };
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const {
      authStore: { isAuthenticated },
      userStore: { getProfile, getProfileDetails },
    } = this.props;

    if (isAuthenticated) {
      await getProfile();
      await getProfileDetails();
    }
    await this.resolveState();
    this.setState({ stateResolved: true });
  }

  async componentDidUpdate(prevProps) {
    const {
      history,
      match: {
        params: { event_id: eventSlug },
      },
      location: { search },
      eventBriefStore: { getEventBrief, event: eventBrief },
      authStore: { isAuthenticated },
    } = this.props;

    if (
      !isEmpty(eventBrief) &&
      eventBrief.hide_registration_form &&
      !isAuthenticated &&
      !eventBrief.public
    ) {
      history.push(`/sign-in/${get(eventBrief, 'slug', '')}${search || ''}`);
    }
    if (!eventSlug && !isEmpty(eventBrief)) {
      history.push(`/sign-up/${get(eventBrief, 'slug', '')}${search || ''}`);
    }
    if (eventSlug !== get(prevProps, 'match.params.event_id')) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showSuccess: false,
        showSuccessAlreadyRequested: false,
        showInterestsForm: false,
        stateResolved: false,
      });
      await getEventBrief({ eventId: eventSlug });
      await this.resolveState();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stateResolved: true });
    }
  }

  async componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.authStore.resetPasswordMessage = '';
    window.removeEventListener('resize', this.updateWindowDimensions);

    // If we remove event object we get the logo and title disappear for a second
    // const {
    //   eventBriefStore: { clearStore },
    // } = this.props;
    // await clearStore();
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  generateTabs = (event, user) => {
    let tabs = [
      {
        label: <FormattedMessage id="generic.signUp" />,
        value: 'sign-up',
      },
      {
        label: <FormattedMessage id="generic.login" />,
        value: 'sign-in',
      },
    ];
    if (event.hide_registration_form)
      tabs = tabs.filter(tab => tab.value !== 'sign-up');
    else if (event.hide_login_form)
      tabs = tabs.filter(tab => tab.value !== 'sign-in');
    if (user) tabs = tabs.filter(tab => tab.value !== 'sign-in');

    return tabs;
  };

  handleTabClick = tabValue => {
    const {
      history,
      location: { search },
      eventBriefStore: { event },
    } = this.props;

    history.push(
      `/${tabValue}${
        event && event.slug ? `/${get(event, 'slug')}` : ''
      }${search || ''}`,
    );
  };

  handleInterestsSubmit = async val => {
    const {
      userStore: { updateProfile, getProfileDetails },
      history,
      eventBriefStore: { event },
    } = this.props;

    const { shouldGotToTheEvent } = this.state;
    let avatar = '';

    if (!isEmpty(val.user.avatar) && typeof val.user.avatar[0] === 'object') {
      avatar = await FileUtil.getBase64(val.user.avatar[0]).catch(e =>
        // eslint-disable-next-line no-console
        console.log(e),
      );
    }

    // eslint-disable-next-line no-return-await
    return await updateProfile(
      { ...val, ...(!!avatar && { avatar }) },
      async () => {
        if (shouldGotToTheEvent) {
          await getProfileDetails();
          const redirUrl = `${routes.home.event}/${event.slug}`;
          history.push(redirUrl);
        } else {
          this.setState({ showSuccess: true, showInterestsForm: false });
        }
      },
    );
  };

  handleOnSubmit = async val => {
    const {
      history,
      match: {
        params: { event_id: eventId, attendance: attendanceParam },
      },
      location: { search },
      authStore: { signUp },
      eventBriefStore: { event },
      userStore: { hasAlreadySignedUpForEvent },
    } = this.props;
    const { token, attendance } = qs.parse(search, { ignoreQueryPrefix: true });

    let avatar = '';
    if (!isEmpty(val.avatar)) {
      avatar = await FileUtil.getBase64(val.avatar[0]).catch(e =>
        // eslint-disable-next-line no-console
        console.log(e),
      );
    }

    const {
      location: { protocol, host },
    } = window;
    const appUrl = `${protocol}//${host}`;

    // eslint-disable-next-line no-return-await
    return await signUp(
      {
        ...val,
        token,
        avatar,
        hasAlreadySignedUpForEvent,
        attendance: attendance || attendanceParam,
        confirm_success_url: `${appUrl}/sign-in/${eventId ||
          get(event, 'slug', '') ||
          ''}`,
      },
      ({ event: theEvent, attendance: theAttendance }) => {
        let shouldGotToTheEvent = false;
        if (theEvent) {
          if (theEvent.public) {
            shouldGotToTheEvent = true;
          }
          if (!theEvent.public) {
            if (theAttendance) {
              // eslint-disable-next-line no-underscore-dangle
              if (theAttendance._status === 'accepted') {
                shouldGotToTheEvent = true;
              }
            }
          }
        }

        const hasInterests = !!(event.interests && event.interests.length);
        if (
          theEvent.networking_available &&
          hasInterests &&
          !hasAlreadySignedUpForEvent(theEvent.id)
        ) {
          this.setState({ showInterestsForm: true, shouldGotToTheEvent });
        } else if (shouldGotToTheEvent) {
          const redirUrl = `${routes.home.event}/${event.slug}`;
          history.push(redirUrl);
        } else {
          this.setState({ showSuccess: true });
        }
      },
      async error => error,
    );
  };

  async resolveState() {
    const {
      history,
      match: {
        params: { event_id: eventSlug },
      },
      location: { search },
      eventBriefStore,
      eventBriefStore: { getEventBrief },
      eventsStore: { getEventTranslations },
      authStore: { isAuthenticated, redirectAuthenticated },
      userStore: {
        hasAcceptedAttendance,
        hasAlreadySignedUpForEvent,
        hasRequestedAttendance,
        getProfile,
      },
    } = this.props;
    await getEventBrief({ eventId: eventSlug });

    const { enter } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    let shoudGoStrightTotheEvent = false;

    console.log('------------ SIGNUP PAGE --------------');
    if (eventBriefStore.event) {
      const { event } = eventBriefStore;
      console.log({ event });
      console.log('PUBLIC: ', event.public);
      console.log('REGISTARTION ALLOWED: ', !event.hide_registration_form);
      console.log('AUTO ACCEPT: ', !event.auto_accept);
      console.log('ENTER: ', enter);
      console.log('IS AUTHENTICATED: ', isAuthenticated);
      console.log(
        'HAS ACCEPTED ATTTENDANCE: ',
        hasAcceptedAttendance(get(eventBriefStore, 'event.id')),
      );
      console.log(
        'HAS REQUESTED ATTTENDANCE: ',
        hasRequestedAttendance(get(eventBriefStore, 'event.id')),
      );
      console.log(
        'HAS REQUESTED AlREADY SIGNED UP FOR THIS EVENT: ',
        hasAlreadySignedUpForEvent(get(eventBriefStore, 'event.id')),
      );

      if (event) {
        await getEventTranslations(event.id);
      }

      const hideEventAccess =
        get(event, 'hide_registration_form') && get(event, 'hide_login_form');

      if (!eventBriefStore.event.public) {
        if (isAuthenticated && !hideEventAccess) {
          await getProfile();
          if (hasAcceptedAttendance(get(eventBriefStore, 'event.id'))) {
            shoudGoStrightTotheEvent = true;
          }
          if (hasRequestedAttendance(get(eventBriefStore, 'event.id'))) {
            this.setState({
              showSuccess: true,
              showInterestsForm: false,
              showSuccessAlreadyRequested: true,
            });
          }
        }
      }
    }

    console.log({ shoudGoStrightTotheEvent });
    if (shoudGoStrightTotheEvent) {
      await redirectAuthenticated(history, eventSlug);
    }
  }

  render() {
    const {
      width,
      showSuccess,
      showSuccessAlreadyRequested,
      showInterestsForm,
      stateResolved,
    } = this.state;
    const {
      match: {
        params: { event_id: eventId },
      },
      location: { search },
      history,
      authStore: { isLoading },
      userStore: { user, isLoading: isLoadingProfile },
      homeStore: { activeOrganization, activeEvent, eventCustomTheme },
      eventBriefStore: {
        event,
        noUpcomming,
        isLoading: isLoadingBrief,
        noEventFoundFlag: noEventBriefFoundFlag,
      },
    } = this.props;
    const isMobile = width <= MOBILE_BREAKPOINT;

    const queryParams = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    const orgColor = get(activeOrganization, 'dominant_color')
      ? toHex(get(activeOrganization, 'dominant_color'))
      : theme.colors.textSemiVisible;
    const primaryColor = get(event, 'organization.dominant_color')
      ? toHex(get(event, 'organization.dominant_color'))
      : orgColor;

    const theEvent = activeEvent || event;

    if (
      isEmpty(theEvent) &&
      !eventCustomTheme &&
      noEventBriefFoundFlag === undefined
    ) {
      return null;
    }

    const {
      buttons_bg: buttonsBg,
      buttons_text_color: buttonsTextColor,
      event_brief_bg: eventBriefBg,
      event_brief_gradient_top: eventBriefGrTop,
      event_brief_gradient_bottom: eventBriefGrBottom,
      terms_and_conditions_disclaimer: disclaimer,
      hide_terms_and_conditions: hideTermsAndConditions,
      header_font_family: headerFontFamily,
    } = eventCustomTheme || {};

    const tabColor = toHex(buttonsBg) || primaryColor;
    const { public: isPublic } = theEvent || {};

    const rightBoxGradient =
      eventBriefGrTop && eventBriefGrBottom
        ? `linear-gradient(
            to bottom,
            ${toHex(eventBriefGrTop)},
            ${toHex(eventBriefGrBottom)}
          );`
        : null;

    const bottomImg = get(
      event,
      'event_custom_theme.landing_page_form_bottom_img.url',
    );
    const SignupFinishedComponent =
      showSuccess && showSuccessAlreadyRequested
        ? SignUpAlreadyRequested
        : SignUpSuccess;

    if (!stateResolved) {
      return (
        <Container>
          <LoaderWrapper>
            <Loader dark />
          </LoaderWrapper>
        </Container>
      );
    }
    return (
      <Container>
        <LeftPart
          backgroundimage={get(
            event,
            'event_custom_theme.landing_page_bckg_img.url',
          )}
        >
          {event && !(event.hide_registration_form && event.hide_login_form) && (
            <Card>
              {event && !isPublic && (
                <CardTabs
                  primaryColor={
                    StylingUtils.isWhite(tabColor)
                      ? theme.colors.textDark
                      : tabColor
                  }
                  tabs={this.generateTabs(event, user)}
                  initialTabIdx={0}
                  onTabClick={this.handleTabClick}
                />
              )}
              <CardContent>
                {(isLoading || isLoadingBrief || isLoadingProfile) && (
                  <Loader dark />
                )}
                {showSuccess && (
                  <SignupFinishedComponent
                    event={event}
                    primaryColor={toHex(buttonsBg) || primaryColor}
                  />
                )}
                {showInterestsForm && (
                  <>
                    <CardHeader>
                      <FormattedMessage id="userInterests.header" />
                    </CardHeader>
                    <UserInterestsForm
                      event={event}
                      primaryColor={toHex(buttonsBg) || primaryColor}
                      buttonsTextColor={toHex(buttonsTextColor)}
                      initialValues={{
                        user: {
                          event_registation_details: {
                            event_id: event.id,
                            networking_enable: true,
                          },
                        },
                      }}
                      onSubmit={this.handleInterestsSubmit}
                      onSkip={() =>
                        this.handleInterestsSubmit({
                          user: {
                            event_registation_details: {
                              event_id: event.id,
                              networking_enable: true,
                            },
                          },
                        })
                      }
                      isLoading={isLoadingProfile}
                    />
                  </>
                )}
                {!(showSuccess || showInterestsForm) &&
                  event &&
                  event.public && (
                    <SignUpNotNeeded
                      event={event}
                      fontFamily={headerFontFamily || theme.fonts.body}
                      primaryColor={toHex(buttonsBg) || primaryColor}
                      history={history}
                      buttonsTextColor={toHex(buttonsTextColor)}
                    />
                  )}
                {!(showSuccess || showInterestsForm) &&
                  event &&
                  !event.public &&
                  event.hide_registration_form && (
                    <SignUpNotPossible
                      event={event}
                      fontFamily={headerFontFamily || theme.fonts.body}
                      primaryColor={toHex(buttonsBg) || primaryColor}
                      history={history}
                    />
                  )}
                {!(showSuccess || showInterestsForm) &&
                  event &&
                  !event.public &&
                  !event.hide_registration_form && (
                    <>
                      <Box>
                        {event && (
                          <>
                            <CardHeader
                              fontFamily={headerFontFamily || theme.fonts.body}
                            >
                              <FormattedMessage
                                id="signup.headerSimple"
                                values={{ event: event.name }}
                              />
                            </CardHeader>
                            <CardText minHeight="40px">
                              <FormattedMessage
                                id={
                                  user
                                    ? 'signup.welcome.existing'
                                    : 'signup.welcome'
                                }
                                values={{
                                  event: event.name,
                                  org: get(event, 'organization.name'),
                                }}
                              />
                            </CardText>
                          </>
                        )}
                        {isLoadingBrief && <Loader dark />}
                      </Box>
                      <Box flexGrow={1} height="100%">
                        <SignUpForm
                          eventId={eventId || get(event, 'slug', '')}
                          shouldSetPassword={!user}
                          user={user}
                          queryParams={queryParams}
                          extraFields={event.registration_fields}
                          onSubmit={this.handleOnSubmit}
                          primaryColor={toHex(buttonsBg) || primaryColor}
                          buttonsTextColor={toHex(buttonsTextColor)}
                          isLoading={isLoading || isLoadingBrief}
                          disclaimer={disclaimer}
                          hideTermsAndConditions={hideTermsAndConditions}
                          activeOrganization={activeOrganization}
                          event={event}
                        />
                      </Box>
                    </>
                  )}
              </CardContent>
            </Card>
          )}
          {bottomImg && (
            <Flex
              css={css`
                padding-top: 30px;
                align-items: center;
                justify-content: center;
              `}
            >
              <Image src={bottomImg} />
            </Flex>
          )}
        </LeftPart>
        <RightPart bckg={toHex(eventBriefBg)} gradient={rightBoxGradient}>
          <EventContainer>
            <EventBrief
              isLoading={isLoadingBrief}
              event={event}
              noUpcomming={noUpcomming}
              isMobile={isMobile}
            />
          </EventContainer>
          {!isMobile && <HeaderFixBlock />}
        </RightPart>
      </Container>
    );
  }
}

export default SignUpPage;
