import { withStyles } from '@material-ui/core';
import React from 'react';
import Box from '@material-ui/core/Box';
import { Text } from 'rebass';
import styled from '@emotion/styled';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import isEmpty from 'lodash/isEmpty';
import theme from '../../_styles/theme';
import { FormError } from './FormError';
import StylingUtils from '../../_app/utils/stylingUtils';

const Label = styled(Text)`
  position: absolute;
  opacity: 0.7;
`;

const FieldWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: inline-block;
  margin-top: 16px;
`;

const IconWrapper = styled(Box)`
  position: absolute;
  right: 23px;
  top: 3px;

  ${StylingUtils.animationFadeIn()}
`;

const styles = () => ({
  select: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    fontWeight: 500,
  },
  inputWhite: {
    color: 'white',
  },
});

const CustomSelect = ({
  meta,
  label,
  input,
  options,
  width,
  classes,
  inputProps,
  labelColor,
  styledWhite,
  disabledIcons,
  ...props
}) => (
  <Box>
    <Box position="relative" width="100%">
      {label && (
        <Label
          color={labelColor || 'textDarkSecondary'}
          htmlFor={label}
          fontSize={theme.fontSizes[1]}
        >
          {label}
        </Label>
      )}
      <FieldWrapper>
        {!disabledIcons && (
          <>
            {meta && meta.touched && (meta.error || meta.submitError) && (
              <IconWrapper color={theme.colors.error}>
                <HighlightOffIcon />
              </IconWrapper>
            )}
            {meta && meta.touched && !meta.error && !meta.submitError && (
              <IconWrapper color={theme.colors.success}>
                <CheckCircleOutlineIcon />
              </IconWrapper>
            )}
          </>
        )}
        <Select
          id={typeof label === 'string' ? label : label?.props?.id}
          className={styledWhite && classes.select}
          fullWidth
          label={' '}
          opt
          onChange={input.onChange}
          value={input.value}
          inputProps={{
            ...inputProps,
            ...(styledWhite && { className: classes.inputWhite }),
          }}
          {...props}
        >
          {!isEmpty(options) &&
            options.map(opt => (
              <MenuItem value={opt.value} key={`option-${opt.value}`}>
                {opt.label}
              </MenuItem>
            ))}
        </Select>
      </FieldWrapper>
    </Box>
    {meta && meta.touched && (meta.error || meta.submitError) && (
      <Box mt={1}>
        <FormError meta={meta} />
      </Box>
    )}
  </Box>
);

export default withStyles(styles)(CustomSelect);
