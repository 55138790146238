import styled from 'styled-components';
import { Text } from 'rebass';
import theme from '../_styles/theme';

const Disclaimer = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textSemiVisible};
  line-height: 22px;
  p {
    margin-bottom: 1em;
  }
  strong {
    font-weight: ${theme.fontWeights.bold};
  }
  em {
    font-style: italic;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  ul {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: disc;
  }
  ol {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: decimal;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export default Disclaimer;
