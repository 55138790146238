import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Image, Text } from 'rebass';
import { useDropzone } from 'react-dropzone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import theme from '../../_styles/theme';
import StylingUtils from '../../_app/utils/stylingUtils';
import { UploadIcon } from '../../_assets/icons/uploadIcon';

const Dropzone = styled(Flex)`
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${props =>
      props.active ? theme.colors.textDark : theme.colors.textSemiVisible};
  border-radius: 3px;
  width: 172px;
  height: 74px;
  padding: 17px;
  cursor: pointer;
`;

const DropzoneText = styled(Text)`
  color: ${props =>
    props.active ? theme.colors.textDark : theme.colors.textSemiVisible};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
`;

const ImageWrapper = styled(Box)`
  position: relative;
  background: ${theme.colors.border};
  height: 60px;
  width: 60px;
`;

const IconWrapper = styled(Box)`
  color: #a94442;
  position: absolute;
  cursor: pointer;
  top: -2px;
  right: -2px;
  :hover {
    color: ${StylingUtils.hexToRgbA('#a94442', 0.7)};
  }
`;

export const ImageUploadInput = ({
  input: { value, onChange, ...input },
  label,
  asDropzone,
  showPreview,
  ...props
}) => {
  const [choosen, setChoosen] = useState(null);
  const [preview, setPreview] = useState(null);

  React.useEffect(() => {
    if (!value.length) {
      setChoosen(null);
      setPreview(null);
    }
  }, [value]);

  const handleChange = ({ target }) => {
    onChange(target.files); // instead of the default target.value
    setChoosen(target.files[0]);
    if (!isEmpty(target.files) && showPreview) {
      const reader = new FileReader();

      reader.onload = e => {
        setPreview(e.target.result);
      };

      reader.readAsDataURL(target.files[0]);
    }
  };
  const elemId = `${label}-file-input`;

  const onDrop = useCallback(acceptedFiles => {
    handleChange({ target: { files: acceptedFiles } });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    onDrop,
  });

  return asDropzone ? (
    <Box mr="10px" mb="10px">
      <Dropzone {...getRootProps()} active={isDragActive || choosen}>
        {showPreview && choosen ? (
          <ImageWrapper>
            <IconWrapper>
              <HighlightOffIcon
                onClick={() => handleChange({ target: { files: [] } })}
              />
            </IconWrapper>
            <Image
              id="avatar-preview"
              type="file"
              src={preview}
              style={{ height: '100%', width: 'auto', borderRadius: '5px' }}
            />
          </ImageWrapper>
        ) : (
          <>
            <input {...getInputProps()} />
            <UploadIcon
              fill={theme.colors.textSemiVisible}
              width={32}
              height={40}
              style={{ flexShrink: 0 }}
            />
            <DropzoneText
              active={isDragActive}
              ml={13}
              fontSize="10px"
              lineHeight="1.2em"
            >
              <FormattedMessage id="settings.field.image.uploadincent" />
            </DropzoneText>
          </>
        )}
      </Dropzone>
    </Box>
  ) : (
    <Box mr="10px" mb="10px">
      {label && (
        <Text
          color="textDarkSecondary"
          fontSize={theme.fontSizes[1]}
          htmlFor={elemId}
          mb={5}
        >
          {label}
        </Text>
      )}
      <input {...input} type="file" onChange={handleChange} {...props} />
      {choosen && (
        <Text color="textDarkSecondary" fontSize={1}>
          {choosen.name}
        </Text>
      )}
    </Box>
  );
};
