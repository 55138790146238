import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Flex, Box, Text, Link } from 'rebass';
import get from 'lodash/get';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import TextInput from '../../components/_forms/TextInput';
import {
  composeValidators,
  mustBeValidEmail,
  required,
} from '../../_app/formValidators';
import { markLinkAsTag } from '../../_app/utils/otherUtils';
import { Button } from '../../components/Button/Button';
import Disclaimer from '../../components/Disclaimer';

const FieldGroup = styled(Box)`
  width: 100%;
`;

const ErrorText = styled(Text)`
  color: red;
  line-height: 22px;
`;
const FieldWrapper = styled(Box)`
  margin-top: 18px;
`;

const SignInForm = ({
  onSubmit,
  isLoading,
  event,
  primaryColor,
  buttonsTextColor,
  disclaimer,
  isOnRoot,
  sessionExpiredMessage,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitErrors }) => (
      <form onSubmit={handleSubmit} style={{ height: '100%' }}>
        <Flex justifyContent="space-between" flexDirection="column">
          {sessionExpiredMessage && (
            <Box mt={3}>
              <ErrorText>
                <FormattedMessage
                  id="signin.sessionexpired"
                  values={{
                    a: (...chunks) => (
                      <Link fontSize={3} color={primaryColor} href="/sign-in">
                        {chunks}
                      </Link>
                    ),
                    br: () => <br />,
                  }}
                />
              </ErrorText>
            </Box>
          )}
          <FieldGroup>
            {event && event.id && (
              <Field
                name="event_id"
                id="event_id-input"
                type="hidden"
                defaultValue={event.id}
                component="input"
              />
            )}
            <FieldWrapper>
              <Field
                label={<FormattedMessage id="signin.email" />}
                name="email"
                id="email-input"
                width="100%"
                inputType="email"
                validate={composeValidators(required, mustBeValidEmail)}
                component={TextInput}
                autocomplete="username"
              />
            </FieldWrapper>
            <FieldWrapper mt="27px">
              <Field
                label={<FormattedMessage id="signin.pass" />}
                name="password"
                id="password-input"
                width="100%"
                inputType="password"
                validate={required}
                component={TextInput}
                autocomplete="current-password"
              />
            </FieldWrapper>
            {!isEmpty(submitErrors) && typeof submitErrors[0] === 'string' && (
              <Box mt={3}>
                <ErrorText>{submitErrors[0]}</ErrorText>
              </Box>
            )}
            {disclaimer && (
              <Disclaimer mt="20px">
                <div
                  dangerouslySetInnerHTML={{
                    __html: markLinkAsTag(disclaimer),
                  }}
                />
              </Disclaimer>
            )}
          </FieldGroup>
          <Box mt="27px">
            <Box width={1} height="53px">
              <Button
                bgColor={primaryColor}
                onClick={handleSubmit}
                isLoading={isLoading}
                color={buttonsTextColor}
              >
                <FormattedMessage id="signin.login" />
              </Button>
            </Box>
          </Box>
          <Flex
            flexDirection="column"
            width="100%"
            mt="18px"
            mb="8px"
            alignItems="center"
            justifyContent="center"
            color="textDarkSecondary"
            fontSize="12px"
          >
            <Link
              color={primaryColor}
              // href="/reset-password"
              href={`/reset-password/${event ? event.slug : ''}`}
            >
              <FormattedMessage id="signin.forgot" />
            </Link>
            {!isOnRoot && (!event || !event.hide_registration_form) && (
              <Box mt={2} fontSize="12px">
                <FormattedMessage
                  id="signin.signup"
                  values={{
                    a: (...chunks) => (
                      <Link
                        color={primaryColor}
                        href={
                          event && event.slug
                            ? `/sign-up/${get(event, 'slug', '')}`
                            : '/sign-up'
                        }
                      >
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </form>
    )}
  />
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignInForm;
