import React from 'react';
import Box from '@material-ui/core/Box';
import styled from '@emotion/styled';
import StylingUtils from '../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';

const Wrapper = styled(Box)`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ShadowLayer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${StylingUtils.hexToRgbA('#000', 0.6)};
`;

const ContentWrapper = styled(({ fullSize, ...rest }) => <Box {...rest} />)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${props =>
    props.fullSize &&
    `
      width: 94%;
      min-width: 370px;
      max-width: 2000px;
      min-height: 220px;
      height: 94%;
      transform: translate(-50%,-50%);
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 96%;
        height: 98%;
      }
    `}
`;

export const Modal = ({ children, onClose, fullSize, ...props }) => {
  return (
    <Wrapper {...props}>
      <ShadowLayer onClick={onClose} />
      <ContentWrapper fullSize={fullSize}>{children}</ContentWrapper>
    </Wrapper>
  );
};
