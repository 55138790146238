/* eslint-disable */
import React from 'react';

export const SendIcon = ({ fill, ...props }) => (
  <svg width="18px" height="18px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Send" transform="translate(-8.000000, -3.000000)" fill={fill || '#fff'} fillRule="nonzero">
        <path d="M9.10557281,12.2111456 L8.96039971,12.2909358 C7.65293292,13.0781358 7.67533756,15.0268467 9.05882353,15.7647059 L14.232,18.523 L14.1892046,18.6058673 C14.0654916,18.8696991 13.9999957,19.1593593 13.9999957,19.455 L13.9999957,23 L14.0052324,23.1524334 C14.1299381,24.9524765 16.4523043,25.6921767 17.5852193,24.2194588 L19.716,21.448 L24.0587137,23.7646473 C25.2584377,24.404596 26.7332656,23.6866128 26.9695671,22.3475707 L29.9695671,5.34757067 C30.2551522,3.7292553 28.5754037,2.47623019 27.1055728,3.21114562 L9.10557281,12.2111456 Z M10,14 L28,5 L25,22 L19.164,18.887 L16,23 L16,19.455 L22.5171639,11.7525269 C22.5798922,11.6783899 22.5802975,11.5699051 22.5181249,11.4953016 C22.461553,11.4274186 22.3695262,11.4065064 22.2915333,11.4370628 L22.2364431,11.4697008 L15.61,16.992 L10,14 Z" id="Path"></path>
      </g>
    </g>
  </svg>
);
