/* eslint-disable */
import React from 'react';

export const PlayIcon = ({ fill, ...props }) => (
  <svg width={25} viewBox="0 0 560 560" {...props}>
    <path
      fill="#fff"
      d="M486.413 221.412L122.347 12.916c-52.938-30.318-95.852-5.44-95.852 55.563v415.652c0 61.004 42.914 85.882 95.852 55.563l364.066-208.49c52.938-30.317 52.938-79.473 0-109.792z"
    />
  </svg>
);
