import { action, observable } from 'mobx';
import { FORM_ERROR } from 'final-form';
import ReactGA from 'react-ga';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import chatRoomsStore from './chatRoomsStore';
import { API, setAnonChatId } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';
import { LS_KEYS } from '../_app/utils/LocalStorageManager';
import userStore, { UserStore } from './userStore';

// const asyncForEach = async (array, callback) => {
//   for (let index = 0; index < array.length; index += 1) {
//     // eslint-disable-next-line no-await-in-loop
//     await callback(array[index], index, array);
//   }
// };

export class ChatStore {
  @observable isLoading = false;

  @observable isSuggestionsFetching = false;

  @observable chat = {};

  @observable activeChatRoomId = null;

  @observable activeChatRoom = {};

  @observable activeChatRoomUsers = [];

  @observable chatRoomNumberOfUsers = null;

  @observable activeChatRoomName = '';

  @observable userTaggingSuggestions = [];

  @observable chatPagination = { last: false };

  @observable isLoadingMore = false;

  @action
  loadMoreMessages = async (page, chatRoomId) => {
    this.isLoadingMore = true;
    const { [LS_KEYS.ANONYM_CHAT_ID]: anonChatId } = Cookies.get();

    try {
      const {
        data: { results, count },
      } = await API.get(
        `${API_ROUTES.CHAT_ARCHIVE(chatRoomId)}?${
          LS_KEYS.ANONYM_CHAT_ID
        }=${anonChatId}&page=${page}&per=40`,
      );

      this.chat.messages = uniqBy([...this.chat.messages, ...results], 'id');

      if (this.chat.messages.length >= count) {
        this.chatPagination.last = true;
      } else {
        this.chatPagination.last = false;
      }
    } catch (e) {
      return { [FORM_ERROR]: "Couldn't get chat archive" };
    } finally {
      this.isLoadingMore = false;
    }
  };

  @action
  getChatParticipants = async chatRoomId => {
    const { [LS_KEYS.ANONYM_CHAT_ID]: anonChatId } = Cookies.get();
    try {
      this.chat.participants = [];

      const {
        data: { participants },
      } = await API.get(
        `${API_ROUTES.CHAT_PARTICIPANTS(chatRoomId)}?${
          LS_KEYS.ANONYM_CHAT_ID
        }=${anonChatId}`,
      );
      this.chat.participants = participants;
      return null;
    } catch (e) {
      return { [FORM_ERROR]: "Couldn't get chat participants" };
    }
  };

  @action
  getChatArchive = async chatRoomId => {
    this.isLoading = true;
    const { [LS_KEYS.ANONYM_CHAT_ID]: anonChatId } = Cookies.get();

    try {
      this.chat.messages = [];

      const {
        data: { results },
      } = await API.get(
        `${API_ROUTES.CHAT_ARCHIVE(chatRoomId)}?${
          LS_KEYS.ANONYM_CHAT_ID
        }=${anonChatId}&per=40`,
      );

      this.chat.messages = results;
    } catch (e) {
      return { [FORM_ERROR]: "Couldn't get chat archive" };
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  chatSubscribe = async chatRoomId => {
    this.isLoading = true;
    try {
      await API.post(API_ROUTES.CHAT_ARCHIVE(chatRoomId));
    } catch (e) {
      return { [FORM_ERROR]: "Couldn't get chat archive" };
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  updateReaction = async (msgId, reaction) => {
    try {
      const { [LS_KEYS.ANONYM_CHAT_ID]: anonChatId } = Cookies.get();
      const resp = await API.post(API_ROUTES.CHAT_MSG_REACTION(msgId), {
        anonymous_chat_user_id: anonChatId,
        reaction_type: reaction,
      });
      const msgIndex = this.chat.messages.findIndex(m => m.id === msgId);
      this.chat.messages[msgIndex] = {
        ...this.chat.messages[msgIndex],
        user_reaction: resp.status === 200 ? resp.data.user_reaction : reaction, // used response reaction to fix ability to remove reaction
      };
    } catch (e) {
      return { [FORM_ERROR]: "Couldn't get chat archive" };
    }

    return null;
  };

  @action
  sendMsg = async (payload, isAnonymous) => {
    const {
      [LS_KEYS.ANONYM_CHAT_NAME]: anonChatName,
      [LS_KEYS.ANONYM_CHAT_ID]: anonChatId,
    } = Cookies.get();

    // hack - first anon msg sent received from websocket before id set in cookie
    if (isAnonymous && !anonChatId) {
      this.forceDelayAddMsg = true;
    }
    try {
      const {
        data: { anonymous_chat_user: anonymousChatUser },
      } = await API.post(API_ROUTES.CHAT_MSG, {
        ...payload,
        anonymous_chat_user_id: anonChatId,
        anonymous_chat_user_name: anonChatName,
      });

      if (!anonChatId && anonymousChatUser) {
        await setAnonChatId(anonymousChatUser.id);
        await userStore.setAnonUser({ id: anonymousChatUser.id });
      }

      ReactGA.event({
        category: 'Chat',
        action: 'Post',
        label: get(payload, 'message'),
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('ERROR:', e);
    }

    return null;
  };

  @action
  addNewMsg = async msg => {
    try {
      // hack - to slow down msg addition - so that set setAnonChatId() sets before displaying
      setTimeout(
        () => {
          if (this.chat.messages.find(m => m.id === msg.id)) {
            if (msg.deleted === true) {
              this.chat.messages = this.chat.messages.filter(
                m => m.id !== msg.id,
              );
            } else if (msg.user_reaction === -1) {
              this.chat.messages = this.chat.messages.map(m => {
                if (m.id === msg.id) {
                  if (msg.user_reaction === -1) {
                    msg.user_reaction = m.user_reaction;
                  }
                  return msg;
                }
                return m;
              });
            }
          } else if (msg.deleted !== true) {
            this.chat.messages.unshift(msg);
          }

          this.forceDelayAddMsg = false;
        },
        this.forceDelayAddMsg ? 500 : 0,
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  @action
  getUserTagSuggestions = async (query, eventId) => {
    this.isSuggestionsFetching = true;

    try {
      const { [LS_KEYS.ANONYM_CHAT_ID]: savedAnonChatId } = Cookies.get();
      const finalAnonId =
        !isEmpty(get(UserStore, 'anonUser.id')) || savedAnonChatId;

      const { data: attendees } = await API.get(
        `${API_ROUTES.EVENT_ATTENDEES({ eventId, query })}&${
          LS_KEYS.ANONYM_CHAT_ID
        }=${finalAnonId || ''}`,
      );

      this.userTaggingSuggestions = attendees || [];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isSuggestionsFetching = false;
    }
  };

  @action
  setActiveChatRoomId = activeChatRoomId => {
    this.activeChatRoomId = activeChatRoomId;
    this.activeChatRoom =
      chatRoomsStore.chatRooms?.find(chat => chat.id === activeChatRoomId) ||
      {};
    if (this.activeChatRoom) {
      this.activeChatRoomUsers = this.activeChatRoom.room_users || [];
    }
  };

  @action
  setActiveChatNumberOfUsers = numberOfUsers => {
    this.chatRoomNumberOfUsers = numberOfUsers;
  };

  @action
  setActiveChatRoomName = activeChatRoomName => {
    this.activeChatRoomName = activeChatRoomName;
  };

  @action clearSuggestions = async () => {
    this.userTaggingSuggestions = [];
  };

  @action
  clearStore = async () => {
    this.chat = {};
    this.userTaggingSuggestions = [];
    this.activeChatRoomName = '';
    this.activeChatRoomId = null;
    this.chatRoomNumberOfUsers = null;
  };
}

export default new ChatStore();
