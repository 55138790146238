import { action, observable } from 'mobx';
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';

export class PollStore {
  @observable isLoading = false;

  @observable pollQuestions = null;

  @observable pollQuestionIntervalHandler = null;

  @action
  sendVote = async vote => {
    this.isLoading = true;
    try {
      await API.post(API_ROUTES.POLL_ANSWER(vote.id));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('ERROR:', e);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  getPollQuestions = async activeEvent => {
    this.isLoading = true;
    try {
      const { data } = await API.get(API_ROUTES.EVENT_POLL(activeEvent.id));
      const pollQuestions = data
        .map(p => p.poll_questions)
        .reduce((acc, val) => acc.concat(val), []);
      this.pollQuestions = pollQuestions;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('ERROR:', e);
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  setPollQuestions = async pollQuestions => {
    this.isLoading = true;
    try {
      this.pollQuestions = pollQuestions;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('ERROR:', e);
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action clearStore = async () => {
    this.pollQuestions = [];

    clearInterval(this.pollQuestionIntervalHandler);
    this.pollQuestionIntervalHandler = null;
  };
}

export default new PollStore();
