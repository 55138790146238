import React from 'react';

import Cookies from 'js-cookie';
import modalStore from '../../stores/modalStore';
import userStore from '../../stores/userStore';
import IEBrowserModalContent from './Modals/IEBrowserModalContent';

export const INTERNET_EXPLORER = 'ie';
export const SAFARI = 'safari';

const closeModalWithCookie = browser => {
  Cookies.set(`${browser}-browser`, 'true', { expires: 1, path: '/' });
  modalStore.setVisibility(false);
};

export const browserCompatibility = (
  whichBrowser,
  showModal,
  forcePopup = false,
) => {
  // forcePopup forces showing modal despite set cookie
  if (whichBrowser === INTERNET_EXPLORER) {
    const isIE = /Trident|MSIE/.test(navigator.userAgent);
    if (isIE && showModal && (!Cookies.get('ie-browser') || forcePopup)) {
      modalStore.setVisibility(
        true,
        <IEBrowserModalContent
          closeModalCookie={() => closeModalWithCookie(INTERNET_EXPLORER)}
        />,
      );
    }
    userStore.isUsingIE = isIE;
    return isIE;
  }
  if (whichBrowser === SAFARI) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (
      isSafari &&
      showModal &&
      (!Cookies.get('safari-browser') || forcePopup)
    ) {
      modalStore.setVisibility(
        true,
        <IEBrowserModalContent
          closeModalCookie={() => closeModalWithCookie(SAFARI)}
        />,
      );
    }
    userStore.isSafari = isSafari;
    return isSafari;
  }

  return undefined;
};
