import { Box, Flex, Text } from 'rebass';
import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled from '@emotion/styled';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FormError } from './FormError';
import theme from '../../_styles/theme';
import StylingUtils from '../../_app/utils/stylingUtils';

const IconWrapper = styled(Box)`
  ${StylingUtils.animationFadeIn()}
`;

const Checkbox = ({
  input,
  meta,
  checkboxContent,
  simpleCheckbox,
  ...custom
}) => {
  return (
    <Box mt={2}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex justifyContent="flex-start" width="100%" alignItems="flex-start">
          <input {...input} {...custom} />
          <Text
            ml={1}
            mt="2px"
            fontSize={simpleCheckbox ? theme.fontSizes[1] : theme.fontSizes[1]}
            color="textSemiVisible"
            width="90%"
          >
            {checkboxContent}
          </Text>
        </Flex>
        {meta &&
          meta.touched &&
          (meta.error || meta.submitError) &&
          !simpleCheckbox && (
            <IconWrapper color={theme.colors.error}>
              <HighlightOffIcon />
            </IconWrapper>
          )}
        {meta &&
          meta.touched &&
          !meta.error &&
          !meta.submitError &&
          !simpleCheckbox && (
            <IconWrapper color={theme.colors.success}>
              <CheckCircleOutlineIcon />
            </IconWrapper>
          )}
      </Flex>
      {meta && (meta.error || meta.submitError) && !simpleCheckbox && (
        <Box mt={1}>
          <FormError meta={meta} />
        </Box>
      )}
    </Box>
  );
};

export default Checkbox;
