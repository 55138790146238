import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { Box, Flex, Image } from 'rebass';
import get from 'lodash/get';
import theme from '../../../_styles/theme';

const BUBBLE_SIZE = '28px';
const PictureWrapper = styled(Box)`
  position: absolute;
  z-index: ${props => props.zIndex};
  height: ${BUBBLE_SIZE};
  width: ${BUBBLE_SIZE};
  background-color: white;
  border: 1px solid ${props => props.customStyle && props.customStyle.border};
  left: ${props => props.index * 20}px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    z-index: ${props => props.zIndex + 1};
    height: calc(${BUBBLE_SIZE} + 6px);
    width: calc(${BUBBLE_SIZE} + 6px);
  }
`;

const Picture = styled(Image)`
  position: absolute;

  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  max-width: initial;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const useStyle = makeStyles({
  tooltip: {
    backgroundColor: props => props.bgColor || theme.colors.bgNavyBlue,
    color: props => (props.primary ? props.primary : '#fff'),
    fontSize: theme.fontSizes[2],
    border: '1px solid #fff',
  },
  arrow: {
    '&::before': {
      border: '1px solid #fff',
      color: props => props.bgColor || theme.colors.bgNavyBlue,
    },
  },
});

const Bubble = ({ index, zIndex, speaker, style, onClick, tooltipStyle }) => {
  const { bgColor } = tooltipStyle;
  const classes = useStyle({
    bgColor,
  });

  return (
    <Tooltip
      title={speaker.name}
      TransitionComponent={Zoom}
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <PictureWrapper
        customStyle={style}
        index={index}
        zIndex={zIndex}
        onClick={onClick}
      >
        <Picture
          src={get(speaker, 'image.thumb.url')}
          alt={`speaker-thumbnail-${speaker.first_name}-${speaker.last_name}`}
        />
      </PictureWrapper>
    </Tooltip>
  );
};

const Container = styled(Flex)`
  position: relative;
  align-items: center;
  overflow-x: scroll;
  // height: calc(${BUBBLE_SIZE} + 16px);
  height: 36px;
  width: 100%;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const BASE_Z_INDEX = 1;
export const SpeakersBubbles = ({
  bubbleStyle,
  onClick,
  speakers,
  tooltipStyle,
}) => (
  <Container>
    {speakers.map((sp, idx) => (
      <Bubble
        zIndex={BASE_Z_INDEX + (speakers.length - idx)}
        index={idx}
        speaker={sp}
        style={bubbleStyle}
        onClick={e => onClick(sp, e)}
        tooltipStyle={tooltipStyle}
      />
    ))}
  </Container>
);
