import React from 'react';
import { Flex, Text } from 'rebass';
import Box from '@material-ui/core/Box';
import styled from '@emotion/styled';
import { css } from 'styled-components';

const Dot = styled(Box)`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: red;
`;

const Wrapper = ({ children }) => (
  <Flex
    css={css`
      align-items: center;
      padding-bottom: 5px;
      top: -13px;
    `}
  >
    {children}
  </Flex>
);

const Label = styled(Text)`
  color: red;
  text-transform: uppercase;
  margin-left: 4px;
  font-size: 75%;
  font-weight: 900;
`;

export const LiveLabel = ({ ...props }) => (
  <Wrapper {...props}>
    <Dot />
    <Label>LIVE</Label>
  </Wrapper>
);
