/* eslint-disable */
import React from 'react';

export const ChatIcon = ({ fill, ...props }) => (
  <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="VideoPlayback" transform="translate(-1135.000000, -92.000000)" stroke={fill || '#fff'} strokeWidth="2">
            <g id="chat" transform="translate(1135.000000, 92.000000)">
                <g id="Group" transform="translate(0.202750, 0.448753)">
                    <path d="M20.6848542,1 L2.26314577,1 C1.91466211,1 1.59868486,1.14159691 1.36982504,1.37016402 C1.14168171,1.59801554 1,1.91259097 1,2.26025112 L1,16.7910796 C1,17.1363191 1.14385296,17.4509088 1.37318183,17.6794942 C1.60238505,17.9079544 1.91824245,18.0513308 2.26760199,18.0513308 L8.59080572,18.0513308 L15.7662902,21.9129181 L15.7662902,18.0513308 L20.681261,18.0513308 C21.0302291,18.0513308 21.3473452,17.9089725 21.5772338,17.6797418 C21.8054587,17.4521702 21.948,17.1382639 21.948,16.7910796 L21.948,2.26025112 C21.948,1.91479055 21.8044903,1.59990605 21.5754331,1.37114185 C21.3472143,1.14321489 21.0327618,1 20.6848542,1 Z" id="shape"></path>
                    <line x1="6.60124983" y1="11.8912467" x2="13.1033366" y2="11.8912467" id="line" strokeLinecap="round"></line>
                    <line x1="6.60124983" y1="7.48924668" x2="16.5217418" y2="7.48924668" id="line" strokeLinecap="round"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
);
