import get from 'lodash/get';
import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import theme from '../_styles/theme';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';

const Container = styled(Box)`
  margin-top: 30px;
  color: ${theme.colors.textOnWhiteBg};
  font-size: ${theme.fontSizes[1]}px;
  border-bottom: ${props => props.borderWidth} solid
    ${props => props.primaryColor};
  padding-bottom: 25px;
  &:first-of-type {
    margin-top: 0;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 0;
    margin-bottom: 30px;
    color: #637781;
    font-size: ${theme.fontSizes[3]}px;
  }
`;

const SpeakerThumbnail = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  flex-shrink: 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 94px;
    width: 94px;
    height: 94px;
    box-shadow: 0 13px 15px -6px rgba(0, 0, 0, 0.6);
  }

  &:hover {
    cursor: ${props => (props.hasImg ? 'pointer' : 'default')};
  }
`;

const SpeakerImage = styled('img')`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

const Name = styled(Text)`
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.almostBold};
  line-height: 1.3em;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 26px;
    color: #1d2534;
    font-weight: ${theme.fontWeights.almostBold};
  }
`;

const Details = styled(Text)`
  font-weight: ${theme.fontWeights.emph};
  line-height: 1.2em;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 14px;
    color: #6e828d;
  }
`;

const Bio = styled(Text)`
  margin-right: 25px;
  line-height: 1.25em;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 30px;
  }
`;

const SpeakerMain = ({
  image,
  name,
  job,
  company_name: companyName,
  bio,
  primaryColor,
  last,
  handleShowBigImage,
}) => {
  return (
    <Container
      primaryColor={primaryColor}
      borderWidth={last ? '0px' : '1px'}
      id={(name || '').toLowerCase().replace(/\s/g, '')}
    >
      <Flex mb={15} flex="1 0 0" flexDirection="row">
        <SpeakerThumbnail
          onClick={() => handleShowBigImage({ imgUrl: image.url })}
          hasImg={image.url !== null}
        >
          {get(image, 'thumb.url') && (
            <SpeakerImage src={get(image, 'thumb.url')} alt={name} />
          )}
        </SpeakerThumbnail>
        <Flex flexDirection="column" justifyContent="center">
          {name && <Name>{name}</Name>}
          {job && <Details>{job}</Details>}
          {companyName && <Details>{companyName}</Details>}
        </Flex>
      </Flex>
      {bio && (
        <Bio>
          <div
            dangerouslySetInnerHTML={{
              __html: bio,
            }}
          />
        </Bio>
      )}
    </Container>
  );
};

export default SpeakerMain;
