export const ATTENDANCE_STATUSES = {
  NONE: 'none',
  ACCEPTED: 'accepted',
  REQUESTED: 'requested',
  INVITED: 'invited',
};

export const AGENDA_ITEM_TYPES = {
  REGULAR: 'agenda_item',
  BREAKOUT_ROOM: 'breakout_room',
};

export const POLL_INTERVAL = 20000;

export const CHAT_ROOMS_INTERVAL = 20000;

export const MEDIA_URLS = {
  email: email => `mailto:${email}`,
  website: website => {
    return website && website.match(/http(s)?:\/\//)
      ? website
      : `https://${website}`;
  },
};

export const REACTION_TYPES = [
  'like',
  'love',
  'lol',
  'angry',
  'sad',
  'confused',
];
