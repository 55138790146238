import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Text } from 'rebass';
import { css } from 'styled-components';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { LiveLabel } from './LiveLabel';
import theme from '../../../_styles/theme';
import { getLocalTimezone } from '../../../_app/utils/dateTimeUtils';
import BreakoutItem from './components/BreakoutItem';
import { AGENDA_ITEM_TYPES } from '../../../_app/constants';
import SpeakersRow from './SpeakersRow';

const Wrapper = ({ active, children, customStyles, style, ...props }) => {
  const styleTextColor = active
    ? style && style.textHighlighted
    : style && style.text;
  return (
    <Box
      css={css`
        color: ${style ? styleTextColor : '#fff'};
        position: relative;
        min-width: 277px;
        padding: 10px 27px 10px 43px;

        overflow-y: hidden;
        ${active
            ? `
            background-color: ${theme.colors.bgNavyBlue};
            border-radius: 10px;
            background-color: ${(style && style.bgColor) ||
              theme.colors.bgNavyBlue};
          `
            : ``}
          :hover {
          cursor: pointer;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  );
};

const Content = styled(Box)`
  position: relative;
  z-index: 2;
`;

const Info = styled(Text)`
  text-transform: uppercase;
  line-height: 1.2em;
`;

const TimeLeft = styled(Text)`
  min-height: 16px;
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 10px;
  letter-spacing: 1.11px;
`;

const TimeLocation = styled(Text)`
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: ${theme.fontWeights.body};
  letter-spacing: 1.11px;
  color: ${props => props.color || 'white'};
`;

const TimeLineDot = styled(Box)`
  position: absolute;
  width: 8px;
  height: 8px;
  left: ${props => props.left || '-30px'};
  top: 0;
  border-radius: 50%;
  opacity: ${props => props.opacity || 1};
  background-color: ${props => props.color || theme.colors.agendaTimeline};
`;

let refreshIntervalHandler = null;
const REFRESH_TIME_INTERVAL = 1000 * 60 * 0.5; // 0.5 minute

const AgendaItemMobile = ({
  active,
  onSetActive,
  agendaItem,
  lastItem,
  isLive,
  agendaItemStyle,
  bubbleStyle,
  chatStyle,
  onBubbleClick,
  showSpeakers,
  infoFunc,
  playFunc,
  ...props
}) => {
  const tz = getLocalTimezone();
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = useState(Date.now()); // only to periodically force refresh component rerender - not sure if a good practice
  const isInFuture = moment(agendaItem.start_date).isAfter(moment());
  const minutesLeft = moment(agendaItem.end_date).diff(moment.now(), 'minutes');
  const minutesUntilStart = moment(agendaItem.start_date).diff(
    moment.now(),
    'minutes',
  );

  useEffect(() => {
    refreshIntervalHandler = setInterval(() => {
      setTime(Date.now());
    }, REFRESH_TIME_INTERVAL);
    return () => {
      clearInterval(refreshIntervalHandler);
    };
  }, []);

  let timeLeftInfo = '';
  if (active) {
    if (minutesLeft > 0) {
      if (isInFuture) {
        timeLeftInfo = (
          <FormattedMessage
            id="agendaitem.timeuntil"
            values={{
              minutes: moment.duration(minutesUntilStart, 'minutes').humanize(),
            }}
          />
        );
      } else {
        timeLeftInfo = (
          <FormattedMessage
            id="agendaitem.timeremaining"
            values={{
              minutes: moment.duration(minutesLeft, 'minutes').humanize(),
            }}
          />
        );
      }
    } else if (minutesLeft === 0) {
      timeLeftInfo = <FormattedMessage id="agendaitem.timelessthanminute" />;
    } else {
      timeLeftInfo = <FormattedMessage id="agendaitem.timeover" />;
    }
  }

  const { speakers, agenda_type: agendaType, timestamp, id } = agendaItem || {};

  return (
    <>
      {(agendaType === undefined ||
        agendaType === AGENDA_ITEM_TYPES.REGULAR) && (
        <Wrapper
          style={agendaItemStyle}
          id={`agenda-item-${agendaItem.id}`}
          active={active}
          onClick={onSetActive}
          {...props}
        >
          <Content>
            <TimeLineDot color={get(agendaItemStyle, 'dot')} />
            {isLive && <LiveLabel />}
            <TimeLocation color={!active ? agendaItemStyle.buttonsBg : 'white'}>
              {!lastItem
                ? `${moment(agendaItem.start_date).format('HH:mm')} - ${moment(
                    agendaItem.end_date,
                  ).format('HH:mm')}`
                : `${moment(agendaItem.end_date).format('HH:mm')}`}
              &nbsp;
              <FormattedMessage id="date.hour" defaultMessage="" />
              &nbsp;
              {tz}
            </TimeLocation>
            <Info fontSize={2} fontWeight="bold" letterSpacing={1}>
              {agendaItem.description}
            </Info>
            <TimeLeft>{timeLeftInfo}</TimeLeft>
            <SpeakersRow
              id={id}
              speakers={speakers}
              showSpeakers={showSpeakers}
              lastItem={lastItem}
              agendaItemStyle={agendaItemStyle}
              onBubbleClick={onBubbleClick}
              bubbleStyle={bubbleStyle}
              chatStyle={chatStyle}
              onSetActive={onSetActive}
              infoFunc={infoFunc}
              playFunc={playFunc}
              timestamp={timestamp}
            />
          </Content>
        </Wrapper>
      )}
      {agendaType === AGENDA_ITEM_TYPES.BREAKOUT_ROOM &&
        agendaItem.group !== null && (
          <Box active={active} onClick={onSetActive} {...props}>
            <BreakoutItem
              breakoutItem={agendaItem}
              style={agendaItemStyle}
              onClick={onSetActive}
              active={active}
            >
              <TimeLineDot
                color={get(agendaItemStyle, 'dot')}
                opacity="0.5"
                left="-17px"
              />
              {isLive && <LiveLabel right="18px" />}
            </BreakoutItem>
          </Box>
        )}
    </>
  );
};

export default AgendaItemMobile;
