import { action, observable } from 'mobx';
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';

export class ExploreItemsStore {
  @observable isLoading = false;

  @observable exploreItem = null;

  @action
  getExploreItems = async eventId => {
    this.isLoading = true;
    try {
      const { data } = await API.get(API_ROUTES.EXPLORE_ITEMS(eventId));
      this.exploreItem = data;
    } catch (e) {
      this.exploreItem = null;
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action clearStore = async () => {
    this.exploreItem = null;
  };
}

export default new ExploreItemsStore();
