import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import { css } from 'styled-components';
import moment from 'moment';
import get from 'lodash/get';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import Twemoji from 'react-twemoji';
import theme from '../../_styles/theme';
import { DefaultAvatar } from '../../_assets/icons/defaultAvatar';
import AlertIcon from '../../_assets/icons/alertIcon';
import StylingUtils from '../../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { LS_KEYS } from '../../_app/utils/LocalStorageManager';
import { Reactions } from './Reactions/Reactions';
import ReportsModal from './reports';
import { markChatLinkAsTag } from '../../_app/utils/otherUtils';
import { Avatar } from '../HeaderBar/HeaderBar';
import Checkmark from '../../_assets/icons/checkmarkIcon';

const MessageBox = ({ active, chatStyle, children }) => {
  const { bgColor, text, bgColorActive } = chatStyle || {};
  return (
    <Flex
      css={css`
        width: calc(100% - 8px); // scrollbar space
        margin-bottom: 7px;
        flex-direction: ${active ? 'row-reverse' : 'row'};
        justify-content: ${active ? 'space-between' : 'flex-start'};
        padding: 14px 20px 20px 13px;
        max-width: 283px;
        background-color: ${active
          ? bgColorActive || theme.colors.chatMsgActive
          : bgColor || theme.colors.bgNavyBlue};
        color: ${text || '#fff'};

        border-radius: 10px;

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
          max-width: none;
        }
      `}
    >
      {children}
    </Flex>
  );
};

const avatarCss = {
  borderRadius: `50%`,
  width: '42px',
  height: '42px',
  minWidth: 'auto',
  border: `1px solid`,
};

const Info = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 42px;
`;

const MsgMain = ({ children, marginLeft, marginRight, ...props }) => (
  <Flex
    css={css`
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-left: ${marginLeft};
      margin-right: ${marginRight};
      min-height: 63px; //subtracted vertical padding
      overflow-y: hidden;
    `}
    {...props}
  >
    {children}
  </Flex>
);

const ContentText = ({ children }) => (
  <Box
    css={css`
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      padding-top: 3px;
      font-size: ${theme.fontSizes[0]}px;
      width: 100%;
    `}
  >
    {children}
  </Box>
);

const Footer = styled(Box)`
  text-overflow: ellipsis;
  width: 100%;
`;

const Date = styled(Text)`
  font-weight: ${theme.fontWeights.emph};
  letter-spacing: -0.29px;
  font-size: ${theme.fontSizes[1]}px;
`;

const Separator = styled(Box)`
  width: 1px;
  height: calc(100% - 48px - 22px);
`;

export const ChatMsg = ({
  allowEmotes,
  isLogged,
  setVisibility,
  msg: msgData,
  msg: {
    message,
    posted_at: postedAt,
    user,
    anonymous_chat_user: anonymousChatUser,
    recipient_name: recipientName,
    recipient_id: recipientId,
  },
  onAvatarAdd,
  myself,
  anonMyself,
  chatStyle,
  userId,
  updateReaction,
  moderatorId,
  primaryColor,
  allowReportMessages,
}) => {
  const { [LS_KEYS.ANONYM_CHAT_ID]: savedAnonChatId } = Cookies.get();
  const isFromAnonymous = !isEmpty(anonymousChatUser);

  const { avatar_chat_thumb_url: avatarUrl, id, name: anonName } =
    user || anonymousChatUser || {};

  const myUserTag = `@${anonMyself ||
    (myself && `${myself.first_name} ${myself.last_name}`)}`;

  const myId = myself?.id || savedAnonChatId;

  const { primary, avatarBckg, chatEmotesEnabled, avatarBorder } =
    chatStyle || {};

  const markAsMine = isFromAnonymous ? savedAnonChatId === id : userId === id;

  const finalAvaterUrl = markAsMine
    ? get(myself, 'avatar.thumb.url') || avatarUrl
    : avatarUrl;

  let sendTo = '';
  if (recipientName) {
    sendTo = (
      <>
        <FormattedMessage
          id="chat.message_send_to"
          values={{ recipientName }}
        />
        {recipientId === moderatorId && (
          <>
            {' '}
            <Checkmark
              fill={primary}
              style={{
                height: `${theme.fontSizes[1]}px`,
                paddingTop: '1px',
              }}
            />
          </>
        )}
      </>
    );
  }
  const markMyTag = msg => {
    let finalMsg = msg;
    const myTagRegExp = new RegExp(
      `<span class="tag" tagged-user-id="${myId}">${myUserTag}</span>`,
      'g',
    );

    finalMsg = msg.replace(
      myTagRegExp,
      `<span class="tag my-tag blink">${myUserTag}</span>`,
    );

    return finalMsg;
  };

  const msgWithTag = markMyTag(message); // unwrap(message);
  const finalMsg = markChatLinkAsTag(msgWithTag);

  const handleReport = () => {
    setVisibility(
      true,
      <ReportsModal
        msg={{ ...msgData, message: finalMsg }}
        avatarBckg={avatarBckg}
        anonName={anonName}
        onClose={() => setVisibility(false)}
      />,
    );
  };

  return (
    // <Box> wrapper is a Safari fix
    <Box>
      <MessageBox chatStyle={chatStyle} active={markAsMine}>
        <Info>
          {finalAvaterUrl && (
            <Avatar
              src={finalAvaterUrl}
              pointer={markAsMine}
              onClick={markAsMine ? onAvatarAdd : null}
              role="img"
              borderColor={avatarBorder}
              customStyles={{
                height: '42px',
                width: '42px',
              }}
            />
          )}
          {!finalAvaterUrl && markAsMine && (
            <DefaultAvatar
              fill={avatarBckg}
              onClick={onAvatarAdd}
              style={{
                ...avatarCss,
                borderColor: avatarBorder,
                cursor: 'pointer',
              }}
            />
          )}
          {!finalAvaterUrl && !markAsMine && (
            <DefaultAvatar
              fill={avatarBckg}
              style={{ ...avatarCss, borderColor: avatarBorder }}
            />
          )}
          <Separator
            backgroundColor={primary || StylingUtils.hexToRgbA('#fff', 0.3)}
          />
          <Date color={primary}>{moment(postedAt).format('HH:mm')}</Date>
        </Info>
        <MsgMain
          marginLeft={markAsMine ? '8px' : '12px'}
          marginRight={markAsMine ? '4px' : 0}
        >
          {user && (
            <Text
              fontWeight={theme.fontWeights.bold}
              letterSpacing="-0.29px"
              fontSize={theme.fontSizes[2]}
              color={primary || '#fff'}
            >
              {user.first_name} {user.last_name}
              {moderatorId === msgData.user?.id && (
                <>
                  {' '}
                  <Checkmark
                    fill={primaryColor}
                    style={{
                      height: `${theme.fontSizes[1]}px`,
                      paddingTop: '1px',
                    }}
                  />{' '}
                </>
              )}
              {sendTo}
            </Text>
          )}
          {anonName && (
            <Text
              fontWeight={theme.fontWeights.bold}
              letterSpacing="-0.29px"
              fontSize={theme.fontSizes[2]}
            >
              {anonName} {sendTo}
            </Text>
          )}
          <ContentText isMine={markAsMine}>
            <Text
              fontSize={1}
              fontWeight={theme.fontWeights.emph}
              lineHeight={1.42}
              paddingBottom="10px"
              letterSpacing="-0.29px"
            >
              <Twemoji noWrapper>
                <div
                  dangerouslySetInnerHTML={{
                    __html: finalMsg,
                  }}
                />
              </Twemoji>
            </Text>
          </ContentText>
          <Footer>
            {!isLogged && (
              <Flex
                css={css`
                  flex-wrap: wrap;
                `}
              >
                {chatEmotesEnabled && (
                  <Reactions
                    msg={msgData}
                    markAsMine={markAsMine}
                    allowEmotes={allowEmotes}
                    updateReaction={updateReaction}
                    chatStyle={chatStyle}
                  />
                )}

                {!markAsMine && allowEmotes && allowReportMessages && (
                  <Flex
                    css={css`
                      justify-content: flex-end;
                      align-items: center;
                      margin-top: 5px;
                      margin-left: auto;
                      cursor: pointer;
                    `}
                    onClick={() => handleReport()}
                  >
                    <Box
                      css={css`
                        margin-left: 2px;
                      `}
                    >
                      <AlertIcon height={12} />
                    </Box>
                  </Flex>
                )}
              </Flex>
            )}
          </Footer>
        </MsgMain>
      </MessageBox>
    </Box>
  );
};
