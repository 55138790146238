import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import Cookies from 'js-cookie';
import get from 'lodash/get';
import cryptoRandomString from 'crypto-random-string';
import authStore from '../stores/authStore';
import { LS_KEYS } from './utils/LocalStorageManager';
import { SUBDOMAIN } from './utils/otherUtils';
import { UserStore } from '../stores/userStore';

export const baseURL = process.env.REACT_APP_API_URL;
export const API_WS_ROOT = `${process.env.REACT_APP_API_URL.replace(
  'https://',
  'wss://',
).replace('http://', 'ws://')}/cable`.replace('//cable', '/cable'); // tu oczywiście host odpowiedni dla środowiska - adres serwera api

const API = axios.create({
  baseURL,
});

const { [LS_KEYS.ANONYM_CHAT_ID]: savedAnonChatId } = Cookies.get();

API.defaults.headers['token-type'] = 'Bearer';
API.defaults.headers['x-cplus-referrer'] = SUBDOMAIN;
API.defaults.headers['X-CPLUS-ANONYMOUS-CHAT-USER-ID'] =
  savedAnonChatId || get(UserStore, 'anonUser.id');

const AUTH_TOKEN = localStorage.getItem(LS_KEYS.AUTH_TOKEN);
if (AUTH_TOKEN) {
  API.defaults.headers.common['access-token'] = AUTH_TOKEN;
}

const CLIENT = localStorage.getItem(LS_KEYS.CLIENT);
if (CLIENT) {
  API.defaults.headers.common.client = CLIENT;
}

const UID = localStorage.getItem(LS_KEYS.UID);
if (UID) {
  API.defaults.headers.common.uid = UID;
}

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      console.log({ error });
      if (get(authStore, 'isAuthenticated')) {
        authStore.logout({
          onSuccess: () => {
            const redirectUrl = `${window.location.origin}/sign-in/?sessionexpired=true`;
            window.location.replace(redirectUrl);
          },
        });
      }
    }
    if (error.response.status === 404) {
      return Promise.reject(error);
    }
    return Promise.reject(error.response.data);
  },
);

const setAuthenticationToken = token => {
  localStorage.setItem(LS_KEYS.AUTH_TOKEN, token);
  API.defaults.headers.common['access-token'] = token;
};

const setClient = client => {
  localStorage.setItem(LS_KEYS.CLIENT, client);
  API.defaults.headers.common.client = client;
};

const setUid = uid => {
  localStorage.setItem(LS_KEYS.UID, uid);
  API.defaults.headers.common.uid = uid;
};

const setAnonChatName = name => {
  Cookies.set(LS_KEYS.ANONYM_CHAT_NAME, name);
};

const setUserUniqUuid = () => {
  const randomString = cryptoRandomString({ length: 15 }, { expires: 365 });
  Cookies.set(LS_KEYS.USER_UNIQ_UUID, randomString);
  return randomString;
};

let { [LS_KEYS.USER_UNIQ_UUID]: savedUserUniqUuid } = Cookies.get();
if (!savedUserUniqUuid) {
  savedUserUniqUuid = setUserUniqUuid();
}
API.defaults.headers['X-CPLUS-USER-UNIQ-UUID'] = savedUserUniqUuid;

const setAnonChatId = id => {
  Cookies.set(LS_KEYS.ANONYM_CHAT_ID, id);
};

const setAnonChatUserAvatar = img => {
  Cookies.set(LS_KEYS.ANONYM_CHAT_USER_AVATAR, img);
};

const clearNotSignedUserDataLeftovers = () => {
  Cookies.remove(LS_KEYS.ANONYM_CHAT_NAME, { path: '/' });
  Cookies.remove(LS_KEYS.ANONYM_CHAT_ID, { path: '/' });
  Cookies.remove(LS_KEYS.ANONYM_CHAT_USER_AVATAR, { path: '/' });
};

export {
  API,
  setAuthenticationToken,
  setClient,
  setUid,
  setAnonChatName,
  setAnonChatId,
  setAnonChatUserAvatar,
  setUserUniqUuid,
  clearNotSignedUserDataLeftovers,
};
