/* eslint-disable */
import React from 'react';

export const DefaultAvatar = ({ fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.83 77.83" {...props}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle fill={ fill || '#e9575b' } cx="38.62" cy="39.21" r="37.62"/>
        <path className="cls-2" fill={'#fff'}
              d="M38.92,0a38.89,38.89,0,0,0-30,63.68s0,0,0,.06a38.91,38.91,0,0,0,14.69,11l.2.08c.45.19.91.37,1.37.54l.55.21L27,76l.78.24,1.08.31.95.24,1,.22,1.1.22c.3,0,.59.11.89.15s.82.12,1.23.18l.8.1c.47,0,.94.08,1.41.11l.66,0c.69,0,1.39.06,2.09.06s1.4,0,2.09,0l.65,0c.48,0,1-.07,1.41-.12l.8-.1,1.23-.17.89-.16,1.1-.22,1-.22,1-.24,1.08-.31.77-.24,1.21-.41.54-.2L54,74.79l.19-.08a38.87,38.87,0,0,0,14.7-11,.33.33,0,0,0,0-.06A38.9,38.9,0,0,0,38.92,0ZM54.69,71.63l-.06,0c-.46.23-.93.44-1.41.64l-.32.14c-.42.17-.84.34-1.26.49l-.53.2L50,73.51l-.71.22-1,.29-.87.21c-.3.08-.61.15-.91.21s-.68.14-1,.2l-.82.15c-.38.06-.76.1-1.15.15-.24,0-.48.07-.72.09-.44,0-.88.08-1.32.11l-.59,0c-.64,0-1.29,0-1.93,0s-1.29,0-1.93,0l-.59,0c-.44,0-.88-.06-1.32-.11-.25,0-.49-.06-.73-.09s-.77-.09-1.15-.15l-.82-.15-1-.2c-.31-.06-.61-.13-.92-.21L29.58,74l-1-.29-.71-.22-1.14-.38-.53-.2c-.42-.15-.84-.32-1.25-.49l-.32-.14c-.48-.2-.95-.41-1.41-.64,0,0,0,0-.06,0a36.2,36.2,0,0,1-11.77-9.07A28.65,28.65,0,0,1,30.12,42.92l.55.32.33.18c.39.2.78.4,1.18.57l.94.37.19.06a16.55,16.55,0,0,0,11.22,0l.18-.06.95-.37c.4-.17.79-.37,1.17-.57l.33-.18.56-.32A28.65,28.65,0,0,1,66.46,62.56,36.69,36.69,0,0,1,54.69,71.63Zm-30-43.09a14.27,14.27,0,1,1,22,12,12.34,12.34,0,0,1-1.59.88l-.19.1a14.58,14.58,0,0,1-12,0l-.2-.1a13.83,13.83,0,0,1-1.59-.88A14.27,14.27,0,0,1,24.65,28.54ZM68.4,60.09a31.25,31.25,0,0,0-18.22-19,16.87,16.87,0,1,0-22.52,0,31.24,31.24,0,0,0-18.23,19A36.31,36.31,0,1,1,75.24,38.92,36.08,36.08,0,0,1,68.4,60.09Z"/>
      </g>
    </g>
  </svg>
);

