import moment from 'moment';
import get from 'lodash/get';
import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import { Tiles } from '@rebass/layout';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import theme from '../_styles/theme';
import { Button } from './Button/Button';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';
import { getLocalTimezone } from '../_app/utils/dateTimeUtils';
import { AddCalendar } from './AddCalendar';
import { markLinkAsTag } from '../_app/utils/otherUtils';

const Container = styled(Box)`
  margin-bottom: 30px;
  font-size: ${theme.fontSizes[1]}px;
  border-bottom: ${props => props.borderWidth} solid
    ${props => props.primaryColor};
  padding-bottom: 25px;
  /* Possible fix for Safari rendering problem */
  backface-visibility: hidden;
  position: relative;
  z-index: 1;
  transform: translateZ(1px);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    color: #637781;
    font-size: ${theme.fontSizes[3]}px;
  }
`;

const Date = styled(Text)`
  color: ${theme.colors.textGrey};
  line-height: 1.4em;
`;

const Description = styled(Text)`
  margin-bottom: ${props => (props.longDescription ? '0' : '10px')};
  color: ${props =>
    props.agendaDetailsTitleColor || `${theme.colors.textGrey}`};
  font-size: ${theme.fontSizes[4]}px;
  font-weight: ${theme.fontWeights.heading};
  line-height: 1.3em;
`;

const LongDescription = styled(Text)`
  margin-top: 20px;
  margin-bottom: ${props => props.marginBottomWidth};
  color: ${props => props.agendaDetailsBodyColor || `${theme.colors.textGrey}`};
  font-size: ${theme.fontSizes[3]}px;
  font-weight: ${theme.fontWeights.body};
  line-height: 1.3em;
  p {
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ul {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: disc;
  }
  ol {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: decimal;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

const SpeakerTile = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  justify-content: space-between;
  background-color: ${theme.colors.bgTileGrey};
  border-radius: 4px;
`;

const SpeakerThumbnail = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
  flex-shrink: 0;
`;

const SpeakerImage = styled('img')`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

const Name = styled(Text)`
  font-size: 14px;
  font-weight: ${theme.fontWeights.heading};
  line-height: 1.4em;
  color: ${theme.colors.textOnWhiteBg};
  word-break: break-word;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 26px;
    color: #1d2534;
    font-weight: ${theme.fontWeights.almostBold};
  }
`;

const SpeakerCompany = styled(Text)`
  font-weight: ${theme.fontWeights.emph};
  line-height: 1.3em;
  word-break: break-word;
  color: ${theme.colors.textOnWhiteBg};
`;

const SpeakerJob = styled(Text)`
  line-height: 1.3em;
  font-weight: ${theme.fontWeights.emph};
  word-break: break-word;
  color: ${theme.colors.textOnWhiteBg};
`;

const SpeakerBio = styled(Text)`
  margin-top: 5px;
  line-height: 1.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: ${theme.colors.textOnWhiteBg};
  word-break: break-word;
`;

const AgendaHeaderWithCalendar = styled(Flex)`
  flex-direction: row;
  z-index: 0;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const AddCalendarWrapper = styled(Box)`
  padding-left: 12px;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

const AgendaMain = ({
  first,
  start_date: startDate,
  end_date: endDate,
  location,
  description,
  long_description: longDescription,
  agendaItemSpeakers,
  color,
  handleAgendaMoreButtonClick,
  homeStore: { activeEvent },
  primaryColor,
  buttonsTextColor,
  last,
  id,
  agendaDetailsStyle,
}) => {
  const tz = getLocalTimezone();

  return (
    <Container
      id={`agenda_${id}`}
      primaryColor={primaryColor}
      borderWidth={last ? '0px' : '1px'}
    >
      <Flex>
        <Flex flexDirection="column" justifyContent="center" width="100%">
          <AgendaHeaderWithCalendar>
            <Box flex="1 0 0">
              {(startDate || location) && (
                <Date>
                  {startDate && moment(startDate).format('HH:mm')}
                  &nbsp;-&nbsp;
                  {endDate && moment(endDate).format('HH:mm')}
                  &nbsp;
                  {tz}
                  {startDate && location && ', '}
                  {location && location}
                </Date>
              )}
              {description && (
                <Description
                  agendaDetailsTitleColor={
                    agendaDetailsStyle.agendaTitleTextColor
                  }
                  longDescription={longDescription}
                >
                  {description}
                </Description>
              )}
            </Box>
            {first && activeEvent.addevent_calendar_id && (
              <AddCalendarWrapper>
                <AddCalendar buttonsTextColor={buttonsTextColor} />
              </AddCalendarWrapper>
            )}
          </AgendaHeaderWithCalendar>
          {longDescription && (
            <LongDescription
              agendaDetailsBodyColor={agendaDetailsStyle.agendaBodyTextColor}
              marginBottomWidth={agendaItemSpeakers.length > 0 ? '20px' : '0px'}
              dangerouslySetInnerHTML={{
                __html: longDescription,
              }}
            />
          )}
          {agendaItemSpeakers.length > 0 && (
            <Tiles columns={[1, 1, 3]}>
              {agendaItemSpeakers.map(speaker => (
                <SpeakerTile key={`speaker-tile-${speaker.id}`}>
                  <Box>
                    <SpeakerThumbnail>
                      {get(speaker.image, 'thumb.url') && (
                        <SpeakerImage
                          src={get(speaker.image, 'thumb.url')}
                          alt={speaker.name}
                        />
                      )}
                    </SpeakerThumbnail>
                    {speaker.name && <Name>{speaker.name}</Name>}
                    {speaker.job && <SpeakerJob>{speaker.job}</SpeakerJob>}
                    {speaker.company_name && (
                      <SpeakerCompany>{speaker.company_name}</SpeakerCompany>
                    )}
                    {speaker.short_bio && (
                      <SpeakerBio
                        dangerouslySetInnerHTML={{
                          __html: markLinkAsTag(speaker.short_bio),
                        }}
                      />
                      // {speaker.bio.length > 85
                      //   ? `${speaker.bio
                      //       .replace(/(<([^>]+)>)/gi, '')
                      //       .slice(0, 85)}...`
                      //   : speaker.bio.replace(/(<([^>]+)>)/gi, '')}
                      // </SpeakerBio>
                    )}
                  </Box>
                  <Button
                    bgColor={color}
                    height="30px"
                    fontSize="12px"
                    color={buttonsTextColor}
                    onClick={() =>
                      handleAgendaMoreButtonClick(
                        speaker.name.toLowerCase().replace(/\s/g, ''),
                      )
                    }
                    mt="12px"
                    pl={0}
                    pr={0}
                  >
                    <FormattedMessage id="generic.more" />
                  </Button>
                </SpeakerTile>
              ))}
            </Tiles>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export default inject('homeStore')(observer(AgendaMain));
