import React, { useState } from 'react';
import { Flex } from 'rebass';
import { css } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import StylingUtils from '../../_app/utils/stylingUtils';
import { ButtonLink } from '../Button/ButtonLink';
import theme from '../../_styles/theme';
import { Avatar } from '../HeaderBar/HeaderBar';
import { DefaultAvatar } from '../../_assets/icons/defaultAvatar';

const defaultAvatarCss = {
  borderRadius: `50%`,
  width: '30px',
  height: '30px',
  minWidth: 'auto',
  border: `1px solid`,
  marginRight: '18px',
};

const useStyle = makeStyles({
  tooltip: {
    maxHeight: '220px',
    overflowY: 'auto',
    backgroundColor: props => props.bgColorActive || theme.colors.chatMsgActive,
    color: props => (props.primary ? props.primary : '#fff'),
    maxWidth: 400,
    fontSize: theme.fontSizes[2],
    border: props =>
      props.primary ? `1px solid ${props.primary}` : '1px solid #fff',
    '&::-webkit-scrollbar': {
      display: 'block',
      '-webkit-appearance': 'none',
      width: '4px', // for vertical mode
      height: '4px', // for horizontal mode
      borderRadius: '2px',
      backgroundColor: props =>
        `${StylingUtils.hexToRgbA(props.primary || '#fff', 0.3)}`,
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'block',
      borderRadius: `2px`,
      backgroundColor: props =>
        `${StylingUtils.hexToRgbA(props.primary || '#fff', 0.65)}`,
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
});

export const TooltipWithUsers = ({
  chatStyle,
  getRoomUsers,
  chatUsers,
  children,
  chatRoomId,
  numberOfUsers,
}) => {
  const { avatarBckg, avatarBorder, bgColor, bgColorActive, primary } =
    chatStyle || {};
  const usersPerPage = 10;
  const [page, setPage] = useState(2);
  const [users, setUsers] = useState(chatUsers);
  const [usersCount, setUsersCount] = useState(
    chatUsers?.length <= usersPerPage ? chatUsers.length : Infinity,
  );

  const loadMore = async () => {
    const { results, count } = await getRoomUsers(
      chatRoomId,
      page,
      usersPerPage,
    );
    setPage(page + 1);
    setUsers([...users, ...results]);
    setUsersCount(count);
  };

  const renderTooltipContent = (data, hasMore) => {
    if (data && data.length > 0) {
      return (
        <>
          {data.map((item, index) => (
            <Flex
              key={`user_${index}_${item.name}`}
              css={css`
                margin-bottom: '0px';
                align-items: center;
              `}
            >
              {item.avatar.thumb.url && (
                <Avatar
                  borderColor={avatarBorder}
                  src={item.avatar.thumb.url}
                  role="img"
                  customStyles={{ marginRight: '18px' }}
                />
              )}
              {!item.avatar.thumb.url && (
                <DefaultAvatar
                  avatarBorder={avatarBorder}
                  fill={avatarBckg}
                  style={{ ...defaultAvatarCss, borderColor: avatarBorder }}
                />
              )}
              {item.name}
            </Flex>
          ))}
          {hasMore && numberOfUsers !== 10 && (
            <ButtonLink
              fontSize={2}
              align="left"
              paddingLeft="0"
              color={primary || '#fff'}
              onClick={e => {
                e.stopPropagation();
                loadMore();
              }}
            >
              ... show more
            </ButtonLink>
          )}
        </>
      );
    }
    return '';
  };

  const classes = useStyle({
    primary,
    bgColor,
    bgColorActive,
  });

  return (
    <Tooltip
      interactive
      TransitionComponent={Zoom}
      id="chat-scroll"
      classes={{
        tooltip: classes.tooltip,
      }}
      title={renderTooltipContent(
        users,
        usersCount >= (page - 1) * usersPerPage,
      )}
    >
      {children}
    </Tooltip>
  );
};
