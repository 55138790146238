/* eslint-disable */
import React from 'react';

export const TickIcon = ({ fill, ...props }) => (
  <svg width="63px" height="63px" viewBox="0 0 63 63" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Login" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Registration-received" transform="translate(-90.000000, -183.000000)">
              <g id="Group-7">
                  <g id="Group-8" transform="translate(90.000000, 183.000000)">
                      <g id="add">
                          <circle id="bg" stroke={fill || '#000'} strokeWidth="2.5" cx="31.5" cy="31.5" r="30.25"></circle>
                          <g id="done" transform="translate(14.000000, 14.000000)">
                              <polyline id="shape" stroke={fill || '#000'} strokeWidth="2.5" strokeLinecap="round" points="2.1 17.4143602 12.42416 28 32.9 7"></polyline>
                              <rect id="bounds" x="0" y="0" width="35" height="35"></rect>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

