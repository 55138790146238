import React from 'react';
import { Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { injectIntl } from 'react-intl';
import { Waypoint } from 'react-waypoint';
import SelectInput from './components/SelectInput';
import theme from '../../_styles/theme';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import SearchBar from './components/SearchBar';
import Person from './components/PersonTile';
import StylingUtils from '../../_app/utils/stylingUtils';
import MoreView from './components/MoreView';
import { Loader } from '../../components/Loader';
import countries from '../../_assets/countryCodesList';
import networkingStore from '../../stores/networkingStore';
import BigImageModal from '../Modals/BigImageModal';

const TabPanelCardOutside = styled(Flex)`
  background-color: ${theme.colors.card};
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-radius: 0;
    min-height: 100%;
  }
`;

const TopBar = styled(Flex)`
  background: ${theme.colors.bgTileGrey};
  width: 100%;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const TabPanelCardInside = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px 25px 25px;
  margin-top: -5px;
`;

const StyledPerson = styled(Person)`
  width: calc(20% - 10px) !important;
  margin: 0 5px;
  ${StylingUtils.animationFadeIn()}
`;

const MiddleBar = styled(Box)`
  padding: 0 30px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  min-height: 28px;
  align-items: center;
`;

const Bold = styled.span`
  font-weight: ${theme.fontWeights.emph};
`;

const Badge = styled(Box)`
  padding 6px 11px;
  background: ${({ bgColor }) => bgColor || '#999'};
  color: #fff;
  font-size: 12px;
  font-weight: ${theme.fontWeights.emph};
  border-radius: 3px;
  ${({ ml }) => ml && `margin-left: 10px;`}
  ${StylingUtils.animationFadeIn()};
`;

const sortingOptions = {
  'first_name ASC': 'First name ascending',
  'first_name DESC': 'First name descending',
  'last_name ASC': 'Last name ascending',
  'last_name DESC': 'Last name descending',
};

@inject('networkingStore', 'modalStore')
@observer
class NetworkingMain extends React.Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = {
      dropdowns: {
        searchTerm: '',
        country: '',
        businessType: '',
        orderBy: '',
      },
      selectedUser: '',
      page: 1,
      clickedPersonTile: 0,
    };
  }

  componentDidMount() {
    const {
      networkingStore: { getParticipants, clearNetworkingStore },
    } = this.props;
    const { dropdowns } = this.state;
    clearNetworkingStore();
    getParticipants(dropdowns, 1);
  }

  componentDidUpdate() {
    const {
      networkingStore: { clearState },
    } = this.props;
    if (clearState) {
      networkingStore.clearState = false;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        dropdowns: {
          searchTerm: '',
          country: '',
          businessType: '',
          orderBy: '',
        },
        selectedUser: '',
        page: 1,
        clickedPersonTile: 0,
      });
    }
  }

  // * * INPUT HANDLERS
  // eslint-disable-next-line react/sort-comp
  search = debounce(() => {
    const { dropdowns, page } = this.state;
    const {
      networkingStore: { getParticipants },
    } = this.props;
    getParticipants(dropdowns, page);
  }, 1000);

  handleOnSearch = val => {
    this.setState(
      prev => ({ dropdowns: { ...prev.dropdowns, searchTerm: val }, page: 1 }),
      this.search,
    );
  };

  handleSelects = e => {
    const { name, value } = e.target;
    this.setState(
      prevState => ({
        dropdowns: { ...prevState.dropdowns, [name]: value },
        page: 1,
      }),
      this.runGetParticipants,
    );
  };

  runGetParticipants = () => {
    const { dropdowns, page } = this.state;
    const {
      networkingStore: { getParticipants },
    } = this.props;
    getParticipants(dropdowns, page);
  };

  handleMoreClick = (id, index) => {
    this.setState({
      selectedUser: id,
      clickedPersonTile: index,
    });
  };

  handleMoreClose = () => {
    this.setState({ selectedUser: '' }, this.scrollToTile);
  };

  scrollToTile = () => {
    const { clickedPersonTile } = this.state;
    setTimeout(() => {
      const el = document.querySelector(
        `#person-networking-${clickedPersonTile}`,
      );
      if (!el) return;
      document
        .querySelector('[data-scrollable="middle-column"]')
        .scrollTo(0, el.offsetTop - window.innerHeight / 2 - 100);
    }, 500);
  };

  loadAdditionalData = () => {
    const {
      networkingStore: { participantsEnded },
    } = this.props;
    if (participantsEnded) return;

    this.setState(prev => ({ page: prev.page + 1 }), this.runGetParticipants);
  };

  // * * UTILS
  renderMessage = () => {
    const { dropdowns } = this.state;
    const {
      intl: { formatMessage },
      networkingStore: { participantsCount },
    } = this.props;
    const baseMessage = `${participantsCount} ${
      participantsCount > 1 || participantsCount === 0
        ? formatMessage({ id: 'networking.results' })
        : formatMessage({ id: 'networking.result' })
    }`;
    let additionalMessage = '';
    Object.entries(dropdowns)
      .filter(el => el[1] !== '')
      .forEach((dropdown, idx, arr) => {
        if (dropdown[1] in sortingOptions) {
          additionalMessage += `"${sortingOptions[dropdown[1]]}"`;
        } else if (dropdown[1] in countries) {
          additionalMessage += `"${countries[dropdown[1]]}"`;
        } else additionalMessage += `"${dropdown[1]}"`;
        if (arr.length - 1 !== idx) {
          additionalMessage += ' & ';
        }
      });
    return (
      <>
        <Bold>{baseMessage}</Bold>{' '}
        {additionalMessage &&
          `${formatMessage({
            id: 'networking.forAttendeesFilteredBy',
          })} ${additionalMessage}`}
      </>
    );
  };

  handleShowBigImage = ({ imgUrl }) => {
    const {
      modalStore: { setVisibility },
    } = this.props;

    if (imgUrl) {
      setVisibility(
        true,
        <BigImageModal
          onClose={() => setVisibility(false)}
          imageUrl={imgUrl}
        />,
        true,
      );
    }
  };

  render() {
    const { searchTerm, dropdowns, selectedUser } = this.state;
    const {
      networkingStore: { participants, isLoadingParticipants },
      intl: { formatMessage },
      buttonsMainColor,
      buttonsTextColor,
      avatarColor,
      chatRoomsEnabled,
      changeActiveTab,
      changeMobileTab,
      activeEvent,
      openChatWith,
    } = this.props;
    return (
      <TabPanelCardOutside>
        {!selectedUser ? (
          <>
            <TopBar>
              <SearchBar
                value={dropdowns.searchTerm}
                onChange={this.handleOnSearch}
              />
              <Box display="flex" flexShrink="0">
                {/* <SelectInput
                  ml={30}
                  name="businessType"
                  label="Business Type"
                  value={dropdowns.businessType}
                  onChange={this.handleSelects}
                  iconColor={buttonsMainColor}
                  items={['KKK', 'UUU']}

                /> */}
                {/* <SelectInput
                  ml={30}
                  name="country"
                  label={formatMessage({ id: 'networking.country' })}
                  value={dropdowns.country}
                  onChange={this.handleSelects}
                  iconColor={buttonsMainColor}
                  items={countries}
                /> */}
                <SelectInput
                  ml={30}
                  name="orderBy"
                  label={formatMessage({ id: 'networking.sortBy' })}
                  value={dropdowns.orderBy}
                  onChange={this.handleSelects}
                  iconColor={buttonsMainColor}
                  items={sortingOptions}
                />
              </Box>
            </TopBar>
            <MiddleBar>
              <Text color={theme.colors.textDarkSecondary} fontSize="12px">
                {!isLoadingParticipants && this.renderMessage()}
              </Text>
              <Box display="flex" flexShrink="0">
                {dropdowns.businessType && !searchTerm && (
                  <Badge ml bgColor={buttonsMainColor}>
                    {dropdowns.businessType}
                  </Badge>
                )}
                {dropdowns.country && !searchTerm && (
                  <Badge ml bgColor={buttonsMainColor}>
                    {countries[dropdowns.country]}
                  </Badge>
                )}
              </Box>
            </MiddleBar>
            <TabPanelCardInside>
              {participants.map((user, idx) => {
                return (
                  <StyledPerson
                    key={`person-networking-${idx}`}
                    id={`person-networking-${idx}`}
                    idx={idx}
                    person={user}
                    color={buttonsMainColor}
                    buttonsTextColor={buttonsTextColor}
                    avatarColor={avatarColor}
                    onMoreClick={this.handleMoreClick}
                    handleShowBigImage={this.handleShowBigImage}
                  />
                );
              })}
              {!isLoadingParticipants ? (
                <Waypoint key="cursor" onEnter={this.loadAdditionalData} />
              ) : (
                <Loader dark />
              )}
            </TabPanelCardInside>
          </>
        ) : (
          <MoreView
            activeEvent={activeEvent}
            chatRoomsEnabled={chatRoomsEnabled}
            primaryColor={buttonsMainColor}
            buttonsTextColor={buttonsTextColor}
            openChatWith={openChatWith}
            id={selectedUser}
            onClose={this.handleMoreClose}
            avatarColor={avatarColor}
            changeActiveTab={changeActiveTab}
            changeMobileTab={changeMobileTab}
          />
        )}
      </TabPanelCardOutside>
    );
  }
}

export default injectIntl(NetworkingMain);
