/* eslint-disable */
import React from 'react';

export const LogoutIcon = ({ fill, ...props }) => (
<svg width="20px" height="20px" viewBox="0 0 490.667 490.667" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px">
  <g>
    <g fill={fill || '#fff'}>
      <path d="M330.667,192c5.888,0,10.667-4.779,10.667-10.667v-128C341.333,23.936,317.419,0,288,0H53.333C23.915,0,0,23.936,0,53.333
        v384c0,29.397,23.915,53.333,53.333,53.333H288c29.419,0,53.333-23.936,53.333-53.333v-128c0-5.888-4.779-10.667-10.667-10.667
        S320,303.445,320,309.333v128c0,17.643-14.357,32-32,32H53.333c-17.643,0-32-14.357-32-32v-384c0-17.643,14.357-32,32-32H288
        c17.643,0,32,14.357,32,32v128C320,187.221,324.779,192,330.667,192z"/>
    </g>
  </g>
  <g>
    <g fill={fill || '#fff'}>
      <path d="M480,234.667H138.667c-5.888,0-10.667,4.779-10.667,10.667S132.779,256,138.667,256H480
        c5.888,0,10.667-4.779,10.667-10.667S485.888,234.667,480,234.667z"/>
    </g>
  </g>
  <g>
    <g fill={fill || '#fff'}>
      <path d="M487.531,237.824l-64-64c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l56.448,56.448l-56.448,56.448
        c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.531-3.093l64-64
        C491.691,248.747,491.691,241.984,487.531,237.824z"/>
    </g>
  </g>
  </svg>
);
