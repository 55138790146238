import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { toJS } from 'mobx';
import { css } from 'styled-components';
import { Form, Field } from 'react-final-form';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import StylingUtils from '../../_app/utils/stylingUtils';
import theme from '../../_styles/theme';
import { toHex } from '../../_app/utils/otherUtils';
import { ChatUser } from './ChatUser';
import { Button } from '../Button/Button';
import { RoomNameInput } from './RoomNameInput';
import SearchBar from '../../home/NetworkingContainer/components/SearchBar';
import CheckboxChatUsers from '../_forms/CheckboxChatUsers';

const NewChatRoomsListContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 18px;
    padding-right: 18px;
    height: auto;
    min-height: calc(
      100% - 100px
    ); // hack - for disclaimer to sit on bottom of page always
  }
`;

const ChatBottom = ({ children }) => (
  <Flex
    css={css`
      position: relative;
      flex-direction: column;
      height: calc(100% - 20px);
      ${StylingUtils.animationFadeIn()};
    `}
  >
    {children}
  </Flex>
);

const NoParticipants = ({ children, color }) => (
  <Flex
    css={css`
      justify-content: center;
      flex-grow: 1;
      font-size: ${theme.fontSizes[2]}px;
      color: ${color || StylingUtils.hexToRgbA(theme.colors.text, 0.2)};
      line-height: 1.5em;
    `}
  >
    {children}
  </Flex>
);

const ScrollContent = ({ children, scrollColor, isMobile, ...props }) => (
  <Box
    css={css`
      overflow: auto;
      max-height: calc(100% - 150px);
      padding-bottom: ${isMobile ? '30px' : '10px'};
      ${StylingUtils.customScroll(scrollColor)}
    `}
    {...props}
  >
    {children}
  </Box>
);

@inject('chatStore', 'chatRoomsStore', 'homeStore', 'modalStore', 'userStore')
@observer
class NewChatRoomList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdowns: {
        searchTerm: '',
      },
      page: 1,
    };
  }

  async componentDidMount() {
    const {
      chatRoomsStore: { getParticipants },
    } = this.props;

    await getParticipants({}, 1);
  }

  // * * INPUT HANDLERS
  // eslint-disable-next-line react/sort-comp
  search = debounce(() => {
    const { dropdowns, page } = this.state;
    const {
      chatRoomsStore: { getParticipants },
    } = this.props;
    getParticipants(dropdowns, page);
    if (this.scroll) {
      this.scroll.pageLoaded = 1;
    }
  }, 1000);

  handleOnSearch = val => {
    this.setState(
      prev => ({ dropdowns: { ...prev.dropdowns, searchTerm: val }, page: 1 }),
      this.search,
    );
  };

  render() {
    const {
      chatStore: { activeChatRoomId },
      homeStore: { activeOrganization, activeEvent },
      modalStore: { setVisibility },
      chatRoomsStore: {
        isLoading,
        participants,
        participantsEnded,
        getParticipants,
        createNewChatRoom,
      },
      chatStyle,
      isMobile,
      changeMobileTab,
      toggleNewChatRoom,
      backOnMobile,
      chatScrollParentRef,
    } = this.props;
    const { dropdowns } = this.state;

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? `#${activeOrganization.dominant_color}`
        : theme.colors.textSecondary;

    const { no_content_text: noContentText, chat_primary: chatPrimary } =
      (activeEvent && activeEvent.event_custom_theme) || {};
    const chatPrimaryHex = toHex(chatPrimary);
    const noContentTextHex = toHex(noContentText);

    const { buttonsTextColor, iconColor, chatButtonsBg } = chatStyle || {};

    return (
      <NewChatRoomsListContainer>
        <>
          <ChatBottom>
            <Form
              onSubmit={values => {
                if (!backOnMobile) {
                  createNewChatRoom(activeEvent.id, values, () =>
                    toggleNewChatRoom(false),
                  );
                } else {
                  createNewChatRoom(activeEvent.id, values, () => {
                    toggleNewChatRoom(false);
                    changeMobileTab();
                  });
                }
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                  {backOnMobile && (
                    <>
                      <Button
                        bgColor={chatButtonsBg}
                        onClick={backOnMobile}
                        color={buttonsTextColor}
                        height="45px"
                        fontSize="14px"
                        mt={10}
                        newChatButton
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        <FormattedMessage id="chat.roomsBack" />
                      </Button>
                      <Button
                        bgColor={chatButtonsBg}
                        onClick={handleSubmit}
                        color={buttonsTextColor}
                        height="45px"
                        fontSize="14px"
                        mt={10}
                        mb={3}
                        newChatButton
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        <FormattedMessage id="chat.newroomchatButton" />
                      </Button>
                    </>
                  )}
                  <Box width="95%" pb={10}>
                    {backOnMobile && <RoomNameInput />}
                    <SearchBar
                      iconFill={iconColor}
                      simpleSearch
                      value={dropdowns.searchTerm}
                      onChange={this.handleOnSearch}
                    />
                  </Box>

                  <ScrollContent
                    isMobile={isMobile}
                    id="chat-scroll"
                    scrollColor={chatPrimaryHex || primaryColor}
                  >
                    {chatScrollParentRef && chatScrollParentRef.current ? (
                      <InfiniteScroll
                        pageStart={1}
                        initialLoad={false}
                        loadMore={page => getParticipants(dropdowns, page)}
                        hasMore={!participantsEnded}
                        threshold={500}
                        useWindow={false}
                        getScrollParent={() => chatScrollParentRef.current}
                        ref={scroll => {
                          this.scroll = scroll;
                        }}
                      >
                        {isEmpty(participants) && !isLoading && (
                          <NoParticipants color={noContentTextHex}>
                            <FormattedMessage id="chat.noParticipant" />
                          </NoParticipants>
                        )}
                        {!isEmpty(participants) &&
                          toJS(participants).map(participant => (
                            <Field
                              id={participant.id}
                              name="chat_room.user_ids"
                              value={participant.id}
                              checkboxContent={
                                <ChatUser
                                  participant={participant}
                                  key={participant.id}
                                  chatRoomAvatar={participant.avatar?.url}
                                  activeChatRoomId={activeChatRoomId}
                                  chatStyle={chatStyle}
                                  chatRoomId={participant.id}
                                  setVisibility={setVisibility}
                                  addColor={chatPrimaryHex || primaryColor}
                                  changeMobileTab={changeMobileTab}
                                />
                              }
                              type="checkbox"
                              mainColor={primaryColor}
                              component={CheckboxChatUsers}
                            />
                          ))}
                      </InfiniteScroll>
                    ) : (
                      <InfiniteScroll
                        pageStart={1}
                        initialLoad={false}
                        loadMore={page => getParticipants(dropdowns, page)}
                        hasMore={!participantsEnded}
                        threshold={500}
                        useWindow={false}
                        ref={scroll => {
                          this.scroll = scroll;
                        }}
                      >
                        {isEmpty(participants) && !isLoading && (
                          <NoParticipants color={noContentTextHex}>
                            <FormattedMessage id="chat.noParticipant" />
                          </NoParticipants>
                        )}
                        {!isEmpty(participants) &&
                          toJS(participants).map(participant => (
                            <Field
                              id={participant.id}
                              name="chat_room.user_ids"
                              value={participant.id}
                              checkboxContent={
                                <ChatUser
                                  participant={participant}
                                  key={participant.id}
                                  chatRoomAvatar={participant.avatar?.url}
                                  activeChatRoomId={activeChatRoomId}
                                  chatStyle={chatStyle}
                                  chatRoomId={participant.id}
                                  setVisibility={setVisibility}
                                  addColor={chatPrimaryHex || primaryColor}
                                  changeMobileTab={changeMobileTab}
                                />
                              }
                              type="checkbox"
                              mainColor={primaryColor}
                              component={CheckboxChatUsers}
                            />
                          ))}
                      </InfiniteScroll>
                    )}
                  </ScrollContent>
                  {!backOnMobile && (
                    <>
                      <RoomNameInput />
                      <Button
                        bgColor={chatButtonsBg}
                        onClick={handleSubmit}
                        color={buttonsTextColor}
                        height="45px"
                        fontSize="14px"
                        mt={10}
                        newChatButton
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        <FormattedMessage id="chat.newroomchatButton" />
                      </Button>
                    </>
                  )}
                </form>
              )}
            />
          </ChatBottom>
        </>
      </NewChatRoomsListContainer>
    );
  }
}

export default NewChatRoomList;
