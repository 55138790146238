import React from 'react';
import Box from '@material-ui/core/Box';
import styled from '@emotion/styled';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import TextInput from '../_forms/TextInput';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';

const Content = styled(Box)`
  padding-top: 10px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const FormContent = styled(Box)`
  position: relative;
`;

const FieldWrapper = styled(Box)`
  width: 95%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 99%;
  }
`;

export const RoomNameInput = () => {
  return (
    <Content>
      <FormContent>
        <FieldWrapper>
          <Field
            label={<FormattedMessage id="chat.newroomnamelabel" />}
            labelColor="textSecondary"
            name="chat_room.name"
            id="chat-name"
            inputType="text"
            width="100%"
            styledWhite
            component={TextInput}
            autocomplete="given-name"
          />
        </FieldWrapper>
      </FormContent>
    </Content>
  );
};
