import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'rebass';
import { css } from 'styled-components';
import moment from 'moment';
import styled from '@emotion/styled';
import { BreakoutIcon } from '../../../../_assets/icons/breakoutIcon';
import StylingUtils from '../../../../_app/utils/stylingUtils';
import theme from '../../../../_styles/theme';
import { MOBILE_BREAKPOINT } from '../../../../_styles/breakpoints';

const Wrapper = ({ active, children, style, clickable, ...props }) => {
  return (
    <Box
      css={css`
        color: ${(style && style.text) || '#fff'};
        position: relative;
        min-height: 77px;
        width: 240px;
        margin: 10px 0;
        padding: 10px 12px 10px 30px;
        ${StylingUtils.animationFadeIn()}

        :before {
          content: '';
          position: absolute;
          top: 0;
          right: -2px;
          bottom: 0;

          left: -12px;
          border-radius: 10px;
          background-color: ${(style && style.bgColor) ||
            theme.colors.bgNavyBlue};
          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            left: 0;
          }
        }
        :hover {
          cursor: ${clickable ? 'pointer' : 'default'};
        }

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  );
};

const Content = styled(Box)`
  position: relative;
  z-index: 2;
`;

const Incent = styled(Text)`
  margin-left: 4px;
  margin-bottom: 4px;
  align-self: flex-end;
  font-weight: ${theme.fontWeights.heading};
  font-size: ${theme.fontSizes[1]}px;
  word-spacing: 2px;
`;

const InlineWrapper = styled(Box)`
  display: flex;
  align-items: bottom;
`;

const TimeLocation = styled(Text)`
  margin-bottom: 5px;
  font-size: ${theme.fontSizes[0]}px;
  font-weight: ${theme.fontWeights.body};
  letter-spacing: 1.11px;
`;

export const BreakoutItem = inject('userStore')(
  observer(({ breakoutItem, active, children, style, tz, isOnEventBrief }) => {
    const { group, description } = breakoutItem;
    const handleClick = () => {
      if (
        active === undefined ||
        !group ||
        group.zoom_link === null ||
        isOnEventBrief
      )
        return;

      if (group.open_in_new_window) {
        window.open(group.zoom_link, '_blank');
      }
    };

    return (
      <Wrapper
        style={style}
        onClick={handleClick}
        clickable={group && group.zoom_link && !isOnEventBrief}
      >
        <Content>
          {children}
          <TimeLocation>
            {`${moment(breakoutItem.start_date).format('HH:mm')} - ${moment(
              breakoutItem.end_date,
            ).format('HH:mm')}`}
            &nbsp;
            <FormattedMessage id="date.hour" defaultMessage="" />
            &nbsp;
            {tz}
          </TimeLocation>
          <Flex mt="10px" justifyContent="flex-start" alignItems="center">
            <Flex
              css={css`
                min-width: 20px;
                max-width: 20px;
              `}
            >
              <BreakoutIcon
                fill={(style && style.text) || (style && style.iconsColor)}
              />
            </Flex>
            {group && description && (
              <InlineWrapper>
                <Incent>{description.toUpperCase()}</Incent>
              </InlineWrapper>
            )}
          </Flex>
        </Content>
      </Wrapper>
    );
  }),
);

export default BreakoutItem;
