import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Box, Text } from 'rebass';
import { css } from 'styled-components';
import moment from 'moment';
import get from 'lodash/get';
import { LiveLabel } from './LiveLabel';
import theme from '../../../_styles/theme';
import StylingUtils from '../../../_app/utils/stylingUtils';
import { getLocalTimezone } from '../../../_app/utils/dateTimeUtils';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';
import { AGENDA_ITEM_TYPES } from '../../../_app/constants';
import BreakoutItem from './components/BreakoutItem';
import SpeakersRow from './SpeakersRow';

const Wrapper = ({ active, children, style, isOnEventBrief, ...props }) => {
  const styleTextColor = active
    ? style && style.textHighlighted
    : style && style.text;

  return (
    <Box
      css={css`
        color: ${style ? styleTextColor : '#fff'};
        position: relative;
        min-height: 105px;
        width: 240px;
        padding: 10px 12px 10px 30px;
        ${StylingUtils.animationFadeIn()}

        ${active
          ? `
        :before {
            content: '';
            position: absolute;
            top: 0;
            right: -2px;
            bottom: 0;

            left: -12px;
            border-radius: 10px;
            background-color: ${(style && style.bgColor) ||
              theme.colors.bgNavyBlue};
          }`
          : ``}
        :hover {
          cursor: ${isOnEventBrief ? 'default' : 'pointer'};
        }

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  );
};

const Content = styled(Box)`
  position: relative;
  z-index: 2;
`;

const Info = styled(Text)`
  text-transform: uppercase;
  line-height: 1.2em;
`;

const TimeLeft = styled(Text)`
  min-height: 16px;
  text-transform: uppercase;
  font-size: ${theme.fontSizes[0]}px;
  font-weight: ${theme.fontWeights.emph};
  margin-top: 5px;
  letter-spacing: 1.11px;
`;

const TimeLocation = styled(Text)`
  margin-bottom: 5px;
  font-size: ${theme.fontSizes[0]}px;
  font-weight: ${theme.fontWeights.body};
  letter-spacing: 1.11px;
  color: ${props => props.color || 'white'};
`;

const TimeLineDot = styled(Box)`
  position: absolute;
  width: 8px;
  height: 8px;
  left: -28px;
  top: -1px;
  border-radius: 50%;
  opacity: ${props => props.opacity || 1};
  background-color: ${props => props.color || theme.colors.agendaTimeline};
`;

let refreshIntervalHandler = null;
const REFRESH_TIME_INTERVAL = 1000 * 60 * 0.5; // 0.5 minute

const AgendaItem = ({
  active,
  onSetActive,
  agendaItem,
  lastItem,
  isLive,
  bubbleStyle,
  onBubbleClick,
  showSpeakers,
  agendaItemStyle,
  infoFunc,
  playFunc,
  isOnEventBrief,
  chatStyle,
  ...props
}) => {
  const tz = getLocalTimezone();
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = useState(Date.now()); // only to periodically force refresh component rerender - not sure if a good practice
  const [
    activeMarkerMovedToThisEvent,
    setActiveMarkerMovedToThisEvent,
  ] = useState(false);
  const isInFuture = moment(agendaItem.start_date).isAfter(moment());
  const minutesLeft = moment(agendaItem.end_date).diff(moment.now(), 'minutes');
  const minutesUntilStart = moment(agendaItem.start_date).diff(
    moment.now(),
    'minutes',
  );

  useEffect(() => {
    refreshIntervalHandler = setInterval(() => {
      setTime(Date.now());
    }, REFRESH_TIME_INTERVAL);
    return () => {
      clearInterval(refreshIntervalHandler);
    };
  }, []);

  let timeLeftInfo = '';
  if (active) {
    if (minutesLeft > 0) {
      if (isInFuture) {
        timeLeftInfo = (
          <FormattedMessage
            id="agendaitem.timeuntil"
            values={{
              minutes: moment.duration(minutesUntilStart, 'minutes').humanize(),
            }}
          />
        );
      } else {
        timeLeftInfo = (
          <FormattedMessage
            id="agendaitem.timeremaining"
            values={{
              minutes: moment.duration(minutesLeft, 'minutes').humanize(),
            }}
          />
        );
      }
    } else if (minutesLeft === 0) {
      timeLeftInfo = <FormattedMessage id="agendaitem.timelessthanminute" />;
    } else {
      timeLeftInfo = <FormattedMessage id="agendaitem.timeover" />;
    }
  }

  if (
    isLive &&
    !active &&
    minutesUntilStart === 0 &&
    !activeMarkerMovedToThisEvent
  ) {
    setActiveMarkerMovedToThisEvent(true);
    onSetActive();
  }

  const { speakers, agenda_type: agendaType, timestamp, id } = agendaItem || {};

  return (
    <>
      {(agendaType === undefined ||
        agendaType === AGENDA_ITEM_TYPES.REGULAR) && (
        <Wrapper
          style={agendaItemStyle}
          active={active}
          onClick={onSetActive}
          isOnEventBrief={isOnEventBrief}
          {...props}
        >
          <Content>
            <TimeLineDot color={get(agendaItemStyle, 'dot')} />
            {isLive && <LiveLabel />}
            <TimeLocation
              color={
                !active
                  ? agendaItemStyle.buttonsBg || agendaItemStyle.text
                  : 'white'
              }
            >
              {!lastItem
                ? `${moment(agendaItem.start_date).format('HH:mm')} - ${moment(
                    agendaItem.end_date,
                  ).format('HH:mm')}`
                : `${moment(agendaItem.end_date).format('HH:mm')}`}
              &nbsp;
              <FormattedMessage id="date.hour" defaultMessage="" />
              &nbsp;
              {tz}
            </TimeLocation>
            <Info fontSize={2} fontWeight="bold" letterSpacing={1}>
              {agendaItem.description}
            </Info>
            {!isOnEventBrief && <TimeLeft>{timeLeftInfo}</TimeLeft>}
            <SpeakersRow
              id={id}
              speakers={speakers}
              showSpeakers={showSpeakers}
              lastItem={lastItem}
              agendaItemStyle={agendaItemStyle}
              onBubbleClick={onBubbleClick}
              bubbleStyle={bubbleStyle}
              chatStyle={chatStyle}
              onSetActive={onSetActive}
              playFunc={playFunc}
              infoFunc={infoFunc}
              timestamp={timestamp}
              isOnEventBrief={isOnEventBrief}
            />
          </Content>
        </Wrapper>
      )}
      {!isOnEventBrief &&
        agendaType === AGENDA_ITEM_TYPES.BREAKOUT_ROOM &&
        agendaItem.group !== null && (
          <Box active={active} onClick={onSetActive} {...props}>
            <BreakoutItem
              tz={tz}
              breakoutItem={agendaItem}
              style={agendaItemStyle}
              onClick={onSetActive}
              active={active}
              isOnEventBrief={isOnEventBrief}
            >
              <TimeLineDot color={get(agendaItemStyle, 'dot')} opacity="0.5" />
              {isLive && <LiveLabel right="18px" />}
            </BreakoutItem>
          </Box>
        )}
    </>
  );
};

export default AgendaItem;
