/* eslint-disable */
import * as React from 'react';

export const EnvelopeIcon = ({ fill, ...props }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="envelope"
    className="svg-inline--fa fa-envelope fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
<g>
	<g>
		<path fill={fill} d="M458.667,85.333H53.333C23.936,85.333,0,109.269,0,138.667v234.667c0,29.397,23.936,53.333,53.333,53.333h405.333
			c29.397,0,53.333-23.936,53.333-53.333V138.667C512,109.269,488.064,85.333,458.667,85.333z M490.667,373.333
			c0,17.643-14.357,32-32,32H53.333c-17.643,0-32-14.357-32-32V138.667c0-17.643,14.357-32,32-32h405.333c17.643,0,32,14.357,32,32
			V373.333z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M467.456,132.651c-3.307-4.864-9.941-6.08-14.827-2.773L277.675,249.579c-13.184,9.003-30.208,9.003-43.371,0
			L59.349,129.877c-4.885-3.349-11.52-2.091-14.827,2.773c-3.307,4.864-2.069,11.499,2.795,14.827l174.955,119.701
			c10.24,7.019,21.995,10.517,33.728,10.517s23.488-3.499,33.728-10.517l174.955-119.701
			C469.547,144.149,470.784,137.515,467.456,132.651z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M189.525,259.819c-3.776-4.501-10.517-5.12-15.04-1.365l-128,106.667c-4.523,3.776-5.141,10.517-1.365,15.04
			c2.112,2.539,5.141,3.84,8.213,3.84c2.411,0,4.843-0.811,6.827-2.475l128-106.667
			C192.683,271.083,193.301,264.341,189.525,259.819z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M465.515,365.12l-128-106.667c-4.544-3.755-11.264-3.136-15.04,1.365c-3.776,4.544-3.157,11.264,1.365,15.04l128,106.667
			c2.005,1.664,4.416,2.475,6.827,2.475c3.051,0,6.08-1.301,8.213-3.84C470.656,375.616,470.037,368.896,465.515,365.12z"/>
	</g>
</g>
  </svg>
);
