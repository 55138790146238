const API_VERSION = 'v1';
const defaultOptions = {
  query: '',
};

const prefix = `/${API_VERSION}`;

const querybuild = (queryObj = '') => {
  return queryObj; // to be continued
};

export const API_ROUTES = {
  SIGN_IN: `${prefix}/auth/sign_in`,
  SIGN_UP: `${prefix}/auth/`,
  SIGN_OUT: `${prefix}/auth/sign_out`,
  RESET_PASSWORD: `${prefix}/auth/password`,

  AGENDA_ITEMS: `${prefix}/agenda_items/`,
  CHAT_MSG: `${prefix}/chat_messages`,
  PROFILE: `${prefix}/users/me`,
  PROFILE_DETAILS: `${prefix}/users/profile_details`,
  ATTENDANCES: `${prefix}/attendances`,

  ORGANIZATIONS: ({ query } = defaultOptions) =>
    `${prefix}/organizations/${querybuild(query)}`,
  EVENTS: ({ query } = defaultOptions) =>
    `${prefix}/events/${querybuild(query)}`,
  EVENT_ATTENDEES: ({ eventId, query }) =>
    `${prefix}/events/${eventId}/attendees?q=${query}`,
  EVENT_CHAT_ROOMS: eventId => `${prefix}/events/${eventId}/chat_rooms?per=30`,
  CREATE_CHAT_ROOM: eventId => `${prefix}/events/${eventId}/chat_rooms`,
  LEAVE_CHAT_ROOM: chatId => `${prefix}/chat_rooms/${chatId}/leave`,
  CHAT_ROOM_USERS: chatId => `${prefix}/chat_rooms/${chatId}/room_users`,
  CHAT_ARCHIVE: chatRoomId =>
    `${prefix}/chat_rooms/${chatRoomId}/chat_messages`,
  CHAT_PARTICIPANTS: chatRoomId =>
    `${prefix}/chat_rooms/${chatRoomId}/participants`,
  CHAT_MSG_REACTION: chatMessageId =>
    `${prefix}/chat_messages/${chatMessageId}/chat_message_reactions`,
  // AGENDA_POLL: agendaId => `${prefix}/agenda_items/${agendaId}/poll`,
  EVENT_POLL: eventId => `${prefix}/events/${eventId}/polls`,
  POLL_ANSWER: pollAnswerId => `${prefix}/poll_answers/${pollAnswerId}/vote`,

  EVENT_BRIEF: `${prefix}/event_detail`,
  NEXT_EVENT: `${prefix}/next_event`,
  ZOOM_SIGNATURE: `${prefix}/zoom`,
  EVENT_TRANSLATIONS: eventId => `${prefix}/events/${eventId}/translations`,
  EVENT: eventId => `${prefix}/events/${eventId}`,
  EXPLORE_ITEMS: eventId =>
    `${prefix}/explore_items/event_active_item?event_id=${eventId}`,
  EVENT_REACTIONS: eventId => `${prefix}/events/${eventId}/event_reactions`,
};
