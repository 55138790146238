/* eslint-disable */
import * as React from 'react';

export const DownloadIcon = ({ fill, paddingLeft, paddingTop, ...props }) => (
  // <svg viewBox="0 0 650 650" style={{ paddingLeft, paddingTop }} {...props}>
  //   <path
  //     fill={fill}
  //     d="M378.528 214.688l-21.088-24c-5.824-6.624-15.904-7.264-22.56-1.472L272 244.32V16c0-8.832-7.168-16-16-16h-32c-8.832 0-16 7.168-16 16v228.32l-62.88-55.104c-6.624-5.792-16.704-5.152-22.56 1.472l-21.088 23.968c-5.856 6.656-5.184 16.8 1.472 22.624l126.528 110.752a16.017 16.017 0 0021.088 0L377.056 237.28c6.656-5.824 7.328-15.968 1.472-22.592zM416 416H64c-8.832 0-16 7.168-16 16v32c0 8.832 7.168 16 16 16h352c8.832 0 16-7.168 16-16v-32c0-8.832-7.168-16-16-16z"
  //   />
  // </svg>
  <svg viewBox="0 0 24 24" style={{ paddingLeft, paddingTop }} {...props}>
    <g><path fill={fill || '#fff'} d="m12 17c-.276 0-.5-.224-.5-.5v-16c0-.276.224-.5.5-.5s.5.224.5.5v16c0 .276-.224.5-.5.5z"/></g>
    <g><path fill={fill || '#fff'} d="m12 17c-.128 0-.256-.049-.354-.146l-4-4c-.195-.195-.195-.512 0-.707s.512-.195.707 0l3.647 3.646 3.646-3.646c.195-.195.512-.195.707 0s.195.512 0 .707l-4 4c-.097.097-.225.146-.353.146z"/></g>
    <g><path fill={fill || '#fff'} d="m21.5 24h-19c-1.379 0-2.5-1.122-2.5-2.5v-5c0-.276.224-.5.5-.5s.5.224.5.5v5c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-5c0-.276.224-.5.5-.5s.5.224.5.5v5c0 1.378-1.121 2.5-2.5 2.5z"/></g>
  </svg>
);

export const CameraIcon = ({ fill, paddingLeft, paddingTop, padding, ...props }) => (
  <svg viewBox="0 -87 472 472" style={{ paddingLeft, paddingTop, padding }} {...props}>
    <path
      fill={fill || '#fff'}
      d="m467.101562 26.527344c-3.039062-1.800782-6.796874-1.871094-9.898437-.179688l-108.296875 59.132813v-35.480469c-.03125-27.601562-22.398438-49.96875-50-50h-248.90625c-27.601562.03125-49.96875 22.398438-50 50v197.421875c.03125 27.601563 22.398438 49.96875 50 50h248.90625c27.601562-.03125 49.96875-22.398437 50-50v-34.835937l108.300781 59.132812c3.097657 1.691406 6.859375 1.625 9.894531-.175781 3.039063-1.804688 4.898438-5.074219 4.898438-8.601563v-227.816406c0-3.53125-1.863281-6.796875-4.898438-8.597656zm-138.203124 220.898437c-.015626 16.5625-13.4375 29.980469-30 30h-248.898438c-16.5625-.019531-29.980469-13.4375-30-30v-197.425781c.019531-16.558594 13.4375-29.980469 30-30h248.90625c16.558594.019531 29.980469 13.441406 30 30zm123.101562-1.335937-103.09375-56.289063v-81.535156l103.09375-56.285156zm0 0"/>
  </svg>
);
