/* eslint-disable */
import React from 'react';

export const DefaultChat = ({ fill, ...props }) => (
<svg viewBox="0 0 480 480" {...props} xmlns="http://www.w3.org/2000/svg">
  <g stroke="null" id="svg_3">
    <circle stroke="null" fill={fill || "#e9575b"} cx="240" cy="240" r="200" id="svg_1"/>
    <path stroke="null" fill={'#fff'} d="m313.49998,141.01616l-146.99998,0c-17.38891,0.01969 -31.48119,14.78174 -31.5,32.99708l0,95.32491c0.0188,18.21535 14.11108,32.97739 31.5,32.99708l9.72925,0l-6.15918,32.2631c-0.26489,1.3874 0.25634,2.80883 1.33642,3.6556s2.5293,0.96672 3.72387,0.30792l65.73437,-36.22662l72.63525,0c17.38891,-0.01969 31.48119,-14.78174 31.5,-32.99708l0,-95.32491c-0.0188,-18.21535 -14.11108,-32.97739 -31.5,-32.99708l0,0.00001zm24.49999,128.32199c-0.01538,14.16769 -10.9751,25.64828 -24.49999,25.6644l-73.49999,0c-0.56909,0 -1.12793,0.14501 -1.63037,0.42249l-59.99389,33.07764l5.5542,-29.0998c0.2102,-1.07949 -0.05298,-2.20017 -0.71777,-3.0523c-0.66479,-0.85393 -1.66114,-1.34803 -2.71216,-1.34803l-14,0c-13.5249,-0.01611 -24.48462,-11.4967 -24.49999,-25.6644l0,-95.32491c0.01538,-14.16769 10.9751,-25.64828 24.49999,-25.6644l146.99998,0c13.5249,0.01611 24.48462,11.4967 24.49999,25.6644l0,95.32491zm0,0" id="svg_2"/>
 </g>
</svg>
);

