import React from 'react';
import { Button as RebassButton } from 'rebass';
import { css } from 'styled-components';
import theme from '../../_styles/theme';
import StylingUtils from '../../_app/utils/stylingUtils';
import { Loader } from '../Loader';

export const Button = ({
  children,
  isLoading,
  bgColor,
  height,
  fontSize,
  disabled,
  color,
  newChatButton,
  uppercase,
  ...props
}) => {
  return (
    <RebassButton
      type="submit"
      css={css`
        display: flex;
        outline: none;
        text-transform: ${uppercase ? 'uppercase' : 'none'};
        color: ${disabled || !color ? 'white' : color};
        width: ${newChatButton ? `calc(100% - 8px)` : '100%'};
        height: ${height || '52px'};
        background-color: ${disabled
          ? theme.colors.bgTileGrey
          : bgColor || theme.colors.primary};
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        font-weight: ${theme.fontWeights.emph};
        font-size: ${fontSize || '17px'};
        &:hover {
          background-color: ${disabled
            ? theme.colors.bgTileGrey
            : StylingUtils.hexToRgbA(bgColor, 0.74)};
          cursor: ${disabled ? 'not-allowed' : 'pointer'};
        }
      `}
      disabled={disabled}
      {...props}
    >
      {isLoading ? <Loader /> : children}
    </RebassButton>
  );
};
