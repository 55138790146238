import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';
import { css } from 'styled-components';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import get from 'lodash/get';
import theme from '../_styles/theme';
import StylingUtils from '../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '../_styles/breakpoints';
import { SUBDOMAIN, toHex } from '../_app/utils/otherUtils';
import { OrganizationBrief } from './components/OrganizationBrief';
import EventList from './components/EventList';

const Container = styled(Flex)`
  flex-direction: row;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-color: ${props => props.bodycolor};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const LeftPart = styled(Box)`
  position: relative;
  width: 44%;
  height: auto;
  padding: 150px 56px 119px 53px;
  ${props =>
    props.backgroundimage
      ? `
      background-image: url(${props.backgroundimage});
      background-position: top left;
      background-repeat: no-repeat;
    `
      : `background-color: ${props.bodycolor}`};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: auto;
    padding: 60px 25px 75px 30px;
  }
`;

const RightPart = ({ children, bckg, gradient }) => (
  <Flex
    css={css`
      position: relative;
      flex-direction: column;
      width: 56%;
      height: auto;
      background: ${gradient || bckg || theme.colors.bgLight};
      padding-top: 75px;
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        padding-top: 0;
        min-height: 300px;
      }
      ${StylingUtils.animationFadeIn()}
    `}
  >
    {children}
  </Flex>
);

const HeaderFixBlock = styled(Box)`
  position: absolute;
  top: -20px;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #000;
`;
@inject(
  'organizationsStore',
  'homeStore',
  'eventsStore',
  'eventBriefStore',
  'userStore',
  'authStore',
)
@observer
class EventListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    const {
      homeStore: { clearActiveEventStore },
      eventBriefStore: { clearStore: clearBriefStore },
    } = this.props;
    clearActiveEventStore();
    clearBriefStore();
  }

  async componentDidUpdate() {
    const {
      organizationsStore: { isLoading },
      homeStore: { activeOrganization },
      eventsStore: { events },
    } = this.props;

    if (events.length === 1) {
      this.handleRedirect(events[0]);
    }

    if (!activeOrganization && !isLoading) {
      await this.handleNoOrg();
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleRedirect = async event => {
    const {
      history,
      userStore: { hasAcceptedAttendance },
      eventBriefStore: { getEventBrief },
      authStore: { isAuthenticated },
    } = this.props;

    let {
      location: { search },
    } = window;

    const {
      location: { pathname },
    } = window;

    if (event && event.id) {
      const isSignIn = pathname.includes('/sign-in');
      const isSignUp = pathname.includes('/sign-up');
      const isResetPasswordFinish = pathname.includes('/reset-password-finish');
      const isResetPassword =
        !isResetPasswordFinish && pathname.includes('/reset-password');

      const hideEventAccess =
        get(event, 'hide_registration_form') && get(event, 'hide_login_form');

      let whereTo = '';
      if (isAuthenticated) {
        if (event.public && !hideEventAccess) {
          whereTo = '/event';
          search = search ? `${search}&enter=true` : `?enter=true`;
        } else if (hasAcceptedAttendance(event.id)) {
          whereTo = '/event';
          search = '';
        } else {
          whereTo = '/sign-up';
          search = '';
        }
      } else if (!isAuthenticated && event.public && !hideEventAccess) {
        whereTo = '/event';
        search = search ? `${search}&enter=true` : `?enter=true`;
      } else {
        whereTo = '/sign-up';
      }

      if (whereTo !== '/event') {
        if (isSignIn) {
          whereTo = '/sign-in';
        }
        if (isSignUp) {
          whereTo = '/sign-up';
        }
        if (isResetPassword && !event.hide_login_form) {
          whereTo = '/reset-password';
        }
        if (isResetPasswordFinish && !event.hide_login_form) {
          whereTo = '/reset-password-finish';
        }
      }

      history.push(`${whereTo}/${get(event, 'slug', '')}${search || ''}`);
      if (whereTo !== '/event') {
        await getEventBrief({ eventId: event.slug });
      }
    }
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleNoOrg = async () => {
    const {
      eventsStore: { getOrganizationEvents },
      homeStore: { setActiveOrganization },
      organizationsStore: { getOrganizationBySlug },
    } = this.props;

    getOrganizationBySlug(SUBDOMAIN, async org => {
      setActiveOrganization(org);
      const events2 = getOrganizationEvents(org.id);
      if (events2.length === 1) {
        this.handleRedirect(events2[0]);
      }
    });
  };

  render() {
    const { width } = this.state;
    const {
      location,
      history,
      eventsStore: { events, getClosestEvent },
      homeStore: { activeOrganization },
    } = this.props;

    let closestFutureEvent;
    if (events && events.length > 0)
      closestFutureEvent = getClosestEvent(events);
    let customTheme;
    if (closestFutureEvent) {
      customTheme = closestFutureEvent.event_custom_theme;
    }

    const {
      buttons_bg: buttonsBg,
      buttons_text_color: buttonsTextColor,
      event_brief_bg: eventBriefBg,
      event_brief_text: eventBriefText,
      event_brief_gradient_top: eventBriefGrTop,
      event_brief_gradient_bottom: eventBriefGrBottom,
      event_listing_buttons_bg: eventListingButtonsBg,
      landing_page_bckg_img: landingPageBgImg,
      landing_page_body: landingPageBody,
      header_font_family: headerFontFamily,
    } = customTheme || {};
    const rightBoxGradient =
      eventBriefGrTop && eventBriefGrBottom
        ? `linear-gradient(
            to bottom,
            ${toHex(eventBriefGrTop)},
            ${toHex(eventBriefGrBottom)}
          );`
        : null;

    const bodyColor = landingPageBody
      ? toHex(landingPageBody)
      : theme.colors.backgroundDarker;

    const orgName = get(activeOrganization, 'name');
    const orgDescription = get(activeOrganization, 'description');

    const isMobile = width <= MOBILE_BREAKPOINT;
    const isTablet = width <= TABLET_BREAKPOINT;

    if (events.length > 1) {
      return (
        <Container bodycolor={bodyColor}>
          <LeftPart
            bodycolor={bodyColor}
            backgroundimage={landingPageBgImg && landingPageBgImg.url}
          >
            <EventList
              location={location}
              history={history}
              buttonsBg={buttonsBg}
              buttonsTextColor={buttonsTextColor}
              isTablet={isTablet}
              themeElements={{
                eventListingButtonsBg: toHex(eventListingButtonsBg),
                headerFontFamily,
              }}
            />
          </LeftPart>
          <RightPart bckg={toHex(eventBriefBg)} gradient={rightBoxGradient}>
            <OrganizationBrief
              orgDescription={orgDescription}
              orgName={orgName}
              themeElements={{
                headerFontFamily,
                buttonsBg: toHex(buttonsBg),
                briefTextHex: toHex(eventBriefText),
              }}
            />
            {!isMobile && <HeaderFixBlock />}
          </RightPart>
        </Container>
      );
    }
    return null;
  }
}

export default EventListingPage;
