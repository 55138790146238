import { action, observable } from 'mobx';
import { isEmpty, get } from 'lodash';
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';
import homeStore from './homeStore';
import { setPageTitle } from '../_app/utils/otherUtils';

export class EventBriefStore {
  @observable isLoading = false;

  @observable event = null;

  @observable noUpcomming = undefined;

  @observable noEventFoundFlag = undefined;

  @action
  getEventBrief = async ({ eventId }) => {
    console.log('API: getEventBrief: ', eventId);
    this.isLoading = true;
    try {
      let url;
      if (eventId) {
        url = `${API_ROUTES.EVENT_BRIEF}/${eventId}`;
      } else {
        url = `${API_ROUTES.NEXT_EVENT}`;
      }
      const { data } = await API.get(url);

      if (isEmpty(data)) {
        if (eventId) {
          this.noEventFoundFlag = true;
          this.noUpcomming = false;
        } else {
          this.noUpcomming = true;
          this.noEventFoundFlag = false;
        }
      }

      this.event = data;
      homeStore.eventCustomTheme = data && data.event_custom_theme;
      setPageTitle(get(this.event, 'name'));
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status === 404) {
          window.location.href = '/404';
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = async () => {
    this.event = null;
    this.noUpcomming = undefined;
  };
}

export default new EventBriefStore();
