import React from 'react';
import ReactGA from 'react-ga';
import { Flex, Image, Text } from 'rebass';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import StylingUtils from '../../_app/utils/stylingUtils';
// import { API } from '../../_app/api';
import theme from '../../_styles/theme';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { DownloadIcon, CameraIcon } from '../../_assets/icons/downloadIcon';

const DocumentWrapper = styled('a')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 5px;
  background-color: ${props => props.theme.bgColor};

  padding: 9px 13px 9px;
  margin-right: 15px;
  // min-width: 153px;
  width: auto;
  height: 94px;

  box-shadow: 2px 2px 5px ${StylingUtils.hexToRgbA('#000', 0.35)};

  :hover {
    text-decoration: none;
    cursor: pointer;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-right: 0;
  }
`;

const DownloadButton = styled(Box)`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 26px;
  height: 26px;
  background-color: ${props => props.theme.primaryColor};
  background-size: 30px 30px;
  border-radius: 13px;
  border: 2px solid ${props => props.theme.primaryColor};
  box-sizing: border-box;
  padding: 3px;
`;

const ImageContainer = styled(Box)`
  height: 100%;
  // min-width: 65px;
  border-radius: 5px;

  overflow: hidden;
  box-shadow: inset 0 0 5px #333;
  background-color: #fff;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LeftBox = styled(Box)`
  display: flex;
  width: auto;
  // min-width: 65px;
  height: 100%;
`;

const RightBox = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-width: 65px;
  margin-left: 10px;
  width: 100%;
  max-width: 150px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: none;
  }
`;

const Name = styled(Text)`
  word-break: break-word;
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.bold};
  color: ${props => props.theme.color};
`;

const DocumentItem = ({
  iframeBreakoutRoom,
  handleVideoClick,
  name,
  // authorized_url: authorizedUrl,
  file,
  custom_thumbnail: customThumbnail,
  documentStyle,
  is_video_type: isVideoType,
  source_url: sourceUrl,
  open_in_event_view: openInEventView,
}) => {
  const {
    documentContainerBg,
    primaryColor,
    documentNameTextColor,
    buttonsTextColor,
  } = documentStyle;

  const handleLinkClick = () => {
    ReactGA.event({
      category: 'Document',
      action: 'Download',
      label: name,
    });
  };

  // const handleLinkClick = async e => {
  //   ReactGA.event({
  //     category: 'Document',
  //     action: 'Download',
  //     label: name,
  //   });

  //   if (authorizedUrl) {
  //     const {
  //       data: { url: authUrl },
  //     } = await API.get(authorizedUrl);
  //     window.open(authUrl);
  //   } else if (file.url) {
  //     window.open(file.url);
  //   }
  //   e.preventDefault();
  // };

  const thumbnail = customThumbnail || file;
  const fileUrl = sourceUrl || (file || {}).url;
  const openInBlankWindow =
    !openInEventView || (openInEventView && iframeBreakoutRoom);

  const openAsVideoElement = isVideoType;

  return (
    <DocumentWrapper
      download
      target={openInBlankWindow ? '_blank' : null}
      href={openInBlankWindow ? fileUrl : null}
      onClick={
        openInBlankWindow
          ? handleLinkClick
          : () => handleVideoClick(fileUrl, openAsVideoElement)
      }
      theme={{
        bgColor:
          StylingUtils.hexToRgbA(documentContainerBg, 0.5) ||
          StylingUtils.hexToRgbA(theme.colors.bgHeader, 0.5),
      }}
    >
      <LeftBox>
        {thumbnail?.thumb?.url && (
          <ImageContainer>
            <Image
              src={thumbnail.thumb.url}
              alt={`${name} file thumbnail`}
              style={{ height: '100%', width: 'auto', maxWidth: 'none' }}
            />
          </ImageContainer>
        )}
      </LeftBox>
      <RightBox>
        {name && (
          <Name
            fontSize={0}
            theme={{
              color: StylingUtils.hexToRgbA(documentNameTextColor),
            }}
          >
            {name.length > 40 ? `${name.slice(0, 40)}...` : name}
          </Name>
        )}
      </RightBox>
      {openInBlankWindow && (
        <DownloadButton theme={{ primaryColor: primaryColor || '#000' }}>
          <DownloadIcon fill={buttonsTextColor} />
        </DownloadButton>
      )}
      {!openInBlankWindow && (
        <DownloadButton theme={{ primaryColor: primaryColor || '#000' }}>
          <CameraIcon fill={buttonsTextColor} />
        </DownloadButton>
      )}
    </DocumentWrapper>
  );
};

DocumentItem.defaultProps = {
  name: '',
  // authorized_url: '',
  file: {},
};

DocumentItem.propTypes = {
  name: PropTypes.string,
  // authorized_url: PropTypes.string,
  file: PropTypes.objectOf(PropTypes.any),
};

export default DocumentItem;
