import React from 'react';
import { withRouter } from 'react-router-dom';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import reset from 'emotion-reset';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
// import get from 'lodash/get';
import theme from './theme';
import { breakpoint, MOBILE_BREAKPOINT } from './breakpoints';
import { toHex } from '../_app/utils/otherUtils';

@inject('eventBriefStore', 'homeStore', 'eventsStore')
@observer
class GlobalStyle extends React.Component {
  render() {
    const {
      homeStore: {
        eventCustomTheme,
        activeEvent,
        noEventFoundFlag,
        activeAgendaItem,
        activeOrganization,
      },
      eventBriefStore: {
        event,
        noEventFoundFlag: noEventBriefFoundFlag,
        noUpcomming,
      },
      eventsStore: { getClosestEvent, events },
    } = this.props;

    const theEvent = activeEvent || event;
    const {
      body1,
      landing_page_body: landingPageBody,
      buttons_bg: buttonsBgEv,
      buttons_text_color: buttonsTextColorEv,
      font_url: fontUrl,
      body_font_family: bodyFontFamily,
      main_gradient1: eventMainGradient1,
      tabs_separator_color: eventTabsSeparatorColor,
      tabs_highlight_color: eventTabsHighlightColor,
      tabs_text_color: eventTabsTextColor,
    } = eventCustomTheme || {};
    const { agenda_item_custom_theme: agendaItemCustomTheme } =
      activeAgendaItem || {};
    const {
      tabs_highlight_color: agendaTabsHighlightColor,
      main_gradient1: agendaMainGradient1,
      tabs_separator_color: agendaTabsSeparatorColor,
      tabs_text_color: agendaTabsTextColor,
      buttons_bg: buttonsBgAg,
      buttons_text_color: buttonsTextColorAg,
    } = agendaItemCustomTheme || {};
    let bodyColor = body1 ? toHex(body1) : theme.colors.backgroundDarker;
    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : theme.colors.textSecondary;
    const { URL } = document;
    const isOnEventPage = URL.includes('/event/');
    const isOnLandingPage =
      URL.includes('/sign-in') ||
      URL.includes('/sign-up') ||
      URL.includes('/reset-password') ||
      URL.includes('/reset-password-finish');
    const isOn404Page = URL.includes('/404');

    if (isOnLandingPage) {
      bodyColor = landingPageBody
        ? toHex(landingPageBody)
        : theme.colors.backgroundDarker;
    }

    const isOnEventListingPage = /(^\/$|^\/reset-password\/?$|^\/reset-password-finish\/?$)/.test(
      window.location.pathname,
    );

    // below is a hack - probably not needed anymore
    if (isOnEventPage) {
      if (isEmpty(theEvent) && !eventCustomTheme && !noEventFoundFlag) {
        bodyColor = '#fff'; // prevents from blinking
      }
    } else if (
      isEmpty(theEvent) &&
      !eventCustomTheme &&
      !noEventBriefFoundFlag &&
      !noUpcomming
    ) {
      bodyColor = '#fff'; // prevents from blinking
    }

    if (isOn404Page) {
      bodyColor = '#151c32';
    }

    const fontsToImport = `@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,900&display=swap&subset=latin-ext');`;
    let finalFont;
    let finalBodyFontFamily;
    if (isOnEventListingPage) {
      const closestEvent = getClosestEvent(events);
      finalBodyFontFamily =
        // eslint-disable-next-line camelcase
        closestEvent?.event_custom_theme?.body_font_family || bodyFontFamily;
      // eslint-disable-next-line camelcase
      finalFont = closestEvent?.event_custom_theme?.font_url || fontUrl;
    } else {
      finalFont = fontUrl;
      finalBodyFontFamily = bodyFontFamily;
    }
    if (finalFont) {
      const fontLink = document.createElement('link');
      fontLink.href = finalFont;
      fontLink.rel = 'stylesheet';
      document.head.appendChild(fontLink);
    }

    return (
      <Global
        styles={css`
          ${reset}
          ${fontsToImport}

          * {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          html,
          body {
            min-width: 0;
          }
          html {
            @media (max-width: ${MOBILE_BREAKPOINT}px) {
              overflow: hidden;
            }
            box-sizing: border-box;
          }
          body {
            overflow-y: auto;
            margin: 0;
            padding: 0;
            font-family: ${finalBodyFontFamily
                ? `${finalBodyFontFamily}, `
                : ''}
              ${theme.fonts.body};
            color: ${theme.colors.text};
            background-color: ${bodyColor};
            position: relative;
            height: 100vh;
            width: 100vw;
            max-width: 100%;
            height: calc(var(--vh, 100vh)); // wtf
            overflow-x: hidden; // avexis - home screen horizontal scroll fix

            @media ${breakpoint.xs} {
              padding: 0;
            }
          }
          a {
            color: ${toHex(buttonsBgEv) || theme.colors.text};
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          .bm-item {
            outline: none;
          }
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }

          #optanon-cookie-policy .optanon-cookie-policy-group-name,
          #optanon-cookie-policy .optanon-cookie-policy-group-description,
          #optanon-cookie-policy .optanon-cookie-policy-cookies-used,
          #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list {
            font-family: ${theme.fonts.body};
            font-size: ${theme.fontSizes[1]}px;
            // letter-spacing: 1px;
            line-height: normal;
            font-weight: ${theme.fontWeights.body};
            margin-bottom: 1em;
            font-kerning: none;
          }

          #optanon-cookie-policy .optanon-cookie-policy-group-name,
          #optanon-cookie-policy .optanon-cookie-policy-cookies-used {
            font-weight: ${theme.fontWeights.bold};
            text-transform: uppercase;
            letter-spacing: 1px;
          }

          #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list {
            list-style-type: disc;
            padding-inline-start: 40px;
          }

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            .bm-burger-button {
              -webkit-overflow-scrolling: touch;
              transform: translate3d(0, 0, 0);
            }
            body.side-menu-opened .bm-burger-button {
              display: none;
            }
          }
          .react-tabs {
            &__tab-list {
              margin: 0 0 23px;
              padding: 0;
              &--sticky {
                position: sticky;
                top: 0;
                padding-bottom: 23px;
                padding-top: 36px;
                z-index: 3;
                background-color: ${bodyColor};
                margin-bottom: 0;
              }
              @media (max-width: ${MOBILE_BREAKPOINT}px) {
                display: flex;
                height: 39px;
                justify-content: space-around;
                margin-bottom: 0;
                width: fit-content;
                min-width: 100%;
              }
            }
            &__tab {
              position: relative;
              display: inline-block;
              padding: 0 10px 4px;
              border-bottom: 2px solid #171922;
              border-color: ${toHex(agendaTabsSeparatorColor) ||
                toHex(eventTabsSeparatorColor) ||
                '#000'};
              color: ${toHex(agendaTabsTextColor) ||
                toHex(eventTabsTextColor) ||
                '#fff'};
              list-style: none;
              cursor: pointer;
              & > div > div {
                margin-left: auto;
                margin-right: auto;
              }
              @media (max-width: ${MOBILE_BREAKPOINT}px) {
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                text-align: center;
                font-size: ${theme.fontSizes[2]}px;
                font-weight: ${theme.fontWeights.emph};
                border-bottom-width: 2px;
                border-color: ${toHex(agendaTabsSeparatorColor) ||
                  toHex(eventTabsSeparatorColor) ||
                  '#000'};
              }
              &--selected {
                border-color: ${toHex(agendaTabsHighlightColor) ||
                  toHex(eventTabsHighlightColor) ||
                  '#fff'};
                color: ${toHex(agendaTabsHighlightColor) ||
                  toHex(eventTabsHighlightColor) ||
                  '#fff'};
              }
              &--disabled {
                color: grey;
                cursor: default;
              }
              &:focus {
                outline: none;
              }
            }
            &__tab-panel {
              transform: translate3d(0, 0, 0);
              display: none;
              @media (max-width: ${MOBILE_BREAKPOINT}px) {
                height: calc(100% - 39px);
              }
              &--selected {
                display: block;
              }
            }
            &--noauth {
              .react-tabs__tab-panel--selected {
                max-height: none;
                overflow-y: hidden;
                padding-top: 20px;
              }
            }
          }
          .hidden {
            display: none;
          }
          .nowrap {
            white-space: nowrap;
          }
          strong {
            font-weight: ${theme.fontWeights.heading};
          }
          em {
            font-style: italic;
          }
          sup {
            vertical-align: super;
            font-size: smaller;
          }
          sub {
            vertical-align: sub;
            font-size: smaller;
          }
          .top-tabs-bg {
            @media (max-width: ${MOBILE_BREAKPOINT}px) {
              background: ${toHex(agendaMainGradient1) ||
                toHex(eventMainGradient1) ||
                '#101119'};
            }
          }
          .unlock-overflow {
            overflow: auto;
          }
          .lock-html-overflow {
            overflow-y: hidden;
            height: 100%;
          }
          .addeventatc {
            display: inline-block;
            position: relative;
            z-index: 99998;
            font-family: 'Open Sans', Roboto, 'Helvetica Neue', Helvetica,
              Optima, Segoe, 'Segoe UI', Candara, Calibri, Arial, sans-serif;
            font-weight: 600;
            line-height: 100%;
            font-size: 15px;
            text-decoration: none;
            border: 1px solid transparent;
            padding: 13px 12px 12px 43px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            cursor: pointer;
            -webkit-font-smoothing: antialiased !important;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
            -webkit-user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.17),
              0 2px 5px 0 rgba(50, 50, 93, 0.1),
              0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08),
              0 0 0 0 transparent !important;
            position: relative;
            background-color: ${toHex(buttonsBgAg) ||
              toHex(buttonsBgEv) ||
              primaryColor ||
              theme.colors.primary} !important;
            color: ${toHex(buttonsTextColorAg) ||
              toHex(buttonsTextColorEv) ||
              '#fff'} !important;
          }
          .addeventatc:hover {
            background-color: #fafafa;
            color: #000;
            font-size: 15px;
            text-decoration: none;
          }
          .addeventatc_dropdown {
            width: 230px;
            position: absolute;
            padding: 6px 0px 0px 0px;
            font-family: 'Open Sans', Roboto, 'Helvetica Neue', Helvetica,
              Optima, Segoe, 'Segoe UI', Candara, Calibri, Arial, sans-serif;
            color: #000 !important;
            font-weight: 600;
            line-height: 100%;
            background: #fff;
            font-size: 15px;
            text-decoration: none;
            text-align: left;
            margin-left: -1px;
            display: none;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
            -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
            transform: scale(0.98, 0.98) translateY(5px);
            opacity: 0.5;
            z-index: -1;
            transition: transform 0.15s ease;
            -webkit-user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          }
          .addeventatc_dropdown span {
            display: block;
            line-height: 100%;
            background: #fff;
            text-decoration: none;
            cursor: pointer;
            font-size: 15px;
            color: #333;
            font-weight: 600;
            padding: 14px 10px 14px 55px;
            margin: -2px 0px;
          }
          .addeventatc_dropdown .ateappleical {
            background: url(https://www.addevent.com/gfx/icon-apple-t5.svg) 18px
              40% no-repeat;
            background-color: rgba(0, 0, 0, 0);
            background-size: auto;
            background-size: 22px 100%;
          }
          .addeventatc_dropdown .ategoogle {
            background: url(https://www.addevent.com/gfx/icon-google-t5.svg)
              18px 50% no-repeat;
            background-size: auto;
            background-size: 22px 100%;
          }
          .addeventatc_dropdown .ateoffice365 {
            background: url(https://www.addevent.com/gfx/icon-office365-t5.svg)
              19px 50% no-repeat;
            background-size: auto;
            background-size: 18px 100%;
          }
          .addeventatc_dropdown .ateoutlook {
            background: url(https://www.addevent.com/gfx/icon-outlook-t5.svg)
              18px 50% no-repeat;
            background-size: auto;
            background-size: 22px 100%;
          }
          .addeventatc_dropdown .ateoutlookcom {
            background: url(https://www.addevent.com/gfx/icon-outlookcom-t5.svg)
              18px 50% no-repeat;
            background-size: auto;
            background-size: 22px 100%;
          }
          .addeventatc_dropdown .ateyahoo {
            background: url(https://www.addevent.com/gfx/icon-yahoo-t5.svg) 18px
              50% no-repeat;
            background-size: auto;
            background-size: 22px 100%;
          }
          .addeventatc_dropdown .copyx {
            height: 21px;
            display: block;
            position: relative;
            cursor: default;
          }
          .addeventatc_dropdown .brx {
            height: 1px;
            overflow: hidden;
            background: #e8e8e8;
            position: absolute;
            z-index: 100;
            left: 10px;
            right: 10px;
            top: 9px;
          }
          .addeventatc_dropdown .frs a {
            background: #fff;
            color: #cacaca !important;
            cursor: pointer;
            font-size: 9px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 110% !important;
            padding-left: 10px !important;
            position: absolute;
            right: 10px;
            text-align: right;
            text-decoration: none;
            top: 5px;
            z-index: 101;
          }
          .addeventatc_dropdown em {
            color: #999 !important;
            font-size: 12px !important;
            font-weight: 400;
          }
          .addeventatc_dropdown.topdown.addeventatc-selected {
            transform: scale(1, 1) translateY(0px) !important;
          }
          .addeventatc_dropdown.addeventatc-selected {
            opacity: 1;
            transform: scale(1, 1) translateY(0px);
            z-index: 99999999;
          }
          .addeventatc_dropdown.topdown {
            transform: scale(0.98, 0.98) translateY(-5px) !important;
          }
          .addeventatc_dropdown span:hover {
            background-color: #f4f4f4;
            color: #000;
            text-decoration: none;
            font-size: 15px;
          }
          img.emoji {
            height: 1em;
            width: 1em;
            margin: 0 0.05em 0 0.1em;
            vertical-align: -0.1em;
          }
        `}
      />
    );
  }
}

export default withRouter(GlobalStyle);

export const Container = styled.div`
  position: relative;
  width: 100%;
`;
