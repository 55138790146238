import React from 'react';
import styled from 'styled-components';

const Mouse = styled.div`
  position: relative;
  width: 30px;
  height: 55px;
  top: 50%;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;

  &:before {
    position: absolute;
    content: '';
    width: 6px;
    left: 50%;
    height: 10px;
    background: #fff;
    margin-left: -3px;
    top: 6px;
    border-radius: 3px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }

  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(35px);
    }
  }
`;

const AnimationWrapper = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.7s linear;
  z-index: 100;
  ${({ shadow }) =>
    shadow &&
    `
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      box-shadow: 0 0 50px 58px rgba(0, 0, 0, 0.4);
      background-color: rgba(0, 0, 0, 0.4);
    }
  `}
  ${({ customStyles }) => customStyles && customStyles}
`;

const scrollIndicator = ({ shadow, visible, color, customStyles }) => {
  return (
    <AnimationWrapper
      shadow={shadow}
      visible={visible}
      customStyles={customStyles}
    >
      <Mouse color={color} />
    </AnimationWrapper>
  );
};

scrollIndicator.defaultProps = {
  visible: 1,
};

export default scrollIndicator;
