import { Flex } from 'rebass';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '@rebass/forms';
import { useIntl } from 'react-intl';
import theme from '../../../_styles/theme';
import SearchIcon from '../../../_assets/icons/searchIcon';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';

const SearchBarWrapper = styled(Flex)`
  background: ${props =>
    props.simpleSearch ? 'transparent' : theme.colors.card};
  color: ${theme.colors.textSemiVisible};
  align-items: center;
  padding-left: 10px;
  border-radius: ${props => (props.simpleSearch ? 'none' : '5px')};
  border: ${props =>
    props.simpleSearch ? 'none' : `1px solid ${theme.colors.borderLight}`};
  border-bottom: ${props =>
    props.simpleSearch && `0.5px solid rgba(0, 0, 0, 0.42)`};

  &:hover {
    border-bottom: ${props =>
      props.simpleSearch && `2px solid rgba(0, 0, 0, 0.42)`};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const SearchInput = styled(Input)`
  outline: none;
  border: none;
  font-family: inherit;

  &::-webkit-input-placeholder {
    font-family: inherit;
  }

  &:-ms-input-placeholder {
    font-family: inherit;
  }

  &:-moz-placeholder {
    font-family: inherit;
  }

  &::-moz-placeholder {
    font-family: inherit;
  }
`;

const SearchBar = ({ value, onChange, simpleSearch, iconFill }) => {
  const intl = useIntl();
  return (
    <SearchBarWrapper simpleSearch={simpleSearch}>
      <SearchIcon fill={iconFill} width="16px" height="18px" />
      <SearchInput
        placeholder={intl.formatMessage({ id: 'networking.search' })}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </SearchBarWrapper>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchBar;
