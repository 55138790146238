import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Flex, Image, Text } from 'rebass';
import { css } from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';
import theme from '../../../_styles/theme';
import { DefaultAvatar } from '../../../_assets/icons/defaultAvatar';
import { Avatar } from '../../HeaderBar/HeaderBar';

const defaultAvatarCss = {
  borderRadius: `50%`,
  width: '30px',
  height: '30px',
  minWidth: 'auto',
  border: `1px solid`,
  marginRight: '18px',
};

const useStyle = makeStyles({
  tooltip: {
    maxHeight: '220px',
    overflowY: 'auto',
    backgroundColor: props =>
      props.markAsMine
        ? props.bgColor || theme.colors.bgNavyBlue
        : props.bgColorActive || theme.colors.chatMsgActive,
    color: props => (props.primary ? props.primary : '#fff'),
    maxWidth: 400,
    fontSize: theme.fontSizes[2],
    border: props =>
      props.primary ? `1px solid ${props.primary}` : '1px solid #fff',
    '&::-webkit-scrollbar': {
      display: ({ showRemaining }) => (showRemaining ? `block` : 'none'),
      '-webkit-appearance': 'none',
      width: '4px', // for vertical mode
      height: '4px', // for horizontal mode
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      display: ({ showRemaining }) => (showRemaining ? `block` : 'none'),
      borderRadius: `2px`,
      backgroundColor: '#fff',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
});

export const Reactions = ({
  allowEmotes,
  chatStyle,
  markAsMine,
  updateReaction,
  msg: {
    id: messageId,
    reactions_stats: reactionsStats,
    reactions_details: reactionsDetails,
    user_reaction: userReaction,
  },
}) => {
  const [showRemaining, setShowRemaining] = useState(false);

  const REACTION_TYPES = ['like', 'love', 'lol', 'angry', 'sad', 'confused'];
  const remainingReactions = REACTION_TYPES.filter(
    item => item !== userReaction,
  );

  let finalReaction = null;
  if (REACTION_TYPES.includes(userReaction)) {
    finalReaction = userReaction;
  }

  const { avatarBorder } = chatStyle || {};

  const mineMsgReactions = Object.keys(reactionsStats);

  const handleReactionsBackground = bgcolorHex => {
    if (bgcolorHex) {
      const rgbColor = bgcolorHex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          // eslint-disable-next-line
          (m, r, g, b) => '#' + r + r + g + g + b + b,
        )
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));
      return `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.2)`;
    }
    return 'rgba(0, 0, 0, 0.2)';
  };

  const reactionsBgColor = handleReactionsBackground(
    chatStyle.reactionsBgColor,
  );

  const handleReaction = async (msgId, reaction) => {
    await updateReaction(msgId, reaction);
  };

  const renderReactionsCount = (reaction, classes) => {
    return (
      <Box
        css={css`
          height: 100%;
        `}
      >
        <Text
          css={css`
            ${classes}
            font-weight: 600;
            font-size: x-small;
          `}
        >
          {reactionsStats[reaction]}
        </Text>
      </Box>
    );
  };

  const renderImage = (mine, reaction, messageIdNo) => {
    if (mine || allowEmotes === false) {
      return (
        <Image
          css={css`
            height: 16px;
            @media (max-width: ${MOBILE_BREAKPOINT}px) {
              height: 25px;
            }
          `}
          src={`/images/icons/chat/${reaction}.svg`}
        />
      );
    }
    if (allowEmotes === true) {
      return (
        <Image
          css={css`
            height: 16px;
            @media (max-width: ${MOBILE_BREAKPOINT}px) {
              height: 25px;
            }
          `}
          src={`/images/icons/chat/${reaction}.svg`}
          onClick={() => handleReaction(messageIdNo, reaction)}
        />
      );
    }
  };

  const handleShowMore = () => {
    setShowRemaining(!showRemaining);
  };

  const handleTooltipClose = () => {
    setShowRemaining(false);
  };

  const renderTooltipContent = (s, data) => {
    if (data && data[s] && data[s].length) {
      return (
        // eslint-disable-next-line
        <React.Fragment>
          {data[s].slice(0, 5).map((item, index) => (
            <Flex
              key={`like_${index}_${item.name}`}
              css={css`
                margin-bottom: ${data[s].length > 1 &&
                (data[s].length !== index + 1 || showRemaining)
                  ? '5'
                  : '0'}px;
                align-items: center;
              `}
            >
              {item.avatar.thumb.url && (
                <Avatar
                  borderColor={avatarBorder}
                  src={item.avatar.thumb.url}
                  role="img"
                  customStyles={{ marginRight: '18px' }}
                />
              )}
              {!item.avatar.thumb.url && (
                <DefaultAvatar
                  avatarBorder={avatarBorder}
                  fill={avatarBckg}
                  style={{ ...defaultAvatarCss, borderColor: avatarBorder }}
                />
              )}
              {item.name}
            </Flex>
          ))}
          {!showRemaining ? (
            <Box
              css={css`
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              `}
              onClick={() => handleShowMore()}
            >
              {data[s].length > 5 && <p>... and {data[s].length - 5} more</p>}
            </Box>
          ) : (
            <>
              {data[s].slice(5, data[s].length).map((item, index) => (
                <Flex
                  key={`like_${index}_${item.name}`}
                  css={css`
                    margin-bottom: ${data[s].length > 1 &&
                    (data[s].length !== index + 1 || showRemaining)
                      ? '5'
                      : '0'}px;
                    align-items: center;
                  `}
                >
                  {item.avatar.thumb.url && (
                    <Avatar
                      borderColor={avatarBorder}
                      customStyles={{ marginRight: '18px' }}
                      src={item.avatar.thumb.url}
                    />
                  )}
                  {!item.avatar.thumb.url && (
                    <DefaultAvatar
                      fill={avatarBckg}
                      avatarBorder={avatarBorder}
                      style={{ ...defaultAvatarCss, borderColor: avatarBorder }}
                    />
                  )}
                  {item.name}
                </Flex>
              ))}
            </>
          )}
        </React.Fragment>
      );
    }
    return '';
  };

  const { bgColor, bgColorActive, primary, avatarBckg } = chatStyle;
  const classes = useStyle({
    primary,
    reactionsBgColor,
    markAsMine,
    bgColor,
    bgColorActive,
    showRemaining,
  });

  if (markAsMine) {
    if (mineMsgReactions.length > 0) {
      return (
        <Flex
          css={css`
            margin-top: 5px;
            background-color: black;
            padding: 2px;
            border-radius: 6rem;
            background-color: ${reactionsBgColor};
          `}
          flexDirection="row"
        >
          {mineMsgReactions.map(s => (
            <Tooltip
              interactive
              TransitionComponent={Zoom}
              classes={{
                tooltip: classes.tooltip,
              }}
              key={`${messageId}_${s}`}
              title={renderTooltipContent(s, reactionsDetails)}
              onClose={handleTooltipClose}
            >
              <Flex
                css={css`
                  padding-left: 2px;
                  padding-right: 2px;
                  align-items: center;
                `}
              >
                <Flex
                  css={css`
                    align-items: center;
                  `}
                >
                  {renderImage(true, s, messageId)}
                </Flex>
                {renderReactionsCount(s)}
              </Flex>
            </Tooltip>
          ))}
        </Flex>
      );
    }
    return null;
  }
  // if (!markAsMine) { // Expected to return a value at the end of arrow function [There is if (markAsMine) above]
  return (
    <Flex>
      {finalReaction && (
        <Flex
          css={css`
            margin-top: 5px;
            background-color: black;
            padding: 2px;
            border-radius: 6rem;
            background-color: ${reactionsBgColor};
            .remaining-reactions {
              display: none;
            }
            &:hover .remaining-reactions {
              cursor: pointer;
              width: auto;
              display: flex;
            }
          `}
          flexDirection="row"
        >
          <Tooltip
            interactive
            TransitionComponent={Zoom}
            classes={{
              tooltip: classes.tooltip,
            }}
            title={renderTooltipContent(finalReaction, reactionsDetails)}
            onClose={handleTooltipClose}
          >
            <Flex
              css={css`
                padding-left: 2px;
                padding-right: 2px;
                align-items: center;
                transition: all 0.2s ease-in-out;
                ${allowEmotes &&
                  `&:hover {
                cursor: pointer;
                transform: scale(1.2);
              }`}
              `}
            >
              {renderImage(false, finalReaction, messageId)}
            </Flex>
          </Tooltip>
          {renderReactionsCount(
            finalReaction,
            'padding-left: 2px; padding-right: 2px;',
          )}
          <div className="remaining-reactions">
            {remainingReactions.map(s => (
              <Tooltip
                interactive
                TransitionComponent={Zoom}
                classes={{
                  tooltip: classes.tooltip,
                }}
                key={`${messageId}_${s}`}
                title={renderTooltipContent(s, reactionsDetails)}
                onClose={handleTooltipClose}
              >
                <Flex
                  css={css`
                    padding-left: 2px;
                    padding-right: 2px;
                    align-items: center;
                    ${allowEmotes &&
                      `transition: all 0.2s ease-in-out;
                    &:hover {
                      cursor: pointer;
                      transform: scale(1.2);
                    }`}
                  `}
                >
                  <Flex
                    css={css`
                      align-items: center;
                    `}
                  >
                    {renderImage(false, s, messageId)}
                  </Flex>
                  {renderReactionsCount(s)}
                </Flex>
              </Tooltip>
            ))}
          </div>
        </Flex>
      )}

      {!finalReaction && (
        <Flex
          css={css`
            margin-top: 5px;
            background-color: black;
            padding: 2px;
            border-radius: 6rem;
            background-color: ${reactionsBgColor};
          `}
          flexDirection="row"
        >
          {REACTION_TYPES.map(s => (
            <Tooltip
              interactive
              TransitionComponent={Zoom}
              classes={{
                tooltip: classes.tooltip,
              }}
              key={`${messageId}_${s}`}
              title={renderTooltipContent(s, reactionsDetails)}
              onClose={handleTooltipClose}
            >
              <Flex
                css={css`
                  padding-left: 2px;
                  padding-right: 2px;
                  align-items: center;
                  ${allowEmotes &&
                    `transition: all 0.2s ease-in-out;
                      &:hover {
                        cursor: pointer;
                        transform: scale(1.2);
                      }`}
                `}
              >
                <Flex
                  css={css`
                    align-items: center;
                  `}
                >
                  {renderImage(false, s, messageId)}
                </Flex>
                {renderReactionsCount(s)}
              </Flex>
            </Tooltip>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
