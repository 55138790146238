import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { toJS } from 'mobx';
import { css } from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import StylingUtils from '../../_app/utils/stylingUtils';
import theme from '../../_styles/theme';
import { toHex } from '../../_app/utils/otherUtils';
import { ChatRoom } from './ChatRoom';
import { NewChatRoom } from './NewChatRoom';

const ChatRoomsListContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 18px;
    padding-right: 18px;
    height: auto;
    min-height: calc(
      100% - 100px
    ); // hack - for disclaimer to sit on bottom of page always
  }
`;

const ChatBottom = ({ children }) => (
  <Flex
    css={css`
      position: relative;
      flex-direction: column;
      height: 100%;
      ${StylingUtils.animationFadeIn()};
    `}
  >
    {children}
  </Flex>
);

const NoChatRooms = ({ children, color }) => (
  <Flex
    css={css`
      justify-content: center;
      flex-grow: 1;
      font-size: ${theme.fontSizes[2]}px;
      color: ${color || StylingUtils.hexToRgbA(theme.colors.text, 0.2)};
      line-height: 1.5em;
    `}
  >
    {children}
  </Flex>
);

const ScrollContent = ({ children, scrollColor, isMobile, ...props }) => (
  <Box
    css={css`
      overflow: auto;
      min-height: 100%;
      padding-bottom: 30px;
      ${StylingUtils.customScroll(scrollColor)}
    `}
    {...props}
  >
    {children}
  </Box>
);

@inject(
  'chatStore',
  'chatRoomsStore',
  'pollStore',
  'homeStore',
  'modalStore',
  'userStore',
  'authStore',
)
@observer
class ChatRoomsList extends React.Component {
  handleLeaveRoom = async chatId => {
    const {
      eventId,
      chatStore: {
        activeChatRoomId,
        setActiveChatRoomId,
        setActiveChatRoomName,
      },
      chatRoomsStore: { getChatRooms, leaveChatRoom },
      homeStore: { activeEvent },
    } = this.props;
    await leaveChatRoom(chatId);
    if (activeChatRoomId === chatId) {
      setActiveChatRoomId(activeEvent.chat_room_id);
      setActiveChatRoomName('GROUP CHAT');
    }
    await getChatRooms(eventId);
  };

  changeActiveChatRoomId = (chatId, chatName, usersCount) => {
    const {
      chatStore: {
        setActiveChatRoomId,
        setActiveChatNumberOfUsers,
        setActiveChatRoomName,
      },
    } = this.props;
    setActiveChatRoomId(chatId);

    if (chatName) {
      setActiveChatRoomName(chatName);
    }

    if (usersCount) {
      setActiveChatNumberOfUsers(usersCount);
    }
  };

  render() {
    const {
      chatStore: { activeChatRoomId },
      chatRoomsStore: {
        isLoading,
        getRoomUsers,
        chatRooms,
        newChatRoomIds,
        markRoomNotNew,
      },
      homeStore: { activeOrganization, activeEvent },
      modalStore: { setVisibility },
      authStore: { isAuthenticated },
      chatStyle,
      networkingAvailable,
      changeMobileTab,
      toggleChatRooms,
      toggleNewChatRoom,
      numberOfUsersColor,
    } = this.props;

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? `#${activeOrganization.dominant_color}`
        : theme.colors.textSecondary;

    const { no_content_text: noContentText, chat_primary: chatPrimary } =
      (activeEvent && activeEvent.event_custom_theme) || {};
    const chatPrimaryHex = toHex(chatPrimary);
    const noContentTextHex = toHex(noContentText);

    const chatRoomName = chatRoom => {
      let roomName = '';
      if (chatRoom.chat_type === 'default') {
        roomName = 'GROUP CHAT';
      } else if (chatRoom.name) {
        roomName = chatRoom.name;
      } else {
        roomName = chatRoom.dynamic_chat_name;
      }
      return roomName;
    };

    const showRoomExit = chatRoom => {
      let showExit = true;
      if (chatRoom.chat_type === 'default' || chatRoom.available_for_all) {
        showExit = false;
      }
      return showExit;
    };

    return (
      <ChatRoomsListContainer pt="14px">
        <>
          <ChatBottom>
            {isEmpty(chatRooms) && !isLoading && (
              <NoChatRooms color={noContentTextHex}>
                <FormattedMessage id="chat.nochatrooms" />
              </NoChatRooms>
            )}
            <ScrollContent
              id="chat-scroll"
              scrollColor={chatPrimaryHex || primaryColor}
            >
              {!isEmpty(chatRooms) &&
                toJS(chatRooms).map(chatRoom => (
                  <ChatRoom
                    chatName={chatRoomName(chatRoom)}
                    recentMsg={
                      chatRoom.last_message && chatRoom.last_message.message
                    }
                    showRoomExit={showRoomExit(chatRoom)}
                    usersCount={
                      chatRoom.users_count > 0 ? chatRoom.users_count : ''
                    }
                    key={chatRoom.id}
                    chatRoomAvatar={chatRoom.chat_icon?.thumb.url}
                    changeActiveChatRoomId={this.changeActiveChatRoomId}
                    activeChatRoomId={activeChatRoomId}
                    chatStyle={chatStyle}
                    chatRoomId={chatRoom.id}
                    setVisibility={setVisibility}
                    exitColor={chatPrimaryHex || primaryColor}
                    leaveChatRoom={this.handleLeaveRoom}
                    toggleChatRooms={toggleChatRooms}
                    changeMobileTab={changeMobileTab}
                    numberOfUsersColor={numberOfUsersColor}
                    chatUsers={chatRoom.room_users}
                    getRoomUsers={getRoomUsers}
                    isNew={newChatRoomIds.includes(chatRoom.id)}
                    markRoomNotNew={markRoomNotNew}
                  />
                ))}
              {isAuthenticated && networkingAvailable && (
                <NewChatRoom
                  toggleNewChatRoom={() => toggleNewChatRoom(true)}
                  chatStyle={chatStyle}
                />
              )}
            </ScrollContent>
          </ChatBottom>
        </>
      </ChatRoomsListContainer>
    );
  }
}

export default ChatRoomsList;
