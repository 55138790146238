import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import { css } from 'styled-components';
import Twemoji from 'react-twemoji';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import { LogoutIcon } from '../../_assets/icons/logoutIcon';
import theme from '../../_styles/theme';
import { DefaultChat } from '../../_assets/icons/defaultChat';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { Avatar } from '../HeaderBar/HeaderBar';
import { TooltipWithUsers } from '../TooltipWithUsers/TooltipWithUsers';

const useStyle = makeStyles({
  tooltip: {
    backgroundColor: props => props.bgColorActive || theme.colors.chatMsgActive,
    color: props => (props.primary ? props.primary : '#fff'),
    fontSize: theme.fontSizes[2],
    border: props =>
      props.primary ? `1px solid ${props.primary}` : '1px solid #fff',
  },
});

const Dot = styled(Box)`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-left: -10px;
  margin-right: 6px;
`;

const ChatRoomBox = ({ onClick, isRoomActive, chatStyle, children }) => {
  const { bgColor, text, bgColorActive } = chatStyle || {};
  return (
    <Flex
      onClick={onClick}
      css={css`
        align-items: center;
        justify-content: center;
        width: calc(100% - 8px);
        margin-bottom: 7px;
        padding: 14px 20px 14px 13px;
        background-color: ${isRoomActive
          ? bgColorActive || theme.colors.chatMsgActive
          : bgColor || theme.colors.bgNavyBlue};
        color: ${text || '#fff'};
        cursor: pointer;

        border-radius: 10px;

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
        }
      `}
    >
      {children}
    </Flex>
  );
};

const chatRoomAvatarCss = {
  borderRadius: `50%`,
  width: '50px',
  height: '50px',
  minWidth: 'auto',
};

const recentMsgCss = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const AvatarWrapper = ({ onClick, children, ...props }) => (
  <Flex
    css={css`
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      min-width: 42px;
    `}
    onClick={onClick}
    {...props}
  >
    {children}
  </Flex>
);

const LeaveChatWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  padding: 36px 28px 35px 26px;
  margin: -14px -20px -14px -13px;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
`;

const ChatRoomBoxMain = ({ children, marginLeft, marginRight, ...props }) => (
  <Flex
    css={css`
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: ${marginLeft};
      margin-right: ${marginRight};
      min-height: 63px;
      overflow-y: hidden;
      cursor: pointer;
    `}
    {...props}
  >
    {children}
  </Flex>
);

const ContentText = ({ children }) => (
  <Box
    css={css`
      padding-left: 10px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      font-size: ${theme.fontSizes[0]}px;
      width: 100%;
    `}
  >
    {children}
  </Box>
);

const NumberOfUsers = styled.span`
  color: ${props =>
    props.numberOfUsersColor ? props.numberOfUsersColor : '#fff'};
`;

export const ChatRoom = ({
  chatName,
  recentMsg,
  chatStyle,
  chatRoomAvatar,
  changeActiveChatRoomId,
  activeChatRoomId,
  chatRoomId,
  usersCount,
  exitColor: chatRoomColor,
  showRoomExit,
  leaveChatRoom,
  toggleChatRooms,
  changeMobileTab,
  numberOfUsersColor,
  chatUsers,
  getRoomUsers,
  markRoomNotNew,
  isNew,
}) => {
  const { avatarBckg, avatarBorder, chatButtonsBg, bgColorActive, primary } =
    chatStyle || {};
  const maxChatNameChars = 25;

  const isRoomActive = chatRoomId === activeChatRoomId;
  let origChatName;
  if (chatName.length > maxChatNameChars) {
    origChatName = chatName;
    chatName = `${chatName.substring(0, maxChatNameChars)}...`;
  }
  const classes = useStyle({
    primary,
    bgColorActive,
  });

  return (
    <Box>
      <ChatRoomBox
        onClick={() => {
          markRoomNotNew(chatRoomId);
          changeActiveChatRoomId(chatRoomId, chatName, usersCount);
          toggleChatRooms();
          if (changeMobileTab) {
            changeMobileTab();
          }
        }}
        chatStyle={chatStyle}
        isRoomActive={isRoomActive}
      >
        <AvatarWrapper>
          {chatRoomAvatar && (
            <Avatar
              borderColor={avatarBorder}
              src={chatRoomAvatar}
              customStyles={{
                height: '42px',
                width: '42px',
              }}
            />
          )}
          {!chatRoomAvatar && (
            <DefaultChat
              width="25px"
              height="25px"
              fill={avatarBckg}
              style={chatRoomAvatarCss}
            />
          )}
        </AvatarWrapper>
        <ChatRoomBoxMain marginLeft="12px" marginRight={0}>
          <ContentText>
            {origChatName ? (
              <Text
                fontSize={1}
                fontWeight={theme.fontWeights.bold}
                lineHeight={1.42}
                letterSpacing="-0.29px"
                maxWidth={120}
              >
                <Tooltip
                  title={origChatName}
                  TransitionComponent={Zoom}
                  placement="top"
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <span>
                    {isNew && <Dot />}
                    {chatName}{' '}
                  </span>
                </Tooltip>
                {usersCount && (
                  <TooltipWithUsers
                    getRoomUsers={getRoomUsers}
                    chatUsers={chatUsers}
                    chatStyle={chatStyle}
                    chatRoomId={chatRoomId}
                    numberOfUsers={usersCount}
                  >
                    <NumberOfUsers numberOfUsersColor={numberOfUsersColor}>
                      ({usersCount})
                    </NumberOfUsers>
                  </TooltipWithUsers>
                )}
              </Text>
            ) : (
              <Text
                fontSize={1}
                fontWeight={theme.fontWeights.bold}
                lineHeight={1.42}
                letterSpacing="-0.29px"
                maxWidth={120}
              >
                {isNew && <Dot />}
                {chatName}{' '}
                {usersCount && (
                  <TooltipWithUsers
                    getRoomUsers={getRoomUsers}
                    chatRoomId={chatRoomId}
                    chatUsers={chatUsers}
                    chatStyle={chatStyle}
                    numberOfUsers={usersCount}
                  >
                    <NumberOfUsers numberOfUsersColor={numberOfUsersColor}>
                      ({usersCount})
                    </NumberOfUsers>
                  </TooltipWithUsers>
                )}
              </Text>
            )}

            {recentMsg && (
              <Text
                fontSize={1}
                fontWeight={theme.fontWeights.emph}
                lineHeight={1.42}
                letterSpacing="-0.29px"
                width="70px"
                color={chatRoomColor || theme.colors.borderLight}
              >
                <Twemoji noWrapper>
                  <div
                    style={recentMsgCss}
                    dangerouslySetInnerHTML={{
                      __html: recentMsg,
                    }}
                  />
                </Twemoji>
              </Text>
            )}
          </ContentText>
        </ChatRoomBoxMain>
        {showRoomExit && (
          <LeaveChatWrapper
            onClick={e => {
              e.stopPropagation();
              leaveChatRoom(chatRoomId);
            }}
            bg={chatButtonsBg}
          >
            <LogoutIcon fill={chatStyle.buttonsTextColor} />
          </LeaveChatWrapper>
        )}
      </ChatRoomBox>
    </Box>
  );
};
