import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'url-search-params-polyfill';
import { Provider } from 'mobx-react';
import App from './App';
import ZoomApp from './ZoomApp';
import * as serviceWorker from './serviceWorker';
import stores from './stores';

const urlParams = new URLSearchParams(window.location.search);
const zoomMeetingOnly = urlParams.get('zoomMeetingOnly');

if (zoomMeetingOnly === 'true') {
  ReactDOM.render(
    <Provider {...stores}>
      <ZoomApp />
    </Provider>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <Provider {...stores}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
