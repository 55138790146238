import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Box, Flex, Text } from 'rebass';
import moment from 'moment';
import { css } from 'styled-components';
import ArrowForward from '@material-ui/icons/ArrowForward';
import theme from '../../../_styles/theme';
import { getLocalTimezone } from '../../../_app/utils/dateTimeUtils';

const Separator = ({ children, color }) => (
  <Flex
    css={css`
      width: 2px;
      background-color: ${color || '#566064'};
      margin: 0 5px;
    `}
  >
    {children}
  </Flex>
);

const AttendanceStatus = ({ customStyle, status, ...props }) => (
  <Flex>
    <Box
      css={css`
        margin-top: 6px;
        position: relative;
        border-radius: 5px;
        background-color: ${(customStyle && customStyle.bckg) || '#38454e'};
        padding: 8px 14px;
        color: ${(customStyle && customStyle.color) || '#fff'};
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
      `}
      {...props}
    >
      <FormattedMessage id={`eventListing.status.${status}`} />
    </Box>
  </Flex>
);

const containerPadding = (active, status) => {
  let padding = '';
  if (active) {
    padding = '6px 8px 6px 8px';
  } else if (status) {
    padding = '20px 35px 20px 35px';
  } else {
    padding = '10px 12px 10px 12px';
  }
  return padding;
};

const Event = ({ children, ...props }) => (
  <Flex
    css={css`
      flex-direction: column;
      justify-content: space-between;
    `}
    {...props}
  >
    {children}
  </Flex>
);

const EventContainer = ({
  customStyle,
  children,
  clickable,
  active,
  status,
  eventListingButtonsBg,
  ...props
}) => (
  <Flex
    css={css`
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      border-radius: ${status ? '15px' : '5px'};
      margin-bottom: 22px;
      width: 100%;
      background-color: ${eventListingButtonsBg ||
        (customStyle && customStyle.bckg) ||
        '#042840'};
      color: ${(customStyle && customStyle.textColor) || '#fff'};
      padding: ${containerPadding(active, status)};
      text-transform: uppercase;
      border: ${active
        ? `4px ${(customStyle && customStyle.activeEventColor) || '#fff'} solid`
        : 'none'};

      &:hover {
        cursor: ${clickable ? 'pointer' : 'default'};
      }
    `}
    {...props}
  >
    {children}
  </Flex>
);

const getStatusName = (status, isPublic) => {
  if (status === 'accepted') {
    if (isPublic) {
      return 'public';
    }
    return 'accepted';
  }
  if (status === 'requested') {
    return 'requested';
  }
  if (status === 'declined') {
    return 'declined';
  }
  return 'unregistered';
};

export const EventListItem = ({
  name,
  status,
  public: isPublic,
  active,
  onClick,
  agenda_start_datetime: agendaStartDatetime,
  agenda_end_datetime: agendaEndDatetime,
  customStyle,
  separatorColor,
  bgColor,
  buttonsTextColor,
  isTablet,
  eventListingButtonsBg,
}) => {
  const tz = getLocalTimezone();
  return (
    <EventContainer
      active={active}
      onClick={onClick}
      clickable={!!onClick}
      customStyle={customStyle}
      status={status}
      eventListingButtonsBg={eventListingButtonsBg}
    >
      <Event>
        <Box>
          <Text
            fontSize="4"
            letterSpacing={2}
            textTransform="uppercase"
            style={{ wordBreak: 'break-word' }}
            fontWeight={theme.fontWeights.bold}
          >
            {name}
          </Text>
        </Box>
        <Box>
          <Flex letterSpacing={1} marginTop={1} textTransform="uppercase">
            {agendaStartDatetime && agendaEndDatetime ? (
              <>
                <Text
                  fontSize={status && !isTablet ? 12 : 7}
                  fontWeight={status ? 'normal' : 'bold'}
                  style={{ textTransform: 'uppercase' }}
                >
                  <FormattedDate
                    value={moment(agendaStartDatetime).toDate()}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                </Text>
                <Separator color={separatorColor} />
                <Text
                  fontSize={status && !isTablet ? 12 : 7}
                  fontWeight={status ? 'normal' : 'bold'}
                >
                  {moment(agendaStartDatetime).format('HH:mm')}
                  &nbsp;-&nbsp;
                  {moment(agendaEndDatetime).format('HH:mm')}
                  &nbsp;
                  {tz}
                </Text>
              </>
            ) : (
              'N/A'
            )}
          </Flex>
        </Box>
        {status && (
          <AttendanceStatus
            customStyle={{ bckg: bgColor, color: buttonsTextColor }}
            status={getStatusName(status, isPublic)}
          />
        )}
      </Event>
      {status && !isTablet && (
        <Flex alignItems="center" justifyContent="flex-end">
          <ArrowForward fontSize="large" />
        </Flex>
      )}
    </EventContainer>
  );
};
