import get from 'lodash/get';
import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import theme from '../_styles/theme';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';
import { markLinkAsTag } from '../_app/utils/otherUtils';

const Container = styled(Box)`
  margin-top: 30px;
  &:first-of-type {
    margin-top: 0;
  }
  ${props => !props.darkBg && `color: ${theme.colors.textOnWhiteBg};`}
  font-size: ${theme.fontSizes[1]}px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 0;
    margin-bottom: 30px;
    color: #637781;
    font-size: ${theme.fontSizes[3]}px;
  }
`;

const SponsorThumbnail = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  flex-shrink: 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 94px;
    width: 94px;
    height: 94px;
    box-shadow: 0 13px 15px -6px rgba(0, 0, 0, 0.6);
  }
`;

const SponsorImage = styled('img')`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

const Name = styled(Text)`
  font-size: 26px;
  font-weight: ${theme.fontWeights.almostBold};
  line-height: 1.3em;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 26px;
    color: #1d2534;
    font-weight: ${theme.fontWeights.almostBold};
  }
`;

const Bio = styled(Box)`
  margin-right: 25px;
  line-height: 1.25em;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 30px;
  }
  p {
    margin-bottom: 1em;
  }
  strong {
    font-weight: ${theme.fontWeights.bold};
  }
  em {
    font-style: italic;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  ul {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: disc;
  }
  ol {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: decimal;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const SponsorMain = ({ darkBg, logo, name, bio }) => (
  <Container darkBg={darkBg}>
    <Flex mb={15} flex="1 0 0">
      <SponsorThumbnail>
        {get(logo, 'thumb.url') && (
          <SponsorImage src={get(logo, 'thumb.url')} alt={name} />
        )}
      </SponsorThumbnail>
      {name && (
        <Flex flexDirection="column" justifyContent="center">
          <Name>{name}</Name>
        </Flex>
      )}
    </Flex>
    {bio && <Bio dangerouslySetInnerHTML={{ __html: markLinkAsTag(bio) }} />}
  </Container>
);

export default SponsorMain;
