import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Box from '@material-ui/core/Box';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Flex, Image, Text } from 'rebass';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import IconDetail from '../../components/IconDetail';
import theme from '../../_styles/theme';
import { AgendaContainer } from '../../home/AgendaContainer/AgendaContainer';
import { Loader } from '../../components/Loader';
import { AgendaIcon } from '../../_assets/icons/agendaIcon';
import { CalendarIcon } from '../../_assets/icons/calendarIcon';
import { SpeakerIcon } from '../../_assets/icons/speakerIcon';
import { SponsorIcon } from '../../_assets/icons/sponsorIcon';
import { markLinkAsTag, SUBDOMAIN, toHex } from '../../_app/utils/otherUtils';
import { getLocalTimezone } from '../../_app/utils/dateTimeUtils';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { NoUpcommingEvent } from './components/NoUpcommingEvent';
import { AddCalendar } from '../../components/AddCalendar';
import Speaker, { SpeakerPopup } from './components/Speaker';
import SponsorMain from '../../components/SponsorMain';
import StylingUtils from '../../_app/utils/stylingUtils';

const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 70px 44px 40px 77px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 30px 0;
  }
`;
const EventContainerTitle = ({ children }) => (
  <Text
    css={css`
      font-size: ${theme.fontSizes[5]}px;
      font-weight: ${theme.fontWeights.heading};
      line-height: 1.4;
      letter-spacing: 1.86px;
      padding-bottom: 13px;
      text-transform: uppercase;
      width: 100%;
    `}
  >
    {children}
  </Text>
);
const EventDesc = styled(Box)`
  font-weight: ${theme.fontWeights.light};
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  overflow-x: hidden;
  p {
    margin-bottom: 1em;
  }
  strong {
    font-weight: ${theme.fontWeights.bold};
  }
  em {
    font-style: italic;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  ul {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: disc;
  }
  ol {
    margin-bottom: 1em;
    padding-left: 18px;
    list-style: decimal;
  }
`;

const Separator = ({ children, color }) => (
  <Flex
    css={css`
      width: 2px;
      background-color: ${color || '#566064'};
      margin: 0 20px;
    `}
  >
    {children}
  </Flex>
);

const ThumbnailsContainer = styled(Flex)`
  flex-wrap: wrap;
  display: block;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TitleContainer = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    padding: 0 20px 20px;
  }
`;

const Thumbnail = styled(Image)`
  width: 230px;
  height: auto;
  background-color: ${theme.colors.backgroundDarker};

  margin: 0 20px 15px 0;
  box-shadow: 14px 16px -6px rgba(0, 0, 0, 0.6);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 12px;
    width: 100%;
  }
`;

const SpeakersContainer = styled(Flex)`
  flex-wrap: wrap;
  display: flex;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const EventInfoWrapper = styled(Flex)`
  line-height: 1.2em;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    line-height: 1.5em;
  }
`;

const TabListMobileWrapper = styled(Box)`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TabPanelInner = styled('div')`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 20px;
  }
`;

const tz = getLocalTimezone();
const EventInfo = ({
  startDate,
  firstAI,
  lastAI,
  separatorColor,
  textColor,
}) => {
  let moreThan1Day = false;
  if (firstAI && lastAI) {
    const firstDay = moment(firstAI.start_date).date();
    const lastDay = moment(lastAI.end_date).date();
    if (firstDay !== lastDay) {
      moreThan1Day = true;
    }
  }

  return (
    <EventInfoWrapper color={textColor || theme.colors.textSecondary}>
      {moreThan1Day ? (
        <>
          <Text
            fontSize={2}
            fontWeight="bold"
            style={{ display: 'flex', textTransform: 'uppercase' }}
          >
            <FormattedMessage id="date.starts" />
            &nbsp;
            <FormattedDate
              value={moment(firstAI.start_date).toDate()}
              year="numeric"
              month="short"
              day="2-digit"
            />
            &nbsp;
            {moment(firstAI.start_date).format('HH:mm')}
            <Separator color={separatorColor} />
            <FormattedMessage id="date.ends" />
            &nbsp;
            <FormattedDate
              value={moment(lastAI.end_date).toDate()}
              year="numeric"
              month="short"
              day="2-digit"
            />
            &nbsp;
            {moment(lastAI.end_date).format('HH:mm')}
            <FormattedMessage id="date.hour" defaultMessage="" />
            &nbsp;
            {tz}
          </Text>
        </>
      ) : (
        <>
          <Text
            fontSize={2}
            fontWeight="bold"
            style={{ textTransform: 'uppercase' }}
          >
            <FormattedDate
              value={moment(startDate).toDate()}
              year="numeric"
              month="short"
              day="2-digit"
            />
          </Text>
          {firstAI && lastAI && (
            <>
              <Separator color={separatorColor} />
              <Text fontSize={2} fontWeight="bold">
                {moment(firstAI.start_date).format('HH:mm')}
                &nbsp;-&nbsp;
                {moment(lastAI.end_date).format('HH:mm')}
                <FormattedMessage id="date.hour" defaultMessage="" />
                &nbsp;
                {tz}
              </Text>
            </>
          )}
        </>
      )}
    </EventInfoWrapper>
  );
};

const displayNoUpcomming = ({ noUpcomming }) => {
  return noUpcomming ? <NoUpcommingEvent /> : null;
};

const content = ({ event, noUpcomming, isMobile, modalStore }) => {
  const primaryColor = toHex(get(event, 'organization.dominant_color'));

  const {
    ai_bckg_active: aiBckgActive,
    ai_style: aiStyle,
    agenda_timeline: agendaTimeline,
    agenda_timeline_dots: agendaTimelineDots,
    icons,
    event_brief_text: eventBriefText,
    event_brief_separator: eventBriefSeparator,
    sidebar_scroll: sidebarScroll,
    text_primary: textPrimary,
    text_secondary: textSecondary,
    header_font_family: headerFontFamily,
    hide_tabbed_sections_bar_unauthorised_page: hideTabbedSectionsBarUnauthorisedPage,
    icons: iconsColor,
    breakout_item_bckg: breakoutItemBckg,
    breakout_item_text: breakoutItemText,
    event_brief_header_text: eventBriefHeaderText,
    hide_date_and_time: hideDateAndTime,
    buttons_text_color: buttonsTextColor,
    chat_msg_bg_active: chatMsgBgActive,
  } = (event && event.event_custom_theme) || {};

  const iconsHex = toHex(icons);
  const briefTextHex = toHex(eventBriefText);
  const briefSeparatorHex = toHex(eventBriefSeparator);
  const sidebarScrollHex = toHex(sidebarScroll);

  const firstAI =
    event && !isEmpty(event.agenda_items) && event.agenda_items[0];
  const lastAI =
    event &&
    !isEmpty(event.agenda_items) &&
    event.agenda_items[event.agenda_items.length - 1];

  const bubbleStyle = {
    border: toHex(textPrimary) || '#fff',
  };
  const showAgendaItemSpeakers = get(event, 'show_agenda_item_speakers', false);

  const handleOnBubbleClick = speaker => {
    const { setVisibility } = modalStore || {};

    const { speakers_popup_header_img: headerImg } =
      (event && event.event_custom_theme) || {};

    setVisibility(
      true,
      <SpeakerPopup
        primaryColor={primaryColor}
        image={get(speaker, 'image')}
        job={speaker.job}
        name={speaker.name}
        companyName={speaker.company_name}
        bio={speaker.bio}
        headerImg={get(headerImg, 'url')}
        onClose={() => setVisibility(false)}
        isMobile={isMobile}
      />,
    );
  };

  const agendaItemStyle = {
    type: aiStyle,
    bgColor: toHex(aiBckgActive),
    text: toHex(textSecondary) || briefTextHex,
    textHighlighted: toHex(textPrimary),
    dot: toHex(agendaTimelineDots),
  };

  const chatStyle = {
    bgColorActive: toHex(chatMsgBgActive),
  };

  const breakoutItemStyle = {
    type: aiStyle,
    bgColor: toHex(breakoutItemText),
    text: toHex(breakoutItemBckg),
    textHighlighted: toHex(textPrimary),
    dot: toHex(agendaTimelineDots),
    iconsColor: toHex(iconsColor) || primaryColor,
  };

  const agendaTimelineStyle = {
    background: toHex(agendaTimeline),
  };

  return event ? (
    <Box color={briefTextHex || theme.colors.textSecondary}>
      <TitleContainer>
        <Box flex="1 0 0" pr="20px">
          <EventContainerTitle>
            <Text fontFamily={headerFontFamily || theme.fonts.body}>
              {' '}
              {!eventBriefHeaderText ? event.name : eventBriefHeaderText}{' '}
            </Text>
          </EventContainerTitle>
        </Box>
        {event && event.addevent_calendar_id && (
          <Box>
            <AddCalendar buttonsTextColor={toHex(buttonsTextColor)} />
          </Box>
        )}
      </TitleContainer>

      <Tabs className="react-tabs react-tabs--noauth">
        <TabListMobileWrapper
          className={hideTabbedSectionsBarUnauthorisedPage && 'hidden'}
        >
          <TabList>
            <Tab className="react-tabs__tab tabs-border">
              <IconDetail
                icon={
                  <CalendarIcon
                    fill={iconsHex || primaryColor}
                    width="16px"
                    height="18px"
                  />
                }
                mr="0"
                iconMR="7px"
                lineHeight="1.25em"
                letterSpacing="0"
                text="DETAILS"
                fontSize={2}
                nowrap
              />
            </Tab>

            {event && !isEmpty(event.agenda_items) && (
              <Tab className="react-tabs__tab tabs-border">
                <IconDetail
                  icon={
                    <AgendaIcon
                      fill={iconsHex || primaryColor}
                      width="12px"
                      height="18px"
                    />
                  }
                  mr="0"
                  iconMR="7px"
                  lineHeight="1.25em"
                  letterSpacing="0"
                  text="AGENDA"
                  fontSize={2}
                  nowrap
                />
              </Tab>
            )}

            {event && !isEmpty(event.speakers) && (
              <Tab className="react-tabs__tab tabs-border">
                <IconDetail
                  icon={
                    <SpeakerIcon
                      fill={iconsHex || primaryColor}
                      width="11px"
                      height="18px"
                    />
                  }
                  mr="0"
                  iconMR="7px"
                  lineHeight="1.25em"
                  letterSpacing="0"
                  text="SPEAKERS"
                  fontSize={2}
                  nowrap
                />
              </Tab>
            )}

            {event && !isEmpty(event.sponsors) && (
              <Tab className="react-tabs__tab tabs-border">
                <IconDetail
                  icon={
                    <SponsorIcon
                      fill={iconsHex || primaryColor}
                      width="23px"
                      height="18px"
                    />
                  }
                  mr="0"
                  iconMR="7px"
                  lineHeight="1.25em"
                  letterSpacing="0"
                  text="SPONSORS"
                  fontSize={2}
                  nowrap
                />
              </Tab>
            )}
          </TabList>
        </TabListMobileWrapper>

        <TabPanel>
          <TabPanelInner>
            {event && !hideDateAndTime && (
              <EventInfo
                startDate={event.planned_start_date}
                firstAI={firstAI}
                lastAI={lastAI}
                domain={`https://${SUBDOMAIN ? `${SUBDOMAIN}.` : ''}cplus.live`}
                separatorColor={briefSeparatorHex}
                textColor={briefTextHex}
              />
            )}
            <Box pt={(!hideDateAndTime || isMobile) && '24px'}>
              <EventDesc>
                <div
                  dangerouslySetInnerHTML={{
                    __html: markLinkAsTag(event && event.description),
                  }}
                />
              </EventDesc>
            </Box>
            {!isEmpty(event.images) && (
              <Box>
                <ThumbnailsContainer>
                  {event.images.map(img => (
                    <Thumbnail src={img.image.url} key={img.id} />
                  ))}
                </ThumbnailsContainer>
              </Box>
            )}
          </TabPanelInner>
        </TabPanel>

        {event && !isEmpty(event.agenda_items) && (
          <TabPanel>
            <TabPanelInner>
              <AgendaContainer
                isLoading={false}
                customStyles={{
                  agendaTimelineStyle,
                  agendaItemStyle,
                  bubbleStyle,
                  breakoutItemStyle,
                  chatStyle,
                }}
                agendaItems={event.agenda_items}
                hasCustomStyles={false}
                onBubbleClick={handleOnBubbleClick}
                showSpeakers={showAgendaItemSpeakers}
                isOnEventBrief
              />
            </TabPanelInner>
          </TabPanel>
        )}

        {event && !isEmpty(event.speakers) && (
          <TabPanel>
            <TabPanelInner>
              <SpeakersContainer>
                {event.speakers.map((speaker, id) => (
                  <Speaker
                    key={`speaker-${id}`}
                    primaryColor={
                      StylingUtils.isWhite(primaryColor)
                        ? theme.colors.textDark
                        : primaryColor
                    }
                    scrollColor={sidebarScrollHex}
                    headerImg={get(
                      event,
                      'event_custom_theme.speakers_popup_header_img.url',
                    )}
                    isMobile={isMobile}
                    {...speaker}
                  />
                ))}
              </SpeakersContainer>
            </TabPanelInner>
          </TabPanel>
        )}

        {event && !isEmpty(event.sponsors) && (
          <TabPanel>
            <TabPanelInner>
              {event.sponsors.map((sponsor, id) => (
                <SponsorMain key={`sponsor-${id}`} darkBg {...sponsor} />
              ))}
            </TabPanelInner>
          </TabPanel>
        )}
      </Tabs>
    </Box>
  ) : (
    displayNoUpcomming({ noUpcomming })
  );
};

const EventBrief = ({ event, isLoading, ...props }) => {
  return (
    <Container>
      {isLoading ? <Loader /> : content({ event, ...props })}
    </Container>
  );
};

export default inject('modalStore')(observer(EventBrief));
