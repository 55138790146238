import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Image, Text } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import theme from '../_styles/theme';
import StylingUtils from '../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';
import { AnimateExpand } from './Expand';
import { toHex } from '../_app/utils/otherUtils';

const Avatar = styled(Image)`
  border-radius: 50%;
  min-width: 14px;
  margin-right: 5px;
`;

const Content = styled(Flex)`
  margin-bottom: 11px;
  width: calc(100% - 8px); //scrollbar space
  display: -webkit-box; // /* HACK: fix height of container - Safari bug */
  ${StylingUtils.animationFadeIn()}

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%
  }
`;

const TopBar = styled(Flex)`
  justify-content: space-between;
  color: ${props => props.color || theme.colors.text};

  :hover {
    cursor: pointer;
  }
`;

const PollContent = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0 10px;
`;

const VoteTitle = styled(Text)`
  color: ${props => props.color || theme.colors.text};
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.bold};
  line-height: 18px;
`;

const Votes = styled(Box)`
  margin-top: 10px;
`;

const Vote = styled(Flex)`
  background-color: ${props => props.bckg || theme.colors.background};
  color: ${props => props.color || theme.colors.text};
  height: 33px;
  border: 1px solid ${props => props.border || '#fff'};
  border-radius: 5px;

  position: relative;
`;

const VoteValue = styled(Text)`
  color: ${props => props.color || theme.colors.text};
  font-size: ${theme.fontSizes[4]}px;
  margin-left: 3px;
`;

const VoteLabel = styled(Text)`
  color: ${props => props.color || theme.colors.text};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes[1]}px;
`;

const VoteText = styled(Flex)`
  position: absolute;
  width: 100%;
  padding: 0 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${theme.fontWeights.bold};
`;

const Answer = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bckg || theme.colors.background};
  color: ${props => props.color || theme.colors.text};
  text-align: center;
  padding: 7px;
  border: 1px solid ${theme.colors.background};
  font-size: ${theme.fontSizes[2]}px;

  :hover {
    background-color: ${props => props.bckgHover};
    cursor: pointer;
  }
`;

const getWidth = props => props.percent;
const getGradient = color =>
  color === 'red' ? '#e25656, #e60000' : '#7ad800, #49a000';
const getFallbackGradient = idx =>
  idx % 2 === 0 ? getGradient('green') : getGradient('red');

const Bar = styled(Box)`
  position: relative;
  border-radius: inherit;
  background: linear-gradient(${props => props.gradient});
  height: 100%;

  animation-name: expand-${getWidth};
  animation-duration: 1s;
  animation-fill-mode: forwards;

  @keyframes expand-${getWidth} {
    from {
      width: 0%;
    }
    to {
      width: ${getWidth}%;
    }
  }
`;

class ChatPoll extends React.Component {
  state = {
    expanded: true,
  };

  handleVote = async pollAnswer => {
    const { sendVote } = this.props;
    sendVote(pollAnswer);
  };

  handleToggle = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { expanded } = this.state;
    const { poll, avatar, showResults, style } = this.props;

    const { poll_answers: pollAnswers } = poll;

    const { pollText, pollAnswerText, pollAnswerBckg } = style || {};

    const hasAlreadyVoted = !!(pollAnswers || []).find(
      ans => ans.voted === true,
    );

    const allVotes = (pollAnswers || []).reduce(
      (sum, vote) => sum + vote.votes_count,
      0,
    );

    return (
      <Content>
        {avatar && <Avatar src={avatar} sx={{ width: 14, height: 14 }} />}
        <PollContent>
          <TopBar onClick={this.handleToggle} color={pollText}>
            <Flex alignItems="center">
              {expanded ? null : (
                <Box ml={2}>
                  <VoteTitle
                    oveflowX="hidden"
                    textOverlap="ellipsis"
                    color={pollText}
                  >
                    {poll.question}
                  </VoteTitle>
                </Box>
              )}
            </Flex>
          </TopBar>
          <AnimateExpand
            styles={{
              overflowY: 'hidden',
              marginTop: expanded ? '10px' : 0,
            }}
            finalHeight={expanded ? 'auto' : 0} // TODO: should be number (ex. 100) instead of auto for animation to work
          >
            <VoteTitle color={pollText}>{poll.question}</VoteTitle>
            {!isEmpty(pollAnswers) && (
              <Votes>
                {hasAlreadyVoted || showResults ? (
                  <>
                    {pollAnswers.map((ans, idx) => {
                      const percent = (
                        (ans.votes_count / allVotes) *
                        100
                      ).toFixed(0);
                      return (
                        <Vote
                          key={`vote-${idx}`}
                          css={idx !== 0 ? { marginTop: '10px' } : {}}
                          bckg={pollAnswerBckg}
                          color={pollAnswerText}
                          border={toHex(
                            get(
                              ans,
                              `poll_answer_custom_theme.poll_answer_gradient_top`,
                            ),
                          )}
                        >
                          <Bar
                            percent={percent}
                            gradient={() =>
                              get(
                                ans,
                                `poll_answer_custom_theme.poll_answer_gradient_top`,
                              )
                                ? `${toHex(
                                    get(
                                      ans,
                                      `poll_answer_custom_theme.poll_answer_gradient_top`,
                                    ),
                                  )}, ${toHex(
                                    get(
                                      ans,
                                      `poll_answer_custom_theme.poll_answer_gradient_bottom`,
                                    ),
                                  )}`
                                : getFallbackGradient(idx)
                            }
                          />
                          <VoteText>
                            <VoteLabel color={pollAnswerText}>
                              {ans.answer}
                            </VoteLabel>
                            <VoteValue color={pollAnswerText}>
                              {percent}%
                            </VoteValue>
                          </VoteText>
                        </Vote>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {pollAnswers.map((ans, idx) => (
                      <Answer
                        key={`${idx}`}
                        onClick={() => this.handleVote(ans)}
                        css={idx !== 0 ? { marginTop: '10px' } : {}}
                        bckg={pollAnswerBckg}
                        bckgHover={`${StylingUtils.hexToRgbA(
                          pollAnswerBckg || theme.colors.background,
                          0.6,
                        )}`}
                        color={pollAnswerText}
                      >
                        {ans.answer}
                      </Answer>
                    ))}
                  </>
                )}
              </Votes>
            )}
          </AnimateExpand>
        </PollContent>
      </Content>
    );
  }
}

export default ChatPoll;
