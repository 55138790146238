import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { LS_KEYS } from '../_app/utils/LocalStorageManager';
import routes from '../_app/routing/routes';

const urlBuilder = ({
  url,
  timestamp,
  isArchived,
  activeAgendaItem,
  user,
  activeEvent,
  isMobile,
}) => {
  const isVimeo = url && url.includes('vimeo.');
  const isZoom = url && url.includes('zoom.us');

  let finalUrl = url;
  if (isZoom) {
    if (
      activeAgendaItem &&
      activeAgendaItem.group.zoom_meeting_id !== null &&
      activeAgendaItem.group.zoom_passcode !== null
    ) {
      const meetingId = activeAgendaItem.group.zoom_meeting_id.replace(
        /\s/g,
        '',
      );
      const pwd = activeAgendaItem.group.zoom_passcode;

      let anonUsername;
      if (!user) {
        const { [LS_KEYS.ANONYM_CHAT_NAME]: savedAnonChatName } = Cookies.get();
        anonUsername = savedAnonChatName;
      }
      const leaveUrl = `${window.location.origin}${
        routes.home.event
      }/${activeEvent.slug || ''}?leaveZoomFrame=true`;
      finalUrl = `${
        process.env.REACT_APP_WEB_URL
      }/zoom.html?zoomMeetingOnly=true&passCode=${pwd}&meetingNumber=${meetingId}&leaveUrl=${leaveUrl}${
        user !== null
          ? `&email=${user.email}&firstName=${user.first_name}&lastName=${user.last_name}`
          : `&firstName=${anonUsername}`
      }`;
    } else {
      finalUrl = 'wrongZoomUrl';
    }
  }

  if (isVimeo && isArchived) {
    finalUrl = `${finalUrl}?autoplay=1${isMobile ? '&muted=1' : ''}`;
    if (timestamp) {
      const min = Math.floor(timestamp / 60);
      const sec = timestamp % 60;
      finalUrl = `${finalUrl}#t=${min}m${sec}s`;
    }
  }
  return finalUrl;
};

@inject('userStore')
@observer
class StreamFrame extends Component {
  state = {
    refresh: 0, // only used to hard rerender iframe
  };

  componentDidUpdate(prevProps) {
    const { timestamp, isArchived } = this.props;
    if (get(prevProps, 'timestamp') !== timestamp) {
      // so basically means - jump through video (refresh iframe) only on archived streams
      if (isArchived) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ refresh: new Date() });
      }
    }
  }

  render() {
    const { refresh } = this.state;
    const {
      activeEvent,
      videoUrl,
      openAsVideoElement = false,
      activeAgendaItem,
      isMobile,
      isArchived,
      timestamp,
      iframeBreakoutRoom,
      userStore: { user },
    } = this.props;

    let finalUrl = null;
    if (iframeBreakoutRoom) {
      const url = get(activeAgendaItem, 'group.zoom_link');
      const returnedUrl = urlBuilder({
        url,
        timestamp,
        isArchived,
        activeAgendaItem,
        user,
        activeEvent,
        isMobile,
      });

      if (!isEmpty(videoUrl)) {
        finalUrl = urlBuilder({ url: videoUrl, isArchived, isMobile });
      } else if (returnedUrl !== 'wrongZoomUrl') {
        finalUrl = returnedUrl;
      } else {
        window.open(get(activeAgendaItem, 'group.zoom_link'), '_blank');
      }
      // finalUrl = `<div class="iframe-container" style="overflow: hidden; padding-top: 56.25%; position: relative;"> <iframe allow="microphone; camera" style="border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%;" src="${url}" frameborder="0"></iframe> </div>`;
    } else if (!isEmpty(videoUrl)) {
      finalUrl = urlBuilder({ url: videoUrl, isArchived, isMobile });
    } else {
      const url = get(activeEvent, 'player.url');
      finalUrl = urlBuilder({ url, timestamp, isArchived, isMobile });
    }

    return (
      <>
        {!openAsVideoElement ? (
          <iframe
            id="stream-frame"
            key={refresh}
            title="Stream"
            style={{
              height: isMobile ? `calc(100vw * 9/16)` : '100%',
              width: '100%',
              position: 'absolute',
              overflow: 'hidden',
            }}
            src={finalUrl}
            allow="autoplay; fullscreen; microphone; camera"
          />
        ) : (
          <video
            key={finalUrl}
            preload="auto"
            playsInline
            muted
            id="video"
            controls
            style={{
              height: isMobile ? `calc(100vw * 9/16)` : '100%',
              width: '100%',
              position: 'absolute',
              overflow: 'hidden',
            }}
            autoPlay="autoplay"
          >
            <track kind="captions" />
            <source src={finalUrl} />
          </video>
        )}
      </>
    );
  }
}

export default StreamFrame;
