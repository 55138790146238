import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { Loader } from '../../components/Loader';
import StylingUtils from '../../_app/utils/stylingUtils';
import AgendaItemMobile from './AgendaItemsList/AgendaItemMobile';
import { AgendaTimeLine } from './AgendaTimeLine/AgendaTimeLine';
import { AGENDA_ITEM_TYPES } from '../../_app/constants';
import DayBreaker from './AgendaItemsList/components/DayBreaker';
import {
  isMultiDayEvent,
  handleMultiDayAIs,
} from '../../_app/utils/otherUtils';

const Container = styled(Box)`
  position: relative;
  flex-direction: column;
  height: auto;
  padding: 25px 20px 25px;

  min-height: calc(
    100% - 100px
  ); // hack - for disclaimer to sit on bottom of mobile page always
  ${StylingUtils.hidingScrollbarFormula()}
  overflow-y: hidden;
  height: auto;
`;

const noOtherLiveAIs = (ai, allAis) => {
  let result = true;
  allAis.forEach(agIt => {
    if (agIt.live && agIt.id !== ai.id) {
      result = false;
    }
  });
  return result;
};

// not used anymore
export const AgendaContainerMobile = ({
  activeAgendaItem,
  agendaItems,
  customStyles: {
    agendaTimelineStyle,
    agendaItemStyle,
    breakoutItemStyle,
    bubbleStyle,
    chatStyle,
  },
  isLoading,
  onSetActive,
  infoFunc,
  playFunc,
  ...props
}) => {
  const moreThan1Day = isMultiDayEvent(agendaItems);

  return (
    <Container>
      {!isEmpty(agendaItems) && (
        <Box height="100%" width="100%">
          {agendaItems.map((agendaItem, idx) => {
            const isHappeningNow = moment().isBetween(
              agendaItem.start_date,
              agendaItem.end_date,
            );

            let correctStyles = agendaItemStyle;
            if (agendaItem.agenda_type === AGENDA_ITEM_TYPES.BREAKOUT_ROOM) {
              correctStyles = breakoutItemStyle;
            }

            const { days, sortedAIs, isNewDay } = handleMultiDayAIs(
              agendaItems,
              agendaItem,
              moreThan1Day,
              idx,
            );

            return (
              <Box key={agendaItem.id}>
                {isNewDay && (
                  <DayBreaker
                    agendaItemStyle={agendaItemStyle}
                    dayAgendaItems={
                      sortedAIs[
                        moment(agendaItem.start_date).format('DD-MM-YYYY')
                      ]
                    }
                    idx={idx}
                    days={days}
                    isMobile
                  />
                )}
                <AgendaItemMobile
                  id={`agenda-item-${agendaItem.id}`}
                  idx={idx}
                  // key={agendaItem.id}
                  agendaItemStyle={correctStyles}
                  agendaItem={agendaItem}
                  bubbleStyle={bubbleStyle}
                  chatStyle={chatStyle}
                  active={
                    activeAgendaItem && agendaItem.id === activeAgendaItem.id
                  }
                  isLive={
                    (agendaItem && agendaItem.live) ||
                    (isHappeningNow && noOtherLiveAIs(agendaItem, agendaItems))
                  }
                  onSetActive={() => onSetActive(idx)}
                  infoFunc={infoFunc}
                  playFunc={playFunc}
                  {...props}
                />
              </Box>
            );
          })}
          <AgendaItemMobile
            id="last"
            key="last"
            agendaItemStyle={agendaItemStyle}
            lastItem
            agendaItem={{
              start_date: last(agendaItems).end_date,
              end_date: last(agendaItems).end_date,
              location: last(agendaItems).location,
              description: <FormattedMessage id="agendaitem.end" />,
            }}
            bubbleStyle={bubbleStyle}
            chatStyle={chatStyle}
            info={`${moment(last(agendaItems).end_date).format('HH:mm')}, ${
              last(agendaItems).location
            }`}
            active={false}
            onSetActive={() => false}
            infoFunc={infoFunc}
            playFunc={playFunc}
          />
          <AgendaTimeLine
            isMobile
            activeAgendaItem={activeAgendaItem}
            activeIdx={
              activeAgendaItem &&
              agendaItems.findIndex(ai => ai.id === activeAgendaItem.id)
            }
            agendaItemsLength={isEmpty(agendaItems) ? 0 : agendaItems.length}
            style={{ top: moreThan1Day ? 38 : 35, left: 33 }}
            customStyle={agendaTimelineStyle}
            moreThan1Day={moreThan1Day}
          />
        </Box>
      )}
      {isLoading && <Loader />}
    </Container>
  );
};
