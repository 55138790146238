import { action, observable } from 'mobx';

export class ModalStore {
  @observable isModalActive = false;

  @observable modalContent = false;

  @observable fullSize = false;

  @observable isLayerShown = false; // is not background layer of modal - only placed here to not create another store. ex. used to close chat tagging dropdown

  @observable layerCloseCallback = null;

  @action setVisibility = async (isVisible, content, fullSize) => {
    this.isModalActive = isVisible;

    if (content) {
      this.modalContent = content;
    }

    this.fullSize = !!fullSize;
  };

  @action setLayerVisibility = async (isVisible, callback) => {
    this.isLayerShown = isVisible;

    if (isVisible) {
      this.layerCloseCallback = callback;
    } else if (this.layerCloseCallback) {
      // eslint-disable-next-line no-unused-expressions
      this.layerCloseCallback && this.layerCloseCallback(isVisible);
      this.layerCloseCallback = null;
    }
  };
}

export default new ModalStore();
