import preset from '@rebass/preset';

export default {
  ...preset,
  colors: {
    text: '#fff',
    textSecondary: '#e6e6e6',
    textTertiary: '#d8d8d8',
    textSemiVisible: '#a1a1a1',
    textDark: '#181d23',
    textDarkSecondary: '#566064',
    textOnWhiteBg: '#181A26',
    textGrey: '#4F6169',

    background: '#4c596c',
    backgroundDarker: '#39444e',
    bgHeader: '#000',
    bgDark: '#000',
    bgDarkSecondary: '#080A0C',
    bgLight: '#181d23',
    bgNavyBlue: '#222a30',
    shadowBlack: '#1f1e1e',
    agendaTimeline: '#fff',
    chatMsgActive: '#55697b',
    card: '#fff',
    checkBoxTag: '#fff',
    bgTileGrey: '#eee',

    primary: '#37c2fe',
    border: 'rgba(255, 255, 255, .2)',
    borderLight: '#b1aaaa',
    borderSemiLight: '#a1a1a1',
    error: '#a94442',
    success: '#428633',
  },
  fonts: {
    body: '"Source Sans Pro", sans-serif',
  },
  fontSizes: [11, 12, 14, 16, 18, 22, 33, 8], // [9, 10, 12, 14, 24, 36],
  fontWeights: {
    body: 400,
    emph: 600,
    heading: 700,
    almostBold: 800,
    bold: 900,
  },
  borders: {
    primary: '1px solid #333',
    secondary: '1px solid #979797',
    header: '1px solid #000',
    mobile: '1px solid #000',
  },
};
