import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

class StylingUtils {
  static hexToRgbA(hex, opacity = 1) {
    let c;

    if (!hex) return null;

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      // eslint-disable-next-line no-bitwise
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
        ',',
      )},${opacity})`;
    }
    // eslint-disable-next-line no-console
    console.warn('Bad Hex:', hex);
    return null;
  }

  static hidingScrollbarFormula() {
    return `
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      ::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }`;
  }

  static animationFadeIn() {
    return `
    animation-name: fadeIn;
    animation-duration: 0.3s;
    
    @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }`;
  }

  static customScroll(customColor, opt) {
    const { bckg } = opt || {};
    return `
      ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px; // for vertical mode
      height: 4px; // for horizontal mode
      background-color: ${StylingUtils.hexToRgbA(
        bckg || customColor || '#fff',
        0.3,
      )};
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: ${StylingUtils.hexToRgbA(customColor || '#fff', 0.65)};
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    `;
  }

  static isWhite(hexColor) {
    return ['#FFF', '#FFFFFF', '#fff', '#ffffff'].includes(hexColor);
  }

  static mergeThemesArray(themesArr) {
    // while passing themesArr mind priority going from lowest to highest
    if (isEmpty(themesArr)) {
      return {};
    }

    const result = {};
    themesArr.forEach(theme => {
      if (!theme) {
        return;
      }
      Object.keys(theme).forEach(themePropKey => {
        if (
          themePropKey === 'bottom_img_file' ||
          themePropKey === 'bottom_img_mobile_file' ||
          themePropKey === 'speakers_popup_header_img'
        ) {
          result[themePropKey] = get(theme[themePropKey], 'url')
            ? theme[themePropKey]
            : result[themePropKey];
        } else {
          result[themePropKey] = theme[themePropKey] || result[themePropKey]; // override if not falsy
        }
      });
    });
    return result;
  }

  static mergeTranslationsJsons(translationsArr) {
    // while passing translationsArr mind priority going from lowest to highest
    if (isEmpty(translationsArr)) {
      return {};
    }

    const result = {};
    translationsArr.forEach(translation => {
      if (!translation) {
        return;
      }

      Object.keys(translation).forEach(translationPropKey => {
        result[`${translationPropKey}`] =
          translation[translationPropKey] || result[translationPropKey]; // override if not falsy
      });
    });
    return result;
  }
}

export default StylingUtils;
