import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import styled from '@emotion/styled';
import { TooltipWithUsers } from './TooltipWithUsers/TooltipWithUsers';
import de from '../translations/de.json';
import en from '../translations/en.json';
import fr from '../translations/fr.json';

const IconWrapper = styled(Box)`
  cursor: pointer;
`;

const TextStyled = styled(Text)`
  ${props => props.nowrap && `white-space: nowrap;`}
`;

const ChatName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
  line-height: 1.2em;
`;

const ToggleWrapper = styled(Flex)`
  cursor: pointer;
`;

const Dot = styled(Box)`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: red;
  margin-bottom: 6px;
`;

const IconDetail = ({
  icon,
  iconTopOffset,
  iconMR,
  mt,
  mr,
  ml,
  pl,
  text,
  fontColor,
  isArchived,
  expanded,
  expandSide,
  onToggle,
  toggleColor,
  fontSize,
  lineHeight,
  letterSpacing,
  nowrap,
  withoutTranslation,
  newChatRoomExpanded,
  chatRoomsExpanded,
  chatRoomNumberOfUsers,
  chatStyle,
  numberOfUsersColor,
  chatRoomId,
  getRoomUsers,
  chatUsers,
  newChatRoomIds,
}) => {
  const IconOpen = expandSide ? KeyboardArrowRightIcon : ExpandLessIcon;
  const IconClosed = expandSide ? KeyboardArrowLeftIcon : ExpandMoreIcon;

  return (
    <Flex alignItems="center" justifyContent="space-between" mr={mr || '5px'}>
      <Flex alignItems="center">
        {icon && (
          <Box mr={iconMR || '11px'} position="relative" top={iconTopOffset}>
            {icon}
          </Box>
        )}
        <TextStyled
          nowrap={nowrap}
          fontSize={fontSize || 2}
          fontWeight={600}
          letterSpacing={letterSpacing || 0.5}
          lineHeight={lineHeight || '1em'}
          {...(fontColor ? { color: fontColor } : {})}
          position="relative"
          top="14px"
          mt={mt}
          ml={ml}
          pl={pl}
        >
          <>
            {!withoutTranslation ? (
              <FormattedMessage
                id={`icon.${text}`}
                defaultMessage={`icon.${text}`}
              />
            ) : (
              <>
                {newChatRoomExpanded ? (
                  <FormattedMessage id="chat.startChatWith" />
                ) : (
                  <Flex>
                    <ChatName>
                      {chatRoomsExpanded ? (
                        <FormattedMessage id="chat.rooms" />
                      ) : (
                        `${text}`
                      )}
                    </ChatName>
                    {chatRoomNumberOfUsers && !chatRoomsExpanded && (
                      <Text
                        css={css`
                          cursor: pointer;
                          .remaining-reactions {
                            display: none;
                          }
                          &:hover .remaining-reactions {
                            cursor: pointer;
                            width: auto;
                            display: flex;
                          }
                        `}
                      >
                        <TooltipWithUsers
                          chatStyle={chatStyle}
                          getRoomUsers={getRoomUsers}
                          chatRoomId={chatRoomId}
                          chatUsers={chatUsers}
                          numberOfUsers={chatRoomNumberOfUsers}
                        >
                          <Box color={numberOfUsersColor}>
                            &nbsp;({chatRoomNumberOfUsers})
                          </Box>
                        </TooltipWithUsers>
                      </Text>
                    )}
                  </Flex>
                )}
              </>
            )}
          </>
          {isArchived &&
            ![
              en['icon.EXPLORE'],
              fr['icon.EXPLORE'],
              fr['icon.EXPLORE'],
              en['icon.AGENDA'],
              de['icon.AGENDA'],
              fr['icon.AGENDA'],
            ].includes(text) && (
              <>
                {' '}
                - <FormattedMessage id="label.archived" />
              </>
            )}
        </TextStyled>
      </Flex>
      <ToggleWrapper
        alignItems="center"
        justifyContent="center"
        onClick={expandSide ? onToggle : undefined}
      >
        {newChatRoomIds && newChatRoomIds.length > 0 && !chatRoomsExpanded && (
          <Dot />
        )}
        {expandSide && (
          <>
            {onToggle && (
              <IconWrapper mt="-3px" color={toggleColor}>
                {expanded && <IconClosed />}
              </IconWrapper>
            )}
            <Text
              fontSize={fontSize || 2}
              letterSpacing={letterSpacing || 0.5}
              lineHeight={lineHeight || '1em'}
              color={fontColor}
              position="relative"
              top="14px"
              mt={mt}
              pl={pl}
              ml={
                newChatRoomIds && newChatRoomIds.length > 0 && !expanded
                  ? '6px'
                  : '0'
              }
            >
              {!expanded ? (
                <FormattedMessage id="chat.rooms" />
              ) : (
                <FormattedMessage id="chat.roomsBack" />
              )}
            </Text>
          </>
        )}
        {onToggle && (
          <IconWrapper
            mt="-3px"
            color={toggleColor}
            onClick={!expandSide ? onToggle : undefined}
          >
            {!expanded ? <IconOpen /> : !expandSide && <IconClosed />}
          </IconWrapper>
        )}
      </ToggleWrapper>
    </Flex>
  );
};

IconDetail.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

export default IconDetail;
