import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Cookies from 'js-cookie';
import theme from '../_styles/theme';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';
import EditProfileForm from './EditProfileForm';
import StylingUtils from '../_app/utils/stylingUtils';
import FileUtil from '../_app/utils/FileUtil';
import { toHex } from '../_app/utils/otherUtils';
import { LS_KEYS } from '../_app/utils/LocalStorageManager';
import { ScrollContent } from '../components/Chat/Chat';
import ChevronLeft from '../_assets/icons/chevronLeft';

const Card = styled(Box)`
  position: relative;
  height: 100%;
  padding: 30px 18px 30px 40px;
  background-color: ${theme.colors.card};
  border-radius: 10px;
  ${StylingUtils.animationFadeIn()}
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    border-radius: 10px;
    padding: 20px 10px 20px 20px;
  }
`;

const CardHeader = styled(Text)`
  font-size: ${theme.fontSizes[3]}px;
  font-weight: ${theme.fontWeights.emph};
  color: ${theme.colors.textDark};
  line-height: 1.1em;
`;

@inject('userStore', 'homeStore', 'modalStore')
@observer
class Settings extends Component {
  handleOnSubmit = async val => {
    const {
      userStore: { updateProfile },
      modalStore: { setVisibility },
    } = this.props;

    let avatar = '';
    const sendData = { ...val };

    if (!isEmpty(val.avatar) && typeof val.avatar[0] === 'object') {
      avatar = await FileUtil.getBase64(val.avatar[0]).catch(e =>
        // eslint-disable-next-line no-console
        console.log(e),
      );
    } else {
      delete sendData.avatar;
    }
    if (val.name && !val.last_name) {
      return updateProfile({ ...val, ...(!!avatar && { avatar }) }, () =>
        setVisibility(false),
      );
    }

    sendData.interest_details = val.interests.map(selectedInterest => ({
      id: selectedInterest,
      selected: true,
    }));

    const networkingDisabled = val.networking_events?.filter(
      n => !val.networking_enabled.includes(n),
    );

    sendData.networking_details = networkingDisabled?.map(
      networkingPerEvent => ({
        event_id: networkingPerEvent,
        networking_enabled: false,
      }),
    );

    const unnecessaryData = [
      'allow_password_change',
      'attendances',
      'created_at',
      'email',
      'event_ids',
      'generic_password',
      'id',
      'interests',
      'last_sign_in_at',
      'last_sign_in_ip',
      'locker_locked_at',
      'locker_locked_until',
      'marketing_terms_accepted',
      'networking_enabled',
      'networking_events',
      'atLeastOneNetworkingAvailable',
      'networking_refusals',
      'organization_id',
      'poll_answer_ids',
      'provider',
      'register_attendance_id',
      'registered_for_events',
      'superuser',
      'terms_and_conditions_accepted',
      'uid',
      'updated_at',
      '_role',
    ];
    unnecessaryData.forEach(ud => delete sendData[ud]);

    return updateProfile(
      { user: { ...sendData }, ...(!!avatar && { avatar }) },
      () => setVisibility(false),
    );
  };

  compareNetworkingDetailsObj = (a, b) => {
    if (a.event_name < b.event_name) {
      return -1;
    }
    if (a.event_name > b.event_name) {
      return 1;
    }
    return 0;
  };

  render() {
    const {
      onClose,
      userStore: { user, userProfile, anonUser, isLoading: isProfileUpdating },
      homeStore: { activeOrganization, activeEvent },
    } = this.props;
    const primaryColor = get(activeOrganization, 'dominant_color')
      ? toHex(activeOrganization.dominant_color)
      : theme.colors.textSemiVisible;

    const {
      buttons_bg: buttonsBg,
      header_font_family: headerFontFamily,
      buttons_text_color: buttonsTextColor,
    } = (activeEvent && activeEvent.event_custom_theme) || {};
    const buttonBgHex = toHex(buttonsBg);
    const buttonTextColorHex = toHex(buttonsTextColor);

    const {
      [LS_KEYS.ANONYM_CHAT_NAME]: savedAnonChatName,
      [LS_KEYS.ANONYM_CHAT_USER_AVATAR]: anonAvatar,
    } = Cookies.get();
    const isAnonymous = !isEmpty(anonUser) || !!savedAnonChatName;
    const { id, ...anonNoId } = isAnonymous;

    const { name: storeAnonName, avatar: storeAnonAvatar } = anonUser || {};
    const finalAnonUsername = storeAnonName || savedAnonChatName;
    const finalAnonAvatar = get(storeAnonAvatar, 'thumb.url') || anonAvatar;

    const finalUser = user ||
      (!isEmpty(anonNoId) && anonNoId) || {
        name: finalAnonUsername,
        avatar: { thumb: { url: finalAnonAvatar } },
      };

    const networkingDetails = get(userProfile, 'networking_details');
    const networkingDetailsSorted =
      !isAnonymous &&
      networkingDetails &&
      JSON.parse(JSON.stringify(networkingDetails)).sort(
        this.compareNetworkingDetailsObj,
      );
    return (
      <Card>
        <ScrollContent
          scrollColor={buttonBgHex || primaryColor}
          pr={18}
          height="100%"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            pt={14}
            pb={10}
          >
            <CardHeader>
              <Text fontFamily={headerFontFamily || theme.fonts.body}>
                <FormattedMessage id="settings.header" />
              </Text>
            </CardHeader>

            <Flex
              onClick={onClose}
              alignItems="center"
              css={css`
                cursor: pointer;
                color: ${theme.colors.textDark};
              `}
            >
              <ChevronLeft
                fill={buttonBgHex || primaryColor}
                width={10}
                height={16}
              />
              <Text
                ml="5px"
                fontSize={2}
                fontFamily={headerFontFamily || theme.fonts.body}
              >
                <FormattedMessage id="generic.back" />
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            flexGrow={1}
          >
            <EditProfileForm
              initialValues={finalUser}
              primaryColor={buttonBgHex || primaryColor}
              buttonsTextColor={buttonTextColorHex}
              onSubmit={this.handleOnSubmit}
              isLoading={isProfileUpdating}
              activeOrganization={activeOrganization}
              extraFields={get(userProfile, 'registration_fields')}
              networkingDetails={networkingDetailsSorted}
              event={activeEvent}
              isAnonymous={isAnonymous}
            />
          </Flex>
        </ScrollContent>
      </Card>
    );
  }
}

export default Settings;
