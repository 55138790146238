import React from 'react';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { ReactionSelector } from './ReactionsSelector';

const Container = styled(Flex)`
  height: 100%;
  width: 100%;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 0;
  left: 0;
`;

const CloseIconBox = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const Content = styled(Flex)`
  width: 100%;
  padding: ${props =>
    props.streamWidth < 450 ? '65px 20px 0px 20px' : '115px 20px 0px 20px'};
`;

export const StreamOverlay = ({
  handleCloseOverlay,
  selectedOverlay,
  handleReactionClick,
  streamSize,
}) => {
  const { width } = streamSize;
  return (
    <Container onClick={() => handleCloseOverlay()}>
      <CloseIconBox onClick={() => handleCloseOverlay()}>
        <CloseIcon />
      </CloseIconBox>
      <Content streamWidth={width}>
        {selectedOverlay === 'emotes' && (
          <ReactionSelector handleReactionClick={handleReactionClick} />
        )}
      </Content>
    </Container>
  );
};
