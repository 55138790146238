import React, { useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { CalendarIcon } from '../_assets/icons/calendarIcon';

export const AddCalendar = inject(
  'homeStore',
  'eventBriefStore',
)(
  observer(
    ({
      homeStore: { activeEvent },
      eventBriefStore: { event },
      buttonsTextColor,
    }) => {
      const [show, setShow] = useState(false);
      useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://addevent.com/libs/atc/1.6.1/atc.min.js';
        document.getElementsByTagName('head')[0].appendChild(script);
        setShow(true);
      }, []);
      const theEvent = event || activeEvent;
      const firstAI =
        theEvent && !isEmpty(theEvent.agenda_items) && theEvent.agenda_items[0];

      const lastAI =
        theEvent &&
        !isEmpty(theEvent.agenda_items) &&
        theEvent.agenda_items[theEvent.agenda_items.length - 1];

      const startTime =
        theEvent &&
        moment(
          `${moment(theEvent.planned_start_date).format('MM/DD/YYYY')} ${
            firstAI ? moment(firstAI.start_date).format('hh:mm A') : ''
          }`,
        ).format('MM/DD/YYYY hh:mm A'); // doesnt work with ('DD/MM/YYYY')

      const endTime =
        lastAI && moment(lastAI.end_date).format('MM/DD/YYYY hh:mm A'); // doesnt work with ('DD/MM/YYYY')

      const { addevent_calendar_id: addEventCalendarId, description, title } =
        theEvent || {};
      return (
        <Fade in={show}>
          {addEventCalendarId ? (
            <a
              data-dropdown-y="down"
              data-styling="none"
              css="false"
              title="+ to Calendar"
              className="addeventatc outline-none"
              data-id={addEventCalendarId}
              href={`https://www.addevent.com/event/${addEventCalendarId}`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <CalendarIcon
                fill={buttonsTextColor}
                width="18px"
                height="18px"
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  left: '12px',
                  top: '11px',
                }}
              />
              ADD TO CALENDAR
            </a>
          ) : (
            <div
              title="ADD TO CALENDAR"
              className="addeventatc outline-none"
              data-event={addEventCalendarId}
              data-id={addEventCalendarId}
              data-dropdown-y="down"
            >
              ADD TO CALENDAR
              <span className="start">{startTime}</span>
              <span className="end">{endTime}</span>
              <span className="title">{title}</span>
              <span className="description">{description}</span>
            </div>
          )}
        </Fade>
      );
    },
  ),
);
