import { action, observable, toJS } from 'mobx';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import isEmpty from 'lodash/isEmpty';
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';
import organizationsStore from './organizationsStore';
import EventBriefStore from './eventBriefStore';

export class EventsStore {
  @observable isLoading = false;

  @observable events = [];

  @observable translations = null;

  @action getEventTranslations = async eventId => {
    this.isLoading = true;

    try {
      const { data } = await API.get(API_ROUTES.EVENT_TRANSLATIONS(eventId));
      this.translations = data.results;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      this.isLoading = false;
    }
    return this.translations;
  };

  @action getEventBySlug = async (orgId, eventSlug) => {
    this.isLoading = true;

    try {
      if (!eventSlug) {
        return;
      }
      const {
        data: { results },
      } = await API.get(`${API_ROUTES.ORGANIZATIONS()}${orgId}/events?per=100`);
      return (results.filter(res => res.slug === eventSlug) || [])[0];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action getOrganizationEvents = async orgId => {
    this.isLoading = true;

    const { organizations: orgObsArr } = organizationsStore;

    const organizations = toJS(orgObsArr);
    try {
      const {
        data: { results },
      } = await API.get(
        `${API_ROUTES.ORGANIZATIONS()}${orgId ||
          organizations[0].id}/events?per=100`,
      );

      this.events = results;

      // hack get anything instead of disaplying no upcomming on registration flow
      if (
        !EventBriefStore.event &&
        EventBriefStore.noUpcomming &&
        !isEmpty(results)
      ) {
        await EventBriefStore.getEventBrief({
          eventId: results[results.length - 1].id,
        });
      }
      return results;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action getClosestEvent = events => {
    if (events.length > 0) {
      // eslint-disable-next-line
      let closestStartDateToNow = events[0];

      events
        .filter(
          event => !moment(event.planned_start_date).isBefore(moment(), 'day'),
        )
        .forEach(event => {
          if (
            moment(moment(event.planned_start_date)).diff(moment()) <
              moment(moment(closestStartDateToNow.planned_start_date)).diff(
                moment(),
              ) ||
            moment(moment(closestStartDateToNow.planned_start_date)).diff(
              moment(),
            ) < 0
          ) {
            closestStartDateToNow = event;
          }
        });
      const finalEvent = closestStartDateToNow;
      return finalEvent;
    }
  };

  @action markAttendance = async attendanceId => {
    this.isLoading = true;

    try {
      await API.put(`${API_ROUTES.ATTENDANCES}/${attendanceId}`);
    } catch (e) {
      return e;
    } finally {
      this.isLoading = false;
    }
    return null;
  };

  @action clearStore = async () => {
    this.events = [];
  };
}

export default new EventsStore();
