/* eslint-disable */
import React from 'react';

export const CloseIcon = ({ fill, ...props }) => (
<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
  <title>icon-close</title>
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
    <g id="close" transform="translate(-1235.000000, -436.000000)" stroke={`${fill || '#fff'}`} strokeWidth="3">
      <g id="icon-close" transform="translate(1237.000000, 438.000000)">
        <line x1="11" y1="-4" x2="11" y2="26" id="Line" transform="translate(11.000000, 11.000000) rotate(-315.000000) translate(-11.000000, -11.000000) "></line>
        <line x1="-4" y1="11" x2="26" y2="11" id="Line" transform="translate(11.000000, 11.000000) rotate(-315.000000) translate(-11.000000, -11.000000) "></line>
      </g>
    </g>
  </g>
</svg>
);
