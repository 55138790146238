import { action, observable, toJS } from 'mobx';
import get from 'lodash/get';
import ReactGA from 'react-ga';
// eslint-disable-next-line import/no-cycle
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';
import eventsStore from './eventsStore';
import cableStore from './cableStore';
import organizationsStore from './organizationsStore';
import { ATTENDANCE_STATUSES } from '../_app/constants';
import { setPageTitle } from '../_app/utils/otherUtils';

export class HomeStore {
  @observable isLoading = false;

  @observable isLoadingAgenda = false;

  @observable isLoadingDocuments = false;

  @observable agendaItems = [];

  @observable documents = [];

  @observable activeOrganization = null;

  @observable activeEvent = null;

  @observable activeEventChanged = false;

  @observable activeAgendaItem = null;

  @observable noEventFoundFlag = false;

  @observable timeStart = 0;

  @observable eventCustomTheme = null;

  @observable videoReactions = [];

  @action refreshActiveEvent = async () => {
    try {
      if (this.activeEvent) {
        const { data } = await API.get(API_ROUTES.EVENT(this.activeEvent.id));
        this.activeEvent.player = data.player;
      }
    } catch (e) {
      console.log(e);
    }
  };

  @action refreshAgendaItems = async event => {
    try {
      const {
        data: { agenda_items: items },
      } = await API.get(`${API_ROUTES.EVENT_BRIEF}/${event.id}`);
      this.agendaItems = items;
    } catch (e) {
      console.log(e);
    }
  };

  @action getEventAgendaItems = async (event, options = {}) => {
    const { callback, dontSet, skipLoader } = options;
    if (!skipLoader) {
      this.isLoadingAgenda = true;
    }
    try {
      if (!event) {
        return;
      }

      // should be taken from dedicated endpoint, but there is no so....
      const {
        data: { agenda_items: newAIs, sponsors },
      } = await API.get(`${API_ROUTES.EVENT_BRIEF}/${event.id}`);

      if (!dontSet) {
        this.agendaItems = newAIs;
      }

      this.sponsors = sponsors;

      if (callback) {
        callback(newAIs);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      if (!skipLoader) {
        this.isLoadingAgenda = false;
      }
    }
  };

  @action getDocuments = async agendaItemId => {
    this.isLoadingDocuments = true;

    const finalAgendaItemId = agendaItemId || toJS(this.activeAgendaItem).id;
    const eventId =
      get(this.activeEvent, 'id') || get(this.activeAgendaItem, 'event_id');
    try {
      this.documents = [];

      const {
        data: { results: eventItemDocs },
      } = await API.get(`${API_ROUTES.EVENTS()}${eventId}/documents?per=20`);

      this.documents = eventItemDocs;

      const {
        data: { results: agendaItemDocs },
      } = await API.get(
        `${API_ROUTES.AGENDA_ITEMS}${finalAgendaItemId}/documents?per=20`,
      );
      this.documents = uniqBy([...this.documents, ...agendaItemDocs], 'id');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoadingDocuments = false;
    }
  };

  @action setActiveOrganization = organization => {
    if (organization) {
      this.activeOrganization = organization;
    } else {
      // eslint-disable-next-line prefer-destructuring
      this.activeOrganization = toJS(organizationsStore.organizations)[0];
    }
    // console.log('Setting active org: ', get(this.activeOrganization, 'name'));

    ReactGA.set({
      dimension2: get(this.activeOrganization, 'name'),
    });
  };

  @action setActiveEvent = async (event, callback) => {
    this.isLoading = true;

    let finalEvent = event;

    this.noEventFoundFlag = false;
    // if by slug
    if (typeof event === 'string') {
      finalEvent = null;

      const ev = await eventsStore.getEventBySlug(
        this.activeOrganization.id,
        event,
      );

      if (ev) {
        finalEvent = ev;
      } else {
        this.noEventFoundFlag = true;
      }
    }

    if (!event) {
      const { data: nextEvent } = await API.get(`${API_ROUTES.NEXT_EVENT}`);

      if (!nextEvent) {
        const lastEvent = eventsStore.events[eventsStore.events.length - 1];
        finalEvent = lastEvent;
      } else {
        const { data } = await API.get(`${API_ROUTES.EVENTS()}${nextEvent.id}`);
        finalEvent = data;
      }
    }

    this.activeEvent = finalEvent;
    if (this.activeEvent) {
      this.eventCustomTheme = finalEvent.event_custom_theme;
      window.fb_pixel_id = finalEvent.fb_pixel_id;
    }

    if (!this.activeEvent) {
      this.noEventFoundFlag = true;
    }
    // console.log('Setting active event: ', get(this.activeEvent, 'name'));
    ReactGA.set({
      dimension1: get(this.activeEvent, 'name'),
    });

    if (
      this.activeEvent &&
      !isEmpty(this.activeEvent.agenda_item_ids) &&
      this.activeEvent.user_attendance.status === ATTENDANCE_STATUSES.ACCEPTED
    ) {
      await this.getEventAgendaItems(this.activeEvent);
      await this.setActiveAgendaItem();
    }

    setPageTitle(get(this.activeEvent, 'name'));
    this.activeEventChanged = true;
    this.isLoading = false;
    if (callback) {
      callback(this.activeEvent, this.activeAgendaItem);
    }
    cableStore.subscribeToEventChannel();
  };

  @action setActiveAgendaItem = async agendaItem => {
    let finalAI = agendaItem;
    if (!agendaItem) {
      const liveAI = this.agendaItems.find(ai => ai.live);

      // if there is one marked as LIVE
      if (liveAI) {
        finalAI = liveAI;
      } else {
        const [firstAI] = toJS(this.agendaItems);
        const isLastAIBeforeToday = moment(firstAI.end_date).isBefore(
          moment(),
          'day',
        );

        if (isLastAIBeforeToday && firstAI) {
          finalAI = firstAI;
        } else {
          const currentAI = this.agendaItems.find(
            ai =>
              moment().isBetween(ai.start_date, ai.end_date) &&
              ai.agenda_type === 'agenda_item',
          );

          // FIND ongoing AI
          if (currentAI) {
            finalAI = currentAI;
          } else {
            if (!firstAI) {
              finalAI = null;
              return;
            }
            let closestStartDateToNow = firstAI;
            this.agendaItems
              .filter(ai => ai.agenda_type === 'agenda_item')
              .forEach(ai => {
                if (
                  moment(moment(ai.start_date)).diff(moment()) <
                    moment(moment(closestStartDateToNow.start_date)).diff(
                      moment(),
                    ) ||
                  moment(moment(closestStartDateToNow.start_date)).diff(
                    moment(),
                  ) < 0
                ) {
                  closestStartDateToNow = ai;
                }
              });
            finalAI = closestStartDateToNow;
          }
        }
      }
    }
    // timing for the prev item:
    if (this.activeAgendaItem) {
      ReactGA.timing({
        category: 'Agenda Item',
        variable: 'View',
        value: moment().valueOf() - this.timeStart,
        label: get(this.activeAgendaItem, 'description'),
      });
    }
    this.activeAgendaItem = finalAI;
    this.timeStart = moment().valueOf();
    if (this.activeAgendaItem) {
      this.getDocuments(this.activeAgendaItem.id);
      ReactGA.set({
        dimension3: get(this.activeAgendaItem, 'description'),
      });
      // console.log('SENDING GA');
      ReactGA.event({
        category: 'Agenda Item',
        action: 'View',
        label: get(this.activeAgendaItem, 'description'),
      });
    }
  };

  @action sendVideoReaction = async ({ type }) => {
    const payload = {
      reaction_type: type,
      event_id: this.activeEvent.id,
    };

    try {
      await API.post(
        `${API_ROUTES.EVENT_REACTIONS(this.activeEvent.id)}`,
        payload,
      );
    } catch (e) {
      console.log(e);
    }
  };

  @action setVideoReactions = msg => {
    const item = {
      id: msg.reaction_id,
      type: msg.reaction_type,
      name: msg.user_name,
      avatarUrl: msg.user_avatar_url,
    };

    this.videoReactions = [...this.videoReactions, item];

    setTimeout(() => {
      this.videoReactions = this.videoReactions.filter(
        reaction => reaction.id !== item.id,
      );
    }, 10000);
  };

  @action resetVideoReactions = () => {
    this.videoReactions = [];
  };

  @action clearStore = async () => {
    this.agendaItems = [];
    this.activeEvent = null;
    this.activeOrganization = null;
    this.activeEventChanged = false;
    // this.eventCustomTheme = null;
    setPageTitle('Conference +');
  };

  @action clearActiveEventStore = async () => {
    this.agendaItems = [];
    this.activeEvent = null;
    this.activeEventChanged = false;
  };
}

export default new HomeStore();
