import React from 'react';
// import ZoomFrame from './components/ZoomFrame';

class ZoomApp extends React.Component {
  render() {
    // return <ZoomFrame />;
    return <></>;
  }
}

export default ZoomApp;
