import { useIntl } from 'react-intl';

const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const placeholderTranslator = id => {
  const intl = useIntl();
  const value = intl.formatMessage({ id });
  return toTitleCase(value);
};
