import React from 'react';
import Box from '@material-ui/core/Box';
import styled from '@emotion/styled';
import { Text } from 'rebass';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import CheckIcon from '@material-ui/icons/Check';
import { required } from '../../_app/formValidators';
import TextInput from '../_forms/TextInput';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';

const Content = styled(Box)`
  padding-bottom: 10px;
`;

const SendButton = styled(Box)`
  position: absolute;
  bottom: 10px;
  right: 18px;

  width: 18px;
  height: 18px;

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    right: 10px;
  }
`;

const FormContent = styled(Box)`
  position: relative;
`;

const FieldWrapper = styled(Box)`
  margin-top: 20px;
  width: 95%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 99%;
  }
`;

export const NameInput = ({ primaryColor, onNameConfirm }) => {
  return (
    <Content>
      <Text fontWeight="bold">
        <FormattedMessage id="chat.nameinput" />
      </Text>
      <Form
        onSubmit={onNameConfirm}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <FormContent>
              <FieldWrapper>
                <Field
                  label={<FormattedMessage id="chat.namelabel" />}
                  labelColor="textSecondary"
                  name="name"
                  id="user-name"
                  inputType="text"
                  width="100%"
                  validate={required}
                  styledWhite
                  component={TextInput}
                  autocomplete="given-name"
                />
              </FieldWrapper>
              <SendButton onClick={handleSubmit} color={primaryColor}>
                <CheckIcon />
              </SendButton>
            </FormContent>
          </form>
        )}
      />
    </Content>
  );
};
