import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import { EventListItem } from './EventListItem';

const Container = styled(Box)``;

export const EventsList = ({
  activeEvent,
  events,
  onClick,
  eventTileStyle,
}) => {
  return (
    <Container>
      {events &&
        events.map((event, idx) => (
          <EventListItem
            active={activeEvent && event.id === activeEvent.id}
            key={idx}
            day={idx + 1}
            customStyle={eventTileStyle}
            onClick={onClick ? () => onClick(event) : null}
            {...event}
          />
        ))}
    </Container>
  );
};
