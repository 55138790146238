import { Box } from 'rebass';
import React from 'react';
import styled from '@emotion/styled';

const CheckBoxWrapper = styled(Box)`
  overflow: auto;

  input + div {
    filter: opacity(40%);
    .avatar {
      filter: grayscale(100%);
    }
    .addUser {
      filter: grayscale(80%);
    }
  }

  input:checked + div {
    filter: opacity(1);
    .avatar {
      filter: grayscale(0);
    }
    .addUser {
      filter: grayscale(0);
    }
  }

  input:hover + div {
    cursor: pointer;
  }
`;

const LabelStyled = styled.label`
  position: relative;
  display: block;
`;
const InputStyled = styled.input`
  position: absolute;
  top: -20px;
`;

const CheckboxChatUsers = ({
  input,
  id,
  meta,
  mainColor,
  checkboxContent,
  ...custom
}) => {
  return (
    <CheckBoxWrapper mainColor={mainColor} mt={2}>
      <LabelStyled htmlFor={id}>
        <InputStyled id={id} {...input} {...custom} />
        <Box>{checkboxContent}</Box>
      </LabelStyled>
    </CheckBoxWrapper>
  );
};

export default CheckboxChatUsers;
