import { action, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import ReactGA from 'react-ga';
import get from 'lodash/get';
import {
  API,
  clearNotSignedUserDataLeftovers,
  setAuthenticationToken,
  setClient,
  setUid,
} from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';
import { LS_KEYS } from '../_app/utils/LocalStorageManager';
import userStore from './userStore';
// eslint-disable-next-line import/no-cycle
import homeStore from './homeStore';
// eslint-disable-next-line import/no-cycle
// import organizationsStore from './organizationsStore';
// import eventsStore from './eventsStore';
import pollStore from './pollStore';
import chatStore from './chatStore';
import chatRoomsStore from './chatRoomsStore';
import eventsStore from './eventsStore';

export class AuthStore {
  @observable isLoading = false;

  @observable authenticationToken =
    localStorage.getItem(LS_KEYS.AUTH_TOKEN) || '';

  @observable isAuthenticated = !!localStorage.getItem(LS_KEYS.AUTH_TOKEN);

  @observable resetPasswordMessage = '';

  @action
  // eslint-disable-next-line camelcase
  signIn = async ({ email, password, event_id }, onSuccess, onError) => {
    this.isLoading = true;
    try {
      const {
        headers: { 'access-token': token, client, uid },
        data: { data },
      } = await API.post(API_ROUTES.SIGN_IN, {
        email,
        password,
        event_id,
      });
      await userStore.clearStore(); // to clear out anon user leftovers

      setAuthenticationToken(token);
      setClient(client);
      setUid(uid);

      clearNotSignedUserDataLeftovers();
      userStore.clearStore();

      ReactGA.set({
        userId: uid,
      });

      userStore.user = data;
      this.authenticationToken = token;
      this.isAuthenticated = true;

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (onError) {
        onError(e.errors);
      }
      return e.errors;
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  signUp = async (values, onSuccess, onError) => {
    this.isLoading = true;
    try {
      const {
        headers: { 'access-token': token, client, uid },
        data,
      } = await API.post(API_ROUTES.SIGN_UP, values);

      setAuthenticationToken(token);
      setClient(client);
      setUid(uid);

      clearNotSignedUserDataLeftovers();
      userStore.clearStore();

      userStore.user = data.data;
      this.authenticationToken = token;
      this.isAuthenticated = true;

      ReactGA.set({
        userId: client,
      });

      if (onSuccess) {
        onSuccess(data);
      }
    } catch (e) {
      if (onError) {
        onError(e.errors);
      }
      return e.errors;
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  logout = async ({ onSuccess, onFailure }) => {
    this.isLoading = true;

    try {
      await API.delete(API_ROUTES.SIGN_OUT);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      // emergancy logout
      if (onFailure) {
        onFailure(e);
      }
    } finally {
      this.isAuthenticated = false;
      this.authenticationToken = '';

      await localStorage.removeItem(LS_KEYS.UID);
      await localStorage.removeItem(LS_KEYS.CLIENT);
      await localStorage.removeItem(LS_KEYS.AUTH_TOKEN);
      await this.clearStore();

      if (onSuccess) {
        onSuccess();
      }
    }

    this.isLoading = false;
  };

  @action resetPasswordInit = async (values, onSuccess, onError) => {
    this.isLoading = true;
    try {
      const {
        data: { message },
      } = await API.post(API_ROUTES.RESET_PASSWORD, {
        email: values.email,
        config_name: values.org,
        redirect_url: values.redirect_url,
      });
      this.resetPasswordMessage = message;
      if (onSuccess) {
        return onSuccess();
      }
    } catch (e) {
      if (onError) {
        onError(e.errors);
      }
      return e.errors;
    } finally {
      this.isLoading = false;
    }
    return null;
  };

  @action resetPassword = async (
    { headers, ...values },
    onSuccess,
    onError,
  ) => {
    this.isLoading = true;

    const oldHeaders = API.defaults.headers;
    try {
      if (headers) {
        API.defaults.headers = {
          ...API.defaults.headers,
          ...headers,
        }; // !!!!!!!!!!!!!!!!!!!!!!!!!!!
      }
      this.resetPasswordMessage =
        'Reseting password successful. You can now sign in with new credentials.';
      await API.put(API_ROUTES.RESET_PASSWORD, values);

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (onError) {
        onError(e.errors);
      }
      return e.errors;
    } finally {
      this.isLoading = false;
      API.defaults.headers = oldHeaders;
    }
    return null;
  };

  @action redirectAuthenticated = async (history, eventSlug, pushToHome) => {
    if (pushToHome) {
      history.push('/');
    } else {
      history.push(`/event/${eventSlug || ''}`);
    }
  };

  @action clearStore = async () => {
    await homeStore.clearActiveEventStore();
    // await organizationsStore.clearStore();
    // await eventsStore.clearStore();
    await pollStore.clearStore();
    await chatStore.clearStore();
    await chatRoomsStore.clearStore();
    await userStore.clearStore();
    await eventsStore.getOrganizationEvents(
      get(homeStore, 'activeOrganization.id'),
    );
  };
}

export default new AuthStore();
