import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import StylingUtils from '../../../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';

const DOT_HEIGHT_PX = 8;

const basicStyle = `
  border-radius: 5px;
  width: 8px;
`;

const TimeLineBackground = styled(Box)`
  ${basicStyle}
  position: absolute;
  top: ${props => (props.morethan1day === 'true' ? '14' : '9')}px;
  left: 2px;
  //25 - padding top - 14px padding bottom - 10px magic offset;
  height: calc(100% - 20px - 14px);
  background-color: ${props => props.bckg};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    // left: 33px;
    // top: 50px;
    height: calc(100% - 20px - 14px);
  }
`;

const timeLineStyles = customStyle => ({
  borderRadius: '5px',
  backgroundColor: get(customStyle, 'background') || '#d3dee1',
  width: '8px',
});

const TimeLine = ({ ...props }) => {
  const { customStyle, ...newProps } = props;
  return <animated.div {...newProps} />;
};

let calcHeightHandler = null;
const CALC_TIME_INTERVAL = 1000 * 5; // 10 sec

export const AgendaTimeLine = ({
  style,
  activeAgendaItem,
  agendaItems,
  customStyle,
  isMobile,
  moreThan1Day,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = useState(Date.now()); // only to periodically force refresh component rerender - not sure if a good practice

  const [timeLineHeight, setTimeLineHeight] = useState(0);

  const animationProps = useSpring({
    height: timeLineHeight,
    ...timeLineStyles(customStyle),
  });

  const calcHeight = () => {
    let finalHeight = 0;
    if (activeAgendaItem) {
      const isAiFuture = moment(activeAgendaItem.start_date).isAfter(
        moment(),
        'day',
      );

      if (isAiFuture) {
        return finalHeight;
      }

      const isFirst =
        !isEmpty(agendaItems) && activeAgendaItem.id === agendaItems[0].id;

      const { id } = activeAgendaItem;
      const aiElem = document.getElementById(`agenda-item-${id}`);
      if (aiElem) {
        const { offsetTop, offsetHeight } = aiElem;

        finalHeight += offsetTop;
        finalHeight += DOT_HEIGHT_PX;

        const { start_date: startDate, end_date: endDate } = activeAgendaItem;

        if (moment(startDate).isSameOrBefore(moment())) {
          const minutesDuration = moment(endDate).diff(startDate, 'minutes');
          const minutesLeft = moment(endDate).diff(moment.now(), 'minutes');

          const aiHeight = offsetHeight - 10; // 10 - item top padding
          const minutesPast = minutesDuration - minutesLeft;
          const percentPast = minutesPast / minutesDuration;

          const fixIfFirstAI = isFirst ? 10 : 0;

          const pixelsPast =
            percentPast >= 1
              ? aiHeight - DOT_HEIGHT_PX
              : percentPast * aiHeight - fixIfFirstAI; // not to exceed AI

          finalHeight += pixelsPast;
        }
      }
    }
    if (isMobile) {
      finalHeight -= 10; // 25 container top padding
      if (moreThan1Day) {
        finalHeight -= 15;
      }
    }
    if (moreThan1Day) {
      finalHeight -= 5; // timeline positioning with daybreakers
    }
    // console.log('HEIGHT: ', finalHeight);
    return finalHeight;
  };

  useEffect(() => {
    calcHeightHandler = setInterval(() => {
      setTime(Date.now());
    }, CALC_TIME_INTERVAL);
    return () => {
      clearInterval(calcHeightHandler);
    };
  }, []);

  useEffect(() => {
    const newHeight = calcHeight();
    if (newHeight !== timeLineHeight) {
      setTimeLineHeight(newHeight);
    }
  }, [activeAgendaItem, time]);

  return (
    <TimeLineBackground
      morethan1day={moreThan1Day ? 'true' : 'false'}
      style={style}
      bckg={`${StylingUtils.hexToRgbA(
        get(customStyle, 'background') || '#fff',
        0.2,
      )}`}
    >
      {activeAgendaItem && timeLineHeight > 0 && (
        <TimeLine
          style={animationProps}
          customStyle={customStyle}
          morethan1day={moreThan1Day ? 'true' : 'false'}
        />
      )}
    </TimeLineBackground>
  );
};
