export default class FileUtil {
  static async getBase64(file) {
    return new Promise((res, rej) => {
      if (!file) {
        rej(new Error('no file'));
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        res(reader.result);
      };
      reader.onerror = error => {
        // eslint-disable-next-line no-console
        console.log('Error: ', error);
        rej(error);
      };
    });
  }

  static b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }
}
