import React from 'react';
import { Box } from 'rebass';
import { Router, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'emotion-theming';
import ReactGA from 'react-ga';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import theme from './_styles/theme';
import GlobalStyle from './_styles/globalStyles';
import routes from './_app/routing/routes';
import Home from './home';
import TermsPage from './terms';
import SignInPage from './signIn';
import EventListingPage from './eventListing';
import HeaderBar from './components/HeaderBar/HeaderBar';
import SideMenu from './components/SideMenu/SideMenu';
import SignUpPage from './signUp';
import ResetPasswordPage from './resetPassword';
import ResetPasswordFinishPage from './resetPassword/resetPasswordFinish';
import ErrorScreen from './errorScreen';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import { CookiesSettings } from './components/CookiesConsent/CookiesConsent';
import { Modal } from './components/Modal';
import { SUBDOMAIN, shouldRedirectToOppo } from './_app/utils/otherUtils';
import { browserCompatibility } from './components/BrowserCompatibility/index';
import StylingUtils from './_app/utils/stylingUtils';
import de from './translations/de.json';
import en from './translations/en.json';
import fr from './translations/fr.json';

const history = createBrowserHistory();

const language =
  window.cplusLang || navigator.browserLanguage || navigator.language; // language without region code

moment.locale(language);
const lang = language.split(/[-_]/)[0];

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
const ClickableLayer = styled(Box)`
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

@inject(
  'authStore',
  'userStore',
  'modalStore',
  'organizationsStore',
  'homeStore',
  'eventsStore',
)
@observer
class App extends React.Component {
  constructor(props) {
    super(props);

    const trackingId = process.env.REACT_APP_GA_ID;
    ReactGA.initialize(trackingId);
  }

  async componentDidMount() {
    const redir = shouldRedirectToOppo();
    if (redir) {
      window.location.href = redir;
    }
    const {
      authStore: { isAuthenticated },
      userStore: { getProfile, getProfileDetails },
      homeStore: { setActiveOrganization },
      eventsStore: { getOrganizationEvents },
      organizationsStore: { getOrganizationBySlug },
    } = this.props;
    if (SUBDOMAIN) {
      await getOrganizationBySlug(SUBDOMAIN, async org => {
        await setActiveOrganization(org);
        await getOrganizationEvents(org.id);
      });
    } else {
      // set pierwsza organizacaj do ktorej ma wjazd ????
      await setActiveOrganization();
    }

    if (isAuthenticated) {
      await getProfile();
      await getProfileDetails();
    }

    const zoomMeeting = document.getElementById('zmmtg-root');
    if (zoomMeeting) {
      zoomMeeting.style.display = 'none';
    }
    browserCompatibility('ie', true);
  }

  closeModal = () => {
    const {
      modalStore: { setVisibility },
    } = this.props;

    setVisibility(false);
  };

  render() {
    const {
      modalStore: {
        isModalActive,
        isLayerShown,
        modalContent,
        fullSize,
        setLayerVisibility,
      },
      eventsStore: { translations },
    } = this.props;

    const messages = {
      de,
      en,
      fr,
    };

    if (translations) {
      Object.keys(translations).forEach(translationLang => {
        messages[translationLang] = StylingUtils.mergeTranslationsJsons([
          messages[translationLang],
          translations[translationLang],
        ]);
      });
    }

    return (
      <IntlProvider locale={lang} messages={messages[lang] || messages.en}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <GlobalStyle />
            {isLayerShown && (
              <ClickableLayer onClick={() => setLayerVisibility(false)} />
            )}
            <Box id="page-container-outer">
              <SideMenu history={history} />
              <Box id="page-container-inner" style={{ maxWidth: '100vw' }}>
                {isModalActive && (
                  <Modal onClose={this.closeModal} fullSize={fullSize}>
                    {modalContent}
                  </Modal>
                )}
                <HeaderBar history={history} />
                <Switch>
                  {/* might want to use other route components in the future (see AuthenticatedRoute.js and UnathenticatedRoute.js) */}
                  <Route
                    exact
                    path={routes.home.base}
                    component={EventListingPage}
                  />
                  <Route
                    path={`${routes.home.event}/:event_name?`}
                    component={Home}
                  />
                  <Route path={routes.home.event} component={Home} />
                  <Route exact path={routes.home.base} component={Home} />
                  <Route
                    path={`${routes.auth.signIn}/:event_id?`}
                    component={SignInPage}
                  />
                  <Route path={routes.home.tandc} component={TermsPage} />
                  <Route path={routes.auth.signIn} component={SignInPage} />
                  <Route
                    path={`${routes.auth.signUp}/:event_id/:attendance`}
                    component={SignUpPage}
                  />
                  <Route
                    path={`${routes.auth.signUp}/:event_id?`}
                    component={SignUpPage}
                  />
                  <Route path={routes.auth.signUp} component={SignUpPage} />
                  <Route
                    exact
                    path={`${routes.auth.resetPasswordInit}/:event_id`}
                    component={ResetPasswordPage}
                  />
                  <Route
                    exact
                    path={routes.auth.resetPasswordInit}
                    component={ResetPasswordPage}
                  />
                  <Route
                    path={`${routes.auth.resetPasswordFinish}/:event_id`}
                    component={ResetPasswordFinishPage}
                  />
                  <Route
                    path={routes.auth.resetPasswordFinish}
                    component={ResetPasswordFinishPage}
                  />

                  <Route path={routes.error} exact component={ErrorScreen} />

                  <Route path="/404" exact component={NotFoundPage} />
                  <Route path="/:whatever" exact component={NotFoundPage} />
                </Switch>
                <CookiesSettings />
              </Box>
            </Box>
          </Router>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default App;
