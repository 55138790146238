/* eslint-disable */
import React from 'react';

export const DocsIcon = ({ fill, ...props }) => (
  <svg width="19px" height="22px" viewBox="0 0 19 22" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Docs" transform="translate(-10.000000, -1.000000)">
        <g id="Group-2" transform="translate(11.000000, 2.000000)">
          <path d="M4.05,4.125 L12.15,4.125 C12.5973506,4.125 12.96,4.48764935 12.96,4.935 L12.96,4.965 C12.96,5.41235065 12.5973506,5.775 12.15,5.775 L4.05,5.775 C3.60264935,5.775 3.24,5.41235065 3.24,4.965 L3.24,4.935 C3.24,4.48764935 3.60264935,4.125 4.05,4.125 Z M4.05,9.075 L12.15,9.075 C12.5973506,9.075 12.96,9.43764935 12.96,9.885 L12.96,9.915 C12.96,10.3623506 12.5973506,10.725 12.15,10.725 L4.05,10.725 C3.60264935,10.725 3.24,10.3623506 3.24,9.915 L3.24,9.885 C3.24,9.43764935 3.60264935,9.075 4.05,9.075 Z M4.05,14.025 L10.53,14.025 C10.9773506,14.025 11.34,14.3876494 11.34,14.835 L11.34,14.865 C11.34,15.3123506 10.9773506,15.675 10.53,15.675 L4.05,15.675 C3.60264935,15.675 3.24,15.3123506 3.24,14.865 L3.24,14.835 C3.24,14.3876494 3.60264935,14.025 4.05,14.025 Z" id="Combined-Shape" fill={fill || '#fff'}></path>
          <rect id="Rectangle-5" stroke={fill || '#fff'} strokeWidth="1.8" x="0" y="0" width="16.2" height="19.8" rx="1.62"></rect>
        </g>
      </g>
    </g>
  </svg>
);
