export default {
  auth: {
    signIn: '/sign-in',
    signUp: '/sign-up',
    resetPasswordInit: '/reset-password',
    resetPasswordFinish: '/reset-password-finish',
  },
  home: {
    base: '/',
    event: '/event',
    tandc: '/terms/tandc',
  },
  error: '/error',
};
