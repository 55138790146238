/* eslint-disable */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedDate } from 'react-intl';
import styled from '@emotion/styled';
import { Flex, Box, Text } from 'rebass';
import { css } from 'styled-components';
import moment from 'moment';
import get from 'lodash/get';
import theme from '../../../../_styles/theme';
import StylingUtils from '../../../../_app/utils/stylingUtils';
import { getLocalTimezone } from '../../../../_app/utils/dateTimeUtils';
import { MOBILE_BREAKPOINT } from '../../../../_styles/breakpoints';
import { AGENDA_ITEM_TYPES } from '../../../../_app/constants';

const Wrapper = ({ children, style, isOnEventBrief, ...props }) => {
  return (
    <Box
      css={css`
        color: ${style ? style.text : '#fff'};
        position: relative;
        width: 240px;
        padding: ${props =>
          props.mobile ? '10px 27px 10px 43px' : '10px 12px 10px 30px'};
        margin-top: ${props => (props.idx === 0 ? '0' : '20')}px;
        ${StylingUtils.animationFadeIn()}

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  );
};

const Content = styled(Box)`
  position: relative;
  z-index: 2;
`;

const EventInfo = styled(Text)`
  text-transform: uppercase;
  line-height: 1.2em;
`;

const TimeLineDot = styled(Box)`
  position: absolute;
  width: 8px;
  height: 8px;
  left: ${props => (props.mobile ? '-30' : '-28')}px;
  top: 4px;
  border-radius: 50%;
  opacity: ${props => props.opacity || 1};
  background-color: ${props => props.color || theme.colors.agendaTimeline};
`;

const Separator = ({ children, color }) => (
  <Flex
    css={css`
      width: 2px;
      background-color: ${color || '#566064'};
      margin: 0 5px;
    `}
  >
    {children}
  </Flex>
);

const BorderBox = styled(Box)`
  margin-top: 10px;
  width: 100%;
  border-bottom: 1px solid ${props => props.bordercolor};
`;

const DayBreaker = inject(
  'homeStore',
  'eventBriefStore',
)(
  observer(
    ({
      agendaItem,
      agendaItemStyle,
      dayAgendaItems,
      idx,
      days,
      isMobile,
      homeStore: { activeEvent },
      eventBriefStore: { event },
      ...props
    }) => {
      const tz = getLocalTimezone();

      const { agenda_type: agendaType } = agendaItem || {};

      let day = null;
      if (days.length > 0 && dayAgendaItems) {
        day =
          days.indexOf(
            `${moment(dayAgendaItems[0].start_date).format('DD-MM-YYYY')}`,
          ) + 1;
      }

      let eventName = null;
      if (activeEvent) {
        eventName = activeEvent.name;
      } else if (event) {
        eventName = event.name;
      }

      return (
        <>
          {(agendaType === undefined ||
            agendaType === AGENDA_ITEM_TYPES.REGULAR) && (
            <Wrapper
              style={agendaItemStyle}
              idx={idx}
              mobile={isMobile}
              {...props}
            >
              <Content>
                <TimeLineDot
                  color={get(agendaItemStyle, 'dot')}
                  mobile={isMobile}
                />
                {eventName && day && (
                  <EventInfo
                    color={
                      agendaItemStyle.buttonsBg ||
                      agendaItemStyle.text ||
                      '#fff'
                    }
                    fontSize={2}
                    fontWeight="bold"
                    letterSpacing={1}
                  >
                    {eventName} - DAY {day}
                  </EventInfo>
                )}

                <Text
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                    fontSize: isMobile ? '12px' : '10px',
                  }}
                >
                  <FormattedDate
                    value={moment(dayAgendaItems[0].start_date).toDate()}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                  <Separator color={agendaItemStyle.text || '#fff'} />
                  {moment(dayAgendaItems[0].start_date).format('HH:mm')}
                  &nbsp;-&nbsp;
                  {moment(dayAgendaItems[dayAgendaItems.length - 1].end_date).format('HH:mm')}
                  &nbsp;
                  {tz}
                </Text>
                <BorderBox
                  bordercolor={
                    agendaItemStyle.buttonsBg || agendaItemStyle.text || '#fff'
                  }
                />
              </Content>
            </Wrapper>
          )}
        </>
      );
    },
  ),
);

export default DayBreaker;
