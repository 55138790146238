import React from 'react';
import { Flex, Text } from 'rebass';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import CameraIcon from '../../_assets/icons/cameraIcon';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import theme from '../../_styles/theme';
import WebcamPopup from '../WebcamPopup';

const WebcamBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${props =>
      props.active ? theme.colors.textDark : theme.colors.textSemiVisible};
  border-radius: 3px;
  width: 172px;
  height: 74px;
  padding: 17px;
  cursor: pointer;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 10px;
  }
`;

const WebcamBoxText = styled(Text)`
  color: ${props =>
    props.active ? theme.colors.textDark : theme.colors.textSemiVisible};
  font-size: 10px;
`;

const MakeProfileImage = ({ colors, input, asModal, modalStore }) => {
  const [webcamOpened, setWebcamOpened] = React.useState(false);
  const { setVisibility } = modalStore;

  const handleWebcamOpen = () => {
    if (asModal) {
      setVisibility(
        true,
        <WebcamPopup
          colors={colors}
          input={input}
          asModal
          toggleWebcam={setVisibility}
        />,
      );
    } else setWebcamOpened(true);
  };

  return (
    <>
      <WebcamBox onClick={handleWebcamOpen}>
        <CameraIcon
          fill={theme.colors.textSemiVisible}
          width={32}
          height={40}
          style={{ flexShrink: 0 }}
        />
        <WebcamBoxText ml={13}>
          <FormattedMessage id="settings.field.image.makephoto" />
        </WebcamBoxText>
      </WebcamBox>
      {webcamOpened && (
        <WebcamPopup
          colors={colors}
          input={input}
          toggleWebcam={setWebcamOpened}
        />
      )}
    </>
  );
};

export default inject('modalStore')(observer(MakeProfileImage));
