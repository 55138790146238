import styled from '@emotion/styled';
import React from 'react';
import { Image, Box } from 'rebass';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StylingUtils from '../_app/utils/stylingUtils';

const StyledImage = styled(Image)`
  max-height: 100%;
  max-width: 100%;
`;

const ImageWrapper = styled(Box)`
  max-width: 100px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
`;

const IconWrapper = styled(Box)`
  color: #a94442;
  position: absolute;
  cursor: pointer;
  top: -5px;
  right: -5px;
  :hover {
    color: ${StylingUtils.hexToRgbA('#a94442', 0.7)};
  }
`;

const AvatarPreview = ({ input }) => {
  const [preview, setPreview] = React.useState(null);

  const { value: fileProp, onChange } = input;

  React.useEffect(() => {
    if (fileProp.length) {
      const reader = new FileReader();

      reader.onload = e => {
        setPreview(e.target.result);
      };

      reader.readAsDataURL(fileProp[0]);
    } else setPreview(null);
  }, [fileProp]);

  return (
    preview && (
      <ImageWrapper>
        <StyledImage src={preview} alt="" />
        <IconWrapper>
          <HighlightOffIcon onClick={() => onChange([])} />
        </IconWrapper>
      </ImageWrapper>
    )
  );
};

export default AvatarPreview;
