import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import { css } from 'styled-components';
import theme from '../../_styles/theme';
import { DefaultAvatar } from '../../_assets/icons/defaultAvatar';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { Avatar } from '../HeaderBar/HeaderBar';
import { AddUserIcon } from '../../_assets/icons/addUserIcon';

const ChatUserBox = ({ onClick, isUserSelected, chatStyle, children }) => {
  const { bgColor, text, bgColorActive } = chatStyle || {};
  return (
    <Flex
      onClick={onClick}
      css={css`
        align-items: center;
        justify-content: center;
        width: calc(100% - 8px);
        margin-bottom: 7px;
        padding: 14px 20px 14px 13px;
        background-color: ${isUserSelected
          ? bgColorActive || theme.colors.chatMsgActive
          : bgColor || theme.colors.bgNavyBlue};
        color: ${text || '#fff'};
        cursor: pointer;

        border-radius: 10px;

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
        }
      `}
    >
      {children}
    </Flex>
  );
};

const AddUserWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  padding: 36px 28px 35px 26px;
  margin: -14px -20px -14px -13px;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
`;

const chatRoomAvatarCss = {
  borderRadius: `50%`,
  width: '42px',
  height: '42px',
  minWidth: 'auto',
};

const AvatarWrapper = ({ onClick, children, ...props }) => (
  <Flex
    css={css`
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      min-width: 42px;
    `}
    onClick={onClick}
    {...props}
  >
    {children}
  </Flex>
);

const ChatUserBoxMain = ({ children, marginLeft, marginRight, ...props }) => (
  <Flex
    css={css`
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: ${marginLeft};
      margin-right: ${marginRight};
      min-height: 63px;
      overflow-y: hidden;
      cursor: pointer;
    `}
    {...props}
  >
    {children}
  </Flex>
);

const ContentText = ({ children }) => (
  <Box
    css={css`
      padding-left: 10px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      font-size: ${theme.fontSizes[0]}px;
      width: 100%;
    `}
  >
    {children}
  </Box>
);

export const ChatUser = ({
  participant,
  chatStyle,
  chatRoomAvatar,
  activeChatRoomId,
  chatRoomId,
  addColor: chatRoomColor,
}) => {
  const { avatarBckg } = chatStyle || {};

  const isUserSelected = chatRoomId === activeChatRoomId;

  return (
    <ChatUserBox chatStyle={chatStyle} isUserSelected={isUserSelected}>
      <AvatarWrapper className="avatar">
        {chatRoomAvatar && (
          <Avatar
            borderColor={chatRoomColor}
            src={chatRoomAvatar}
            customStyles={{
              height: '42px',
              width: '42px',
            }}
          />
        )}
        {!chatRoomAvatar && (
          <DefaultAvatar
            width="25px"
            height="25px"
            fill={avatarBckg}
            style={chatRoomAvatarCss}
          />
        )}
      </AvatarWrapper>
      <ChatUserBoxMain marginLeft="12px" marginRight={0}>
        <ContentText>
          <Text
            fontSize={1}
            fontWeight={theme.fontWeights.bold}
            lineHeight={1.42}
            letterSpacing="-0.29px"
          >
            {participant.first_name} {participant.last_name}
          </Text>
        </ContentText>
      </ChatUserBoxMain>
      <AddUserWrapper bg={chatStyle.chatButtonsBg} className="addUser">
        <AddUserIcon width={20} height={20} fill={chatStyle.buttonsTextColor} />
      </AddUserWrapper>
    </ChatUserBox>
  );
};
