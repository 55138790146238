import validator from 'validator';
import { createIntl } from 'react-intl';
import de from '../translations/de.json';
import en from '../translations/en.json';
import fr from '../translations/fr.json';

const messages = {
  de,
  en,
  fr,
};

const language = (
  window.cplusLang ||
  navigator.browserLanguage ||
  navigator.language
).split(/[-_]/)[0]; // language without region code

const intl = createIntl({
  locale: language,
  messages: messages[language] || messages.en,
});
export const composeValidators = (...validators) => (value, values) =>
  validators.reduce(
    (error, _validator) => error || _validator(value, values),
    undefined,
  );

export const passwordsMustMatch = (value, allValues) =>
  value === allValues.password
    ? undefined
    : intl.formatMessage({ id: 'validator.passmatch' });

export const required = value =>
  value ? undefined : intl.formatMessage({ id: 'validator.required' });

export const mustBeValidEmail = value =>
  validator.isEmail(value)
    ? undefined
    : intl.formatMessage({ id: 'validator.emailinvalid' });

export const mustBeAtLeast = atLeast => (value = 0) =>
  value && value.length >= atLeast
    ? undefined
    : intl.formatMessage({ id: 'validator.tooshort' }, { atLeast });

export const passwordMustBeStrength = pass => {
  let passwordStrength = 0;
  if (pass.match(/[a-z]/)) {
    passwordStrength += 1;
  }
  if (pass.match(/[A-Z]/)) {
    passwordStrength += 1;
  }
  if (pass.match(/\d/)) {
    passwordStrength += 1;
  }
  if (pass.match(/\W/)) {
    passwordStrength += 1;
  }
  if (pass && pass.length >= 8) {
    passwordStrength += 1;
  }

  return passwordStrength < 5
    ? 'Complexity requirement not met. Please use: 1 uppercase, 1 lowercase, 1 digit and 1 special character'
    : undefined;
};
