import React, { Component } from 'react';
import styled from '@emotion/styled';
import { css } from 'styled-components';
import { Flex, Text, Image } from 'rebass';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import Box from '@material-ui/core/Box';
import theme from '../../../_styles/theme';
import StylingUtils from '../../../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';
import { DefaultAvatar } from '../../../_assets/icons/defaultAvatar';
import { LS_KEYS } from '../../../_app/utils/LocalStorageManager';
import { API } from '../../../_app/api';
import { API_ROUTES } from '../../../_app/api_routes';

const Card = styled(Box)`
  position: relative;
  background-color: ${theme.colors.card};
  border-radius: 10px;
  overflow: hidden;
  width: 420px;

  box-shadow: 0 14px 16px -6px rgba(0, 0, 0, 0.43);

  ${StylingUtils.animationFadeIn()}
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const CardInner = styled(Box)`
  padding: 30px;
`;

const Name = styled(Text)`
  font-weight: ${theme.fontWeights.heading};
`;

const ReportButton = styled(Box)`
  background-color: #eb2121;
  padding: 15px 0;
  color: #fff;
  flex: 1;
  text-align: center;
  cursor: pointer;
`;

const CancelButton = styled(Box)`
  padding: 15px 0;
  color: #eb2121;
  flex: 1;
  text-align: center;
  cursor: pointer;
`;

const ReportedMessageOuter = styled(Box)`
  border-radius: 3px;
  background-color: ${theme.colors.textSecondary};
  display: flex;
  color: ${theme.colors.textDark};
  margin-top: 15px;
`;

const ReportedMessageInner = styled(Box)`
  display: flex;
  padding: 10px;
  max-height: 300px;
  overflow: auto;

  span.tag {
    color: ${theme.colors.textDark};
  }
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  border-top: 1px solid #eb2121;
`;

const ModalText = styled(Text)`
  color: ${theme.colors.textDark};
  font-size: ${theme.fontSizes[4]};
  font-weight: ${theme.fontWeights.heading};
`;

const ErrorMessage = styled(Text)`
  margin-top: 10px;
  color: #eb2121;
  font-size: ${theme.fontSizes[1]};
`;

const avatarCss = {
  borderRadius: `50%`,
  width: '42px',
  height: '42px',
  minWidth: 'auto',
  border: `1px solid ${theme.colors.borderLight}`,
};

@inject('modalStore')
@observer
class ReportsModal extends Component {
  state = {
    failure: false,
  };

  handleReport = async () => {
    try {
      const {
        msg: { id: messageId },
        onClose,
      } = this.props;

      const { [LS_KEYS.ANONYM_CHAT_ID]: anonChatId } = Cookies.get();

      await API.post(
        `${API_ROUTES.CHAT_MSG}/${messageId}/report_abuse`,
        anonChatId ? { anonymous_chat_user_id: anonChatId } : {},
      );
      this.setState({ failure: false });
      onClose();
    } catch (e) {
      this.setState({ failure: true });
    }
  };

  render() {
    const {
      onClose,
      msg,
      avatarBckg,
      anonName,
      msg: { message },
    } = this.props;

    const { failure } = this.state;

    const {
      first_name: firstName,
      last_name: lastName,
      avatar_chat_thumb_url: avatarUrl,
    } = msg.user || {};

    return (
      <Card>
        <Flex flexDirection="column" justifyContent="space-between">
          <CardInner>
            <ModalText>
              <FormattedMessage id="chat.report.heading" />
            </ModalText>
            <ReportedMessageOuter>
              <ReportedMessageInner>
                {avatarUrl && (
                  <Image
                    src={avatarUrl}
                    css={css`
                      border-radius: 50%;
                      width: 42px;
                      height: 42px;
                      min-width: auto;
                      border: 1px solid ${theme.colors.borderLight};
                      flex-shrink: 0;
                    `}
                  />
                )}
                {!avatarUrl && (
                  <DefaultAvatar
                    fill={avatarBckg}
                    style={{ ...avatarCss, cursor: 'pointer', flexShrink: 0 }}
                  />
                )}
                <Box ml="10px">
                  <Name>
                    {msg.user && `${firstName} ${lastName}`}
                    {anonName && anonName}
                  </Name>
                  <Text mt="5px">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message,
                      }}
                    />
                  </Text>
                </Box>
              </ReportedMessageInner>
            </ReportedMessageOuter>
            {failure && (
              <ErrorMessage>
                <FormattedMessage id="chat.report.error" />
              </ErrorMessage>
            )}
          </CardInner>

          <ButtonWrapper>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <ReportButton onClick={this.handleReport}>Report</ReportButton>
          </ButtonWrapper>
        </Flex>
      </Card>
    );
  }
}

export default ReportsModal;
