import styled from '@emotion/styled';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import get from 'lodash/get';
import { inject } from 'mobx-react';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Box, Flex } from 'rebass';
import { css } from 'styled-components';
import { Loader } from '../../components/Loader';
import StreamFrame from '../../components/StreamFrame';
import { toHex } from '../../_app/utils/otherUtils';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
// import { ReactionsBar } from '../StreamReactions/ReactionsBar';
import { OverlaySelectBar } from '../StreamOverlays/OverlaySelectBar';
import { ReactionsList } from '../StreamOverlays/ReactionsList';
import { StreamOverlay } from '../StreamOverlays/StreamOverlay';

const StreamContainer = ({
  children,
  minimized,
  customRatioPadding,
  bckg,
  isMobile,
  ...props
}) => (
  <Box
    id="stream-container"
    css={css`
      position: relative;
      width: ${customRatioPadding === '110%' ? '64%' : '100%'};
      padding-bottom: ${customRatioPadding || '56.25%'};
      background-color: ${bckg || '#000'};
      overflow: hidden;

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        height: 100%;
        box-shadow: none;
      }

      ${minimized
        ? `
          width: 117px;
          padding-bottom: 56.25%;
          height: 66px;

           animation-name: stream-minimize;
            animation-duration: 0.3s;

            @keyframes fadeIn {
                from {
                  width: 100%;
                }
                to {
                  width: 117px;
                }
              }
      `
        : ``}
    `}
    {...props}
  >
    {children}
  </Box>
);

const LoaderWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

const IFrameWrapper = styled(Box)`
  position: absolute;
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
  z-index: 1;
`;

const CallOutBox = styled('a')`
  display: flex;
  background-color: #fff;
  width: 200px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  color: ${props => props.color || '#000'};
  padding: 8px 0 8px 8px;
  font-size: 14px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
`;

@inject('homeStore')
class Stream extends React.Component {
  constructor(props) {
    super(props);
    // this.iframewrapper = React.createRef();
    this.state = {
      showOverlay: false,
      selectedOverlay: null,
      streamSize: {
        height: 0,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const elem = document.getElementById('stream-container');
    if (elem) {
      const sizes = elem.getBoundingClientRect();
      this.setState({
        streamSize: { height: sizes.height, width: sizes.width },
      });
    }
  };

  handleSelectOverlay = type => {
    const { selectedOverlay } = this.state;

    if (selectedOverlay === type) {
      this.setState({ selectedOverlay: null });
    } else {
      this.setState({ selectedOverlay: type });
    }
  };

  handleCloseOverlay = () => {
    this.setState({ selectedOverlay: null });
  };

  handleReactionClick = type => {
    const {
      homeStore: { sendVideoReaction },
    } = this.props;

    sendVideoReaction({ type });
  };

  clickControl = async () => {
    const noClick = () => {
      return new Promise(() => {
        setTimeout(() => {
          this.setState({ showOverlay: true });
          return true;
        }, 1500);
      });
    };
    await noClick();
  };

  render() {
    const {
      homeStore: { activeEvent, activeAgendaItem },
      isLoading,
      isMobile,
      isArchived,
      minimized,
      videoUrl,
      openAsVideoElement,
      exploreItem,
      exploreItemStyle,
      user,
      videoReactions,
      avatarBg,
    } = this.props;

    const { showOverlay, selectedOverlay, streamSize } = this.state;

    const { buttonsBg } = exploreItemStyle;

    const iframeBreakoutRoom =
      activeAgendaItem &&
      activeAgendaItem.group &&
      !activeAgendaItem.group.open_in_new_window;
    const { body1 } = (activeEvent && activeEvent.event_custom_theme) || {};

    let urlWithParams = exploreItem?.url;

    if (user && urlWithParams && activeEvent) {
      urlWithParams = urlWithParams.replace('%%ID%%', user?.id);
      if (user.attendances && user.attendances.length > 0) {
        const filteredAttendances = user.attendances.filter(
          item => item.event_id === activeEvent.id,
        );

        if (filteredAttendances.length > 0) {
          urlWithParams = urlWithParams.replace(
            '%%ATTENDANCEID%%',
            filteredAttendances[0].id,
          );
        }
      }
    }

    return (
      <StreamContainer
        minimized={minimized}
        bckg={isMobile && body1}
        customRatioPadding={
          !isMobile && get(activeEvent, 'player.vertical') && '110%' // should be 177.78%  to be exactly 9:16
        }
      >
        <OverlaySelectBar
          onOverlaySelect={this.handleSelectOverlay}
          selectedColor={buttonsBg}
          selectedOverlay={selectedOverlay}
          activeEvent={activeEvent}
          streamSize={streamSize}
        />
        <CSSTransition
          classNames="stream-overlay-animation"
          unmountOnExit
          in={selectedOverlay !== null}
          timeout={300}
        >
          <StreamOverlay
            selectedOverlay={selectedOverlay}
            streamSize={streamSize}
            handleCloseOverlay={this.handleCloseOverlay}
            handleReactionClick={this.handleReactionClick}
          />
        </CSSTransition>
        <ReactionsList
          videoReactions={videoReactions}
          isMobile={isMobile}
          reactionBorderColor={avatarBg}
          streamSize={streamSize}
        />
        {showOverlay && (
          <IFrameWrapper
            onContextMenu={e => {
              e.preventDefault();
            }}
            onMouseDown={e => {
              switch (e.button) {
                case 0:
                  this.setState({ showOverlay: false });
                  break;
                case 1:
                  return false;
                case 2:
                  e.preventDefault();
                  return false;
                default:
                  break;
              }
              this.clickControl();
              return false;
            }}
          />
        )}
        {activeEvent && (
          <StreamFrame
            minimized={minimized}
            activeEvent={activeEvent}
            iframeBreakoutRoom={iframeBreakoutRoom}
            activeAgendaItem={activeAgendaItem}
            timestamp={activeAgendaItem && activeAgendaItem.timestamp}
            isMobile={isMobile}
            isArchived={isArchived}
            videoUrl={videoUrl}
            openAsVideoElement={openAsVideoElement}
          />
        )}
        {exploreItem && exploreItem.show_call_out_box && (
          <CallOutBox
            bgColor={toHex(exploreItem.bg_color) || '#fff'}
            color={exploreItemStyle.text || '#000'}
            href={urlWithParams}
            target={exploreItem.open_in_new_tab ? '_blank' : '_self'}
          >
            <Flex
              css={css`
                align-items: center;
                width: 85%;
                margin-right: 5px;
              `}
            >
              {exploreItem.call_out_text}
            </Flex>
            <Flex
              css={css`
                width: 15%;
              `}
            >
              <OpenInNewIcon
                style={{
                  color: exploreItemStyle.buttonsBg || '#000',
                }}
              />
            </Flex>
          </CallOutBox>
        )}
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </StreamContainer>
    );
  }
}

export default Stream;
