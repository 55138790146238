/* eslint-disable */
import React from 'react';

export const PollIcon = ({ fill, ...props }) => (
  <svg
    width="37px"
    height="25px"
    viewBox="0 0 45 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="graph"
      transform="translate(8.000000, 0.000000)"
      fill="none"
      stroke={fill || '#FFF'}
      strokeWidth="2.5"
    >
      <path
        d="M6.12371889,9 L0.876281113,9 C0.394582723,9 0.00512445095,9.39283253 0,9.88387319 L0,23.1161268 C0,23.6071675 0.394582723,24 0.876281113,24 L6.12371889,24 C6.61054173,24 7,23.6019986 7,23.1161268 L7,9.88387319 C7,9.39283253 6.60541728,9 6.12371889,9 Z"
        id="Path"
      />
      <path
        d="M16.1237189,0 L10.8762811,0 C10.3945827,0 10,0.381669805 10,0.853735091 L10,23.1412429 C10,23.6183302 10.3945827,24 10.8762811,24 L16.1237189,24 C16.6105417,24 17,23.6133082 17,23.1412429 L17,0.858757062 C17,0.381669805 16.6054173,0 16.1237189,0 Z"
        id="Path"
      />
      <path
        d="M26.1237189,6 L20.8762811,6 C20.3894583,6 20,6.40070299 20,6.90158172 L20,23.0984183 C20,23.599297 20.3945827,24 20.8762811,24 L26.1237189,24 C26.6105417,24 27,23.5940246 27,23.0984183 L27,6.90158172 C27,6.40070299 26.6054173,6 26.1237189,6 Z"
        id="Path"
      />
    </g>
  </svg>
);
