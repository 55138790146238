import React from 'react';
import styled from '@emotion/styled';
import { css } from 'styled-components';
import { Flex, Button as RebassButton } from 'rebass';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import theme from '../../_styles/theme';
import StylingUtils from '../../_app/utils/stylingUtils';
import { toHex } from '../../_app/utils/otherUtils';

const Container = styled(Flex)`
  background-color: ${props => props.bgColor || '#fff'};
  color: black;
  padding: 8px;
  border-radius: 5px;
  margin: ${props => (props.isMobile ? '0px 20px' : '0 12px 0 0')};
  }
`;

const LeftCol = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  height: 100%;
  margin-right: 5px;
`;

const MidCol = styled(Flex)`
  flex-direction: column;
  width: 60%;
  height: 100%;
`;

const RightCol = styled(Flex)`
  height: 100%;
`;

const Img = styled('img')`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Button = ({ bgColor, children, ...props }) => (
  <RebassButton
    type="button"
    css={css`
      width: 100%;
      margin-top: 10px;
      outline: none;
      color: white;
      background-color: ${bgColor || theme.colors.primary};
      border-radius: 5px;
      border: 0px;
      justify-content: center;
      align-items: center;
      font-weight: ${theme.fontWeights.emph};
      &:hover {
        background-color: ${StylingUtils.hexToRgbA(
          bgColor || theme.colors.primary,
          0.74,
        )};
        cursor: pointer;
      }
    `}
    {...props}
  >
    {children}
  </RebassButton>
);

const Title = styled(Flex)`
  color: ${props => props.color || '#000'}
  width: 100%;
  font-weight: ${theme.fontWeights.emph};
  font-size: 14px;
  margin-bottom: 5px;
`;

const Description = styled(Flex)`
  color: ${props => props.color || '#000'};
  width: 100%;
  font-size: 12px;
  line-height: 1.2;
`;

const Link = styled('a')`
  width: 100%;
`;

export const ExploreItem = ({
  activeEvent,
  exploreItem,
  exploreItemStyle,
  isMobile,
  user,
}) => {
  const {
    button_text: buttonText,
    bg_color: containerBg,
    image: { url: imageUrl },
    url,
    title,
    description,
    open_in_new_tab: openInNewTab,
  } = exploreItem;

  const { buttonsBg, text } = exploreItemStyle;

  let urlWithParams = url;
  if (user && activeEvent) {
    urlWithParams = url.replace('%%ID%%', user?.id);
    if (user.attendances && user.attendances.length > 0) {
      const filteredAttendances = user.attendances.filter(
        item => item.event_id === activeEvent.id,
      );

      if (filteredAttendances.length > 0) {
        urlWithParams = urlWithParams.replace(
          '%%ATTENDANCEID%%',
          filteredAttendances[0].id,
        );
      }
    }
  }

  return (
    <Container bgColor={toHex(containerBg) || '#fff'} isMobile={isMobile}>
      <LeftCol>
        <Img src={imageUrl} />
        <Link href={urlWithParams} target={openInNewTab ? '_blank' : '_self'}>
          <Button bgColor={buttonsBg}>{buttonText || 'Visit'}</Button>
        </Link>
      </LeftCol>
      <MidCol>
        <Title color={text}>{title}</Title>
        <Description color={text}>
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Description>
      </MidCol>
      <RightCol>
        <OpenInNewIcon
          style={{
            color: buttonsBg || '#000',
          }}
        />
      </RightCol>
    </Container>
  );
};
