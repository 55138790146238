import React from 'react';
import get from 'lodash/get';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { Box, Flex, Image, Text, Link as RebassLink } from 'rebass';
import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import routes from '../../_app/routing/routes';
import theme from '../../_styles/theme';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { LogoutIcon } from '../../_assets/icons/logoutIcon';
import { HelpIcon } from '../../_assets/icons/helpIcon';
import { MenuIcon } from '../../_assets/icons/menuIcon';
import { toHex } from '../../_app/utils/otherUtils';
import Settings from '../../settings';
import { LS_KEYS } from '../../_app/utils/LocalStorageManager';
import { DefaultAvatar } from '../../_assets/icons/defaultAvatar';
import StylingUtils from '../../_app/utils/stylingUtils';
import { MEDIA_URLS } from '../../_app/constants';

const HeaderWrapper = styled(Flex)`
  box-shadow: 0px 6px 9px 6px rgba(4, 4, 4, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: sticky;
  }
`;

const HeaderText = styled(Text)`
  display: inline-block;
  margin-left: 21px;
  font-size: ${theme.fontSizes[3]}px;
  font-weight: ${theme.fontWeights.heading};
  vertical-align: text-top;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 68px);
`;

const HeaderSubtitle = styled('span')`
  font-weight: ${theme.fontWeights.body};
`;

const Logo = styled(Image)`
  animation-name: logo-fadeIn;
  animation-duration: 1s;
  animation-duration: 0.5s;

  @keyframes logo-fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const SignInOut = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
`;

export const SettingsLink = styled(Text)`
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2em;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const LogoutIconWrapper = styled(Box)`
  margin-left: 18px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const AvatarWrapper = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: auto;
  position: relative;
  overflow: hidden;
  border: 1px solid ${({ borderColor }) => borderColor && borderColor};

  &:hover {
    ${({ pointer }) => pointer && 'cursor: pointer;'}
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ customStyles }) => customStyles && customStyles}
`;

export const Avatar = ({ src, ...props }) => (
  <AvatarWrapper {...props}>
    <img src={src} alt="avatar" />
  </AvatarWrapper>
);

@inject(
  'userStore',
  'authStore',
  'homeStore',
  'eventBriefStore',
  'organizationsStore',
  'modalStore',
  'eventsStore',
)
@observer
class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  openProfileEdit = () => {
    const {
      modalStore: { setVisibility },
    } = this.props;

    setVisibility(
      true,
      <Settings onClose={() => setVisibility(false)} />,
      true,
    );
  };

  handleLogout = async () => {
    const {
      history: {
        push,
        location: { search, pathname },
      },
      authStore: { logout },
    } = this.props;
    await logout({});
    const eventSlug = pathname.split('/')[2]; // because cannot access 'match: {params}' from this component
    const isOnRoot = pathname === '/';
    if (!isOnRoot) {
      push({ pathname: `${routes.auth.signIn}/${eventSlug || ''}`, search });
    } else {
      window.location.reload();
    }
  };

  render() {
    const { width } = this.state;
    const {
      userStore: { user, anonUser },
      authStore: { isAuthenticated },
      homeStore: {
        activeOrganization,
        activeEvent,
        activeAgendaItem,
        eventCustomTheme,
        noEventFoundFlag,
      },
      eventBriefStore: {
        event: eventBriefInfo,
        noEventFoundFlag: noEventBriefFoundFlag,
        noUpcomming,
      },
      eventsStore: { events, getClosestEvent },
    } = this.props;
    const avatarUrl = get(user || anonUser, 'avatar.thumb.url');
    const isMobile = width <= MOBILE_BREAKPOINT;

    const eventBriefLogoUrl = get(eventBriefInfo, 'organization_logo.url');
    const theEvent = activeEvent || eventBriefInfo;

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : '#fff';

    let closestFutureEvent;
    if (events.length > 0) closestFutureEvent = getClosestEvent(events);

    let finalTheme;
    if (eventCustomTheme) {
      finalTheme = StylingUtils.mergeThemesArray([
        eventCustomTheme,
        get(activeAgendaItem, 'agenda_item_custom_theme'),
      ]);
    } else if (closestFutureEvent) {
      finalTheme = closestFutureEvent.event_custom_theme;
    }

    const {
      chat_primary: chatPrimary,
      header_text_primary: headerTextPrimary,
      header_bg: header,
      icons: iconsColor,
      header_icons: headerIcons,
      header_logo_width_percent: headerLogoWidthPercent,
      header_profile_border: headerProfileBorder,
      header_font_family: headerFontFamily,
      chat_avatar: chatAvatar,
    } = finalTheme || {};

    const headerColor = header ? toHex(header) : 'bgHeader';
    const titleColor = headerTextPrimary ? toHex(headerTextPrimary) : '#fff';

    const {
      [LS_KEYS.ANONYM_CHAT_NAME]: anonChatName,
      [LS_KEYS.ANONYM_CHAT_USER_AVATAR]: anonAvatar,
    } = Cookies.get();
    const { name: storeAnonName, avatar: storeAnonAvatar } = anonUser || {};

    const finalAnonUsername = storeAnonName || anonChatName;
    const finalAnonAvatar = get(storeAnonAvatar, 'thumb.url') || anonAvatar;
    const isAnonUser = !!finalAnonUsername;

    const isOnEventPage = document.URL.includes('/event');
    const finalNoEventFoundFlag = !noEventBriefFoundFlag && !noEventFoundFlag;
    const isOnRoot = window.location.pathname === '/';
    const isResettingPwd = window.location.pathname.includes('/reset-password');
    if (finalNoEventFoundFlag && !isOnRoot && !isResettingPwd) {
      if (isEmpty(theEvent) && !noUpcomming) {
        return null;
      }
    }
    const avatarBckg = toHex(chatAvatar) || toHex(chatPrimary) || primaryColor;

    return (
      <HeaderWrapper
        width="100%"
        height={65}
        pl={isMobile ? 9 : 29}
        pr={isMobile ? 9 : 19}
        py={10}
        bg={headerColor}
        borderBottom={headerColor ? `none` : theme.borders.header}
        alignItems="center"
      >
        {isMobile ? (
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Box textAlign="left" width={headerLogoWidthPercent / 100 || 2 / 5}>
              <Link to="/">
                {eventBriefLogoUrl ? (
                  <Logo
                    src={eventBriefLogoUrl}
                    sx={{ maxHeight: 45 }}
                    alt={activeOrganization?.name}
                  />
                ) : (
                  activeOrganization &&
                  activeOrganization.logo && (
                    <Logo
                      src={activeOrganization.logo.url}
                      sx={{ maxHeight: 45 }}
                      alt={activeOrganization.name}
                    />
                  )
                )}
              </Link>
            </Box>
            {activeOrganization && activeOrganization.help_url && (
              <RebassLink
                target="_blank"
                width="15px"
                height="15px"
                mr={73}
                mt={-10}
                href={
                  activeOrganization.help_url.match(/^.+@.+\..{2,}$/)
                    ? `${MEDIA_URLS.email(activeOrganization.help_url)}`
                    : `${MEDIA_URLS.website(activeOrganization.help_url)}`
                }
              >
                <HelpIcon
                  width="20px"
                  height="20px"
                  fill={toHex(headerIcons) || '#fff'}
                />
              </RebassLink>
            )}
          </Flex>
        ) : (
          <>
            <Flex width={2 / 5} alignItems="center">
              <Link to="/">
                <MenuIcon fill={toHex(headerIcons) || '#fff'} />
              </Link>

              <HeaderText
                color={titleColor}
                fontFamily={headerFontFamily || theme.fonts.body}
              >
                {theEvent && `${theEvent.name}`}
                <HeaderSubtitle>
                  {theEvent && (
                    <>
                      {' - '}
                      <FormattedDate
                        value={moment(theEvent.planned_start_date).toDate()}
                        day="2-digit"
                        month="short"
                      />
                    </>
                  )}
                </HeaderSubtitle>
              </HeaderText>
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              width={headerLogoWidthPercent / 100 || 1 / 5}
              height="100%"
            >
              <Link to="/">
                {eventBriefLogoUrl ? (
                  <Logo
                    src={eventBriefLogoUrl}
                    alt={activeOrganization?.name}
                    sx={
                      eventBriefLogoUrl.match(/.svg/)
                        ? { height: '36px' }
                        : { maxHeight: '36px' }
                    }
                  />
                ) : (
                  activeOrganization &&
                  activeOrganization.logo && (
                    <Logo
                      src={activeOrganization.logo.url}
                      alt={activeOrganization.name}
                      sx={
                        activeOrganization.logo.url?.match(/.svg/)
                          ? { height: '36px' }
                          : { maxHeight: '36px' }
                      }
                    />
                  )
                )}
              </Link>
            </Flex>
            <Box textAlign="right" width={2 / 5}>
              {isAuthenticated && user && !isAnonUser && (
                <SignInOut>
                  {activeOrganization && activeOrganization.help_url && (
                    <RebassLink
                      mr={18}
                      target="_blank"
                      href={
                        activeOrganization.help_url.match(/^.+@.+\..{2,}$/)
                          ? `${MEDIA_URLS.email(activeOrganization.help_url)}`
                          : `${MEDIA_URLS.website(activeOrganization.help_url)}`
                      }
                    >
                      <HelpIcon
                        width="25px"
                        height="25px"
                        fill={toHex(headerIcons) || '#fff'}
                      />
                    </RebassLink>
                  )}
                  {avatarUrl && (
                    <Avatar
                      src={avatarUrl}
                      pointer
                      borderColor={toHex(headerProfileBorder) || '#ccc'}
                      onClick={this.openProfileEdit}
                      role="img"
                      customStyles={{ marginRight: '18px' }}
                    />
                  )}
                  {!avatarUrl && (
                    <DefaultAvatar
                      fill={avatarBckg}
                      style={{
                        borderRadius: '50%',
                        width: 30,
                        height: 30,
                        marginRight: 18,
                        minWidth: 'auto',
                        border: `2px solid ${toHex(headerProfileBorder) ||
                          '#ccc'}`,
                        cursor: 'pointer',
                      }}
                      onClick={this.openProfileEdit}
                    />
                  )}
                  <SettingsLink
                    color={titleColor}
                    onClick={this.openProfileEdit}
                  >
                    {user.first_name} {user.last_name}
                  </SettingsLink>
                  <LogoutIconWrapper onClick={this.handleLogout}>
                    <LogoutIcon
                      fill={toHex(headerIcons) || toHex(iconsColor)}
                    />
                  </LogoutIconWrapper>
                </SignInOut>
              )}
              {theEvent && isOnEventPage && isAnonUser && (
                <Flex alignItems="center" justifyContent="flex-end">
                  {finalAnonAvatar ? (
                    <Avatar
                      src={finalAnonAvatar}
                      borderColor={toHex(headerProfileBorder) || '#ccc'}
                      onClick={this.openProfileEdit}
                    />
                  ) : (
                    <DefaultAvatar
                      fill={avatarBckg}
                      style={{
                        borderRadius: '50%',
                        width: 30,
                        height: 30,
                        marginRight: 9,
                        minWidth: 'auto',
                        border: `2px solid ${toHex(headerProfileBorder) ||
                          '#ccc'}`,
                        cursor: 'pointer',
                      }}
                      onClick={this.openProfileEdit}
                    />
                  )}
                  <SettingsLink
                    color={titleColor}
                    onClick={this.openProfileEdit}
                  >
                    {finalAnonUsername}
                  </SettingsLink>
                </Flex>
              )}
            </Box>
            {activeOrganization &&
              activeOrganization.help_url &&
              !isAuthenticated && (
                <RebassLink
                  target="_blank"
                  mr={18}
                  ml={18}
                  href={
                    activeOrganization.help_url.match(/^.+@.+\..{2,}$/)
                      ? `${MEDIA_URLS.email(activeOrganization.help_url)}`
                      : `${MEDIA_URLS.website(activeOrganization.help_url)}`
                  }
                >
                  <HelpIcon
                    width="25px"
                    height="25px"
                    fill={toHex(headerIcons) || '#fff'}
                  />
                </RebassLink>
              )}
          </>
        )}
      </HeaderWrapper>
    );
  }
}

export default HeaderBar;
