import React from 'react';
import { Flex, Text } from 'rebass';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import theme from '../../../_styles/theme';

const Container = styled(Flex)`
  flex-direction: column;
`;

export const EventsInfo = ({
  organizationName,
  isPast,
  listing,
  showOrgName,
}) => {
  return (
    <Container mb={2}>
      {showOrgName && organizationName && (
        <Text fontWeight="bold" mb={3}>
          {organizationName}
        </Text>
      )}
      <Text color={listing ? theme.colors.textDark : 'inherit'} mb={3}>
        {isPast ? (
          <FormattedMessage id="menu.headerPast" />
        ) : (
          <FormattedMessage id="menu.header" />
        )}
      </Text>
    </Container>
  );
};
