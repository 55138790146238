import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import theme from '../../../_styles/theme';
import ChevronDown from '../../../_assets/icons/chevronDown';
import { MOBILE_BREAKPOINT } from '../../../_styles/breakpoints';

const StyledSelect = styled(Select)`
  font-family: inherit;
  font-weight: ${theme.fontWeights.emph};
  font-size: 14px;
  display: flex;
  align-items: center;
  ${({ ml }) => ml && `margin-left: ${ml}px`};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 15px !important;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: inherit;
`;

const SelectInput = ({
  iconColor,
  value,
  onChange,
  items,
  name,
  label,
  ...rest
}) => {
  const dropdownIcon = p => (
    <ChevronDown
      {...p}
      height="15px"
      width="20px"
      fill={iconColor}
      style={{ top: 'calc(50% - 7.5px)' }}
    />
  );
  return (
    <StyledSelect
      name={name}
      value={value}
      autoWidth
      onChange={onChange}
      disableUnderline
      displayEmpty
      IconComponent={dropdownIcon}
      renderValue={value === '' ? () => label : undefined}
      {...rest}
    >
      <StyledMenuItem value="">None</StyledMenuItem>
      {Object.entries(items).map(key => (
        <StyledMenuItem value={key[0]} key={`select-networking-${key[0]}`}>
          {key[1]}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default SelectInput;
