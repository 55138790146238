export default class LocalStorageManager {
  static get(arr = []) {
    const obj = {};
    arr.forEach(key => {
      obj[key] = localStorage.getItem(key);
    });
    return obj;
  }
}

export const LS_KEYS = {
  AUTH_TOKEN: 'congress-auth-token',
  CLIENT: 'congress-client',
  UID: 'congress-uid',

  USER_UNIQ_UUID: 'user_uniq_uuid',
  ANONYM_CHAT_NAME: 'anonymous_chat_user_name',
  ANONYM_CHAT_ID: 'anonymous_chat_user_id',
  ANONYM_CHAT_USER_AVATAR: 'anonymous_chat_user_avatar',
};
