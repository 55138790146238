import React from 'react';
import { Box } from '@material-ui/core';
import styled from '@emotion/styled';
// import { Link } from 'rebass';

const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  opacity: 0.6;
`;
export const CookiesSettings = () => {
  return (
    <Container>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {/* <Link href="" className="optanon-show-settings" /> */}
    </Container>
  );
};
