import { Text } from 'rebass';
import { Box } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import theme from '../../_styles/theme';
import { TickIcon } from '../../_assets/icons/tickIcon';
import StylingUtils from '../../_app/utils/stylingUtils';

const Container = styled(Box)``;

const BodyText = styled(Text)`
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
  letter-spacing: 0.3px;

  ${StylingUtils.animationFadeIn()};
`;

// const Bold = styled('span')`
//   font-weight: ${theme.fontWeights.bold};
//   color: ${theme.colors.textDark};
// `;

const Tick = styled('div')`
  margin: 10px 0 28px;
`;

const Header = styled(Box)`
  color: ${theme.colors.textDark};
  font-size: ${theme.fontSizes[6]}px;
  font-weight: ${theme.fontWeights.heading};
  text-transform: uppercase;
  line-height: 37px;
  letter-spacing: 1px;

  max-width: 300px;

  margin-bottom: 18px;
`;

export const SignUpNotPossible = ({ event, primaryColor }) => {
  return (
    <Container>
      <Tick sx={{ width: '63px', height: '63px' }}>
        <TickIcon fill={primaryColor} />
      </Tick>
      <Header>
        <FormattedMessage id="signup.not_possible" />
      </Header>
      <BodyText height="260px">
        <FormattedMessage
          id="signup.not_possible.thankyoumessage"
          values={{
            event: event && event.name,
          }}
        />
      </BodyText>
    </Container>
  );
};
