import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import { Flex, Box, Text, Link } from 'rebass';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import {
  composeValidators,
  required,
  mustBeAtLeast,
  passwordsMustMatch,
} from '../../../_app/formValidators';
import TextInput from '../../../components/_forms/TextInput';
import { Button } from '../../../components/Button/Button';
import theme from '../../../_styles/theme';

const FieldGroup = styled(Box)`
  width: 100%;
  padding: 73px 0;
`;

const ErrorText = styled(Text)`
  color: red;
  line-height: 22px;
`;
const ResetPasswordFinishForm = ({
  onSubmit,
  primaryColor,
  buttonsTextColor,
  isLoading,
  signInRedirectPath,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitErrors }) => (
      <form onSubmit={handleSubmit} style={{ height: '100%' }}>
        <Flex
          height="100%"
          justifyContent="space-between"
          flexDirection="column"
        >
          <FieldGroup>
            <Box>
              <Field
                label={<FormattedMessage id="resetpassword.pass" />}
                name="password"
                id="password"
                validate={composeValidators(required, mustBeAtLeast(8))}
                inputType="password"
                component={TextInput}
                autocomplete="new-password"
              />
            </Box>
            <Box mt="27px">
              <Field
                label={<FormattedMessage id="resetpassword.repeat" />}
                name="password_confirmation"
                id="password_confirmation"
                inputType="password"
                validate={composeValidators(required, passwordsMustMatch)}
                component={TextInput}
                autocomplete="new-password"
              />
            </Box>
            {!isEmpty(submitErrors) && typeof submitErrors[0] === 'string' && (
              <Box mt={3}>
                <ErrorText>{submitErrors[0]}</ErrorText>
              </Box>
            )}
          </FieldGroup>
          <Box>
            <Box width={1} height="53px">
              <Button
                bgColor={primaryColor}
                onClick={handleSubmit}
                color={buttonsTextColor}
                isLoading={isLoading}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                <FormattedMessage id="resetpassword.setpassword.confirm" />
              </Button>
            </Box>
          </Box>
          <Box mt={3} textAlign="center">
            <Link
              fontSize={theme.fontSizes[1]}
              color={primaryColor}
              href={signInRedirectPath}
            >
              <FormattedMessage id="resetpassword.signin" />
            </Link>
          </Box>
        </Flex>
      </form>
    )}
  />
);

ResetPasswordFinishForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordFinishForm;
