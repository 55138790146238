import React from 'react';
import { Flex, Box, Image } from 'rebass';
import styled from '@emotion/styled';
import { CloseIcon } from '../../_assets/icons/close';

const ModalBody = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const CloseBox = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const Img = styled(Image)``;

const BigImageModal = ({ imageUrl, onClose }) => {
  return (
    <ModalBody onClick={onClose}>
      <Img src={imageUrl} />
      <CloseBox onClick={onClose}>
        <CloseIcon />
      </CloseBox>
    </ModalBody>
  );
};

export default BigImageModal;
