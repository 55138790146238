import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { Field, Form } from 'react-final-form';
import { Flex, Box, Text } from 'rebass';
import styled from '@emotion/styled';
import { Button } from '../components/Button/Button';
import { ButtonLink } from '../components/Button/ButtonLink';
import Checkbox from '../components/_forms/Checkbox';
import CheckboxTag from '../components/_forms/CheckBoxTag';
import theme from '../_styles/theme';
import { PhotosOptions } from '../settings/EditProfileForm';
import AvatarPreview from '../components/AvatarPreview';
import MakeProfileImage from '../components/_forms/MakeProfileImage';
import { ImageUploadInput } from '../components/_forms/ImageUploadInput';

const ErrorText = styled(Text)`
  color: red;
`;
const Separator = styled(Box)`
  margin-top: 15px;
  border-top: 1px solid ${theme.colors.borderSemiLight};
`;

export const InterestsDescription = ({ description }) => {
  return (
    <Separator>
      <Text
        mt={15}
        mb={18}
        fontSize={theme.fontSizes[2]}
        color={theme.colors.textDarkSecondary}
      >
        <FormattedMessage id={description} />
      </Text>
    </Separator>
  );
};

const UserInterestsForm = ({
  onSubmit,
  onSkip,
  primaryColor,
  buttonsTextColor,
  initialValues,
  event,
  isLoading,
}) => {
  const isIOSChromeFirefox = /(iPhone|iPad|iPod).*(FxiOS|CriOS)/i.test(
    navigator.userAgent,
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitError }) => (
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <Flex
            height="100%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box mb={36}>
              <Box mt={10} mb={36}>
                <Text
                  mt={15}
                  mb={18}
                  fontSize={theme.fontSizes[2]}
                  color={theme.colors.textDarkSecondary}
                >
                  <FormattedMessage id="settings.field.image.uploadlabel" />
                </Text>
                <Box mt="15px" mb="10px">
                  <Field name="user.avatar" component={AvatarPreview} />
                </Box>
                <PhotosOptions mt="15px" flexWrap="wrap">
                  <Field
                    label={
                      <FormattedMessage id="settings.field.image.uploadlabel" />
                    }
                    name="user.avatar"
                    type="text"
                    asDropzone
                    width="100%"
                    component={ImageUploadInput}
                  />
                  {!isIOSChromeFirefox && (
                    <Field
                      label={
                        <FormattedMessage id="settings.field.image.uploadlabel" />
                      }
                      name="user.avatar"
                      type="text"
                      width="100%"
                      asModal
                      colors={{ buttonsTextColor, buttonsBg: primaryColor }}
                      component={MakeProfileImage}
                    />
                  )}
                </PhotosOptions>
              </Box>
              <Box mt={10}>
                <Field
                  name="user.event_registation_details.networking_enable"
                  checkboxContent={
                    <Text
                      ml="2px"
                      fontSize={theme.fontSizes[2]}
                      color={theme.colors.textDarkSecondary}
                    >
                      <FormattedMessage id="userInterests.checkbox" />
                    </Text>
                  }
                  type="checkbox"
                  component={Checkbox}
                  simpleCheckbox
                />
                <Field
                  name="user.event_registation_details.event_id"
                  type="hidden"
                  component="input"
                />
              </Box>
              {!isEmpty(event.interests) && (
                <>
                  <InterestsDescription description="userInterests.description" />
                  <Flex flexWrap="wrap">
                    {event.interests.map(interest => {
                      return (
                        <Box key={`interest-${interest.id}`}>
                          <Field
                            id={interest.id}
                            name="user.event_registation_details.interest_ids"
                            value={interest.id}
                            checkboxContent={
                              <Text
                                fontSize={theme.fontSizes[3]}
                                fontWeight={theme.fontWeights.emph}
                              >
                                {interest.name}
                              </Text>
                            }
                            type="checkbox"
                            mainColor={primaryColor}
                            component={CheckboxTag}
                          />
                        </Box>
                      );
                    })}
                  </Flex>
                </>
              )}
              {submitError && <ErrorText>{submitError}</ErrorText>}
            </Box>
            <Box>
              <Box width={1} height={53}>
                <Button
                  bgColor={primaryColor}
                  onClick={handleSubmit}
                  isLoading={isLoading}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  color={buttonsTextColor}
                >
                  <FormattedMessage id="userInterests.button" />
                </Button>
              </Box>
            </Box>
            <Flex
              flexDirection="column"
              width="100%"
              mb="8px"
              alignItems="center"
              justifyContent="center"
              color="textDarkSecondary"
              fontSize={3}
            >
              <ButtonLink onClick={onSkip} fontSize={2} color={primaryColor}>
                <FormattedMessage id="userInterests.skip" />
              </ButtonLink>
            </Flex>
          </Flex>
        </form>
      )}
    />
  );
};

export default UserInterestsForm;
