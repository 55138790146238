import { action, observable } from 'mobx';
import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import differenceBy from 'lodash/differenceBy';
import homeStore from './homeStore';
import chatStore from './chatStore';
import { API } from '../_app/api';
import { API_ROUTES } from '../_app/api_routes';

export class ChatRoomsStore {
  @observable isLoading = false;

  @observable chatRooms = [];

  @observable newChatRoomIds = [];

  @observable participants = [];

  @observable participantsEnded = false;

  @observable chatRoomsIntervalHandler = null;

  @action
  getChatRooms = async (eventId, markNew = false) => {
    this.isLoading = true;
    try {
      const {
        data: { results },
      } = await API.get(API_ROUTES.EVENT_CHAT_ROOMS(eventId));

      if (markNew) {
        const newRooms = differenceBy(results, this.chatRooms, 'id');
        this.newChatRoomIds = [
          ...this.newChatRoomIds,
          ...newRooms.map(room => room.id),
        ];
      }

      this.chatRooms = results;
    } catch (e) {
      return { [FORM_ERROR]: "Couldn't get chat rooms" };
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  markRoomNotNew = roomId => {
    if (roomId) {
      this.newChatRoomIds = this.newChatRoomIds.filter(id => id !== roomId);
    }
  };

  @action
  leaveChatRoom = async chatId => {
    this.isLoading = true;
    try {
      await API.put(API_ROUTES.LEAVE_CHAT_ROOM(chatId));
    } catch (e) {
      console.warn(e);
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  oneToOneChatRoom = async (eventId, payload) => {
    this.isLoading = true;

    try {
      const { data: chat } = await API.post(
        API_ROUTES.CREATE_CHAT_ROOM(eventId),
        {
          chat_room: { user_ids: payload },
        },
      );
      await this.getChatRooms(eventId);
      chatStore.setActiveChatRoomId(chat.id);
      chatStore.activeChatRoomName = chat.dynamic_chat_name;
      chatStore.chatRoomNumberOfUsers = 2;
    } catch (e) {
      console.warn(e);
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  createNewChatRoom = async (eventId, payload, onSuccess) => {
    this.isLoading = true;

    try {
      const { data: chat } = await API.post(
        API_ROUTES.CREATE_CHAT_ROOM(eventId),
        payload,
      );
      await this.getChatRooms(eventId);
      chatStore.setActiveChatRoomId(chat.id);
      chatStore.activeChatRoomName = chat.dynamic_chat_name;
      chatStore.chatRoomNumberOfUsers = chat.users_count;
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.warn(e);
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action getParticipants = async (
    { searchTerm = '', country = '', orderBy = '' },
    page = 1,
    per = 20,
  ) => {
    const eventId =
      get(homeStore.activeEvent, 'id') ||
      get(homeStore.activeAgendaItem, 'event_id');

    this.isLoadingParticipants = true;
    if (page === 1) {
      this.participantsEnded = false;
      this.participants = [];
    }
    if (!eventId || this.participantsEnded) return;

    try {
      const { data } = await API.get(
        `${API_ROUTES.EVENTS()}${eventId}/participants?search=${searchTerm ||
          ''}&country=${country || ''}&per=${per}&page=${page}&order_by=${
          orderBy ? encodeURI(orderBy) : ''
        }`,
      );
      this.participantsCount = data.count;
      this.participants = [...this.participants, ...data.results];
      if (this.participants.length < per * page) this.participantsEnded = true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoadingParticipants = false;
    }
  };

  @action getRoomUsers = async (chatRoomId, page = 1, per = 10) => {
    if (!chatRoomId) return { results: [], count: 0 };

    try {
      const { data } = await API.get(
        `${API_ROUTES.CHAT_ROOM_USERS(chatRoomId)}?per=${per}&page=${page}`,
      );
      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      this.isLoadingRoomUsers = false;
    }
    return { results: [], count: 0 };
  };

  @action
  clearStore = async () => {
    this.chatRooms = [];
    this.newChatRoomIds = [];

    clearInterval(this.chatRoomsIntervalHandler);
    this.chatRoomsIntervalHandler = null;
  };
}

export default new ChatRoomsStore();
