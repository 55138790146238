import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'rebass';
import styled from '@emotion/styled';
import moment from 'moment';
import qs from 'qs';
import { css } from 'styled-components';
import { Box } from '@material-ui/core';
import get from 'lodash/get';
import theme from '../../_styles/theme';
import StylingUtils from '../../_app/utils/stylingUtils';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import { Loader } from '../../components/Loader';
import { toHex } from '../../_app/utils/otherUtils';
import { EventTile } from './EventListItem';
import { EventsInfo } from '../../components/SideMenu/EventsList/EventsInfo';
import { CardTabs } from '../../signIn/components/CardTabs';
import SignInForm from '../../signIn/components/SignInForm';

const Card = styled(Box)`
  background-color: ${theme.colors.card};
  border-radius: 10px;

  // height: 100%;
  min-height: 520px; // temp fix

  box-shadow: 0 14px 16px -6px rgba(0, 0, 0, 0.43);

  ${StylingUtils.animationFadeIn()}
`;

const CardContent = styled(Flex)`
  padding: 30px 47px 30px 38px;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    // max-height: 600px; // temp fix
    padding: 30px 31px 30px 32px;
  }
`;

const CardHeader = styled(Text)`
  font-size: 28px;
  font-weight: ${theme.fontWeights.heading};
  color: ${theme.colors.textDark};
  margin-bottom: 13px;
  text-transform: uppercase;
`;

const CardText = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
`;

const CardSectionHeader = styled(Text)`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${theme.fontSizes[5]}px;
  font-weight: ${theme.fontWeights.heading};
  color: ${theme.colors.textDark};
  margin-bottom: 13px;
  text-transform: uppercase;
`;

@inject(
  'organizationsStore',
  'eventsStore',
  'eventBriefStore',
  'homeStore',
  'userStore',
  'authStore',
)
@observer
class EventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'events',
      sessionExpiredMessage: false,
    };
  }

  async componentDidMount() {
    const {
      location: { search },
      authStore: { isAuthenticated },
    } = this.props;

    const {
      sessionexpired: sessionExpiredMessage,
      signintab: singInTab,
    } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    if (sessionExpiredMessage) {
      this.setState({ sessionExpiredMessage: true });
    }
    if (singInTab && !isAuthenticated) {
      this.setState({ selectedTab: 'sign-in' });
    }
  }

  async componentWillUnmount() {
    const {
      eventBriefStore: { clearStore },
    } = this.props;
    await clearStore();
  }

  handleEventClick = async event => {
    const {
      history,
      userStore: { hasAcceptedAttendance },
      eventBriefStore: { getEventBrief },
      authStore: { isAuthenticated },
    } = this.props;

    let {
      location: { search },
    } = window;

    const {
      location: { pathname },
    } = window;

    if (event && event.id) {
      const isSignIn = pathname.includes('/sign-in');
      const isSignUp = pathname.includes('/sign-up');
      const isResetPasswordFinish = pathname.includes('/reset-password-finish');
      const isResetPassword =
        !isResetPasswordFinish && pathname.includes('/reset-password');

      const hideEventAccess =
        get(event, 'hide_registration_form') && get(event, 'hide_login_form');

      let whereTo = '';
      if (isAuthenticated && !hideEventAccess) {
        if (event.public) {
          whereTo = '/event';
          search = search ? `${search}&enter=true` : `?enter=true`;
        } else if (hasAcceptedAttendance(event.id)) {
          whereTo = '/event';
          search = '';
        } else {
          whereTo = '/sign-up';
          search = '';
        }
      } else {
        whereTo = '/sign-up';
      }

      if (whereTo !== '/event') {
        if (isSignIn) {
          whereTo = '/sign-in';
        }
        if (isSignUp) {
          whereTo = '/sign-up';
        }
        if (isResetPassword) {
          whereTo = '/reset-password';
        }
        if (isResetPasswordFinish) {
          whereTo = '/reset-password-finish';
        }
      }

      history.push(`${whereTo}/${get(event, 'slug', '')}${search || ''}`);
      if (whereTo !== '/event') {
        await getEventBrief({ eventId: event.slug });
      }
    }
  };

  handleTabClick = e => {
    this.setState({ selectedTab: e });
  };

  handleLoginClick = () => {
    this.setState({ selectedTab: 'sign-in' });
  };

  handleOnSubmit = async val => {
    const {
      authStore: { signIn },
      userStore: { getProfile },
      // organizationsStore: { getOrganizationBySlug },
      eventsStore: { getOrganizationEvents },
      homeStore: { activeOrganization },
    } = this.props;

    // eslint-disable-next-line no-return-await
    return await signIn(val, async () => {
      await getProfile();
      this.setState({ selectedTab: 'events' });
      // await getOrganizationBySlug(SUBDOMAIN, async org => {
      //   await setActiveOrganization(org);
      await getOrganizationEvents(activeOrganization.id);
      // });
    });
  };

  render() {
    const {
      buttonsBg,
      buttonsTextColor,
      isTablet,
      location: { state },
      authStore: { isAuthenticated },
      homeStore: { activeOrganization },
      eventsStore: { events, isLoading: isLoadingEvents },
      themeElements: { headerFontFamily, eventListingButtonsBg },
    } = this.props;

    const { selectedTab, sessionExpiredMessage } = this.state;

    const orgName = get(activeOrganization, 'name');

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : theme.colors.textSecondary;

    const initMsg = get(state, 'initMsg');

    const publicCount = events.filter(obj => obj.public === true).length;
    const hideLoginTab = publicCount === events.length;

    const userEvents = events.filter(
      event =>
        event.user_attendance.status === 'accepted' ||
        event.user_attendance.status === 'requested',
    );

    const pastUserEvents = userEvents
      .filter(ev => moment(ev.agenda_end_datetime).isBefore(moment()))
      .sort((event1, event2) =>
        moment(event2.agenda_start_datetime).diff(
          event1.agenda_start_datetime,
          'minutes',
        ),
      );

    const upcomingUserEvents = userEvents.filter(ev =>
      moment(ev.agenda_end_datetime).isSameOrAfter(moment()),
    );

    const otherEvents = events.filter(
      event =>
        event.user_attendance.status !== 'accepted' &&
        event.user_attendance.status !== 'requested',
    );

    const pastOtherEvents = otherEvents
      .filter(ev => moment(ev.agenda_end_datetime).isBefore(moment()))
      .sort((event1, event2) =>
        moment(event2.agenda_start_datetime).diff(
          event1.agenda_start_datetime,
          'minutes',
        ),
      );

    const upcomingOtherEvents = otherEvents.filter(ev =>
      moment(ev.agenda_end_datetime).isSameOrAfter(moment()),
    );

    const mainColor = toHex(buttonsBg) || primaryColor;
    const buttonsTextColorHex = toHex(buttonsTextColor);

    if (isLoadingEvents) {
      return (
        <Card>
          <CardTabs
            currentlySelected={selectedTab}
            primaryColor={mainColor}
            tabs={
              hideLoginTab
                ? [
                    {
                      label: `${orgName && orgName.toUpperCase()} EVENTS`,
                      value: 'events',
                    },
                  ]
                : [
                    {
                      label: `${orgName && orgName.toUpperCase()} EVENTS`,
                      value: 'events',
                    },
                    {
                      label: <FormattedMessage id="generic.login" />,
                      value: 'sign-in',
                    },
                  ]
            }
            initialTabIdx={0}
            onTabClick={this.handleTabClick}
          />
          <CardContent>
            <Box>
              {initMsg && (
                <Box mt={2}>
                  <Text color="error">{initMsg}</Text>
                </Box>
              )}
              <Loader dark />
            </Box>
          </CardContent>
        </Card>
      );
    }

    return (
      <Card>
        <CardTabs
          currentlySelected={selectedTab}
          primaryColor={mainColor}
          tabs={
            !isAuthenticated && !hideLoginTab
              ? [
                  {
                    label: `${orgName && orgName.toUpperCase()} EVENTS`,
                    value: 'events',
                  },
                  {
                    label: <FormattedMessage id="generic.login" />,
                    value: 'sign-in',
                  },
                ]
              : [
                  {
                    label: `${orgName && orgName.toUpperCase()} EVENTS`,
                    value: 'events',
                  },
                ]
          }
          initialTabIdx={0}
          onTabClick={this.handleTabClick}
        />
        <CardContent>
          {selectedTab === 'events' && (
            <>
              <Box>
                {initMsg && (
                  <Box mt={2}>
                    <Text color="error">{initMsg}</Text>
                  </Box>
                )}
              </Box>
              <Box flexGrow={1} height="100%">
                {userEvents.length && (
                  <>
                    <CardSectionHeader
                      fontFamily={headerFontFamily || theme.fonts.body}
                    >
                      <FormattedMessage id="eventListing.registeredEvents" />
                    </CardSectionHeader>
                    <>
                      {upcomingUserEvents.length && (
                        <Box>
                          <EventsInfo listing />
                          {upcomingUserEvents.map((event, index) => (
                            <EventTile
                              key={`event_${index}`}
                              {...event}
                              onClick={this.handleEventClick}
                              name={event.name}
                              status={event.user_attendance.status}
                              bgColor={mainColor}
                              buttonsTextColor={buttonsTextColorHex}
                              isTablet={isTablet}
                              eventListingButtonsBg={eventListingButtonsBg}
                            />
                          ))}
                        </Box>
                      )}
                      {pastUserEvents.length && (
                        <Box>
                          <EventsInfo listing isPast />
                          {pastUserEvents.map((event, index) => (
                            <EventTile
                              key={`event_${index}`}
                              {...event}
                              onClick={this.handleEventClick}
                              name={event.name}
                              status={event.user_attendance.status}
                              bgColor={mainColor}
                              buttonsTextColor={buttonsTextColorHex}
                              isTablet={isTablet}
                              eventListingButtonsBg={eventListingButtonsBg}
                            />
                          ))}
                        </Box>
                      )}
                    </>
                  </>
                )}

                {otherEvents.length && (
                  <>
                    <CardSectionHeader
                      fontFamily={headerFontFamily || theme.fonts.body}
                    >
                      <FormattedMessage id="eventListing.otherEvents" />
                    </CardSectionHeader>
                    <>
                      {upcomingOtherEvents.length && (
                        <Box>
                          <EventsInfo listing />
                          {upcomingOtherEvents.map((event, index) => (
                            <EventTile
                              key={`other_event_${index}`}
                              {...event}
                              onClick={this.handleEventClick}
                              name={event.name}
                              status={event.user_attendance.status}
                              bgColor={mainColor}
                              buttonsTextColor={buttonsTextColorHex}
                              isTablet={isTablet}
                              eventListingButtonsBg={eventListingButtonsBg}
                            />
                          ))}
                        </Box>
                      )}
                      {pastOtherEvents.length && (
                        <Box>
                          <EventsInfo listing isPast />
                          {pastOtherEvents.map((event, index) => (
                            <EventTile
                              key={`other_event_${index}`}
                              {...event}
                              onClick={this.handleEventClick}
                              name={event.name}
                              status={event.user_attendance.status}
                              bgColor={mainColor}
                              isTablet={isTablet}
                              buttonsTextColor={buttonsTextColorHex}
                              eventListingButtonsBg={eventListingButtonsBg}
                            />
                          ))}
                        </Box>
                      )}
                    </>
                  </>
                )}
                {!isAuthenticated && !hideLoginTab && (
                  <Flex
                    flexDirection="column"
                    width="100%"
                    mt="15px"
                    mb="8px"
                    alignItems="center"
                    justifyContent="center"
                    color="textDarkSecondary"
                    fontSize={3}
                  >
                    <Flex mt={1}>
                      <FormattedMessage id="eventListing.registeredAlready" />{' '}
                      <Text
                        onClick={this.handleLoginClick}
                        css={css`
                          margin-left: 5px;
                          color: ${mainColor};

                          &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                          }
                        `}
                      >
                        <FormattedMessage id="eventListing.login" />
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Box>
            </>
          )}
          {selectedTab === 'sign-in' && !isAuthenticated && (
            <>
              <Box>
                <CardHeader>
                  <FormattedMessage id="signin.accessSimple" />
                </CardHeader>
              </Box>
              <Box flexGrow={1} height="100%">
                <CardText minHeight="40px">
                  <FormattedMessage
                    id="signin.organisation.header"
                    values={{
                      org: orgName,
                    }}
                  />
                </CardText>
                <SignInForm
                  onSubmit={this.handleOnSubmit}
                  primaryColor={mainColor}
                  buttonsTextColor={buttonsTextColorHex}
                  isOnRoot
                  sessionExpiredMessage={sessionExpiredMessage}
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default EventList;
