import { action, observable } from 'mobx';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import ActionCable from 'actioncable';
import LocalStorageManager, {
  LS_KEYS,
} from '../_app/utils/LocalStorageManager';
import { API_WS_ROOT } from '../_app/api';
import homeStore from './homeStore';
import userStore from './userStore';
import { getRandomInt } from '../_app/utils/otherUtils';

// import { API } from '../_app/api';
// import { API_ROUTES } from '../_app/api_routes';
// import homeStore from './homeStore';
// import { setPageTitle } from '../_app/utils/otherUtils';

export class CableStore {
  @observable isLoading = false;

  @observable CABLE_INSTANCE = null;

  @observable EVENT_SUBSCRIPTION = null;

  @action setCable = async () => {
    const {
      activeEvent: { id: eventId },
    } = homeStore;
    const { anonUser } = userStore;
    const {
      [LS_KEYS.CLIENT]: client,
      [LS_KEYS.UID]: uid,
      [LS_KEYS.AUTH_TOKEN]: accessToken,
    } = await LocalStorageManager.get([
      LS_KEYS.AUTH_TOKEN,
      LS_KEYS.CLIENT,
      LS_KEYS.UID,
    ]);
    const { [LS_KEYS.ANONYM_CHAT_ID]: savedAnonChatId } = Cookies.get();
    console.log('CHAT: CREATE CABLE INSTANCE: ', API_WS_ROOT);

    const WSS_URL = `${API_WS_ROOT}?uid=${encodeURIComponent(
      uid,
    )}&token=${accessToken}&client=${client}&event_id=${eventId}&anonymous_chat_user_id=${savedAnonChatId ||
      get(anonUser, 'id') ||
      ''}`;
    this.CABLE_INSTANCE = ActionCable.createConsumer(WSS_URL);
  };

  @action subscribeToEventChannel = async () => {
    const {
      activeEvent: { id: eventId },
      setVideoReactions,
    } = homeStore;

    if (this.CABLE_INSTANCE == null) {
      await this.setCable();
    }

    if (
      this.EVENT_SUBSCRIPTION !== null &&
      !this.EVENT_SUBSCRIPTION.identifier.includes(eventId)
    ) {
      this.CABLE_INSTANCE.subscriptions.remove(this.EVENT_SUBSCRIPTION);
      this.EVENT_SUBSCRIPTION = null;
    }

    this.EVENT_SUBSCRIPTION = this.CABLE_INSTANCE.subscriptions.create(
      {
        channel: 'EventChannel',
        event_id: eventId,
      },
      {
        received(msg) {
          const { type, reload } = msg;
          if (type === 'management') {
            if (reload === true) {
              const shouldBeReloadedIn = getRandomInt(0, 60000);
              setTimeout(() => {
                window.location.reload();
              }, shouldBeReloadedIn);
            }
          }

          if (type === 'event_reaction') {
            setVideoReactions(msg);
          }
        },
      },
    );
  };

  @action clearStore = async () => {
    this.CABLE_INSTANCE = null;
    this.EVENT_SUBSCRIPTION = null;
  };
}

export default new CableStore();
