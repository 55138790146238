import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'rebass';
import { Box } from '@material-ui/core';
import styled from '@emotion/styled';
import qs from 'qs';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { css } from 'styled-components';
import routes from '../_app/routing/routes';
import ResetPasswordForm from './compoenents/ResetPasswordForm';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';
import StylingUtils from '../_app/utils/stylingUtils';
import theme from '../_styles/theme';
import { ResetPasswordRequestSuccess } from './compoenents/ResetPasswordRequestSuccess';
import { SUBDOMAIN, toHex } from '../_app/utils/otherUtils';
import { OrganizationBrief } from '../eventListing/components/OrganizationBrief';
import EventBrief from '../signIn/components/EventBrief';

const Container = styled(Flex)`
  flex-direction: row;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const LeftPart = styled(Box)`
  position: relative;
  width: 44%;
  ${props =>
    props.backgroundimage
      ? `
      background-image: url(${props.backgroundimage});
      background-position: top left;
      background-repeat: no-repeat;
    `
      : `background-color: ${props.bodycolor}`};
  // gradient turned off on Marcus' request (see CPLUS Tweaks line 95)
  // background: linear-gradient(
  //   147deg,
  //   ${StylingUtils.hexToRgbA('#2b3a45', 0.4)},
  //   ${StylingUtils.hexToRgbA('#3a4f69', 0.4)}
  // );

  padding: 150px 56px 119px 53px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: auto;
    padding: 60px 25px 75px 30px;
  }
`;

const RightPart = ({ children, bckg, gradient }) => (
  <Flex
    css={css`
      position: relative;
      flex-direction: column;
      width: 56%;
      height: auto;
      background: ${gradient || bckg || theme.colors.bgLight};
      padding-top: 75px;
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        padding-top: 0;
        min-height: 300px;
      }
      ${StylingUtils.animationFadeIn()}
    `}
  >
    {children}
  </Flex>
);

const Card = styled(Flex)`
  background-color: ${theme.colors.card};
  border-radius: 10px;
  padding: 30px 47px 30px 38px;

  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 14px 16px -6px rgba(0, 0, 0, 0.43);

  ${StylingUtils.animationFadeIn()}
`;

const CardHeader = styled(Text)`
  font-size: ${theme.fontSizes[6]}px;
  font-weight: ${theme.fontWeights.heading};
  color: ${theme.colors.textDark};
  margin-bottom: 13px;
`;

const CardText = styled(Text)`
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 22px;
`;

const HeaderFixBlock = styled(Box)`
  position: absolute;
  top: -20px;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #000;
`;

const EventContainer = styled(Box)`
  position: relative;
  z-index: 2;
  // max-height: calc(100vh - 65px);
  overflow-y: auto;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: auto;
  }
`;

@inject(
  'authStore',
  'eventsStore',
  'eventBriefStore',
  'organizationsStore',
  'homeStore',
)
@observer
class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      showSuccess: false,
      errorMessage: null,
    };
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    const {
      history,
      match: {
        params: { event_id: eventSlug },
      },
      location: { search },
      eventBriefStore,
      eventBriefStore: { getEventBrief },
      authStore: { isAuthenticated, redirectAuthenticated },
    } = this.props;

    if (eventSlug) await getEventBrief({ eventId: eventSlug });

    const { tokeninvalid } = qs.parse(search, { ignoreQueryPrefix: true });
    if (tokeninvalid) {
      this.setState({ errorMessage: true });
    }

    if (eventBriefStore.event) {
      const { event } = eventBriefStore;
      const hideEventAccess =
        get(event, 'hide_registration_form') && get(event, 'hide_login_form');

      if (isAuthenticated || hideEventAccess || get(event, 'hide_login_form')) {
        await redirectAuthenticated(history, null, true);
      }
    } else if (isAuthenticated) {
      await redirectAuthenticated(history, null, true);
    }
    // await getOrganizationBySlug(SUBDOMAIN, async org => {
    //   await setActiveOrganization(org);
    //   await getOrganizationEvents(org.id);
    // });
  }

  async componentDidUpdate(prevProps) {
    const {
      organizationsStore: { isLoading },
      homeStore: { activeOrganization },
      eventsStore: { events },
      match: {
        params: { event_id: eventSlug },
      },
      history,
      eventBriefStore: { getEventBrief },
    } = this.props;
    const shouldRedirectToEventContext =
      events.length === 1 &&
      events[0].slug !== get(prevProps, 'match.params.event_id');

    if (shouldRedirectToEventContext) {
      history.push(`/reset-password/${events[0].slug}`);
    }

    if (eventSlug !== get(prevProps, 'match.params.event_id')) {
      await getEventBrief({ eventId: eventSlug });
    }
    if (!activeOrganization && !isLoading) {
      await this.handleNoOrg();
    }
  }

  async componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.authStore.resetPasswordMessage = '';
    window.removeEventListener('resize', this.updateWindowDimensions);

    const {
      eventBriefStore: { clearStore },
    } = this.props;

    await clearStore();
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleNoOrg = async () => {
    const {
      eventsStore: { getOrganizationEvents },
      homeStore: { setActiveOrganization },
      organizationsStore: { getOrganizationBySlug },
    } = this.props;

    getOrganizationBySlug(SUBDOMAIN, async org => {
      setActiveOrganization(org);
      const events2 = getOrganizationEvents(org.id);
      if (events2.length === 1) {
        this.handleRedirect(events2[0]);
      }
    });
  };

  handleOnSubmit = async val => {
    const {
      authStore: { resetPasswordInit },
      homeStore: { activeOrganization },
      match: {
        params: { event_id: eventSlug },
      },
    } = this.props;

    const {
      location: { protocol, host },
    } = window;
    const appUrl = `${protocol}//${host}`;
    const redirectUrl = `${appUrl}${routes.auth.resetPasswordFinish}${
      eventSlug ? `/${eventSlug}` : ''
    }`;
    return resetPasswordInit(
      {
        ...val,
        org: activeOrganization.id,
        redirect_url: redirectUrl,
      },
      () => {
        this.setState({ showSuccess: true });
      },
      error => error,
    );
  };

  render() {
    const { showSuccess, width, errorMessage: showErrorMessage } = this.state;
    const {
      authStore: { isLoading, resetPasswordMessage },
      homeStore: { activeOrganization },
      eventBriefStore: { noUpcomming, isLoading: isLoadingBrief, event },
      match: {
        params: { event_id: eventSlug },
      },
      eventsStore: { events, getClosestEvent },
    } = this.props;

    let closestFutureEvent;
    if (events && events.length > 0) {
      if (eventSlug) {
        closestFutureEvent = event;
      } else {
        closestFutureEvent = getClosestEvent(events);
      }
    }
    const signInRedirectPath =
      events && events.length === 1
        ? `/sign-in/${events[0].slug}`
        : '/?signintab=true';

    let customTheme;
    if (closestFutureEvent) {
      customTheme = closestFutureEvent.event_custom_theme;
    }
    const isMobile = width <= MOBILE_BREAKPOINT;

    const {
      buttons_bg: buttonsBg,
      buttons_text_color: buttonsTextColor,
      event_brief_text: eventBriefText,
      event_brief_bg: eventBriefBg,
      event_brief_gradient_top: eventBriefGrTop,
      event_brief_gradient_bottom: eventBriefGrBottom,
      landing_page_bckg_img: landingPageBgImg,
      landing_page_body: landingPageBody,
      header_font_family: headerFontFamily,
    } = customTheme || {};

    const rightBoxGradient =
      eventBriefGrTop && eventBriefGrBottom
        ? `linear-gradient(
            to bottom,
            ${toHex(eventBriefGrTop)},
            ${toHex(eventBriefGrBottom)}
          );`
        : null;

    const bodyColor = landingPageBody
      ? toHex(landingPageBody)
      : theme.colors.backgroundDarker;

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : theme.colors.textSecondary;

    const mainColor = toHex(buttonsBg) || primaryColor;

    const orgName = get(activeOrganization, 'name');
    const orgDescription = get(activeOrganization, 'description');
    return (
      <Container>
        <LeftPart
          bodycolor={bodyColor}
          backgroundimage={landingPageBgImg && landingPageBgImg.url}
        >
          <Card>
            {showSuccess ? (
              <ResetPasswordRequestSuccess
                fontFamily={theme.fonts.body}
                primaryColor={mainColor}
                buttonsTextColor={toHex(buttonsTextColor)}
                signInRedirectPath={signInRedirectPath}
              />
            ) : (
              <>
                <Box>
                  <CardHeader fontFamily={theme.fonts.body}>
                    <FormattedMessage id="resetpassword.header" />
                  </CardHeader>
                  <CardText minHeight="40px">
                    <FormattedMessage id="resetpassword.desc" />
                  </CardText>
                </Box>
                <Box flexGrow={1} height="100%">
                  <ResetPasswordForm
                    onSubmit={this.handleOnSubmit}
                    signInRedirectPath={signInRedirectPath}
                    isLoading={isLoading}
                    resetPasswordMessage={resetPasswordMessage}
                    primaryColor={mainColor}
                    buttonsTextColor={toHex(buttonsTextColor)}
                    showErrorMessage={showErrorMessage}
                  />
                </Box>
              </>
            )}
          </Card>
        </LeftPart>
        <RightPart bckg={toHex(eventBriefBg)} gradient={rightBoxGradient}>
          {eventSlug ? (
            <EventContainer>
              <EventBrief
                isLoading={isLoadingBrief}
                event={closestFutureEvent}
                noUpcomming={noUpcomming}
                isMobile={isMobile}
              />
            </EventContainer>
          ) : (
            <OrganizationBrief
              orgDescription={orgDescription}
              orgName={orgName}
              themeElements={{
                headerFontFamily,
                buttonsBg: toHex(buttonsBg),
                briefTextHex: toHex(eventBriefText),
              }}
            />
          )}
          {/* Hiding horizontal shadow as requested by Marcus */}
          {/* <SideShadow color={toHex(eventBriefShadow)} /> */}
          {/* <BottomShadow color={toHex(eventBriefShadow)} /> */}
          {!isMobile && <HeaderFixBlock />}
        </RightPart>
      </Container>
    );
  }
}

export default ResetPasswordPage;
