import React from 'react';
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import { Flex, Box, Text, Link } from 'rebass';
import moment from 'moment';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Button } from '../components/Button/Button';
import Checkbox from '../components/_forms/Checkbox';
import CheckboxTag from '../components/_forms/CheckBoxTag';
import { ImageUploadInput } from '../components/_forms/ImageUploadInput';
import TextInput from '../components/_forms/TextInput';
import {
  FIELD_TYPES,
  componentForField,
} from '../signUp/components/SignUpForm';
import { DefaultAvatar } from '../_assets/icons/defaultAvatar';
import countryCodesList from '../_assets/countryCodesList';
import theme from '../_styles/theme';
import { required } from '../_app/formValidators';
import AvatarPreview from '../components/AvatarPreview';
import MakeProfileImage from '../components/_forms/MakeProfileImage';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';

const FieldGroup = styled(Box)`
  width: 100%;
  margin-top: 20px;
`;

const FieldWrapper = styled(Box)`
  margin-top: ${props => props.mt || '18px'};
`;

const CardText = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.body};
  color: ${theme.colors.textDarkSecondary};
  line-height: 1.2em;
`;

const ErrorText = styled(Text)`
  color: red;
`;

const Avatarcontainer = styled(Flex)`
  padding: 20px 0;
  border-top: 2px solid ${({ borderColor }) => borderColor};
  border-bottom: 2px solid ${({ borderColor }) => borderColor};
`;

const Avatar = styled(Flex)`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: ${({ avatar }) => (avatar ? `url(${avatar})` : '#999')} no-repeat
    center center;
  background-size: cover;
  overflow: hidden;
  flex-shrink: 0;
`;

export const PhotosOptions = styled(Flex)`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const SectionText = styled(Text)`
  color: ${props =>
    props.active ? theme.colors.textDark : theme.colors.textSemiVisible};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
`;

const EditProfileForm = ({
  initialValues,
  onSubmit,
  isLoading,
  primaryColor,
  buttonsTextColor,
  activeOrganization,
  extraFields,
  networkingDetails,
  isAnonymous,
}) => {
  if (
    initialValues.interests?.length > 0 &&
    typeof initialValues.interests[0] === 'object'
  ) {
    initialValues.interests = initialValues.interests.map(o => o.id);
  }

  if (networkingDetails) {
    initialValues.networking_events = networkingDetails.map(
      int => int.event_id,
    );
    initialValues.atLeastOneNetworkingAvailable = networkingDetails
      .map(int => int.networking_available)
      .some(t => t);
    // initialValues.networking_enabled = networkingDetails
    //   .filter(int => int.networking_enabled)
    //   .map(int => int.networking_enabled && int.event_id);

    initialValues.networking_enabled = initialValues.networking_events.filter(
      n => !initialValues.networking_refusals.includes(n),
    );
  }

  // disable webcam profile pic on Ios Chrome and Firefox (webcam integration won't work)
  const isIOSChromeFirefox = /(iPhone|iPad|iPod).*(FxiOS|CriOS)/i.test(
    navigator.userAgent,
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError }) => {
        const extraFieldsOther = extraFields?.filter(
          ({ field_type: type }) =>
            type === FIELD_TYPES.COMPANY || type === FIELD_TYPES.JOB_TITLE,
        );
        return (
          <form onSubmit={handleSubmit}>
            <Avatarcontainer borderColor={primaryColor}>
              <Avatar
                avatar={initialValues.avatar?.thumb?.url}
                mr={26}
                justifyContent="center"
                alignItems="center"
              >
                {!initialValues?.avatar?.thumb?.url && (
                  <DefaultAvatar
                    fill={primaryColor}
                    width="110px"
                    height="110px"
                  />
                )}
              </Avatar>
              <Box>
                <SectionText>
                  <FormattedMessage id="settings.field.image.uploadlabeluppc" />
                </SectionText>
                <PhotosOptions mt="15px">
                  <Field name="avatar" component={AvatarPreview} />
                  <Field
                    label={
                      <FormattedMessage id="settings.field.image.uploadlabel" />
                    }
                    name="avatar"
                    type="text"
                    asDropzone
                    width="100%"
                    component={ImageUploadInput}
                  />
                  {!isIOSChromeFirefox && (
                    <Field
                      label={
                        <FormattedMessage id="settings.field.image.uploadlabel" />
                      }
                      name="avatar"
                      type="text"
                      width="100%"
                      colors={{ buttonsTextColor, buttonsBg: primaryColor }}
                      component={MakeProfileImage}
                    />
                  )}
                </PhotosOptions>
              </Box>
            </Avatarcontainer>

            {!isAnonymous ? (
              <FieldGroup>
                <FieldWrapper>
                  <Field
                    label={<FormattedMessage id="settings.field.first_name" />}
                    name="first_name"
                    width="100%"
                    type="text"
                    component={TextInput}
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <Field
                    label={<FormattedMessage id="settings.field.last_name" />}
                    name="last_name"
                    width="100%"
                    type="text"
                    component={TextInput}
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <Field
                    label={<FormattedMessage id="settings.field.email" />}
                    name="email"
                    width="100%"
                    type="text"
                    component={TextInput}
                    disabled
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <Field
                    label={<FormattedMessage id="settings.field.bio" />}
                    name="bio"
                    width="100%"
                    type="text"
                    multiline
                    component={TextInput}
                  />
                </FieldWrapper>
              </FieldGroup>
            ) : (
              <FieldGroup>
                <FieldWrapper>
                  <Field
                    label={<FormattedMessage id="settings.field.name" />}
                    name="name"
                    width="100%"
                    type="text"
                    component={TextInput}
                  />
                </FieldWrapper>
              </FieldGroup>
            )}

            {!isEmpty(extraFieldsOther) && !isAnonymous && (
              <FieldGroup>
                {extraFieldsOther.map(
                  ({
                    field_name: fieldName,
                    name,
                    field_type: fieldType,
                    options,
                    required: isRequired,
                  }) => (
                    <FieldWrapper key={`field-${fieldName}`}>
                      <Field
                        name={fieldName}
                        label={
                          <FormattedMessage
                            id={`signup.fields.${fieldName}`}
                            defaultMessage={name}
                          />
                        }
                        parse={value => value} // a hack to send empty values
                        validate={isRequired && required}
                        component={componentForField(fieldType)}
                        options={
                          fieldType === FIELD_TYPES.COUNTRY
                            ? Object.keys(countryCodesList).map(key => ({
                                value: key,
                                label: countryCodesList[key],
                              }))
                            : options?.map(option => ({
                                label: option,
                                value: option,
                              }))
                        }
                      />
                    </FieldWrapper>
                  ),
                )}
              </FieldGroup>
            )}

            {!isEmpty(networkingDetails) &&
              !isAnonymous &&
              initialValues.atLeastOneNetworkingAvailable && (
                <>
                  <Text
                    mt={20}
                    fontSize={theme.fontSizes[2]}
                    color={theme.colors.textDarkSecondary}
                  >
                    <FormattedMessage id="userInterests.description" />
                  </Text>
                  {networkingDetails.map(
                    attendEvent =>
                      attendEvent.networking_available && (
                        <Box key={`attendEvent-${attendEvent.event_name}`}>
                          <Text
                            mt={20}
                            mb="5px"
                            fontSize={theme.fontSizes[2]}
                            fontWeight={theme.fontWeights.emph}
                            color={theme.colors.textDarkSecondary}
                          >
                            {attendEvent.event_name}
                          </Text>

                          <Box mt={10}>
                            <Field
                              name="networking_enabled[]"
                              checkboxContent={
                                <Text
                                  ml="2px"
                                  fontSize={theme.fontSizes[2]}
                                  color={theme.colors.textDarkSecondary}
                                >
                                  <FormattedMessage id="userInterests.checkbox" />
                                </Text>
                              }
                              type="checkbox"
                              value={attendEvent.event_id}
                              component={Checkbox}
                              simpleCheckbox
                            />
                          </Box>

                          <Flex flexWrap="wrap">
                            {attendEvent.interests.map(interest => (
                              <Box key={`interest-${interest.id}`}>
                                <Field
                                  id={interest.id}
                                  name="interests"
                                  value={interest.id}
                                  checkboxContent={
                                    <Text
                                      fontSize={theme.fontSizes[3]}
                                      fontWeight={theme.fontWeights.emph}
                                    >
                                      {interest.name}
                                    </Text>
                                  }
                                  type="checkbox"
                                  mainColor={primaryColor}
                                  component={CheckboxTag}
                                />
                              </Box>
                            ))}
                          </Flex>
                        </Box>
                      ),
                  )}
                </>
              )}

            {!isEmpty(submitError) && (
              <Box mt={3}>
                <ErrorText>{submitError}</ErrorText>
              </Box>
            )}

            <Button
              bgColor={primaryColor}
              onClick={handleSubmit}
              isLoading={isLoading}
              color={buttonsTextColor}
              width="150px"
              height="36px"
              fontSize="14px"
              mt="34px"
              mb="50px"
              pl={0}
              pr={0}
            >
              <FormattedMessage id="settings.save.profile" />
            </Button>

            {get(initialValues, 'last_sign_in_at') && (
              <CardText mt={20}>
                <FormattedMessage id="settings.lastlogin" />
                <FormattedDate
                  value={moment(get(initialValues, 'last_sign_in_at')).toDate()}
                  weekday="short"
                  day="2-digit"
                  month="short"
                  year="numeric"
                />{' '}
                <FormattedTime
                  value={moment(get(initialValues, 'last_sign_in_at')).toDate()}
                  hour12={false}
                />{' '}
                from {get(initialValues, 'last_sign_in_ip')}
              </CardText>
            )}

            <CardText>
              <FormattedMessage
                id="footer.copy"
                values={{
                  year: moment().format('YYYY'),
                  a: (...chunks) => (
                    <Link
                      href={`http://cplus.live/terms.html${
                        activeOrganization
                          ? `?orgName=${encodeURIComponent(
                              activeOrganization.name,
                            )}`
                          : ''
                      }`}
                      color={theme.colors.textDarkSecondary}
                      fontWeight={theme.fontWeights.emph}
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </CardText>
          </form>
        );
      }}
    />
  );
};

export default EditProfileForm;
