/* eslint-disable */
import React from 'react';

export const CalendarIcon = ({ fill, ...props }) => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Login" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Register" transform="translate(-710.000000, -225.000000)">
        <g id="Group-6" transform="translate(630.000000, 0.000000)">
          <g id="Group-12">
            <g id="Group-10" transform="translate(79.000000, 224.000000)">
              <g id="Group">
                <g id="calendar">
                  <g id="group" transform="translate(1.440000, 1.440000)" stroke={fill || '#fff'} strokeWidth="2">
                    <rect id="shape" x="1" y="1" width="19.12" height="19.12" rx="1.92"></rect>
                    <line x1="4.55111111" y1="4.93333333" x2="16.4408889" y2="4.93333333" id="line" strokeLinecap="square"></line>
                    <rect id="oval" x="4.664" y="9.64" width="1.36" height="1.36" rx="0.68"></rect>
                    <rect id="oval" x="9.944" y="9.64" width="1.36" height="1.36" rx="0.68"></rect>
                    <rect id="oval" x="15.224" y="9.64" width="1.36" height="1.36" rx="0.68"></rect>
                    <rect id="oval" x="4.664" y="14.88" width="1.36" height="1.36" rx="0.68"></rect>
                    <rect id="oval" x="9.944" y="14.88" width="1.36" height="1.36" rx="0.68"></rect>
                    <rect id="oval" x="15.4" y="14.88" width="1.36" height="1.36" rx="0.68"></rect>
                  </g>
                  <rect id="bounds" x="0" y="0" width="24" height="24"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
