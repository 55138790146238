import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import MoodIcon from '@material-ui/icons/Mood';

const Container = styled(Flex)`
  align-items: center;
  justify-content: space-around;
  z-index: 99999;
  position: absolute;
  left: 20px;
  top: ${props => (props.streamWidth < 450 ? '20' : '70')}px;
  background-color: ${props =>
    props.selected ? props.selectedColor : '#747474'};
  border-radius: 5px;
  padding: 3px;

  &:hover {
    cursor: pointer;
  }
`;

const MenuItem = styled(Flex)``;

export const OverlaySelectBar = ({
  onOverlaySelect,
  selectedColor,
  selectedOverlay,
  activeEvent,
  streamSize,
}) => {
  const { width } = streamSize;
  // refactor later when more options available
  if (activeEvent.event_reactions_enabled) {
    return (
      <Container
        onClick={() => onOverlaySelect('emotes')}
        selected={selectedOverlay === 'emotes'}
        selectedColor={selectedColor}
        streamWidth={width}
      >
        <MenuItem>
          <MoodIcon />
        </MenuItem>
      </Container>
    );
  }
  return null;
};
