import { action, observable } from 'mobx';
import { FORM_ERROR } from 'final-form';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import { API_ROUTES } from '../_app/api_routes';
import { ATTENDANCE_STATUSES } from '../_app/constants';
import authStore from './authStore';
import {
  API,
  setAnonChatId,
  setAnonChatName,
  setAnonChatUserAvatar,
} from '../_app/api';
import { LS_KEYS } from '../_app/utils/LocalStorageManager';

export class UserStore {
  @observable user = null;

  @observable userProfile = null;

  @observable anonUser = null;

  @observable isLoading = false;

  @observable isUsingIE = false;

  @observable isUsingSafari = false;

  @action getProfile = async () => {
    this.isLoading = true;

    try {
      const { data } = await API.get(API_ROUTES.PROFILE);
      this.user = data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      authStore.logout({});
    } finally {
      this.isLoading = false;
    }
  };

  @action getProfileDetails = async () => {
    this.isLoading = true;

    try {
      const { data } = await API.get(API_ROUTES.PROFILE_DETAILS);
      this.userProfile = data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateProfile = async (val, onSuccess) => {
    this.isLoading = true;

    const {
      [LS_KEYS.ANONYM_CHAT_ID]: savedAnonChatId,
      [LS_KEYS.ANONYM_CHAT_NAME]: savedAnonChatName,
    } = Cookies.get();
    const finalAnonUserId = get(this.anonUser, 'id') || savedAnonChatId;
    const finalAnonUserName = get(this.anonUser, 'name') || savedAnonChatName;

    const isAnonUser = finalAnonUserId || finalAnonUserName;
    try {
      const { data } = await API.put(API_ROUTES.PROFILE, {
        ...val,
        anonymous_chat_user_id: finalAnonUserId,
        anonymous_chat_user_name: finalAnonUserName,
      });

      if (isAnonUser) {
        this.anonUser = data;
        if (get(data, 'avatar.thumb.url')) {
          setAnonChatUserAvatar(get(data, 'avatar.thumb.url'));
        }
        setAnonChatId(get(data, 'id'));
        setAnonChatName(get(data, 'name'));
      } else {
        this.user = data;
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      return {
        [FORM_ERROR]:
          e.errors ||
          'Unexpected error. Please send chat message to initialize your profile and try again.',
      };
    } finally {
      this.isLoading = false;
    }
    return null;
  };

  @action setAnonUser = async user => {
    this.isLoading = true;

    try {
      const { data } = await API.put(API_ROUTES.PROFILE, {
        anonymous_chat_user_name: user.name,
      });
      this.anonUser = data;
      setAnonChatId(get(data, 'id'));
      setAnonChatName(get(data, 'name'));
    } catch (e) {
      return {
        [FORM_ERROR]:
          e.errors ||
          'Unexpected error. Please send chat message to initialize your profile and try again.',
      };
    } finally {
      this.isLoading = false;
    }
  };

  @action getAttendance = eventId => {
    if (!this.user) return null;
    const { attendances } = this.user;
    return (attendances || []).find(att => att.event_id === eventId);
  };

  @action hasAcceptedAttendance = eventId => {
    if (!this.user || !eventId) return false;
    const { attendances } = this.user;
    return (attendances || []).find(
      att =>
        att.event_id === eventId && att.status === ATTENDANCE_STATUSES.ACCEPTED,
    );
  };

  @action hasAlreadySignedUpForEvent = eventId => {
    if (!this.user) return false;
    const { registered_for_events: registeredForEvents } = this.user;
    return registeredForEvents.includes(eventId);
  };

  @action hasRequestedAttendance = eventId => {
    if (!this.user || !eventId) return false;
    const { attendances } = this.user;
    return (attendances || []).find(
      att =>
        att.event_id === eventId &&
        att.status === ATTENDANCE_STATUSES.REQUESTED,
    );
  };

  @action clearStore = async () => {
    this.anonUser = null;
    this.user = null;
    this.userProfile = null;
  };
}

export default new UserStore();
