import React from 'react';
import { Box, Flex, Heading, Image, Text, Link } from 'rebass';
import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { isEmpty, get, filter } from 'lodash';
import { css } from '@emotion/core';
import moment from 'moment';
import qs from 'qs';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import homeStore from '../stores/homeStore';
import IconDetail from '../components/IconDetail';
import { MOBILE_BREAKPOINT, size } from '../_styles/breakpoints';
import Chat from '../components/Chat/Chat';
import ChatRoomsList from '../components/ChatRooms/CharRoomsList';
import NewChatRoomList from '../components/ChatRooms/NewChatRoomList';
import Stream from './StreamContainer/Stream';
import theme from '../_styles/theme';
import StylingUtils from '../_app/utils/stylingUtils';
import { DocumentsList } from './DocumentsList/DocumentsList';
import routes from '../_app/routing/routes';
import {
  ATTENDANCE_STATUSES,
  POLL_INTERVAL,
  AGENDA_ITEM_TYPES,
  CHAT_ROOMS_INTERVAL,
} from '../_app/constants';
import { EventDoesntExist } from './EventDoesntExists/EventDoesntExist';
import { AgendaContainerMobile } from './AgendaContainer/AgendaContainerMobile';
import { SUBDOMAIN, markLinkAsTag, toHex } from '../_app/utils/otherUtils';
import { ChatIcon } from '../_assets/icons/chatIcon';
import { PollIcon } from '../_assets/icons/pollIcon';
import { Loader } from '../components/Loader';
import { SpeakerPopup } from '../signIn/components/components/Speaker';
import ChatPoll from '../components/ChatPoll';
import ZoomModalContent from '../components/BrowserCompatibility/Modals/ZoomModalContent';
import AgendaMain from '../components/AgendaMain';
import ScrolltoWrapper from '../components/ScrolltoWrapper';
import SpeakerMain from '../components/SpeakerMain';
import SponsorMain from '../components/SponsorMain';
import NetworkingMain from './NetworkingContainer';
import { ExploreItem } from './ExploreItems/ExploreItem';
import { ColumnLeft } from './ColumnLeft';
import { ColumnMiddle } from './ColumnMiddle';
import BigImageModal from './Modals/BigImageModal';

export const headerPadding = '75px';

const ExploreWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;

const MobileContainer = styled(Flex)`
  flex-direction: column;
  overflow-y: hidden;
  height: calc(var(--vh) - 65px);
`;

const MobileVideoPane = ({ children, minimized, ...props }) => (
  <Box
    css={css`
      position: relative;
      background: linear-gradient(to top, #2c343c, #1a1f25);
      ${minimized
        ? `
        height: 100%;
      `
        : `
        height: auto
      `}
    `}
    {...props}
  >
    {children}
  </Box>
);

const MobileDetailsPane = ({
  backgroundImage,
  hideTabbedSectionsBar,
  children,
  ...props
}) => (
  <Box
    css={css`
      position: relative;
      height: calc(
        var(--vh, 100vh) - 100vw * 9 / 16 -
          ${hideTabbedSectionsBar ? '65' : '100'}px
      );
      background-image: ${backgroundImage};

      @media screen and (orientation: landscape) {
        min-height: calc(100vh - 65px);
      }
    `}
    {...props}
  >
    {children}
  </Box>
);

const Container = styled(Flex)`
  height: 100vh;
  position: relative;
  overflow: hidden;
  ${StylingUtils.animationFadeIn()}
  @media (max-width: ${size.xxl}px) {
    /* height: -webkit-fill-available; */
    height: calc(var(--vh, 100vh));
  }
`;

const BottomImage = styled(Image)`
  position: absolute;
  z-index: -1; // to go under Documents Label
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    z-index: 0;
  }
`;

const ColRight = ({ children, gradientTop, gradientBottom }) => (
  <Box
    css={css`
      display: flex;
      flex-direction: column;
      width: 320px;
      height: 100vh;
      padding: calc(25px + ${headerPadding}) 10px 0 15px;
      overflow: hidden;
      background-image: linear-gradient(
        to bottom,
        ${gradientTop || theme.colors.backgroundDarker} 11%,
        ${gradientBottom || theme.colors.background}
      );
    `}
  >
    {children}
  </Box>
);

const LoaderWrapper = styled(Box)`
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
`;

const Separator = ({ children, color }) => (
  <Box
    css={css`
      height: 2px;
      background-color: ${color || '#000'};
      border-bottom: 1px solid
        ${StylingUtils.hexToRgbA(color || '#979797', 0.48)};
    `}
  >
    {children}
  </Box>
);

export const Disclaimer = styled(Text)`
  position: ${props => props.position};
  bottom: 1px;
  left: 0;
  width: 100%;
  padding: 31px 0 0px; // 15px
  text-align: left;
  z-index: 1;
  font-weight: ${theme.fontWeights.emph};
  font-size: 8px;
  white-space: pre-line;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 20px 15px 35px 15px;
    font-size: 10px;
  }
`;

export const TabRoundedInside = styled(Box)`
  background-color: ${theme.colors.card};
  border-radius: 5px;
  padding: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-radius: 0;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
  }
`;

const TabRoundedInsidesTitle = styled(Heading)`
  font-size: 26px;
  font-weight: ${theme.fontWeights.almostBold};
  color: ${theme.colors.textOnWhiteBg};
  font-family: inherit;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const BottomTabs = styled(Flex)`
  width: 100%;
  padding-top: 11px;
  justify-content: space-between;
  align-items: center;
`;

const TabListMobileWrapper = styled(Box)`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 100%;
    overflow-x: auto;
    background: ${({ bgColor }) => bgColor};
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const BottomTab = ({
  active,
  children,
  highlightColor,
  textColor,
  inactiveColor,
  ...props
}) => {
  const { fontWeights } = theme;
  return (
    <Box
      css={`
        color: ${active ? highlightColor : textColor};
        font-weight: ${fontWeights.emph};
        text-align: center;
        text-transform: uppercase;
        flex-grow: 1;
        height: 26px;
        cursor: pointer;

        ${active && highlightColor && inactiveColor
          ? `border-bottom: 1px solid ${highlightColor}`
          : `border-bottom: 1px solid ${inactiveColor}`};
      `}
      {...props}
    >
      {children}
    </Box>
  );
};

const TabContent = styled(Box)`
  height: calc(100% - 26px); // tabs switch height
  overflow: scroll;
`;

export const TabContainer = styled(Tabs)`
  width: 100%;
  ${({ h }) => h && `height: ${h}`};
`;

const Dot = styled(Box)`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-left: -10px;
  margin-right: 6px;
`;

const ScrollContent = ({ children, scrollColor, isMobile, ...props }) => (
  <Box
    css={css`
      overflow: auto;
      ${StylingUtils.customScroll(scrollColor)}
      max-height: calc(50vh - 92px);
    `}
    {...props}
  >
    {children}
  </Box>
);

const MOBILE_TABS = newChatRoomIds => [
  {
    label: 'Agenda',
    value: 'agenda',
  },
  {
    label: 'Chat',
    value: 'chat',
  },
  {
    label: (
      <>
        {newChatRoomIds && newChatRoomIds.length > 0 ? <Dot /> : ''}Chat Rooms
      </>
    ),
    value: 'chatRooms',
  },
  {
    label: 'Docs',
    value: 'docs',
  },
];

const getAttendance = (event, user) => {
  if (!event || !user) {
    return null;
  }

  const { attendances } = user;
  const { id } = event;

  return (attendances || []).find(att => att.event_id === id);
};

// COMMENTED AI autoswitch
// const CHECK_AIS_UPDATES_INTERVAL = 1000 * 20 * 1; // 20 seconds
@inject(
  'organizationsStore',
  'eventsStore',
  'eventBriefStore',
  'homeStore',
  'chatStore',
  'chatRoomsStore',
  'pollStore',
  'authStore',
  'userStore',
  'modalStore',
  'exploreItemsStore',
  'chatRoomsStore',
)
@observer
class Home extends React.Component {
  checkAIsUpdatesInterval = null;

  activeAITimeout = null;

  aiInterval = null;

  eventInterval = null;

  exploreItemInterval = null;

  constructor(props) {
    super(props);

    this.state = {
      activeMobileTab: MOBILE_TABS()[0],
      width: 0,
      showPollResults: false,
      chatExpanded: true,
      pollExpanded: true,
      exploreExpanded: true,
      selectedTabIndex: 0,
      mountedAlready: false,
      scrollTo: null,
      videoUrl: '',
      openAsVideoElement: false,
      chatRoomsExpanded: false,
      showScrollIndicator: true,
      newChatRoomExpanded: false,
    };

    this.chatContainerRef = React.createRef();
    this.containerRef = React.createRef();
  }

  async componentDidMount() {
    await this.updateWindowDimensions();

    window.addEventListener('resize', this.updateWindowDimensions);

    const {
      history,
      match: {
        params: { event_name: eventName },
      },
      location: { search },
      history: { push },
      eventBriefStore,
      eventBriefStore: { getEventBrief },
      eventsStore: { getEventTranslations },
      homeStore: { setActiveOrganization, setActiveEvent },
      organizationsStore: { getOrganizationBySlug },
      eventsStore: { markAttendance, events },
      chatStore: {
        setActiveChatRoomId,
        setActiveChatRoomName,
        setActiveChatNumberOfUsers,
      },
      chatRoomsStore: { getChatRooms },
      userStore: { getProfile, hasAcceptedAttendance },
      authStore: { isAuthenticated },
      exploreItemsStore: { getExploreItems },
    } = this.props;

    const { attendance, enter, tab, leaveZoomFrame } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    await getEventBrief({ eventId: eventName });
    const { event } = eventBriefStore;

    let shoudlSignUp = true;

    console.log('------------ HOME PAGE --------------');
    if (event) {
      // PUBLIC EVENT
      console.log({ event });
      console.log('PUBLIC: ', event.public);
      console.log('REGISTARTION ALLOWED: ', !event.hide_registration_form);
      console.log('AUTO ACCEPT: ', !event.auto_accept);
      console.log('ENTER: ', enter);
      console.log('IS AUTHENTICATED: ', isAuthenticated);

      const hideEventAccess =
        get(event, 'hide_registration_form') && get(event, 'hide_login_form');

      if (event.public) {
        if (enter && !hideEventAccess) {
          shoudlSignUp = false;
        }
      }

      // PRIVATE EVENT
      if (!event.public) {
        if (isAuthenticated) {
          await getProfile();
          if (hasAcceptedAttendance(event.id)) {
            shoudlSignUp = false;
          }
        }
      }
      console.log(
        'HAS ACCEPTED ATTTENDANCE: ',
        hasAcceptedAttendance(event.id),
      );
    }

    if (shoudlSignUp) {
      const url = `${routes.auth.signUp}/${eventName || ''}${search || ''}`;
      await history.push({
        pathname: url,
        search,
      });
    }
    if (attendance) {
      await markAttendance(attendance);
    }
    if (leaveZoomFrame === 'true') {
      const redirUrl = `${window.location.origin}${
        routes.home.event
      }/${eventName || ''}${enter ? '?enter=true' : ''}`;

      window.top.location.href = redirUrl; // zoom iframe redirect
    }
    const isOnEventPage = document.URL.includes('/event');
    const isOnRoot = window.location.pathname === '/';
    // to not trigger on other pages (signin, signup, etc.)
    if (isOnEventPage) {
      if (!isOnRoot || events.length === 1) {
        if (SUBDOMAIN) {
          await getOrganizationBySlug(SUBDOMAIN, async org => {
            await setActiveOrganization(org);
            // eslint-disable-next-line
            await setActiveEvent(eventName, async (ev, ai) => {
              if (get(ev, 'chat_group_enabled')) {
                this.chatRoomsResubscribe();
                await getChatRooms(ev.id);
              }
              setActiveChatRoomId(ev.chat_room_id || ai.chat_room_id);
              setActiveChatRoomName('GROUP CHAT');
              setActiveChatNumberOfUsers(ev.public ? '' : ev.users_count || '');

              getEventTranslations(ev.id);
              getExploreItems(ev.id);
              // also sets activeAgendaItem
              if (!eventName && ev) {
                push(`/event/${ev.slug}${search || ''}`);
              }
              if (tab) {
                this.setActiveMobileTab({ value: tab });
              }
            });
          });
        }
      }

      this.aiInterval = setInterval(this.refreshAI, 120000);
      this.eventInterval = setInterval(this.refreshEvent, 60000);
      this.exploreItemInterval = setInterval(this.refreshExploreItems, 60000);
    }

    this.setState({ mountedAlready: true });
  }

  componentDidUpdate() {
    if (homeStore.activeEventChanged) {
      homeStore.activeEventChanged = false;
      if (this.containerRef.current) {
        this.containerRef.current.scrollTop = 0;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedTabIndex: 0,
        showScrollIndicator: true,
        chatRoomsExpanded: false,
        newChatRoomExpanded: false,
        chatExpanded: true,
      });
    }
  }

  componentWillUnmount() {
    const {
      homeStore: { clearActiveEventStore },
      chatRoomsStore,
    } = this.props;

    window.removeEventListener('resize', this.updateWindowDimensions);

    clearInterval(this.checkAIsUpdatesInterval);
    clearInterval(this.aiInterval);
    clearInterval(this.eventInterval);
    clearInterval(this.exploreItemInterval);
    clearInterval(chatRoomsStore.chatRoomsIntervalHandler);
    chatRoomsStore.chatRoomsIntervalHandler = null;

    clearActiveEventStore();
  }

  chatRoomsResubscribe = () => {
    const {
      chatRoomsStore: { getChatRooms },
      chatRoomsStore,
      homeStore: { activeEvent },
    } = this.props;

    clearInterval(chatRoomsStore.chatRoomsIntervalHandler);

    chatRoomsStore.chatRoomsIntervalHandler = setInterval(
      () => getChatRooms(activeEvent.id, true),
      CHAT_ROOMS_INTERVAL,
    );
  };

  refreshExploreItems = async () => {
    const {
      homeStore: { activeEvent },
      exploreItemsStore: { getExploreItems },
    } = this.props;
    getExploreItems(activeEvent.id);
  };

  refreshEvent = async () => {
    const {
      homeStore: { refreshActiveEvent },
    } = this.props;
    refreshActiveEvent();
  };

  handleScrollHiding = () => {
    const {
      homeStore: { activeEvent },
    } = this.props;
    const { showScrollIndicator } = this.state;
    if (showScrollIndicator) this.setState({ showScrollIndicator: false });
    localStorage.setItem(activeEvent.id, true);
  };

  refreshAI = async () => {
    const {
      homeStore: { activeEvent, refreshAgendaItems },
    } = this.props;
    refreshAgendaItems(activeEvent);
  };

  checkForAgendaItemsUpdate = async () => {
    const {
      homeStore: {
        activeEvent,
        agendaItems,
        getEventAgendaItems,
        setActiveAgendaItem,
      },
      pollStore: { clearStore: clearPollStore },
    } = this.props;

    await getEventAgendaItems(activeEvent, {
      dontSet: true,
      skipLoader: true,
      callback: async freshAIs => {
        const liveAIs = freshAIs.filter(ai => ai.live);
        const oldLiveAIs = agendaItems.filter(ai => ai.live);

        const firstOldLiveAI = !isEmpty(oldLiveAIs) && oldLiveAIs[0];
        const firstNewLiveAI = !isEmpty(liveAIs) && liveAIs[0];

        // kinda naiv checking if new live AI has been set
        let newLiveAI = false;
        if (firstOldLiveAI && firstNewLiveAI) {
          newLiveAI = firstOldLiveAI.id !== firstNewLiveAI.id;
        }
        const anyImportantChangeFound =
          (!isEmpty(agendaItems) && agendaItems.length !== freshAIs.length) ||
          (liveAIs && oldLiveAIs && liveAIs.length !== oldLiveAIs.length) ||
          newLiveAI;

        if (anyImportantChangeFound) {
          await getEventAgendaItems(activeEvent, {
            callback: async () => {
              await clearPollStore();
              await setActiveAgendaItem();
            },
          });
        }
      },
    });
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight}px`,
    );
  };

  setActiveMobileTab = async activeMobileTab => {
    const {
      history: { push },
      // pollStore: { clearStore },
      homeStore: { activeEvent },
    } = this.props;

    if (isEmpty(activeEvent)) {
      return;
    }
    // if (activeMobileTab.value !== 'chat') {
    //   await clearStore(); // to make sure it's stopped fetching for polls
    // }

    this.setState({ activeMobileTab });
    push(`/event/${activeEvent.slug}?enter=true&tab=${activeMobileTab.value}`);
  };

  setActiveTabIndex = index => {
    const { videoUrl } = this.state;

    this.setState({ selectedTabIndex: index });
    if (!isEmpty(videoUrl) && index === 0) {
      this.setState({ videoUrl: '' });
    }
  };

  handleAgendaMoreButtonClick = el => {
    this.setState({ selectedTabIndex: 2, scrollTo: el });
  };

  handleAgendaInfoButtonClick = (e, el) => {
    e.stopPropagation();
    this.setState({ selectedTabIndex: 1, scrollTo: el });
  };

  changeActiveAgendaItem = async activeAgendaItemIdx => {
    this.setState({ selectedTabIndex: 0 });
    const {
      // pollStore: { clearStore: clearPollStore },
      homeStore: {
        activeOrganization,
        agendaItems: agendaItemsObsArr,
        activeAgendaItem: activeAgendaItemObs,
        setActiveAgendaItem,
      },
      modalStore: { setVisibility },
      eventBriefStore: { event },
    } = this.props;

    const { videoUrl } = this.state;

    const { buttons_bg: buttonsBg } = (event && event.event_custom_theme) || {};

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : theme.colors.textSecondary;

    const agendaItems = toJS(agendaItemsObsArr);
    const activeAgendaItem = toJS(activeAgendaItemObs);

    const selectedAgendaItem = agendaItems[activeAgendaItemIdx];

    if (selectedAgendaItem.id !== activeAgendaItem.id) {
      if (selectedAgendaItem.group) {
        const isIE = /Trident|MSIE/.test(navigator.userAgent);
        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent,
        );
        const linkIncludesZoom =
          selectedAgendaItem.group.zoom_link &&
          selectedAgendaItem.group.zoom_link.includes('zoom.us');
        if (
          (isIE || isSafari) &&
          linkIncludesZoom &&
          !selectedAgendaItem.group.open_in_new_window
        ) {
          setVisibility(
            true,
            <ZoomModalContent
              primaryColor={toHex(buttonsBg) || primaryColor}
              zoomUrl={selectedAgendaItem.group.zoom_link}
              closeModal={() => setVisibility(false)}
            />,
          );

          return;
        }
      }
      if (!isEmpty(videoUrl)) {
        this.setState({ videoUrl: '' });
      }
      // await clearPollStore();
      await setActiveAgendaItem(selectedAgendaItem);
    }
  };

  handleOnBubbleClick = (speaker, e) => {
    e.stopPropagation();
    const {
      homeStore: { activeEvent, activeOrganization },
      modalStore: { setVisibility },
    } = this.props;

    const { isMobile } = this.state;

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : theme.colors.textSecondary;

    const { speakers_popup_header_img: headerImg } =
      (activeEvent && activeEvent.event_custom_theme) || {};

    setVisibility(
      true,
      <SpeakerPopup
        primaryColor={primaryColor}
        image={get(speaker, 'image')}
        job={speaker.job}
        name={speaker.name}
        companyName={speaker.company_name}
        bio={speaker.bio}
        headerImg={get(headerImg, 'url')}
        onClose={() => setVisibility(false)}
        isMobile={isMobile}
      />,
    );
  };

  setAIAutoswitch = agendaItem => {
    const {
      homeStore: { agendaItems, setActiveAgendaItem },
      pollStore: { clearStore: clearPollStore },
    } = this.props;
    clearTimeout(this.activeAITimeout);
    this.activeAITimeout = null;

    if (agendaItem) {
      const { end_date: endDate, start_date: startDate } = agendaItem;
      const timeLeftToEnd = moment(endDate).diff(moment(), 'miliseconds');
      const timeLeftToStart = moment(startDate).diff(moment(), 'miliseconds');

      if (timeLeftToStart <= 0 && timeLeftToEnd >= 0) {
        this.activeAITimeout = setTimeout(async () => {
          const aiIdx = agendaItems.findIndex(ai => ai.id === agendaItem.id);
          const nextAI =
            agendaItems.length - 1 >= aiIdx
              ? agendaItems[aiIdx + 1]
              : agendaItem;
          await clearPollStore();
          await setActiveAgendaItem(nextAI);
          if (nextAI) {
            await this.setAIAutoswitch(nextAI);
          }
        }, timeLeftToEnd);
      }
    }
  };

  handleSendVote = async vote => {
    const {
      homeStore: { activeEvent },
      pollStore: { sendVote, getPollQuestions },
      pollStore,
    } = this.props;
    await sendVote(vote);

    await clearInterval(pollStore.pollQuestionIntervalHandler);
    await getPollQuestions(activeEvent);
    pollStore.pollQuestionIntervalHandler = setInterval(
      () => getPollQuestions(activeEvent),
      POLL_INTERVAL,
    );
  };

  zoomOpenInNewTabLink = (item, color, anyActiveItem, backToItem) => {
    const {
      homeStore: { setActiveAgendaItem },
    } = this.props;
    if (item && item.group && item.agenda_type === 'breakout_room') {
      const {
        zoom_link: zoomLink,
        open_in_new_window: openInNewWidow,
      } = item.group;
      if (zoomLink && zoomLink.includes('zoom.us') && !openInNewWidow) {
        return (
          <Flex
            css={css`
              align-items: center;
              justify-content: center;
              width: 100%;
            `}
            onClick={() => setActiveAgendaItem(anyActiveItem || backToItem)}
          >
            <Link
              variant="nav"
              target="_blank"
              css={css`
                text-decoration: none;
                font-size: 14px;
                color: ${color};

                &:hover {
                  text-decoration: none;
                  color: ${color};
                }
              `}
              href={zoomLink}
            >
              Pop out to Zoom app
            </Link>
          </Flex>
        );
      }
      return null;
    }
    return null;
  };

  setVideoUrl = (url, openAsVideoElement = false) => {
    if (url) {
      this.setState({ videoUrl: url, openAsVideoElement });
    }
  };

  handleVideoBackButton = () => {
    this.setState({ videoUrl: '' });
    this.setActiveTabIndex(0);
  };

  setStateScrollTo = () => {
    this.setState({ scrollTo: null });
  };

  setStateChatExpanded = () => {
    this.setState(() => ({
      chatRoomsExpanded: false,
      chatExpanded: true,
    }));
  };

  handleShowBigImage = ({ imgUrl }) => {
    const {
      modalStore: { setVisibility },
    } = this.props;
    console.log('test', imgUrl);
    if (imgUrl) {
      setVisibility(
        true,
        <BigImageModal
          onClose={() => setVisibility(false)}
          imageUrl={imgUrl}
        />,
        true,
      );
    }
  };

  render() {
    const {
      activeMobileTab,
      width,
      showPollResults,
      pollExpanded,
      chatExpanded,
      exploreExpanded,
      selectedTabIndex,
      mountedAlready,
      scrollTo,
      videoUrl,
      openAsVideoElement,
      chatRoomsExpanded,
      showScrollIndicator,
      newChatRoomExpanded,
    } = this.state;
    const {
      history,
      location: { search },
      homeStore: {
        activeOrganization,
        agendaItems,
        activeAgendaItem,
        activeEvent,
        documents,
        noEventFoundFlag,
        // isLoading,
        isLoadingAgenda,
        isLoadingDocuments,
        setActiveAgendaItem,
        sponsors,
        videoReactions,
      },
      userStore: { user },
      // authStore: { logout },
      pollStore: { pollQuestions },
      chatStore: {
        activeChatRoomId,
        activeChatRoomUsers,
        activeChatRoomName,
        chatRoomNumberOfUsers,
      },
      exploreItemsStore: { exploreItem },
      chatStore: {
        setActiveChatRoomId,
        setActiveChatRoomName,
        setActiveChatNumberOfUsers,
      },
      chatRoomsStore: { getRoomUsers, newChatRoomIds },
      // pollStore,
    } = this.props;
    const chatEnabled = get(activeEvent, 'chat_enabled', false);
    const chatMode = get(activeEvent, 'chat_mode', 'global');
    const agendaColumnEnabled = get(activeEvent, 'agenda_column_enabled');
    const docsPanelEnabled = get(activeEvent, 'document_panel_enabled');
    const chatRoomsEnabled = get(activeEvent, 'chat_group_enabled');
    const mainChatRoomId = get(activeEvent, 'chat_room_id');

    const showAgendaItemSpeakers = get(
      activeEvent,
      'show_agenda_item_speakers',
      false,
    );

    const streamEl = document.getElementById('stream-frame');
    let enableDesktopAgenda = true;
    if (streamEl) {
      if (streamEl.src.includes('zoomMeetingOnly')) {
        enableDesktopAgenda = false;
      } else {
        enableDesktopAgenda = true;
      }
    }

    let backToItem;
    let anyActiveItem;
    let backButtonOnBreakoutRoom = false;
    if (agendaItems && activeAgendaItem) {
      const activeIndex = agendaItems
        .map(function(x) {
          return x.id;
        })
        .indexOf(activeAgendaItem.id);
      [backToItem] = agendaItems
        .slice(0, activeIndex)
        .filter(el => el.agenda_type !== 'breakout_room')
        .slice(-1);
      [anyActiveItem] = agendaItems.filter(
        el =>
          moment(el.end_date).diff(moment(), 'miliseconds') >= 0 &&
          moment(el.start_date).diff(moment(), 'miliseconds') <= 0 &&
          el.agenda_type !== 'breakout_room',
      );
      if (
        activeAgendaItem &&
        activeAgendaItem.group &&
        activeAgendaItem.agenda_type === 'breakout_room'
      ) {
        const {
          zoom_link: zoomLink,
          open_in_new_window: openInNewWidow,
        } = activeAgendaItem.group;
        if (zoomLink && !zoomLink.includes('zoom.us') && !openInNewWidow) {
          backButtonOnBreakoutRoom = true;
        }
      }
    }

    if (
      activeEvent &&
      !activeEvent.public &&
      !activeEvent.auto_accept &&
      get(getAttendance(activeEvent, user), 'status') !==
        ATTENDANCE_STATUSES.ACCEPTED
    ) {
      // FIXME: When is it supposed to happen?
      // logout({
      //   onSuccess: () => {
      history.push({
        pathname: `${routes.auth.signIn}/${get(activeEvent, 'slug', '')}`,
        search: search || '',
        state: {
          initMsg: 'You have not yet been granted access to this event.',
        },
      });
      //   },
      // });
    }

    if (noEventFoundFlag) {
      return <EventDoesntExist />;
    }

    if (isEmpty(activeEvent) && !noEventFoundFlag) {
      return null;
    }

    const primaryColor =
      activeOrganization && activeOrganization.dominant_color
        ? toHex(activeOrganization.dominant_color)
        : theme.colors.textSecondary;

    const hasCustomStyle =
      activeEvent && !isEmpty(activeEvent.event_custom_theme);

    const finalTheme = StylingUtils.mergeThemesArray([
      get(activeEvent, 'event_custom_theme'),
      get(activeAgendaItem, 'agenda_item_custom_theme'),
    ]);

    const toggleChatRooms = () => {
      if (!chatExpanded) {
        setActiveChatRoomId(mainChatRoomId);
        setActiveChatRoomName('GROUP CHAT');
        setActiveChatNumberOfUsers(get(activeEvent, 'users_count'));
      }

      this.setState(state => ({
        chatRoomsExpanded: !state.chatRoomsExpanded,
        chatExpanded: !state.chatExpanded,
      }));
    };

    const toggleBackNewChatRooms = () => {
      this.setState(() => ({
        chatExpanded: false,
        chatRoomsExpanded: true,
        newChatRoomExpanded: false,
      }));
    };

    const toggleNewChatRoom = showNewChatRoom => {
      this.setState(() => ({
        chatExpanded: !showNewChatRoom,
        chatRoomsExpanded: false,
        newChatRoomExpanded: showNewChatRoom,
      }));
    };

    const {
      _ai_style: aiStyle,
      ai_bckg_active: aiBckgActive,
      agenda_timeline: agendaTimeline,
      agenda_timeline_dots: agendaTimelineDots,
      breakout_item_bckg: breakoutItemBckg,
      breakout_item_text: breakoutItemText,
      bottom_img: bottomImg,
      bottom_img_file: bottomImgFile,
      bottom_img_mobile_file: bottomImgMobileFile,
      buttons_bg: buttonsBg,
      buttons_text_color: buttonsTextColor,
      chat_avatar: chatAvatar,
      chat_emotes_enabled: chatEmotesEnabled,
      disclaimer,
      disclaimer_text: disclaimerText,
      disclaimer_text_mobile: disclaimerTextMobile,
      document_container_bckg: documentContainerBg,
      document_name_text_color: documentNameTextColor,
      event_listing_buttons_bg: eventListingButtonsBg,
      icons: iconsColor,
      label_text: labelText,
      main_gradient1: mainGradient1,
      main_gradient2: mainGradient2,
      poll_answer_text: pollAnswerText,
      poll_bckg: pollBckg,
      poll_answer_bckg: pollAnswerBckg,
      poll_text: pollText,
      body1,
      hide_icons: hideIcons,
      hide_tabbed_sections_bar: hideTabbedSectionsBar,
      chat_msg_bg: chatMsgBg,
      chat_msg_bg_active: chatMsgBgActive,
      chat_text: chatText,
      chat_primary: chatPrimary,
      no_content_text: noContentText,
      sidebar_scroll: sidebarScroll,
      text_secondary: textSecondary,
      text_primary: textPrimary,
      reactions_bckg_color: reactionsBckg,
      tabs_text_color: tabsTextColor,
      tabs_highlight_color: tabsHighlightColor,
      tabs_separator_color: tabsSeparatorColor,
      header_profile_border: headerProfileBorder,
      back_button_color: eventBackButtonColor,
      agenda_details_title_color: agendaDetailsTitleColor,
      agenda_details_body_color: agendaDetailsBodyColor,
    } = finalTheme || {};

    const documentStyle = {
      documentContainerBg: toHex(documentContainerBg),
      documentNameTextColor: toHex(documentNameTextColor) || toHex(textPrimary),
      primaryColor: toHex(buttonsBg) || primaryColor,
      buttonsTextColor: toHex(buttonsTextColor),
    };

    const chatStyle = {
      bgColor: toHex(chatMsgBg),
      bgColorActive: toHex(chatMsgBgActive),
      text: toHex(chatText),
      iconColor: toHex(iconsColor) || toHex(chatPrimary) || primaryColor,
      primary: toHex(chatPrimary),
      avatarBckg: toHex(chatAvatar) || toHex(chatPrimary) || primaryColor,
      chatButtonsBg: toHex(buttonsBg) || primaryColor,
      reactionsBgColor: toHex(reactionsBckg),
      avatarBorder: toHex(headerProfileBorder) || primaryColor || '#ccc',
      chatEmotesEnabled,
      buttonsTextColor: toHex(buttonsTextColor) || '#fff',
    };

    const pollStyle = {
      pollAnswerText: toHex(pollAnswerText),
      pollBckg: toHex(pollBckg),
      pollAnswerBckg: toHex(pollAnswerBckg),
      pollText: toHex(pollText),
    };

    const agendaTimelineStyle = {
      background: toHex(agendaTimeline),
    };

    const agendaItemStyle = {
      eventListingButtonsBg: toHex(eventListingButtonsBg),
      buttonsBg: toHex(buttonsBg),
      hideTabbedSectionsBar,
      orgPrimaryColor: primaryColor,
      type: aiStyle,
      bgColor: toHex(aiBckgActive),
      text: toHex(textSecondary),
      textHighlighted: toHex(textPrimary),
      dot: toHex(agendaTimelineDots),
    };

    const agendaDetailsStyle = {
      agendaTitleTextColor:
        toHex(agendaDetailsTitleColor) || theme.colors.textGrey,
      agendaBodyTextColor:
        toHex(agendaDetailsBodyColor) || theme.colors.textGrey,
    };

    const exploreItemStyle = {
      buttonsBg: toHex(buttonsBg),
      text: toHex(textSecondary),
    };

    const bubbleStyle = {
      border: toHex(textPrimary) || '#fff',
    };

    const breakoutItemStyle = {
      type: aiStyle,
      text: toHex(breakoutItemText),
      bgColor: toHex(breakoutItemBckg),
      textHighlighted: toHex(textPrimary),
      dot: toHex(agendaTimelineDots),
      iconsColor: (hasCustomStyle && toHex(iconsColor)) || primaryColor,
    };

    const amIModerator =
      activeEvent &&
      user &&
      activeEvent.chat_mode === 'with_moderator_only' &&
      activeEvent.chat_moderator_id === user.id;

    let isArchived = false;
    if (!isEmpty(agendaItems)) {
      const lastAI = agendaItems[agendaItems.length - 1];
      isArchived = moment(lastAI.end_date).isBefore(moment(), 'minute');
    }

    const isMainChat = mainChatRoomId === activeChatRoomId;
    let chatTitle = 'CHAT';
    const roomsTitle = newChatRoomExpanded
      ? 'startChatWith'
      : activeChatRoomName;
    if (chatMode === 'with_moderator_only') {
      chatTitle = amIModerator
        ? 'CHAT_WITH_MODERATOR_FOR_MODERATOR'
        : 'CHAT_WITH_MODERATOR';
    }

    let backButtonColor;
    if (eventBackButtonColor) {
      backButtonColor = toHex(eventBackButtonColor);
    } else if (textPrimary) {
      if (
        StylingUtils.isWhite(toHex(textPrimary)) &&
        StylingUtils.isWhite(toHex(body1))
      ) {
        if (textSecondary) {
          backButtonColor = toHex(textSecondary);
        } else {
          backButtonColor = theme.colors.textDark;
        }
      } else {
        backButtonColor = toHex(textPrimary);
      }
    } else if (StylingUtils.isWhite(toHex(body1))) {
      backButtonColor = theme.colors.textDark;
    } else {
      backButtonColor = 'white';
    }

    if (hideTabbedSectionsBar && selectedTabIndex !== 0) {
      this.setActiveTabIndex(0);
    }

    let showChatInput = false;
    if (agendaItems.length > 0) {
      showChatInput = moment().isBefore(
        moment(agendaItems[agendaItems.length - 1].end_date).add(1, 'hours'),
      );
    }

    const networkingAvailable =
      !activeEvent.public && activeEvent.networking_available;

    const iframeBreakoutRoom =
      activeAgendaItem &&
      activeAgendaItem.group &&
      !activeAgendaItem.group.open_in_new_window;

    return width > MOBILE_BREAKPOINT ? (
      <Container>
        {!mountedAlready || isEmpty(activeEvent) ? (
          <LoaderWrapper>
            <Loader />
            <Text
              fontSize={2}
              color={StylingUtils.hexToRgbA(theme.colors.text, 0.5)}
              lineHeight="1.5em"
              textAlign="center"
            >
              <FormattedMessage id="loader.streamloading" />
            </Text>
          </LoaderWrapper>
        ) : (
          <>
            {agendaColumnEnabled && enableDesktopAgenda && (
              <ColumnLeft
                mainGradient1={mainGradient1}
                mainGradient2={mainGradient2}
                labelText={labelText}
                hideIcons={hideIcons}
                primaryColor={primaryColor}
                hasCustomStyle={hasCustomStyle}
                iconsColor={iconsColor}
                isArchived={isArchived}
                showScrollIndicator={showScrollIndicator}
                containerRef={this.containerRef}
                activeEvent={activeEvent}
                handleScrollHiding={this.handleScrollHiding}
                setActiveTabIndex={this.setActiveTabIndex}
                handleAgendaInfoButtonClick={this.handleAgendaInfoButtonClick}
                agendaTimelineStyle={agendaTimelineStyle}
                agendaItemStyle={agendaItemStyle}
                bubbleStyle={bubbleStyle}
                breakoutItemStyle={breakoutItemStyle}
                chatStyle={chatStyle}
                isLoadingAgenda={isLoadingAgenda}
                agendaItems={agendaItems}
                activeAgendaItem={activeAgendaItem}
                changeActiveAgendaItem={this.changeActiveAgendaItem}
                handleOnBubbleClick={this.handleOnBubbleClick}
                showAgendaItemSpeakers={showAgendaItemSpeakers}
              />
            )}
            <ColumnMiddle
              hideTabbedSectionsBar={hideTabbedSectionsBar}
              chatEnabled={chatEnabled}
              agendaColumnEnabled={agendaColumnEnabled}
              enableDesktopAgenda={enableDesktopAgenda}
              body1={body1}
              disclaimer={disclaimer}
              backButtonOnBreakoutRoom={backButtonOnBreakoutRoom}
              backButtonColor={backButtonColor}
              setActiveAgendaItem={setActiveAgendaItem}
              anyActiveItem={anyActiveItem}
              backToItem={backToItem}
              videoUrl={videoUrl}
              bottomImgFile={bottomImgFile}
              bottomImg={bottomImg}
              selectedTabIndex={selectedTabIndex}
              iconsColor={iconsColor}
              primaryColor={primaryColor}
              agendaItems={agendaItems}
              activeEvent={activeEvent}
              sponsors={sponsors}
              networkingAvailable={networkingAvailable}
              openAsVideoElement={openAsVideoElement}
              isArchived={isArchived}
              exploreItem={exploreItem}
              exploreItemStyle={exploreItemStyle}
              user={user}
              activeAgendaItem={activeAgendaItem}
              disclaimerText={disclaimerText}
              docsPanelEnabled={docsPanelEnabled}
              labelText={labelText}
              hideIcons={hideIcons}
              hasCustomStyle={hasCustomStyle}
              iframeBreakoutRoom={iframeBreakoutRoom}
              documentStyle={documentStyle}
              isLoadingDocuments={isLoadingDocuments}
              documents={documents}
              noContentText={noContentText}
              sidebarScroll={sidebarScroll}
              scrollTo={scrollTo}
              buttonsTextColor={buttonsTextColor}
              buttonsBg={buttonsBg}
              chatRoomsEnabled={chatRoomsEnabled}
              chatPrimary={chatPrimary}
              chatAvatar={chatAvatar}
              videoReactions={videoReactions}
              avatarBg={chatStyle.avatarBckg}
              handleShowBigImage={this.handleShowBigImage}
              handleVideoBackButton={this.handleVideoBackButton}
              setActiveTabIndex={this.setActiveTabIndex}
              zoomOpenInNewTabLink={this.zoomOpenInNewTabLink}
              handleAgendaMoreButtonClick={this.handleAgendaMoreButtonClick}
              setVideoUrl={this.setVideoUrl}
              setStateScrollTo={this.setStateScrollTo}
              setStateChatExpanded={this.setStateChatExpanded}
              agendaDetailsStyle={agendaDetailsStyle}
            />
            {chatEnabled && (
              <Box position="relative">
                <ColRight
                  gradientBottom={toHex(mainGradient2)}
                  gradientTop={toHex(mainGradient1)}
                >
                  {!isEmpty(pollQuestions) && (
                    <Box mb={3}>
                      <IconDetail
                        fontColor={toHex(labelText)}
                        icon={
                          !hideIcons && (
                            <PollIcon
                              fill={
                                (hasCustomStyle && toHex(iconsColor)) ||
                                primaryColor
                              }
                              width="28px"
                              height="18px"
                            />
                          )
                        }
                        iconMR="2px"
                        mt={-1}
                        ml={hideIcons && 3}
                        text="POLL"
                        isArchived={isArchived}
                        onToggle={() => {
                          this.setState(state => ({
                            pollExpanded: !state.pollExpanded,
                          }));
                        }}
                        expanded={pollExpanded}
                        toggleColor={
                          (hasCustomStyle && toHex(iconsColor)) || primaryColor
                        }
                      />
                      {pollExpanded && (
                        <ScrollContent
                          scrollColor={toHex(chatPrimary) || primaryColor}
                        >
                          {pollQuestions.map((pollQ, idx) => (
                            <ChatPoll
                              key={`chat-poll-${idx}`}
                              showResults={showPollResults}
                              poll={pollQ}
                              sendVote={this.handleSendVote}
                              style={pollStyle}
                            />
                          ))}
                        </ScrollContent>
                      )}
                    </Box>
                  )}
                  {exploreItem && exploreItem.show_side_explore && (
                    <Box mb="3">
                      <IconDetail
                        fontColor={toHex(labelText)}
                        icon={
                          !hideIcons && (
                            <OpenInNewIcon
                              style={{
                                color:
                                  (hasCustomStyle && toHex(iconsColor)) ||
                                  primaryColor,
                              }}
                            />
                          )
                        }
                        iconMR="5px"
                        mt={-1}
                        ml={hideIcons && 3}
                        text="EXPLORE"
                        isArchived={isArchived}
                        onToggle={() => {
                          this.setState(state => ({
                            exploreExpanded: !state.exploreExpanded,
                          }));
                        }}
                        expanded={exploreExpanded}
                        toggleColor={
                          (hasCustomStyle && toHex(iconsColor)) || primaryColor
                        }
                      />
                      {exploreExpanded && (
                        <ExploreWrapper>
                          <ExploreItem
                            exploreItem={exploreItem}
                            exploreItemStyle={exploreItemStyle}
                            user={user}
                            activeEvent={activeEvent}
                          />
                        </ExploreWrapper>
                      )}
                    </Box>
                  )}
                  {!chatRoomsEnabled && chatEnabled && (
                    <IconDetail
                      fontColor={toHex(labelText)}
                      icon={
                        !hideIcons && (
                          <ChatIcon
                            fill={
                              (hasCustomStyle && toHex(iconsColor)) ||
                              primaryColor
                            }
                            width="18px"
                            height="18px"
                          />
                        )
                      }
                      mt={-2}
                      ml={hideIcons && 3}
                      text={chatTitle}
                      isArchived={isArchived}
                      onToggle={() => {
                        this.setState(state => ({
                          chatExpanded: !state.chatExpanded,
                        }));
                      }}
                      expanded={chatExpanded}
                      toggleColor={
                        (hasCustomStyle && toHex(iconsColor)) || primaryColor
                      }
                    />
                  )}
                  {chatRoomsEnabled && chatEnabled && (
                    <IconDetail
                      fontColor={toHex(labelText)}
                      icon={
                        !hideIcons && (
                          <ChatIcon
                            fill={
                              (hasCustomStyle && toHex(iconsColor)) ||
                              primaryColor
                            }
                            width="18px"
                            height="18px"
                          />
                        )
                      }
                      mt="-5px"
                      ml={hideIcons && 3}
                      withoutTranslation
                      text={roomsTitle}
                      chatStyle={chatStyle}
                      chatRoomId={activeChatRoomId}
                      chatUsers={activeChatRoomUsers}
                      chatRoomNumberOfUsers={
                        !(activeEvent?.public && isMainChat) &&
                        chatRoomNumberOfUsers
                      }
                      newChatRoomExpanded={newChatRoomExpanded}
                      isArchived={isArchived}
                      getRoomUsers={getRoomUsers}
                      chatRoomsExpanded={chatRoomsExpanded}
                      onToggle={() => {
                        if (newChatRoomExpanded) {
                          toggleBackNewChatRooms();
                        } else {
                          toggleChatRooms();
                        }
                      }}
                      expanded={
                        chatRoomsExpanded || newChatRoomExpanded || !isMainChat
                      }
                      expandSide
                      toggleColor={
                        (hasCustomStyle && toHex(iconsColor)) || primaryColor
                      }
                      numberOfUsersColor={
                        (hasCustomStyle && toHex(buttonsBg)) || primaryColor
                      }
                      newChatRoomIds={newChatRoomIds}
                    />
                  )}
                  {activeAgendaItem &&
                    chatRoomsExpanded &&
                    chatRoomsEnabled &&
                    chatEnabled && (
                      <ChatRoomsList
                        networkingAvailable={networkingAvailable}
                        chatStyle={chatStyle}
                        toggleChatRooms={() =>
                          this.setState(() => ({
                            chatRoomsExpanded: false,
                            chatExpanded: true,
                          }))
                        }
                        eventId={activeEvent.id}
                        activeAgendaItem={activeAgendaItem}
                        toggleNewChatRoom={toggleNewChatRoom}
                        numberOfUsersColor={
                          (hasCustomStyle && toHex(buttonsBg)) || primaryColor
                        }
                        channelId={
                          activeEvent.chat_room_id ||
                          activeAgendaItem.chat_room_id
                        }
                      />
                    )}
                  {activeAgendaItem &&
                    chatRoomsEnabled &&
                    chatEnabled &&
                    newChatRoomExpanded && (
                      <NewChatRoomList
                        chatStyle={chatStyle}
                        toggleNewChatRoom={() => toggleNewChatRoom()}
                        eventId={activeEvent.id}
                        activeAgendaItem={activeAgendaItem}
                        channelId={
                          activeEvent.chat_room_id ||
                          activeAgendaItem.chat_room_id
                        }
                      />
                    )}
                  {activeAgendaItem && chatExpanded && (
                    <Chat
                      chatRoomNumberOfUsers={chatRoomNumberOfUsers}
                      activeChatRoomName={activeChatRoomName}
                      chatScrollParentRef={this.chatContainerRef}
                      showInput={showChatInput}
                      chatStyle={chatStyle}
                      eventId={activeEvent.id}
                      activeAgendaItem={activeAgendaItem}
                      channelId={activeChatRoomId}
                      disableTaggingAutosuggest={
                        chatMode === 'with_moderator_only'
                      }
                    />
                  )}
                </ColRight>
              </Box>
            )}
          </>
        )}
      </Container>
    ) : (
      <MobileContainer id="container">
        <MobileVideoPane minimized={selectedTabIndex !== 0}>
          <TabContainer
            selectedIndex={selectedTabIndex}
            onSelect={this.setActiveTabIndex}
            h="100%"
          >
            <TabListMobileWrapper
              bgColor={toHex(mainGradient1) || '#101119'}
              className={hideTabbedSectionsBar && 'hidden'}
            >
              <TabList className="react-tabs__tab-list top-tabs-bg">
                <Tab className="react-tabs__tab tabs-border nowrap">
                  <Box>
                    <FormattedMessage id="icon.DETAILS" />
                  </Box>
                </Tab>

                {!isEmpty(agendaItems) && (
                  <Tab className="react-tabs__tab tabs-border nowrap">
                    <Box>
                      <FormattedMessage id="icon.AGENDA_DETAILS" />
                    </Box>
                  </Tab>
                )}

                {activeEvent && !isEmpty(activeEvent.speakers) && (
                  <Tab className="react-tabs__tab tabs-border nowrap">
                    <Box>
                      <FormattedMessage id="icon.SPEAKERS" />
                    </Box>
                  </Tab>
                )}

                {!isEmpty(sponsors) && (
                  <Tab className="react-tabs__tab tabs-border nowrap">
                    <Box>
                      <FormattedMessage id="icon.SPONSORS" />
                    </Box>
                  </Tab>
                )}
                {networkingAvailable && (
                  <Tab className="react-tabs__tab tabs-border">
                    <Box>
                      <FormattedMessage id="icon.NETWORKING" />
                    </Box>
                  </Tab>
                )}
              </TabList>
            </TabListMobileWrapper>

            <TabPanel forceRender>
              <Stream
                videoUrl={videoUrl}
                openAsVideoElement={openAsVideoElement}
                isArchived={isArchived}
                isLoading={false}
                isMobile
                exploreItem={exploreItem}
                exploreItemStyle={exploreItemStyle}
                user={user}
                videoReactions={videoReactions}
                avatarBg={chatStyle.avatarBckg}
              />
            </TabPanel>

            {!isEmpty(agendaItems) && (
              <TabPanel>
                <TabRoundedInside id="tab-panel--agendaItems">
                  <ScrolltoWrapper
                    id="tab-panel--agendaItems"
                    scrollTo={scrollTo}
                    clearScroll={() => this.setState({ scrollTo: null })}
                  >
                    {agendaItems.map((agendaItem, id) => {
                      if (
                        agendaItem.agenda_type ===
                          AGENDA_ITEM_TYPES.BREAKOUT_ROOM &&
                        agendaItem.group === null
                      ) {
                        return null;
                      }

                      const agendaItemSpeakers = [];
                      const agendaItemSponsors = [];
                      agendaItem.event_speaker_ids.forEach(evSpeaker => {
                        if (activeEvent.speakers) {
                          const matchedSpeaker = activeEvent.speakers.find(
                            x => x.id === evSpeaker,
                          );
                          if (matchedSpeaker)
                            agendaItemSpeakers.push(matchedSpeaker);
                        }
                      });
                      agendaItem.sponsor_ids.forEach(evSponsor => {
                        if (sponsors) {
                          const matchedSponsor = sponsors.find(
                            x => x.id === evSponsor,
                          );
                          if (matchedSponsor)
                            agendaItemSponsors.push(matchedSponsor);
                        }
                      });
                      return (
                        <AgendaMain
                          id={agendaItem.id}
                          first={!id}
                          key={`agendaItem-${id}`}
                          agendaItemSpeakers={agendaItemSpeakers}
                          agendaItemSponsors={agendaItemSponsors}
                          color={toHex(buttonsBg) || toHex(primaryColor)}
                          buttonsTextColor={toHex(buttonsTextColor)}
                          agendaDetailsStyle={agendaDetailsStyle}
                          last={agendaItems.length === id + 1}
                          handleAgendaMoreButtonClick={
                            this.handleAgendaMoreButtonClick
                          }
                          {...agendaItem}
                        />
                      );
                    })}
                  </ScrolltoWrapper>
                </TabRoundedInside>
              </TabPanel>
            )}

            {activeEvent && !isEmpty(activeEvent.speakers) && (
              <TabPanel className="react-tabs__tab-panel react-tabs__tab-panel--speakers">
                <TabRoundedInside id="tab-panel--speakers">
                  <ScrolltoWrapper
                    id="tab-panel--speakers"
                    scrollTo={scrollTo}
                    clearScroll={() => this.setState({ scrollTo: null })}
                  >
                    <TabRoundedInsidesTitle>
                      <FormattedMessage id="label.speakers" />
                    </TabRoundedInsidesTitle>
                    {activeEvent.speakers.map((speaker, id) => (
                      <SpeakerMain
                        key={`speaker-${id}`}
                        {...speaker}
                        last={activeEvent.speakers.length === id + 1}
                        primaryColor={primaryColor}
                        handleShowBigImage={this.handleShowBigImage}
                      />
                    ))}
                  </ScrolltoWrapper>
                </TabRoundedInside>
              </TabPanel>
            )}

            {!isEmpty(sponsors) && (
              <TabPanel>
                <TabRoundedInside>
                  <TabRoundedInsidesTitle>
                    <FormattedMessage id="label.sponsors" />
                  </TabRoundedInsidesTitle>
                  {sponsors.map((sponsor, id) => (
                    <SponsorMain key={`sponsor-${id}`} {...sponsor} />
                  ))}
                </TabRoundedInside>
              </TabPanel>
            )}
            {networkingAvailable && (
              <TabPanel
                data-scrollable="middle-column"
                selectedClassName="react-tabs__tab-panel--selected unlock-overflow"
              >
                <NetworkingMain
                  changeActiveTab={() => this.setActiveTabIndex(0)}
                  changeMobileTab={() =>
                    this.setActiveMobileTab({
                      value: 'chat',
                    })
                  }
                  activeEvent={activeEvent}
                  chatRoomsEnabled={chatRoomsEnabled}
                  buttonsMainColor={toHex(buttonsBg) || toHex(primaryColor)}
                  buttonsTextColor={toHex(buttonsTextColor)}
                  avatarColor={
                    toHex(chatAvatar) || toHex(chatPrimary) || primaryColor
                  }
                />
              </TabPanel>
            )}
          </TabContainer>
        </MobileVideoPane>
        {selectedTabIndex === 0 && (
          <MobileDetailsPane
            backgroundImage={
              mainGradient1 && mainGradient2
                ? `linear-gradient(to bottom,
                ${toHex(mainGradient1)}, ${toHex(mainGradient2)}
              )`
                : 'linear-gradient(to bottom, #101119, #1a1f25)'
            }
            hideTabbedSectionsBar={hideTabbedSectionsBar}
          >
            <BottomImage src={bottomImgMobileFile && bottomImgMobileFile.url} />
            <Separator color={toHex(mainGradient1)} />
            <BottomTabs>
              {filter(MOBILE_TABS(newChatRoomIds), tab => {
                if (tab.value === 'chat' && !chatEnabled) {
                  return false;
                }
                if (tab.value === 'chatRooms' && !chatRoomsEnabled) {
                  return false;
                }
                if (tab.value === 'agenda' && !agendaColumnEnabled) {
                  return false;
                }
                if (tab.value === 'docs' && !docsPanelEnabled) {
                  return false;
                }
                return true;
              }).map((tab, idx) => (
                <BottomTab
                  key={`menu-tab-${idx}`}
                  fontSize={2}
                  active={activeMobileTab.value === tab.value}
                  onClick={() => this.setActiveMobileTab(tab)}
                  highlightColor={toHex(tabsHighlightColor) || '#fff'}
                  inactiveColor={toHex(tabsSeparatorColor) || 'black'}
                  textColor={toHex(tabsTextColor) || '#fff'}
                >
                  {tab.label}
                </BottomTab>
              ))}
            </BottomTabs>

            <TabContent ref={this.chatContainerRef}>
              {activeMobileTab.value === 'agenda' && agendaColumnEnabled && (
                <AgendaContainerMobile
                  playFunc={this.setActiveTabIndex}
                  infoFunc={this.handleAgendaInfoButtonClick}
                  isLoading={isLoadingAgenda}
                  agendaItems={agendaItems}
                  activeAgendaItem={activeAgendaItem}
                  customStyles={{
                    agendaTimelineStyle,
                    agendaItemStyle,
                    bubbleStyle,
                    breakoutItemStyle,
                    chatStyle,
                  }}
                  onSetActive={this.changeActiveAgendaItem}
                  onBubbleClick={this.handleOnBubbleClick}
                  showSpeakers={showAgendaItemSpeakers}
                />
              )}
              {activeMobileTab.value === 'chat' && activeAgendaItem && (
                <>
                  {!isEmpty(pollQuestions) && (
                    <Box ml="20px" mr="20px">
                      {pollQuestions.map((pollQ, idx) => (
                        <ChatPoll
                          key={`chat-poll-${idx}`}
                          showResults={showPollResults}
                          poll={pollQ}
                          sendVote={this.handleSendVote}
                          style={pollStyle}
                        />
                      ))}
                    </Box>
                  )}
                  {exploreItem && (
                    <Box mb="3">
                      <ExploreWrapper>
                        <ExploreItem
                          exploreItem={exploreItem}
                          exploreItemStyle={exploreItemStyle}
                          isMobile
                          user={user}
                          activeEvent={activeEvent}
                        />
                      </ExploreWrapper>
                    </Box>
                  )}
                  <Chat
                    activeChatRoomName={activeChatRoomName}
                    chatRoomNumberOfUsers={chatRoomNumberOfUsers}
                    chatScrollParentRef={this.chatContainerRef}
                    showInput={showChatInput}
                    eventId={activeEvent.id}
                    chatStyle={chatStyle}
                    activeAgendaItem={activeAgendaItem}
                    channelId={activeChatRoomId}
                    disableTaggingAutosuggest={
                      chatMode === 'with_moderator_only'
                    }
                    isMobile
                  />
                </>
              )}
              {activeMobileTab.value === 'chatRooms' &&
                activeAgendaItem &&
                chatRoomsEnabled && (
                  <>
                    {!newChatRoomExpanded ? (
                      <ChatRoomsList
                        networkingAvailable={networkingAvailable}
                        chatStyle={chatStyle}
                        toggleChatRooms={() =>
                          this.setState(() => ({
                            chatRoomsExpanded: false,
                            chatExpanded: true,
                          }))
                        }
                        eventId={activeEvent.id}
                        activeAgendaItem={activeAgendaItem}
                        changeMobileTab={() =>
                          this.setActiveMobileTab({
                            value: 'chat',
                          })
                        }
                        toggleNewChatRoom={toggleNewChatRoom}
                        channelId={
                          activeEvent.chat_room_id ||
                          activeAgendaItem.chat_room_id
                        }
                        numberOfUsersColor={
                          (hasCustomStyle && toHex(buttonsBg)) || primaryColor
                        }
                      />
                    ) : (
                      <NewChatRoomList
                        isMobile
                        chatScrollParentRef={this.chatContainerRef}
                        chatStyle={chatStyle}
                        toggleNewChatRoom={() => toggleNewChatRoom()}
                        changeMobileTab={() =>
                          this.setActiveMobileTab({
                            value: 'chat',
                          })
                        }
                        eventId={activeEvent.id}
                        activeAgendaItem={activeAgendaItem}
                        channelId={
                          activeEvent.chat_room_id ||
                          activeAgendaItem.chat_room_id
                        }
                        backOnMobile={() =>
                          this.setState({
                            newChatRoomExpanded: false,
                          })
                        }
                      />
                    )}
                  </>
                )}
              {activeMobileTab.value === 'docs' && docsPanelEnabled && (
                <DocumentsList
                  iframeBreakoutRoom={iframeBreakoutRoom}
                  handleVideoClick={this.setVideoUrl}
                  documentStyle={documentStyle}
                  isMobile
                  isLoading={isLoadingDocuments}
                  documents={documents}
                  noContentTextColor={noContentText}
                  scrollColor={toHex(sidebarScroll)}
                />
              )}
              {disclaimer && (
                <Disclaimer
                  position="relative"
                  color={
                    toHex(disclaimerTextMobile) || theme.colors.textSemiVisible
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: markLinkAsTag(disclaimer),
                    }}
                  />
                </Disclaimer>
              )}
            </TabContent>
          </MobileDetailsPane>
        )}
      </MobileContainer>
    );
  }
}

export default Home;
