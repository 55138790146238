import { TextField, withStyles } from '@material-ui/core';
import React from 'react';
import Box from '@material-ui/core/Box';
import styled from '@emotion/styled';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import theme from '../../_styles/theme';
import { FormError } from './FormError';
import StylingUtils from '../../_app/utils/stylingUtils';

const FieldWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: inline-block;
`;

const IconWrapper = styled(Box)`
  position: absolute;
  right: 0;
  top: 5px;

  ${StylingUtils.animationFadeIn()}
`;

const styles = () => ({
  textFieldWhite: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  inputWhite: {
    color: 'white',
  },
});

const TextInput = ({
  meta,
  label,
  input,
  inputType,
  width,
  classes,
  inputProps,
  autocomplete,
  labelColor,
  styledWhite,
  disabledIcons,
  maxLength,
  ...props
}) => (
  <Box>
    <Box position="relative" width="100%">
      {label && (
        <label
          htmlFor={typeof label === 'string' ? label : label?.props?.id}
          style={{
            opacity: 0.7,
            fontSize: theme.fontSizes[1],
            color: labelColor || theme.colors.textDarkSecondary,
          }}
        >
          {label}
        </label>
      )}
      <FieldWrapper>
        {!disabledIcons && (
          <>
            {meta && meta.touched && (meta.error || meta.submitError) && (
              <IconWrapper color={theme.colors.error}>
                <HighlightOffIcon />
              </IconWrapper>
            )}
            {meta && meta.touched && !meta.error && !meta.submitError && (
              <IconWrapper color={theme.colors.success}>
                <CheckCircleOutlineIcon />
              </IconWrapper>
            )}
          </>
        )}
        <TextField
          id={typeof label === 'string' ? label : label?.props?.id}
          className={styledWhite && classes.textFieldWhite}
          fullWidth
          label={' '}
          onChange={input.onChange}
          value={input.value}
          type={inputType || 'text'}
          inputProps={{
            ...inputProps,
            ...(autocomplete && {
              autoComplete: autocomplete,
            }),
            ...(styledWhite && { className: classes.inputWhite }),
            maxLength,
          }}
          {...props}
        />
      </FieldWrapper>
    </Box>
    {meta && meta.touched && (meta.error || meta.submitError) && (
      <Box mt={1}>
        <FormError meta={meta} />
      </Box>
    )}
  </Box>
);

export default withStyles(styles)(TextInput);
