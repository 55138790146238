import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Text } from 'rebass';
import styled from '@emotion/styled';

const Container = styled(Flex)`
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
`;

class ErrorPage extends Component {
  render() {
    const { errorInfo } = this.props;

    return (
      <Container>
        <Text fontSize={4}>
          <FormattedMessage id="errors.error" />
        </Text>
        <Text fontSize={1}>
          <FormattedMessage id="errors.detail" values={{ errorInfo }} />
        </Text>
      </Container>
    );
  }
}

export default ErrorPage;
