import React from 'react';
import { Flex, Text } from 'rebass';
import { css } from '@emotion/core';
import Box from '@material-ui/core/Box';
import styled from '@emotion/styled';
import theme from '../../_styles/theme';
import { MOBILE_BREAKPOINT } from '../../_styles/breakpoints';
import StylingUtils from '../../_app/utils/stylingUtils';

const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 70px 44px 40px 77px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 30px 0;
  }
`;
const TitleContainer = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    padding: 0 20px 20px;
  }
`;
const OrganizationContainerTitle = ({ children, fontFamily, color }) => (
  <Text
    css={css`
      color: ${!color ? '#fff' : color};
      font-family: ${fontFamily};
      font-size: ${theme.fontSizes[5]}px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 1.86px;
      padding-bottom: 20px;
      text-transform: uppercase;
      width: 100%;
    `}
  >
    {children}
  </Text>
);

const OrganizationContainer = styled(Box)`
  z-index: 2;
  width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: auto;
  }
  ${StylingUtils.customScroll(theme.colors.primary)}
`;

const OrganizationDescription = ({ children, color, linkColor }) => (
  <Text
    css={css`
      color: ${!color ? '#fff' : color};
      font-size: ${theme.fontSizes[2]}px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: 1px;
      padding-bottom: 13px;
      width: 100%;
      a {
        color: ${linkColor || theme.colors.text};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    `}
    dangerouslySetInnerHTML={{
      __html: children,
    }}
  />
);

export const OrganizationBrief = ({
  orgName,
  orgDescription,
  themeElements: { headerFontFamily, briefTextHex, buttonsBg },
}) => {
  return (
    <OrganizationContainer>
      <Container>
        <TitleContainer>
          <Box flex="1 0 0" pr="20px">
            <OrganizationContainerTitle
              color={briefTextHex}
              fontFamily={headerFontFamily || theme.fonts.body}
            >
              {orgName}
            </OrganizationContainerTitle>
            <OrganizationDescription color={briefTextHex} linkColor={buttonsBg}>
              {orgDescription && orgDescription}
            </OrganizationDescription>
          </Box>
        </TitleContainer>
      </Container>
    </OrganizationContainer>
  );
};
