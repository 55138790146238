/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { FormattedMessage } from 'react-intl';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import theme from '../_styles/theme';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';


const Container = styled(Flex)`
  flex-direction: column;
  // background-color: ${theme.colors.bgLight};
  width: 100%;
  min-height: 100vh;
  // height: auto;
  // padding: 0 40px;
  line-height: 1.5em;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0;
  }
`;

// const Title = styled(Box)`
//   text-transform: uppercase;
//   font-weight: ${theme.fontWeights.body};
//   font-size: ${theme.fontSizes[6]}px;
//   text-align: center;
//   padding-top: 70px;
// `

const SideShadow = styled(Box)`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 40%;
  z-index: 0;
  // background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000000 78%);
  // background-image: linear-gradient( to right,rgba(57,68,79,0),rgba(32,39,46,0.8) );
`;

const Content = styled(Box)`
  background-color: #151c32;
  padding: 25px 0 0px;
  position: relative;
  z-index: 1;
  // height: 100vh;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 45px 0px 0;
  }
`;

// const Ul = styled.ul`
//   list-style: disc;
//   padding-left: 25px;
// `;

// const Ull = styled.ul`
//   list-style: circle;
//   padding-left: 25px;
// `;

// const Bold = styled.b`
//   margin: 20px 0;
//   font-weight: 700;
// `;


class TermsPage extends Component {

  // component not used ATM - instead redirect user to cplus.live/terms.html - see Footer.js
  constructor() {
    super();
    this.frameRef = React.createRef();
    this.state = {
      iFrameHeight: '0px',
    }
  }

  render() {
    const { iFrameHeight } = this.state
    return (
      <Container>
        <Content>
          {/* <Title>Terms & Conditions</Title> */}
          <iframe
            title="T&Cs"
            src="/terms/terms.html"
            style={{width: '100%', height: iFrameHeight}}
            ref={this.frameRef}
            height={iFrameHeight}
            onLoad={() => {
              // eslint-disable-next-line react/no-find-dom-node
              const frame = this.frameRef.current;
              // eslint-disable-next-line no-console
              console.log('FRAME: ', frame);
              this.setState({
                iFrameHeight: `${frame.contentWindow.document.body.scrollHeight}px`,
              })
            }}
          />
          {/* optanon-cookie-policy already provided by iframe content */}
          {/* <div id="optanon-cookie-policy" style={{margin: "0px 40px"}}/> */}
        </Content>
        <SideShadow />
      </Container>
    );
  }
}

export default TermsPage;
