import React, { Component } from 'react';
import { Flex, Text } from 'rebass';
import { withStyles } from '@material-ui/core';
import styled from '@emotion/styled';
import Box from '@material-ui/core/Box';

import StylingUtils from '../../_app/utils/stylingUtils';
import routes from '../../_app/routing/routes';

const styles = () => ({
  shruggingEmoji: {
    height: '8em!important',
    width: '8em!important',
  },
});

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 65px);

  ${StylingUtils.animationFadeIn()}
`;

const StyledText = styled(Text)`
  color: #fff;
`;

class NotFoundPage extends Component {
  componentDidMount = async () => {
    const {
      match: {
        params: { whatever },
      },
      location: { search },
    } = this.props;
    if (whatever) {
      window.location.href = `${routes.home.event}/${whatever}${search}`;
    }
  };

  render = () => {
    const {
      classes,
      match: {
        params: { whatever },
      },
    } = this.props;
    if (whatever) {
      return null;
    }
    return (
      <Container>
        <Box>
          <img
            draggable="false"
            className={classes.shruggingEmoji}
            alt="🤷"
            src="https://twemoji.maxcdn.com/v/13.0.1/svg/1f937.svg"
          />
          <StyledText mt={2} mb={2} fontSize={6}>
            404 Page Not Found
          </StyledText>
          <StyledText fontSize={4}>Please check URL provided.</StyledText>
        </Box>
      </Container>
    );
  };
}

export default withStyles(styles)(NotFoundPage);
