import React from 'react';
import { Flex, Text } from 'rebass';
import styled from '@emotion/styled';
import Box from '@material-ui/core/Box';
import StylingUtils from '../../_app/utils/stylingUtils';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 65px);

  ${StylingUtils.animationFadeIn()}
`;
export const EventDoesntExist = () => {
  return (
    <Container>
      <Box>
        <Text mb={2} fontSize={4}>
          Event not found
        </Text>
        <Text fontSize={2}>Please check provided URL</Text>
      </Box>
    </Container>
  );
};
