import last from 'lodash/last';

export const getLocalTimezone = () => {
  try {
    const date = new Date();
    const string = date.toLocaleTimeString(undefined, {
      timeZoneName: 'short',
    });
    return last(string.split(' '));
  } catch (e) {
    return '';
  }
};
