import React from 'react';
import { animated, useSpring } from 'react-spring';

export const AnimateExpand = ({ children, finalHeight, styles }) => {
  const { height } = useSpring({
    from: { height: 0 },
    to: { height: finalHeight },
  });

  return <animated.div style={{ height, ...styles }}>{children}</animated.div>;
};
